import React from "react"
import { useTranslation } from "react-i18next"
import { Pressable, StyleSheet, View } from "react-native"
import { useStyle } from "react-native-style-utilities"

import {
  AlertBodyText,
  AlertCard,
  AppText,
  Badge,
  Box,
  CancelButton,
  CardHeader,
  Link,
  Row,
  Spinner,
  useIsDarkMode,
} from "./components"
import { AppIcons } from "./components/icons"
import { ListItemTextSecondary } from "./components/ListItem"
import { testIds } from "./components/test-id"
import { COLORS, SIZES, SPACING } from "./components/theme"
import { RADII } from "./components/theme/radii"
import { useClipboard } from "./components/useClipboard"
import { useFormatLatCheckInText } from "./format-relative-time"
import { InstallationTypeIndicator } from "./InstallationTypeIndicator"
import { getGrafanaUrlForDevice } from "./Internal"
import { canScheduleActions } from "./named-device-actions.reducer"
import { RunProgressBar } from "./RunProgressBar"
import i18n from "./translations/i18n"
import { VfdIndicatorLarge } from "./VfdIndicator"

import type { ViewProps } from "react-native"
import type {
  AcceptsChildren,
  AppTextProps,
  BadgeProps,
  BoxProps,
  NoChildren,
  RowProps,
} from "./components"
import type { DeviceSummary } from "./device-configurations.reducer"
import type {
  DeviceStatusData,
  SensorStatusBadges,
  StatusBadgeProps,
} from "./device-event-last.reducer"
import type { TargetDatabaseName } from "./Internal"
import type { InstallationType } from "./sensor-configurations"
import type { SensorState } from "./sensor-events"

import type * as Models from "./models"
/**
 *
 */
export function StatusBadge({
  colorScheme = "$gray",
  iconName,
  id,
  text,
  ...rest
}: StatusBadgeProps) {
  if (typeof text === "string") {
    return (
      <Badge
        style={{ marginVertical: SIZES.$1 }}
        {...testIds(id)}
        StartIconComponent={AppIcons[iconName]}
        colorScheme={colorScheme}
        {...rest}
      >
        {text}
      </Badge>
    )
  }
  return null
}

/**
 * Display the last check in time
 */
export function LastCheckInText({
  deviceEventTimestamp,
  ...rest
}: NoChildren<AppTextProps> & {
  deviceEventTimestamp: string | undefined
}) {
  const formatLastCheckIn = useFormatLatCheckInText()
  return (
    <ListItemTextSecondary fontSize="$sm" {...rest}>
      {formatLastCheckIn({ deviceEventTimestamp })}
    </ListItemTextSecondary>
  )
}
export function DeviceUpdating(props: AcceptsChildren) {
  const { t } = useTranslation("devices")
  return (
    <View>
      {props.children}
      <Row>
        <Box mr="$2">
          <Spinner />
        </Box>
        <AppText colorScheme="secondary" flex={1}>
          {t("waitingForUpdate")}
        </AppText>
      </Row>
    </View>
  )
}
/**
 * Display the device name and a loading spinner if it is updating
 */
export function DeviceNameText({
  _textProps,
  codaDeviceAlias,
  deviceName,
  isUpdating,
  ...rest
}: BoxProps & {
  codaDeviceAlias: string
  deviceName: string
  isUpdating: boolean
  _textProps?: AppTextProps
}) {
  let element = (
    <React.Fragment>
      <AppText {..._textProps} isTruncated>
        {deviceName}
      </AppText>
      {codaDeviceAlias === deviceName ? null : (
        <AppText isTruncated colorScheme="secondary" fontSize="$xs">
          {codaDeviceAlias}
        </AppText>
      )}
    </React.Fragment>
  )
  if (isUpdating) {
    element = <DeviceUpdating>{element}</DeviceUpdating>
  }
  return <Box {...rest}>{element}</Box>
}

/**
 * Only shown for admin users
 */
function AdminInfoText(props: AppTextProps) {
  return <ListItemTextSecondary fontSize="$xs" {...props} />
}

/**
 * Only shown for admin users
 */
export function AdminInfo({
  deviceId,
  firmwareVersion,
  hardwareGeneration,
  ...rest
}: Pick<DeviceSummary, "deviceId" | "firmwareVersion" | "hardwareGeneration"> &
  ViewProps) {
  const { onCopy } = useClipboard()
  return (
    <View {...rest}>
      <Row>
        <AdminInfoText>{`HW: ${hardwareGeneration}`}</AdminInfoText>
        <Box mr="$2">
          <AdminInfoText>{`FW: ${firmwareVersion ?? "null"}`}</AdminInfoText>
        </Box>
      </Row>
      <Pressable onPress={() => onCopy(deviceId)}>
        <AdminInfoText>{`ID: ${deviceId}`}</AdminInfoText>
      </Pressable>
    </View>
  )
}

/**
 * Display Show the next scheduled action
 */
export function NextScheduledActionText({
  deviceInstallationType,
  nextScheduledAction,
  ...rest
}: BoxProps & {
  deviceInstallationType: InstallationType
  nextScheduledAction: Models.ScheduledDeviceAction | null | undefined
}) {
  const { t } = useTranslation("schedules")

  let text: string | undefined
  if (nextScheduledAction) {
    text = t("nextScheduleActionWithTimestamp", {
      hour: "numeric",
      minute: "numeric",
      // weekday: "auto",
      timestamp: new Date(
        // TODO: we multiply this by 1000 because it is in seconds but
        // that hopefully will change to become a string
        typeof nextScheduledAction.executeAtTs === "number"
          ? nextScheduledAction.executeAtTs * 1000
          : nextScheduledAction.executeAtTs,
      ),
    })
  } else if (canScheduleActions(deviceInstallationType)) {
    text = t("noScheduledActions", { ns: "common" })
  }

  if (typeof text === "string") {
    return (
      <Row {...rest}>
        <Box mr="$2">
          <AppIcons.Clock size={14} />
        </Box>
        <AppText colorScheme="secondary" fontSize="$sm">
          {text}
        </AppText>
      </Row>
    )
  }
  return null
}

export function BatteryCriticalWarning({
  batteryState,
  ...rest
}: BoxProps & {
  batteryState: SensorState<"battery"> | null | undefined
}) {
  if (batteryState === "BES") {
    return (
      <AlertCard
        py="$1.5"
        severity="error"
        titleText={i18n.t("warning")}
        {...rest}
      >
        <AlertBodyText colorScheme="darkText" fontSize="$sm">
          {i18n.t("sensorStates:BES.long")}
        </AlertBodyText>
      </AlertCard>
    )
  }
  return null
}

export function DeviceOfflineBadge(props: RowProps) {
  return (
    <Row {...props}>
      <Box mr="$2">
        <AppIcons.DeviceOffline />
      </Box>
      <AppText colorScheme="secondary">
        {i18n.t("devices:deviceOffline")}
      </AppText>
    </Row>
  )
}

/**
 * Display badges in this order
 */
const SENSOR_STATUS_BADGE_ORDER: Array<keyof SensorStatusBadges> = [
  "batteryVoltageExternal",
  "batteryStateOfChargeInternal",
  "relay",
  "device",
  "gps",
  "flow",
  "hallSwitch",
  "icm",
  "pressure",
  "pressureSwitch",
  "gpsHeading",
  "reel",
  "wheel",
  "runEta",
  "temperature",
]
/**
 * Display badges in this order
 */
export function StatusBadges({
  badgeProps,
  badges,
  deviceStatus,
  omit,
  ...rest
}: NoChildren<BoxProps> & {
  badges: SensorStatusBadges
  deviceStatus: DeviceStatusData["deviceStatus"]
  badgeProps?: Omit<BadgeProps, "id">
  omit?: Array<keyof SensorStatusBadges> | keyof SensorStatusBadges
}) {
  if (deviceStatus === "offline") {
    return (
      <Box m="auto" py="$2" {...rest}>
        <DeviceOfflineBadge />
      </Box>
    )
  }

  // filter out undefined badges so that the don't interfere with spacing
  let toRender = SENSOR_STATUS_BADGE_ORDER.filter((key) => Boolean(badges[key]))

  const omitArray = typeof omit === "string" ? [omit] : omit
  // Remove omitted badges if necessary
  if (omitArray) {
    toRender = toRender.filter((key) => {
      if (omitArray.includes(key)) {
        return false
      }
      if (key === "vfdStatus") {
        return false
      }
      return true
    })
  }
  if (toRender.length === 0) {
    return null
  }

  return (
    <React.Fragment>
      <Row {...rest} alignItems="baseline" flex={1} mb="$2" mx="$-1">
        {toRender.map((key): React.JSX.Element | null => {
          const badge = badges[key] as StatusBadgeProps | undefined
          if (badge) {
            return (
              <Box key={key} mx="$1">
                <StatusBadge {...badge} {...badgeProps} />
              </Box>
            )
          }
          return null
        })}
      </Row>
      {badges.vfdStatus ? <VfdIndicatorLarge {...badges.vfdStatus} /> : null}
    </React.Fragment>
  )
}

const styles = StyleSheet.create({
  commentDisplay: {
    alignItems: "center",
    backgroundColor: COLORS.$background.light,
    borderColor: COLORS.$gray[300],
    borderRadius: RADII.$default,
    borderWidth: 0.5,
    display: "flex",
    flexDirection: "row",
    flexWrap: "nowrap",
    justifyContent: "flex-start",
    paddingHorizontal: SPACING.$2,
    paddingVertical: SPACING.$2,
  },
})

/**
 * Display a device comment
 */
export function DeviceCommentDisplay({
  contentText,
  createDate,
  createdByUser: { email, nameFamily, nameGiven },
  deleteButtonElement,
  editButtonElement,
  id,
  ...rest
}: Models.DeviceComment &
  Omit<RowProps, "id"> & {
    deleteButtonElement?: React.JSX.Element
    editButtonElement?: React.JSX.Element
  }) {
  const { t } = useTranslation()
  const isDark = useIsDarkMode()
  const fontSize = "$sm"

  const createdByElement = (
    <Row minW="$16">
      <Box mr="$2">
        <AppText colorScheme="secondary" fontSize={fontSize}>
          {typeof nameGiven === "string" && typeof nameFamily === "string"
            ? `${nameGiven} ${nameFamily}`
            : email ?? ""}
        </AppText>
      </Box>
      <AppText colorScheme="secondary" fontSize={fontSize}>
        {t("datetimeWithVal", {
          dateStyle: "short",
          timeStyle: "short",
          val: new Date(createDate),
        })}
      </AppText>
    </Row>
  )

  return (
    <Row
      key={id}
      style={useStyle(() => {
        return [
          styles.commentDisplay,
          isDark
            ? {
                backgroundColor: COLORS.$background.dark,
              }
            : {},
        ]
      }, [isDark])}
      {...rest}
    >
      <AppIcons.Comment />
      <Box flex={1} minW="$24" mx="$2">
        <AppText fontSize={fontSize}>{contentText}</AppText>
        {createdByElement}
      </Box>
      <Row>
        <Box mr="$2">{editButtonElement}</Box>
        {deleteButtonElement}
      </Row>
    </Row>
  )
}

/**
 * Shows device name and current status
 */
export function DeviceListItem({
  badges,
  batteryState,
  codaDeviceAlias,
  commentActive,
  deviceEventTimestamp,
  deviceId,
  deviceInstallationType,
  deviceName,
  deviceStatus,
  firmwareVersion,
  hardwareGeneration,
  index,
  isUpdating,
  nextScheduledAction,
  runCompletion,
  showAdminInfo,
  showComment,
  showMetadata = true,
  targetDatabaseName,
  ...rest
}: BoxProps &
  DeviceStatusData & {
    index: number
    showAdminInfo: boolean
    showComment: boolean
    targetDatabaseName: TargetDatabaseName
    showMetadata?: boolean
  }): React.JSX.Element {
  return (
    <Box id={`devices-list-item-${index}`} py="$2" {...rest}>
      {showMetadata ? (
        <Row alignItems="flex-start" justifyContent="space-between">
          <DeviceNameText
            codaDeviceAlias={codaDeviceAlias}
            deviceName={deviceName}
            flex={1}
            isUpdating={isUpdating}
            style={{ minWidth: 200 }}
          />
          <InstallationTypeIndicator value={deviceInstallationType} />
        </Row>
      ) : null}
      <Box my="$1">
        <LastCheckInText
          deviceEventTimestamp={deviceEventTimestamp}
          fontSize="$xs"
        />
      </Box>
      {showAdminInfo ? (
        <AdminInfo
          deviceId={deviceId}
          firmwareVersion={firmwareVersion}
          hardwareGeneration={hardwareGeneration}
        />
      ) : null}
      {showComment && commentActive ? (
        <Box mb="$2">
          <DeviceCommentDisplay {...commentActive} />
        </Box>
      ) : null}
      <StatusBadges
        badges={badges}
        deviceStatus={deviceStatus}
        omit={["runEta", "batteryStateOfChargeInternal"]}
      />

      <BatteryCriticalWarning batteryState={batteryState} />

      <NextScheduledActionText
        deviceInstallationType={deviceInstallationType}
        nextScheduledAction={nextScheduledAction}
      />
      <Row w="$full">
        <Box flexGrow={1} mr="$2">
          {runCompletion ? <RunProgressBar {...runCompletion} /> : null}
        </Box>
        {badges.runEta ? <StatusBadge {...badges.runEta} /> : null}
      </Row>
      {showAdminInfo ? (
        <Box ml="auto" mt="$2">
          <Pressable>
            <Link
              text="Open in Grafana"
              to={getGrafanaUrlForDevice({
                codaDeviceAlias,
                targetDatabaseName,
              })}
            />
          </Pressable>
        </Box>
      ) : null}
    </Box>
  )
}

export function NoDeviceLocation({
  onClose,
  ...rest
}: BoxProps & {
  onClose: () => void
}) {
  // TODO: Link to set permanent location

  return (
    <Box p="$4" {...rest}>
      <CardHeader
        IconComponent="LocationPinned"
        titleText={i18n.t("statusMap:locateDevice.noLocationTitle")}
      />
      <Box mx="auto">
        <AppText>{i18n.t("statusMap:locateDevice.noLocationMessage")}</AppText>
      </Box>
      <Box mt="$2">
        <CancelButton onPress={onClose} />
      </Box>
    </Box>
  )
}
