import React from "react"
import { useTranslation } from "react-i18next"

import { setAdminMode } from "./async-storage"
import {
  AppIcons,
  Box,
  ListItem,
  ListItemContainer,
  ListItemTextPrimary,
  Pressable,
  Row,
  Switch,
  useThemeProviderContext,
} from "./components"
import { prepareDemoEnvironmentAsync } from "./farmhq-api"
import { logger } from "./logger"
import { useExitDemoButton } from "./PrepareDemoButton"
import { getIsAdminModeEnabledFromState } from "./selectors"
import { useErrorHandler } from "./useErrorHandler"
import { useRootDispatch } from "./useRootDispatch"
import { useRootSelector } from "./useRootSelector"

/**
 *
 */
export function ThemeToggle(): React.JSX.Element {
  const { t } = useTranslation("account")
  const { colorMode, onColorModeChanged } = useThemeProviderContext()
  return (
    <ListItem
      IconComponent="ThemeLightDark"
      id="toggle-theme-btn"
      text={t("darkModeToggle")}
      EndComponent={
        <Switch
          value={colorMode === "dark"}
          onValueChange={(isDark) =>
            onColorModeChanged(isDark ? "dark" : "light")
          }
        />
      }
    />
  )
}

export function AdminModeToggleItem() {
  const isAdminModeEnabled = useRootSelector(getIsAdminModeEnabledFromState)
  const dispatch = useRootDispatch()
  const { t } = useTranslation("account")
  return (
    <ListItemContainer>
      <Row w="$full">
        <Box mr="$2">
          <AppIcons.Admin />
        </Box>
        <ListItemTextPrimary>{t("adminModeToggle")}</ListItemTextPrimary>
        <Box ml="auto">
          <Switch
            value={isAdminModeEnabled}
            onValueChange={(nextValue) => {
              dispatch(setAdminMode(nextValue)).catch((e) => {
                logger.error(e)
                throw e
              })
            }}
          />
        </Box>
      </Row>
    </ListItemContainer>
  )
}
export function LoadDemoListItem({ onSuccess }: { onSuccess: () => void }) {
  const dispatch = useRootDispatch()
  const handleError = useErrorHandler()

  const handlePress = () => {
    dispatch(prepareDemoEnvironmentAsync())
      .then(onSuccess)
      .catch((error) => handleError(error, { toastMessage: "default" }))
  }
  return (
    <Pressable onPress={handlePress}>
      <ListItem
        EndComponent={<AppIcons.ListItemEnd />}
        IconComponent="Truck"
        text="Load Demo"
      />
    </Pressable>
  )
}

export function ExitDemoListItem(): React.JSX.Element {
  const { onPress, text } = useExitDemoButton()
  return (
    <Pressable onPress={onPress}>
      <ListItem
        EndComponent={<AppIcons.ListItemEnd />}
        IconComponent="SignOut"
        text={text}
      />
    </Pressable>
  )
}
