import convert from "convert-units"
import React from "react"
import { Controller } from "react-hook-form"

import { Box, FormControl, Row } from "./components"
import * as CreateDeviceConfiguration from "./CreateDeviceConfigurationContext"
import { useFormValidation } from "./form-validation"
import { useMeasurementPreference } from "./selectors"
import { useSensorUnitLabel } from "./sensor-formatting"
import { SingleValueSlider } from "./sliders"
import { isValidNumber } from "./type-guards"

const DEGREES_VALUE_MAX = 359
const DEGREES_VALUE_MIN = 0
const RADIUS_METERS_MAX = 600

const RADIUS_FEET_MAX = Math.round(
  convert(RADIUS_METERS_MAX).from("m").to("ft"),
)
const RADIUS_METERS_MIN = 1

export function RadiusInput() {
  const { form } = CreateDeviceConfiguration.useContext()
  const { integerPattern, maxValue, minValue, required } = useFormValidation()

  const isMetric = useMeasurementPreference() === "metric"
  const getUnitLabel = useSensorUnitLabel()

  let maximumRadius = RADIUS_FEET_MAX
  if (isMetric) {
    maximumRadius = RADIUS_METERS_MAX
  }

  const labelText = `Radius (${getUnitLabel("pivotRadiusMeters")})`

  return (
    <Controller
      control={form.control}
      name="pivotRadiusMeters"
      render={({ field, fieldState }) => {
        const errorMessage = fieldState.error?.message
        return (
          <FormControl.Provider
            id="radius-input"
            isInvalid={Boolean(errorMessage)}
          >
            <FormControl.Label>{labelText}</FormControl.Label>
            <Row>
              <Box flex={1} mr="$4">
                <SingleValueSlider
                  maximumValue={maximumRadius}
                  minimumValue={RADIUS_METERS_MIN}
                  value={field.value ?? 0}
                  onValueChange={(nextValue) => {
                    return field.onChange(nextValue)
                  }}
                />
              </Box>
              <Box w="$20">
                <FormControl.Input
                  keyboardType="number-pad"
                  returnKeyType="done"
                  value={`${field.value ?? ""}`}
                  onChangeText={(nextValue) => {
                    const asNumber = Number(nextValue)
                    if (isValidNumber(asNumber)) {
                      return field.onChange(asNumber)
                    }
                    return field.onChange(nextValue)
                  }}
                />
              </Box>
            </Row>
            <FormControl.ErrorMessage>{errorMessage}</FormControl.ErrorMessage>
          </FormControl.Provider>
        )
      }}
      rules={{
        max: maxValue(maximumRadius),
        min: minValue(RADIUS_METERS_MIN),
        pattern: integerPattern,
        required,
      }}
    />
  )
}

/**
 * Start azimuth input
 */
export function AzimuthStartInput() {
  const labelText = "Start Angle"
  const { form } = CreateDeviceConfiguration.useContext()
  const {
    integerPattern,
    maxValue,
    minValue,
    // required
  } = useFormValidation()

  return (
    <Controller
      control={form.control}
      name="pivotPathStopsHeadingsDegrees"
      render={({ field, fieldState }) => {
        const errorMessage = fieldState.error?.message
        const index = 0
        const currentValue = Math.round((field.value ?? [])[index] ?? 0)

        // Update the first value in the array (start azimuth)
        const onChange = (nextValue: number) => {
          const copied = [...(field.value ?? [])]
          copied[index] = nextValue

          // Make sure that there are two values in the array
          // if the user starts changing the angles
          if (typeof copied[1] === "undefined") {
            copied[1] = 0
          }
          return field.onChange(copied)
        }
        return (
          <FormControl.Provider isInvalid={Boolean(errorMessage)}>
            <FormControl.Label>{labelText}</FormControl.Label>
            <Row>
              <Box flex={1} mr="$4">
                <SingleValueSlider
                  maximumValue={DEGREES_VALUE_MAX}
                  minimumValue={DEGREES_VALUE_MIN}
                  value={currentValue}
                  onValueChange={(nextValue) => {
                    return onChange(nextValue)
                  }}
                />
              </Box>
              <Box w="$20">
                <FormControl.Input
                  keyboardType="number-pad"
                  returnKeyType="done"
                  value={`${currentValue}`}
                  onChangeText={(nextValue) => {
                    const asNumber = Number(nextValue)
                    if (isValidNumber(asNumber)) {
                      return onChange(asNumber)
                    }
                    return undefined
                    // return field.onChange(nextValue)
                  }}
                />
              </Box>
            </Row>
            <FormControl.ErrorMessage>{errorMessage}</FormControl.ErrorMessage>
          </FormControl.Provider>
        )
      }}
      rules={{
        max: maxValue(DEGREES_VALUE_MAX),
        min: minValue(DEGREES_VALUE_MIN),
        pattern: integerPattern,
        // required,
      }}
    />
  )
}

/**
 * End azimuth input
 */
export function AzimuthEndInput() {
  const labelText = "End Angle"
  const { form } = CreateDeviceConfiguration.useContext()
  const {
    integerPattern,
    maxValue,
    minValue,
    // required
  } = useFormValidation()

  return (
    <Controller
      control={form.control}
      name="pivotPathStopsHeadingsDegrees"
      render={({ field, fieldState }) => {
        const errorMessage = fieldState.error?.message
        const currentValue = Math.round((field.value ?? [])[1] ?? 0)
        // Update the second value in the array (start azimuth)
        const onChange = (nextValue: number) => {
          const copied = [...(field.value ?? [])]
          copied[1] = nextValue

          // Make sure that there are two values in the array
          // if the user starts changing the angles
          if (typeof copied[0] === "undefined") {
            copied[0] = 0
          }
          return field.onChange(copied)
        }
        return (
          <FormControl.Provider isInvalid={Boolean(errorMessage)}>
            <FormControl.Label>{labelText}</FormControl.Label>
            <Row>
              <Box flex={1} mr="$4">
                <SingleValueSlider
                  maximumValue={DEGREES_VALUE_MAX}
                  minimumValue={DEGREES_VALUE_MIN}
                  value={currentValue}
                  onValueChange={(nextValue) => {
                    return onChange(nextValue)
                  }}
                />
              </Box>
              <Box w="$20">
                <FormControl.Input
                  keyboardType="number-pad"
                  returnKeyType="done"
                  value={`${currentValue}`}
                  onChangeText={(nextValue) => {
                    const asNumber = Number(nextValue)
                    if (isValidNumber(asNumber)) {
                      return onChange(asNumber)
                    }
                    return undefined
                    // return field.onChange(nextValue)
                  }}
                />
              </Box>
            </Row>
            <FormControl.ErrorMessage>{errorMessage}</FormControl.ErrorMessage>
          </FormControl.Provider>
        )
      }}
      rules={{
        max: maxValue(DEGREES_VALUE_MAX),
        min: minValue(DEGREES_VALUE_MIN),
        pattern: integerPattern,
        // required,
      }}
    />
  )
}
