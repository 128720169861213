import React from "react"
import { StyleSheet } from "react-native"
import { useStyle } from "react-native-style-utilities"

import { AppText, Column, Image } from "./components"
import { SIZES } from "./components/theme"
import { Urls } from "./Internal"

import type { ImageProps } from "./components"
const styles = StyleSheet.create({
  root: {
    height: SIZES.$64,
    width: "100%",
  },
})

export function SupportImage({
  altText,
  captionText,
  fileName,
  style,
  ...rest
}: Omit<ImageProps, "source"> & {
  altText: string
  captionText: string | null
  fileName: string
}): React.JSX.Element {
  const uri = Urls.buildSupportImageUrl(fileName)
  const imageElement = (
    <Image
      alt={altText}
      resizeMode="contain"
      source={uri}
      style={useStyle(() => {
        return [styles.root, style]
      })}
      {...rest}
    />
  )
  if (typeof captionText === "string") {
    return (
      <Column space="$2">
        {imageElement}
        <AppText colorScheme="secondary" fontSize="$md" variant="imageCaption">
          {captionText}
        </AppText>
      </Column>
    )
  }
  return imageElement
}
