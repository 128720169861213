import React from "react"
import { Navigate, Outlet, useLocation } from "react-router"

import { getRealFarmCountFromState, useRootSelector } from "@fhq/app"
import { Box, Center } from "@fhq/app/components"

import type { UseLocationReturnType } from "./pages/base/paths"

import type { RouteObject } from "react-router"
const CreateFarmPage = React.lazy(
  //
  async () => import("./pages/CreateFarmPage"),
)

const NoFarmsLandingPage = React.lazy(
  //
  async () => import("./pages/NoFarmsLandingPage"),
)

function Root(): React.JSX.Element {
  const location = useLocation() as UseLocationReturnType
  const numFarms = useRootSelector(getRealFarmCountFromState)
  if (numFarms > 0) {
    return <Navigate to={location.state?.from ?? "/"} />
  }
  const id = "no-farms"
  return (
    <div data-testid={id} id={id} style={{ flex: 1, overflowY: "auto" }}>
      <Outlet />
    </div>
  )
}
export const NoFarmRoutes: RouteObject = {
  children: [
    {
      element: (
        <Center p="$4">
          <Box maxW="$xl" mx="auto">
            <NoFarmsLandingPage />
          </Box>
        </Center>
      ),
      index: true,
    },
    {
      element: (
        <Box pt="$4" px="$4">
          <CreateFarmPage />
        </Box>
      ),
      path: "create-farm",
    },
  ],
  element: <Root />,
}
