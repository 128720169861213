import { StyleSheet } from "react-native"

import { AppText, View } from "@fhq/app/components"

const styles = StyleSheet.create({
  root: {
    flex: 1,
  },
})

export function ScratchPage() {
  return (
    <View style={styles.root}>
      <AppText>Scratch</AppText>
    </View>
  )
}
