import React from "react"

import {
  Geo,
  getDeviceStatusDataByDeviceIdFromState,
  getMarkerImageSourceByDeviceIdFromState,
  Models,
  useRootSelector,
} from "@fhq/app"
import { COLORS } from "@fhq/app/components/theme"
import { makeUseDeviceMarkerArrow } from "@fhq/app/useDirectionArrow"
import { Marker } from "@react-google-maps/api"

import CenterPivotOutlineVisualization from "./CenterPivotOutlineVisualization"
import CenterPivotPathVisualization from "./CenterPivotPathVisualization"
import { ArrowBase } from "./DirectionArrow"
import LinearPathVisualization from "./LinearPathVisualization"
import LinearSwathOutlineVisualization from "./LinearSwathOutlineVisualization"

import type { DeviceIdProps, SelectDeviceHandler } from "@fhq/app"

import type { MarkerProps } from "@react-google-maps/api"
interface DeviceMarkerProps extends DeviceIdProps {
  showDeviceActivityVisualizations: boolean
  showParkingSpots: boolean
  isAnySelected?: boolean
  isClickable?: boolean
  isHidden?: boolean
  isSelected?: boolean
  onSelectDeviceActivity?: (params: DeviceIdProps) => void
  onSelectDeviceEvent?: SelectDeviceHandler
  showCenterPivotOutline?: boolean
}

const ARROW_LENGTH_METERS = 200

const useDirectionArrow = makeUseDeviceMarkerArrow(Geo.pointToGmaps)

/**
 *
 */
export function DeviceMarker({
  deviceId,
  isAnySelected,
  isClickable = true,
  isHidden = false,
  isSelected,
  onSelectDeviceActivity,
  onSelectDeviceEvent,
  showCenterPivotOutline = true,
  showDeviceActivityVisualizations,

  showParkingSpots,
}: DeviceMarkerProps): React.JSX.Element | null {
  const configuration = useRootSelector((state) => {
    return Models.deviceConfiguration.selectById(state, deviceId)
  })
  const deviceStatusData = useRootSelector((state) => {
    return getDeviceStatusDataByDeviceIdFromState(state, deviceId)
  })
  const deviceActivity = useRootSelector((state) => {
    return Models.deviceActivity.selectById(state, deviceId)
  })

  const {
    codaDeviceAlias,
    deviceInstallationType,
    gpsHeading,
    gpsLocation,
    isInMotion = false,
    isStoppedShort,
    isUpdating = false,
  } = deviceStatusData ?? {}

  let animation: google.maps.Animation | undefined
  let fillOpacity = 1
  let markerTitle = codaDeviceAlias
  let strokeColor: string = COLORS.$black
  let strokeWeight = 1

  if (isSelected === true) {
    animation = 1
    strokeColor = COLORS.$cyan[500]
    strokeWeight = 3
  } else if (isAnySelected === true) {
    fillOpacity = 0.8
    strokeWeight = 0
  }
  if (isUpdating) {
    animation = 1
  }

  // TODO: We can get rid of the text here and just use the icon

  if (deviceInstallationType === "unconfigured" || isStoppedShort === true) {
    markerTitle += "-warning"
  }

  const uri = useRootSelector((state) =>
    getMarkerImageSourceByDeviceIdFromState(state, deviceId),
  )

  const options = React.useMemo((): Pick<MarkerProps, "icon"> & {
    marker: google.maps.MarkerOptions
  } => {
    const visible = !isHidden
    const markerSizeFactor = 10
    return {
      icon: {
        fillOpacity,
        scaledSize: new google.maps.Size(
          5 * markerSizeFactor,
          6 * markerSizeFactor,
        ),
        strokeColor,
        strokeWeight,
        url: uri,
      },
      marker: { visible },
    }
  }, [fillOpacity, isHidden, strokeColor, strokeWeight, uri])

  const arrowPath = useDirectionArrow({
    center: gpsLocation?.web,
    deviceInstallationType,
    direction: gpsHeading,
    isInMotion,
    lengthMm: ARROW_LENGTH_METERS * 1000,
  })

  /**
   * From the configuration - outlines of pivots, parking spots for linears etc.
   */
  let configuredActivityPathElement: React.JSX.Element | null = null
  /**
   * From the device activity - current pivot path/linear completion etc.
   */
  let deviceActivityElement: React.JSX.Element | null = null

  /**
   * Marker for device event
   */
  let markerElement: React.JSX.Element | null = null

  if (gpsLocation?.web) {
    markerElement = (
      <Marker
        animation={animation}
        clickable={isClickable}
        icon={options.icon}
        position={gpsLocation.web}
        title={markerTitle}
        onClick={
          onSelectDeviceEvent && typeof codaDeviceAlias === "string"
            ? () => {
                onSelectDeviceEvent({
                  codaDeviceAlias,
                  deviceId,
                  gpsLocation,
                })
              }
            : undefined
        }
      />
    )
  }

  switch (deviceInstallationType) {
    case "center_pivot": {
      if (showCenterPivotOutline) {
        configuredActivityPathElement = (
          <CenterPivotOutlineVisualization deviceId={deviceId} />
        )
      }
      if (deviceActivity && showDeviceActivityVisualizations) {
        deviceActivityElement = (
          <CenterPivotPathVisualization
            isClickable={isClickable}
            showCompletionPercentage={false}
            onSelect={onSelectDeviceActivity}
            {...deviceActivity}
          />
        )
      }
      break
    }
    case "linear_move": {
      if (showParkingSpots && configuration) {
        configuredActivityPathElement = (
          <LinearSwathOutlineVisualization
            isClickable={isClickable}
            linearPathStops={configuration.linearPath}
            linearSpanHeadingDegrees={configuration.linearSpanHeadingDegrees}
            linearSpanWidthMm={configuration.linearSpanWidthMm}
          />
        )
      }
      if (deviceActivity && showDeviceActivityVisualizations) {
        deviceActivityElement = (
          <LinearPathVisualization
            isClickable={isClickable}
            showParkingSpots={showParkingSpots}
            {...deviceActivity}
          />
        )
      }

      break
    }

    case "reel":
    case "reel_with_booster_off_only": {
      if (arrowPath) {
        deviceActivityElement = (
          <ArrowBase {...arrowPath} strokeColor={COLORS.$reelRunSwath.arrow} />
        )
      }
      break
    }
    case "traveller_soft":
    case "prototype":
    case "pump":
    case "pump_off_only":
    case "pump_on_off":
    case "pump_vfd":
    case "unconfigured":
    case "valve":
    case undefined: {
      break
    }
  }

  return (
    <React.Fragment>
      {configuredActivityPathElement}
      {deviceActivityElement}
      {markerElement}
    </React.Fragment>
  )
}
