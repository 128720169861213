import React from "react"

import { useIsDarkMode } from "@fhq/app/components"
import { COLORS, SIZES } from "@fhq/app/components/theme"

import type { AcceptsChildren, TestId } from "@fhq/app/components"

const STYLES: { [key in "main" | "scrollingContent"]: React.CSSProperties } = {
  main: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
    overflow: "hidden",
    width: "100%",
  },
  scrollingContent: {
    flexGrow: 1,
    overflowX: "hidden",
    overflowY: "auto",
    paddingBottom: SIZES.$toolbar,
  },
}
export function Main({ children }: AcceptsChildren) {
  const isDark = useIsDarkMode()
  return (
    <div
      data-testid="app"
      id="app"
      style={{
        ...STYLES.main,
        backgroundColor: isDark
          ? COLORS.$background.dark
          : COLORS.$background.light,
      }}
    >
      {children}
    </div>
  )
}

/**
 *
 */
export const ScrollingContent = React.forwardRef<
  HTMLDivElement,
  AcceptsChildren & {
    id?: TestId
  }
>(function ScrollingContentWithRef({ children, id }, ref) {
  return (
    <div
      ref={ref}
      id={id ?? "scrollingContent"}
      style={STYLES.scrollingContent}
    >
      {children}
    </div>
  )
})
