import React from "react"
import { StyleSheet, TouchableOpacity } from "react-native"
import { useStyle } from "react-native-style-utilities"

import type { TouchableOpacityProps } from "react-native"

const styles = StyleSheet.create({
  root: {
    alignItems: "center",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    flex: 1,
    justifyContent: "center",
  },
})

/**
 * A component that displays a full-screen overlay.
 */
export function Overlay({ style, ...rest }: TouchableOpacityProps) {
  return (
    <TouchableOpacity
      activeOpacity={1}
      id="overlay"
      style={useStyle(() => [styles.root, style], [style])}
      {...rest}
    />
  )
}
