import { useNavigate } from "react-router-dom"

import {
  ChooseInstallationType,
  CreateDeviceConfigurationContext,
  DeviceNameText,
  DeviceSummaryContext,
  i18n,
} from "@fhq/app"
import { AlertCard, TitleWithIcon } from "@fhq/app/components"

import type { AppTextProps } from "@fhq/app/components"

const TEXT_PROPS: AppTextProps = {
  colorScheme: "secondary",
  fontSize: "$lg",
}
/**
 *
 */
export function ChooseInstallationTypePage() {
  const navigate = useNavigate()
  const { codaDeviceAlias, deviceName } = DeviceSummaryContext.useContext()
  return (
    <CreateDeviceConfigurationContext.PageWrapper>
      <CreateDeviceConfigurationContext.Container variant="header">
        <TitleWithIcon
          IconComponent="DeviceConfiguration"
          px="$4"
          titleText={i18n.t("deviceConfiguration:setUp")}
          variant="pageTitle"
          rightElement={
            <DeviceNameText
              _textProps={TEXT_PROPS}
              codaDeviceAlias={codaDeviceAlias}
              deviceName={deviceName}
              isUpdating={false}
            />
          }
        />
        <AlertCard
          IconComponent={null}
          bodyText={i18n.t("deviceConfiguration:chooseInstallationType")}
          mt="$2"
          severity="info"
        />
      </CreateDeviceConfigurationContext.Container>
      <CreateDeviceConfigurationContext.ScrollView roundCorners="bottom">
        <ChooseInstallationType onChange={navigate} />
      </CreateDeviceConfigurationContext.ScrollView>
    </CreateDeviceConfigurationContext.PageWrapper>
  )
}
