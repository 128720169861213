import React from "react"

const TIMEOUT_SECONDS = 30
export function TimeoutTracker() {
  React.useEffect(() => {
    const timeout = setTimeout(() => {
      throw new TypeError("Load app timed out")
    }, TIMEOUT_SECONDS * 1000)

    return () => {
      clearTimeout(timeout)
    }
  }, [])
  return null
}
