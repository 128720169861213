import React from "react"

import { useToasts } from "./components/useToasts"
import { loadDeviceActivityAsync } from "./farmhq-api"
import { logger } from "./logger"
import { useBackendRequest } from "./useBackendRequest"
import { getActiveFarmIdFromState } from "./user-farms.selectors"
import { useRootSelector } from "./useRootSelector"

export function useAutoRefresh({
  callback,
  isFocused,
  logMessage,
  seconds,
}: {
  callback: () => void
  isFocused: boolean
  seconds: number
  logMessage?: string
}) {
  const countdownValue = React.useRef(seconds)
  React.useEffect(() => {
    const timeout = setInterval(() => {
      if (isFocused) {
        countdownValue.current = countdownValue.current - 1
        if (countdownValue.current === 0) {
          logger.debug(logMessage ?? `Executing callback`)

          callback()
          countdownValue.current = seconds
        }
      }
    }, 1000)

    return () => {
      clearTimeout(timeout)
    }
  }, [callback, isFocused, logMessage, seconds])
}

/**
 *
 */
const REFRESH_INTERVAL_SECONDS = 10

export function useDeviceActivityAutoRefresh({
  callerName,
  isFocused,
  refreshIntervalSeconds,
  shouldLog = false,
}: {
  callerName: string
  isFocused: boolean
  refreshIntervalSeconds?: number
  shouldLog?: boolean
}) {
  const countdownValue = React.useRef(10)
  const toast = useToasts()
  const activeFarmId = useRootSelector(getActiveFarmIdFromState)
  const { handleError, isLoading, sendRequest } = useBackendRequest(
    loadDeviceActivityAsync,
  )

  /**
   *
   */
  React.useEffect(() => {
    const timeout = setInterval(() => {
      if (typeof activeFarmId === "number" && isFocused) {
        countdownValue.current = countdownValue.current - 1
        if (countdownValue.current === 0) {
          const message = `[${callerName}]: Polling for device activity updates...`

          shouldLog && logger.info(message)
          sendRequest({ activeFarmId }).catch(handleError)
          countdownValue.current =
            refreshIntervalSeconds ?? REFRESH_INTERVAL_SECONDS
        }
      }
    }, 1000)
    return () => {
      clearTimeout(timeout)
    }
  }, [
    activeFarmId,
    callerName,
    handleError,
    isFocused,
    refreshIntervalSeconds,
    sendRequest,
    shouldLog,
    toast,
  ])
  return {
    isLoading,
  }
}
export function DeviceActivityAutoRefresher({
  callerName,
  isFocused,
  loadingElement,
  refreshIntervalSeconds,
}: {
  callerName: string
  isFocused: boolean
  loadingElement: React.JSX.Element | null
  refreshIntervalSeconds?: number
}) {
  const { isLoading } = useDeviceActivityAutoRefresh({
    callerName,
    isFocused,
    refreshIntervalSeconds,
  })

  if (isLoading) {
    return loadingElement
  }
  return null
}
