import React from "react"
import { useColorScheme } from "react-native"

import AsyncStorage from "@react-native-async-storage/async-storage"

import { getCurrentBreakpointKey } from "../app-state.reducer"
import { logger } from "../logger"
import { useRootSelector } from "../useRootSelector"
import { useTrackScreenSize } from "./useTrackScreenSize"

import type { BreakpointToken } from "./theme/breakpoints"
import type { ThemedValue, ThemePreference } from "./types"

interface ProviderProps {
  colorMode?: ThemePreference
}

const COLOR_MODE_STORAGE_KEY = "@colorMode"

export type ColorModeValue = "dark" | "light"

/**
 * Logic for managing the color mode
 */
function useThemeProvider() {
  // const screenSize: BreakpointToken = useTrackScreenSize()
  useTrackScreenSize()
  const preference = useColorScheme()
  const [currentColorMode, setCurrentColorMode] =
    React.useState<ColorModeValue>(preference ?? "light")

  /**
   * On load, check for saved color mode and set it if it exists
   */
  React.useEffect(() => {
    AsyncStorage.getItem(COLOR_MODE_STORAGE_KEY)
      .then((savedValue) => {
        if (savedValue === "light" || savedValue === "dark") {
          return setCurrentColorMode(savedValue)
        }
        return undefined
      })
      .catch((error) => {
        logger.error(error)
      })
  }, [])

  /**
   * Update the color mode and save it to storage
   */
  const onColorModeChanged = React.useCallback((nextValue: string) => {
    if (nextValue === "light" || nextValue === "dark") {
      setCurrentColorMode(nextValue)
      AsyncStorage.setItem(COLOR_MODE_STORAGE_KEY, nextValue).catch((error) => {
        logger.error(error)
      })
    } else {
      throw new TypeError(`Invalid color mode: ${nextValue}`)
    }
  }, [])
  return {
    colorMode: currentColorMode,
    onColorModeChanged,
    // screenSize,
  }
}

type ContextValue = ReturnType<typeof useThemeProvider>

const Context = React.createContext<ContextValue | undefined>(undefined)

export function ThemeProvider({
  children,
}: React.PropsWithChildren<ProviderProps>): JSX.Element | null {
  const value = useThemeProvider()
  return <Context.Provider value={value}>{children}</Context.Provider>
}

export function useThemeProviderContext() {
  const ctx = React.useContext(Context)
  if (typeof ctx === "undefined") {
    throw new TypeError(`ThemeProvider Context must be used inside of provider`)
  }
  return ctx
}
export function useColorMode() {
  return useThemeProviderContext().colorMode
}
export function useIsDarkMode() {
  return useColorMode() === "dark"
}

export function useThemedValue(): <T>(value: ThemedValue<T>) => T
export function useThemedValue<T>(value: ThemedValue<T>): T
export function useThemedValue<T>(
  initial?: ThemedValue<T>,
): T | ((value: ThemedValue<T>) => T) {
  const colorMode = useColorMode()

  const getValue = React.useCallback(
    (value: ThemedValue<T>) => {
      if (typeof value === "object" && "light" in value) {
        return value[colorMode]
      }
      return value
    },
    [colorMode],
  )
  if (typeof initial === "undefined") {
    return getValue
  }
  return getValue(initial)
}

export type BreakpointInputValue<V> = {
  [key in BreakpointToken | "base"]?: V
}

export interface ResponsiveComponentProps {
  breakpoint?: BreakpointToken
}
export function useBreakpointValue() {
  // const { screenSize } = useThemeProviderContext()
  const screenSize = useRootSelector(getCurrentBreakpointKey)

  return function getValue<V>(props: BreakpointInputValue<V>) {
    if (screenSize === "xl") {
      return props.xl ?? props.lg ?? props.md ?? props.sm ?? props.base
    }
    if (screenSize === "lg") {
      return props.lg ?? props.md ?? props.sm ?? props.base
    }
    if (screenSize === "md") {
      return props.md ?? props.sm ?? props.base
    }

    if (screenSize === "sm") {
      return props.sm ?? props.base
    }

    return props.base
  }
}
// export function useBottomSheetBackgroundColor() {
//   const isDark = useIsDarkMode()
//   return isDark ? COLORS.$bottomSheet.dark : COLORS.$bottomSheet.light
// }
