import type { EntityState, Reducer } from "@reduxjs/toolkit"

import { createEntityAdapter, createSlice, isAnyOf } from "@reduxjs/toolkit"

import * as AppStorage from "./async-storage"
import { signOutAsync } from "./auth.reducer"
import {
  callNamedDeviceActionAsync,
  loadDeviceFlowHistoryDataAsync,
  loadDeviceFunctionCallsAsync,
  loadDeviceTimeseriesDataAsync,
  requestUpdateFromDeviceAsync,
} from "./farmhq-api"
import * as Models from "./models"
import {
  getDeviceAnalyticsDateValue,
  getUserMeasurementPreferenceFromState,
} from "./selectors"
import { convertSensorValue, requiresConversion } from "./sensor-conversions"
import { isTruthyString, isValidNumber } from "./type-guards"
import { useRootSelector, useShallowEqualSelector } from "./useRootSelector"

import type {
  DeviceFunctionCall,
  DeviceTimeSeriesUnion,
  ModelState,
} from "./models"
import type { DeviceTimeseriesKey } from "./sensors"
import type { MinDateMaxDate } from "./types"

import type { RootState } from "./root.reducer"
export const functionInvocationsAdapter =
  createEntityAdapter<DeviceFunctionCall>({
    sortComparer: (a, b) => {
      if (
        isTruthyString(a.requestSentTimestamp) &&
        isTruthyString(b.requestSentTimestamp)
      ) {
        return b.requestSentTimestamp.localeCompare(a.requestSentTimestamp)
      }
      return 0
    },
  })

export const { selectAll: selectAllDeviceFunctionCalls } =
  functionInvocationsAdapter.getSelectors(
    (state: RootState) => state.deviceProfile.functionInvocations,
  )

interface DeviceProfileState {
  analytics: {
    dateMsMax: number
    dateMsMin: number
  }
  functionInvocations: EntityState<DeviceFunctionCall>
  reelRuns: ModelState<Models.ReelRun>
  timeseries: ModelState<DeviceTimeSeriesUnion, DeviceTimeSeriesUnion["key"]>
}

const initialState: DeviceProfileState = {
  analytics: {
    dateMsMax: AppStorage.STORAGE_DEFAULTS.deviceAnalyticsDateMax,
    dateMsMin: AppStorage.STORAGE_DEFAULTS.deviceAnalyticsDateMin,
  },
  functionInvocations: functionInvocationsAdapter.getInitialState(),
  reelRuns: { ...Models.getInitialEntityState() },
  timeseries: { ...Models.getInitialEntityState() },
}

const deviceProfileSlice = createSlice({
  extraReducers: (builder) =>
    builder
      .addCase(loadDeviceFunctionCallsAsync.fulfilled, (state, { payload }) => {
        functionInvocationsAdapter.setAll(
          state.functionInvocations,
          payload.items,
        )
      })
      .addCase(
        AppStorage.readLocalStorageAsync.fulfilled,
        (state, { payload }) => {
          state.analytics.dateMsMin = payload.deviceAnalyticsDateMin
          state.analytics.dateMsMax = payload.deviceAnalyticsDateMax
        },
      )
      .addCase(
        loadDeviceTimeseriesDataAsync.fulfilled,
        (state, { payload }) => {
          Models.deviceTimeseries.adapter.setAll(
            state.timeseries,
            payload.items,
          )
        },
      )
      .addCase(
        loadDeviceFlowHistoryDataAsync.fulfilled,
        (state, { payload }) => {
          Models.deviceTimeseries.adapter.setAll(
            state.timeseries,
            payload.items,
          )
        },
      )
      .addCase(
        // MIN DATE
        AppStorage.setDeviceAnalyticsDateMin.pending,
        (state, action) => {
          state.analytics.dateMsMin = action.meta.arg
        },
      )
      .addCase(
        // MAX DATE
        AppStorage.setDeviceAnalyticsDateMax.pending,
        (state, action) => {
          state.analytics.dateMsMax = action.meta.arg
        },
      )
      .addCase(callNamedDeviceActionAsync.fulfilled, (state, action) => {
        if (action.payload.result) {
          functionInvocationsAdapter.upsertOne(
            state.functionInvocations,
            action.payload.result,
          )
        }
      })
      .addMatcher(
        isAnyOf(
          callNamedDeviceActionAsync.fulfilled,
          requestUpdateFromDeviceAsync.fulfilled,
        ),
        (state, { payload }) => {
          if (payload.result) {
            functionInvocationsAdapter.upsertOne(
              state.functionInvocations,
              payload.result,
            )
          }
        },
      )
      .addMatcher(isAnyOf(signOutAsync.fulfilled), () => ({ ...initialState })),
  initialState,
  name: "deviceProfile",
  reducers: {},
})

export const deviceProfileReducer: Reducer<DeviceProfileState> =
  deviceProfileSlice.reducer

export function useDeviceProfileDateValues(): MinDateMaxDate {
  return {
    dateMsMax: useRootSelector(getDeviceAnalyticsDateValue.getMax),
    dateMsMin: useRootSelector(getDeviceAnalyticsDateValue.getMin),
  }
}

export function useTimeseriesValuesFromState(
  key: DeviceTimeseriesKey,
): DeviceTimeSeriesUnion | undefined {
  return useShallowEqualSelector((state) => {
    const measurementPreference = getUserMeasurementPreferenceFromState(state)
    const data = Models.deviceTimeseries.selectById(state, key)
    if (data) {
      if (requiresConversion(data.key)) {
        const convert = (rawValue: number): number => {
          return convertSensorValue({
            fieldName: data.key,
            measurementPreference,
            rawValue,
            target: "user",
          })
        }

        return {
          ...data,
          maxValue: convert(data.maxValue),
          values: data.values.map((value) => {
            if (isValidNumber(value.y)) {
              return { ...value, y: convert(value.y) }
            }
            return value
          }),
        }
      }
      return data
    }
    return undefined
  })
}
