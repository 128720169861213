import React from "react"
import { useStyle } from "react-native-style-utilities"

import { Box } from "./Box"
import { COLORS } from "./theme/colors"
import { useIsDarkMode } from "./ThemeProvider"

import type { BoxProps } from "./Box"

export interface PaperProps extends BoxProps {
  isDisabled?: boolean
}

/**
 * A component that displays a paper-like surface with a shadow.
 */
export function Paper({
  bg,
  children,
  isDisabled,
  style,
  ...rest
}: PaperProps) {
  const isDark = useIsDarkMode()
  return (
    <Box
      {...rest}
      style={useStyle(() => {
        let backgroundColor = COLORS.$paper.light
        if (isDark) {
          backgroundColor = COLORS.$paper.dark
        }

        if (isDisabled === true) {
          if (isDark) {
            backgroundColor = COLORS.$gray[700]
          } else {
            backgroundColor = COLORS.$gray[200]
          }
        }
        return [{ backgroundColor: bg ?? backgroundColor }, style]
      }, [bg, isDark, isDisabled, style])}
    >
      {children}
    </Box>
  )
}

// import { StyleSheet } from "react-native";

// const styles = StyleSheet.create({
//   smallScreen: {
//     borderRadius: 0,
//   },
// })

// export function PaperResponsive({ style, ...rest }: PaperProps) {
//   const getValue = useBreakpointValue()
//   return (
//     <Paper
//       {...rest}
//       style={useStyle(() => {
//         return [
//           style,
//           getValue({
//             base: styles.smallScreen,
//             sm: undefined,
//           }),
//         ]
//       }, [getValue, style])}
//     />
//   )
// }
