import React from "react"
import { StyleSheet } from "react-native"

import { Box } from "./Box"
import { Theme } from "./theme"
import { useBreakpointValue } from "./ThemeProvider"

import type { ResponsiveComponentProps } from "./ThemeProvider"
import type { BoxProps } from "./Box"

const styles = StyleSheet.create({
  pageFooter: {
    paddingBottom: Theme.spacing.$4,
  },
})

export type ContainerProps = BoxProps & {
  variant?: "pageFooter"
}

/**
 * A component that wraps its children in a container with padding.
 */
export function Container({ style, variant, ...props }: ContainerProps) {
  return (
    <Box
      px="$4"
      style={[style, variant === "pageFooter" && styles.pageFooter]}
      {...props}
    />
  )
}

/**
 * Removes padding at the provided breakpoint.
 */
export function ResponsiveContainer({
  breakpoint = "sm",
  ...rest
}: BoxProps & ResponsiveComponentProps) {
  const getValue = useBreakpointValue()
  return <Box px={getValue({ base: "$4", [breakpoint]: "$0" })} {...rest} />
}
