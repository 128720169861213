import React from "react"
import { StyleSheet, View } from "react-native"
import { useStyle } from "react-native-style-utilities"

import { AppText } from "../Text"
import { SPACING } from "../theme"
import { getFontName } from "../theme/fonts"
import { useFormControlContext } from "./base"

import type { AppTextProps } from "../Text"
import type { AcceptsChildren } from "../types"

const styles = StyleSheet.create({
  container: {
    alignItems: "center",
    display: "flex",
    flexDirection: "row",
    flexWrap: "nowrap",
    marginBottom: SPACING.$1,
  },
  invalid: {
    // color: COLORS.$invalid,
  },
  root: {
    fontFamily: getFontName("OpenSans_400Regular"),
    marginBottom: SPACING.$1,
  },
  textContainer: {
    flexShrink: 1,
    marginRight: SPACING.$2,
  },
})

export interface FormControlLabelProps extends AppTextProps {
  isInvalid?: boolean
}

export function Label({
  children,
  disabled,
  isInvalid,
  style,
  ...rest
}: FormControlLabelProps) {
  const ctx = useFormControlContext()

  if (typeof isInvalid === "undefined") {
    isInvalid = ctx.isInvalid
  }
  return (
    <AppText
      colorScheme="secondary"
      disabled={disabled}
      style={useStyle(() => {
        return [
          styles.root,
          isInvalid === true ? styles.invalid : undefined,
          style,
        ]
      }, [isInvalid, style])}
      {...rest}
    >
      {children}
    </AppText>
  )
}

export function FormControlLabelContainer(
  props: AcceptsChildren & {
    helpContent?: React.JSX.Element | null
  },
) {
  return (
    <View style={styles.container}>
      <View style={styles.textContainer}>{props.children}</View>
      {props.helpContent}
    </View>
  )
}
