import React from "react"
import { StyleSheet } from "react-native"
import { useStyle } from "react-native-style-utilities"

import { AppText } from "./Text"
import { FONT_SIZES, getFontName } from "./theme/fonts"

import type { TextStyle } from "react-native"
import type { AppTextProps } from "./Text"

export interface HeadingProps extends Omit<AppTextProps, "variant"> {
  variant?: "h1" | "h2" | "h3" | "h4" | "h5" | "h6"
}

const styles = StyleSheet.create({
  root: {
    fontFamily: getFontName("Poppins_600SemiBold"),
  },
})

/**
 * A component that displays a heading.
 */
export function Heading({
  fontSize,
  style,
  variant = "h4",
  ...rest
}: HeadingProps) {
  let fontSizeValue: TextStyle["fontSize"]
  if (typeof fontSize === "undefined") {
    switch (variant) {
      case "h1": {
        fontSizeValue = FONT_SIZES.$h1
        break
      }
      case "h2": {
        fontSizeValue = FONT_SIZES.$h2
        break
      }
      case "h3": {
        fontSizeValue = FONT_SIZES.$h3
        break
      }
      case "h4": {
        fontSizeValue = FONT_SIZES.$h4
        break
      }
      case "h5": {
        fontSizeValue = FONT_SIZES.$h5
        break
      }
      case "h6": {
        fontSizeValue = FONT_SIZES.$h6
        break
      }
    }
  } else {
    fontSizeValue = FONT_SIZES[fontSize]
  }
  return (
    <AppText
      accessibilityRole="header"
      style={useStyle(() => {
        return [styles.root, { fontSize: fontSizeValue }, style]
      }, [fontSizeValue, style])}
      {...rest}
    />
  )
}

// export function ScreenHeader(props: HeadingProps): React.JSX.Element {
//   return <Heading {...props} />
// }

// export function ScreenSubheader({
//   children,
//   ...rest
// }: AppTextProps): React.JSX.Element {
//   return (
//     <AppText colorScheme="secondary" fontWeight="bold" {...rest}>
//       {children}
//     </AppText>
//   )
// }

// export function MapCardTitle(props: HeadingProps): React.JSX.Element {
//   return <Heading fontSize="$sm" {...props} />
// }

export function Subheading(props: HeadingProps): React.JSX.Element {
  return <Heading colorScheme="secondary" variant="h5" {...props} />
}
