import { useSelector } from "react-redux"

import { createAction, createSlice, isRejected } from "@reduxjs/toolkit"

import { denyPermission } from "./actions"
import * as AppStorage from "./async-storage"
import { REQUEST_BLOCKED_IN_DEMO } from "./constants"
import { acceptTermsOfServiceAsync, loadActiveUserAsync } from "./farmhq-api"
import { loadActiveFarmAsync } from "./load-app"
import { logger } from "./logger"
import { isTruthyString } from "./type-guards"

import type { PermissionDeniedPayload } from "./types"
import type { RootState } from "./root.reducer"
import type { FarmUserPermissionName, FarmUserPermissions } from "./permissions"
import type { Reducer } from "@reduxjs/toolkit"

import type { AnyRequestName } from "./send-request"
interface MissingPermission {
  missing: FarmUserPermissionName[]
  reason: "missing permissions"
}

interface BlockedInDemoMode {
  reason: typeof REQUEST_BLOCKED_IN_DEMO
  requestName: AnyRequestName | undefined
}

interface PermissionsState {
  farmUserPermissions?: FarmUserPermissions
  isAdmin?: boolean
  isAdminModeEnabled?: boolean
  permissionDenied?: BlockedInDemoMode | MissingPermission
  termsOfServiceAccepted?: boolean
}

const permissionsInitialState: PermissionsState = {}

export const clearPermissionDenied = createAction("CLEAR PERMISSION DENIED")

const slice = createSlice({
  extraReducers: (builder) =>
    builder
      .addCase(
        AppStorage.readLocalStorageAsync.fulfilled,
        (state, { payload }) => {
          state.isAdminModeEnabled = payload.isAdminModeEnabled
        },
      )
      .addCase(loadActiveUserAsync.fulfilled, (state, { payload }) => {
        state.termsOfServiceAccepted =
          payload.userData.termsOfServiceAccepted === true

        if (payload.userData.isAdmin === true) {
          state.isAdmin = true
        } else {
          state.isAdmin = false
          state.isAdminModeEnabled = undefined
        }
      })
      .addCase(loadActiveFarmAsync.fulfilled, (state, { payload }) => {
        state.farmUserPermissions = payload.userPermissions
      })
      .addCase(denyPermission, (state, { payload }) => {
        if (payload.reason === REQUEST_BLOCKED_IN_DEMO) {
          state.permissionDenied = {
            reason: "REQUEST_BLOCKED_IN_DEMO",
            requestName: undefined,
          }
        } else {
          let missing: FarmUserPermissionName[] = []
          if (Array.isArray(payload.missingPermissions)) {
            missing = payload.missingPermissions
          } else if (isTruthyString(payload.missingPermissions)) {
            missing = [payload.missingPermissions]
          }
          state.permissionDenied = { missing, reason: "missing permissions" }
        }
      })
      .addCase(clearPermissionDenied, (state) => {
        state.permissionDenied = undefined
      })
      .addCase(AppStorage.setAdminMode.pending, (state, { meta }) => {
        if (state.isAdmin === true) {
          state.isAdminModeEnabled = meta.arg
        }
      })
      .addCase(acceptTermsOfServiceAsync.fulfilled, (state) => {
        state.termsOfServiceAccepted = true
      })
      .addMatcher(isRejected, (state, action) => {
        if (
          action.meta.rejectedWithValue &&
          typeof action.payload === "object"
        ) {
          const value = action.payload as
            | Partial<PermissionDeniedPayload>
            | undefined
          const [requestName] = action.type.split("/")
          if (value && value.reason === "REQUEST_BLOCKED_IN_DEMO") {
            state.permissionDenied = {
              reason: "REQUEST_BLOCKED_IN_DEMO",
              requestName: requestName as AnyRequestName | undefined,
            }
          } else if (value && value.reason === "missing permissions") {
            let missing: FarmUserPermissionName[] | undefined
            if (Array.isArray(value.missingPermissions)) {
              missing = value.missingPermissions
            } else if (typeof value.missingPermissions === "string") {
              missing = [value.missingPermissions]
            }
            if (missing) {
              state.permissionDenied = {
                missing,
                reason: "missing permissions",
              }
            }
          }
        }
        logger.error(action)
      }),
  initialState: permissionsInitialState,
  name: "permissions",
  reducers: {},
})

const permissions: Reducer<PermissionsState> = slice.reducer
export default permissions

export function getFarmUserPermissionsFromState(
  state: RootState,
): FarmUserPermissions | undefined {
  return state.permissions.farmUserPermissions
}
export function getUserHasPermission(
  state: RootState,
  permissionName: FarmUserPermissionName,
) {
  return getFarmUserPermissionsFromState(state)?.[permissionName] === true
}

export function getUserHasAllPermissions(
  state: RootState,
  toCheck: FarmUserPermissionName | FarmUserPermissionName[] | null | undefined,
) {
  let required: FarmUserPermissionName[] = []
  if (typeof toCheck === "string") {
    required = [toCheck]
  } else if (Array.isArray(toCheck)) {
    required = toCheck
  }
  if (required.length === 0) {
    //  User has all permissions
    return true
  }

  const provided = getFarmUserPermissionsFromState(state)

  return required.every((key) => {
    if (provided) {
      return provided[key]
    }
    return false
  })
}
export function useUserHasPermissions(
  ...permissionNames: FarmUserPermissionName[]
) {
  return useSelector((state: RootState) => {
    const provided = getFarmUserPermissionsFromState(state)
    return permissionNames.every((key) => {
      if (provided) {
        return provided[key]
      }
      return false
    })
  })
}
