import React from "react"

import { Box } from "./Box"
import { Heading } from "./Heading"
import { ICON_SIZES, renderIconFromIconProp } from "./icons"
import { Row } from "./Row"

import type { BoxProps } from "./Box"
import type { HeadingProps } from "./Heading"
import type { IconProp } from "./icons"
import type { NoChildren } from "./types"
import type { ViewProps } from "./View"

export interface TitleWithIconProps extends NoChildren<BoxProps> {
  titleText: string
  IconComponent?: IconProp | null
  _headingProps?: HeadingProps
  colorScheme?: HeadingProps["colorScheme"]
  headingVariant?: HeadingProps["variant"]
  helpElement?: JSX.Element | null
  rightElement?: JSX.Element | string | null
  subheaderElement?: React.JSX.Element | string | null
  variant?: "pageTitle"
}

/**
 * A title with an icon on the left and an optional element on the right.
 */
export function TitleWithIcon({
  _headingProps,
  IconComponent,
  colorScheme,
  headingVariant = "h4",
  helpElement,
  id,
  rightElement,
  subheaderElement,
  titleText,
  ...rest
}: TitleWithIconProps & ViewProps): React.JSX.Element {
  const iconElement = renderIconFromIconProp(IconComponent, {
    colorScheme,
    size: ICON_SIZES.$lg,
  })

  let elementRight: React.JSX.Element | null = null

  if (typeof rightElement === "string") {
    elementRight = (
      <Heading colorScheme="secondary" variant={headingVariant}>
        {rightElement}
      </Heading>
    )
  } else if (rightElement) {
    elementRight = rightElement
  }

  return (
    <Box id={id} {...rest}>
      <Row flexWrap="nowrap">
        <Row flex={1} flexWrap="nowrap">
          {iconElement ? <Box mr="$2">{iconElement}</Box> : null}
          <Heading
            isTruncated
            colorScheme={colorScheme}
            textTransform="capitalize"
            variant={headingVariant}
            {..._headingProps}
          >
            {titleText}
          </Heading>
          {helpElement ? <Box ml="$1">{helpElement}</Box> : null}
        </Row>
        {elementRight ? <Box ml="auto">{elementRight}</Box> : null}
      </Row>
      {typeof subheaderElement === "string" ? (
        <Heading colorScheme="secondary" variant="h5">
          {subheaderElement}
        </Heading>
      ) : (
        subheaderElement
      )}
    </Box>
  )
}
