import _ from "lodash"
import React from "react"
import { Controller, useFormContext } from "react-hook-form"
import { useTranslation } from "react-i18next"

import {
  AlertCard,
  AlertDialog,
  AlertDialogBodyText,
  AlertDialogScrollView,
  AppText,
  Column,
  FormControl,
  HelpIconButton,
  Radio,
  useOpenState,
} from "./components"
import { InputRightAddon } from "./components/form-control/Input"
import { FormControlLabelContainer } from "./components/form-control/Label"
import { SIZES } from "./components/theme"
import { useFormValidation } from "./form-validation"
import { PressureThresholdSlider } from "./PressureThresholdSlider"
import { useIsPending } from "./requests.reducer"
import { getUserMeasurementPreferenceFromState } from "./selectors"
import {
  MUTABLE_SENSOR_NAMES,
  SENSOR_DEFAULTS,
  SensorPorts,
  SprinklerTypes,
  SwitchTypes,
} from "./sensor-configurations"
import { getSensorConversionSpec } from "./sensor-conversions"
import {
  getSensorUnitLabel,
  useFormatSensorName,
  useSensorUnitLabel,
} from "./sensor-formatting"
import { SupportImage } from "./SupportImage"
import i18n from "./translations/i18n"
import { isValidNumber } from "./type-guards"
import { useRootSelector } from "./useRootSelector"

import type { Path } from "react-hook-form"
import type { FormControlProviderProps } from "./components/form-control/base"
import type { InputProps } from "./components/form-control/Input"
import type {
  MutableSensorName,
  SensorConfigKey,
  SensorName,
} from "./sensor-configurations"

import type { DeviceConfiguration } from "./device-configurations.reducer"
/**
 *
 */
export function radioValueToString(itemValue: unknown): string {
  if (isValidNumber(itemValue)) {
    return `${itemValue}`
  }
  if (!Boolean(itemValue)) {
    return ""
  }
  if (typeof itemValue === "string") {
    return itemValue
  }
  throw new TypeError(`Invalid value supplied ${typeof itemValue}`)
}

/**
 *
 */
export function MagnetSelect({
  helpContent,
  sensorName,
  ...rest
}: FormControlProviderProps & {
  sensorName: SensorName<"reel" | "wheel">
  helpContent?: React.JSX.Element
}): React.JSX.Element {
  const { required } = useFormValidation()

  const { t } = useTranslation("sensorFields")
  const { control } = useFormContext<DeviceConfiguration>()

  const fieldName = `${sensorName}.nMagnets` as const
  const { isOpen, onClose, onOpen } = useOpenState()

  const labelText = t("nMagnets.displayName")
  return (
    <Controller
      control={control}
      name={fieldName}
      rules={{ required }}
      render={({ field: { onChange, value }, fieldState }) => {
        const errorMessage = fieldState.error?.message
        const isInvalid = Boolean(errorMessage)

        let selectedValueAsString: string
        if (typeof value === "number") {
          selectedValueAsString = value.toFixed(0)
        } else {
          selectedValueAsString = ""
        }
        return (
          <FormControl.Provider
            isRequired
            id="nMagnets"
            isInvalid={isInvalid}
            {...rest}
          >
            <FormControlLabelContainer
              helpContent={
                <React.Fragment>
                  <HelpIconButton onPress={onOpen} />
                  <AlertDialog
                    isOpen={isOpen}
                    titleElement={labelText}
                    onClose={onClose}
                  >
                    <AlertDialogScrollView>{helpContent}</AlertDialogScrollView>
                  </AlertDialog>
                </React.Fragment>
              }
            >
              <FormControl.Label>{labelText}</FormControl.Label>
            </FormControlLabelContainer>
            <FormControl.Select
              label={labelText}
              selectedValue={selectedValueAsString}
              options={_.range(20, 1, -1).map((option) => {
                const optionAsString = `${option}`
                return {
                  label: optionAsString,
                  value: optionAsString,
                }
              })}
              onValueChange={(next) => onChange(Number.parseInt(next))}
            />
            <FormControl.ErrorMessage>{errorMessage}</FormControl.ErrorMessage>
          </FormControl.Provider>
        )
      }}
    />
  )
}

/**
 *
 */
export function ChooseSensorPort({
  sensorName,
  ...rest
}: FormControlProviderProps & {
  sensorName: MutableSensorName
}): React.JSX.Element {
  const { control, setValue, watch } = useFormContext<DeviceConfiguration>()

  const helpDialog = useOpenState()
  const { required } = useFormValidation()
  const formatSensorName = useFormatSensorName()

  const labelText: string = i18n.t("sensorPort.fieldLabelWithSensorName", {
    ns: "sensorFields",
    sensorName: formatSensorName(sensorName),
  })

  return (
    <Controller
      control={control}
      name={`${sensorName}.sensorPort`}
      rules={{ required }}
      render={({ field: { onChange, value }, fieldState }) => {
        const errorMessage = fieldState.error?.message
        const selectedValue = radioValueToString(value)
        const isInvalid = Boolean(errorMessage)
        const handleChange = (nextValue: string) => {
          const valueAsNumber = Number.parseInt(nextValue)
          if (!isValidNumber(valueAsNumber)) {
            throw new TypeError(
              `Unable to parse value for sensor port ${nextValue} to number`,
            )
          }
          const configValues = watch()
          // If the configuration values in the form have another sensor
          // with a sensor port, we need to automatically switch that other sensor
          // port to prevent two sensors from being assigned to the same
          // port

          // Find the name of the other sensor
          const otherSensorWithSensorPort = MUTABLE_SENSOR_NAMES.find(
            (otherSensorName) => {
              if (otherSensorName === sensorName) {
                return false
              }
              const otherSensorValues = configValues[otherSensorName]
              if (otherSensorValues) {
                return true
              }
              return false
            },
          )

          // If one exists, flip its value
          if (otherSensorWithSensorPort) {
            setValue(
              `${otherSensorWithSensorPort}.sensorPort`,
              valueAsNumber === SensorPorts.ONE
                ? SensorPorts.TWO
                : SensorPorts.ONE,
            )
          }
          return onChange(valueAsNumber)
        }
        return (
          <FormControl.Provider id="sensorPort" isInvalid={isInvalid} {...rest}>
            <FormControlLabelContainer
              helpContent={
                <React.Fragment>
                  <HelpIconButton onPress={helpDialog.onOpen} />
                  <AlertDialog
                    isOpen={helpDialog.isOpen}
                    titleElement={labelText}
                    onClose={helpDialog.onClose}
                  >
                    <AlertDialogScrollView>
                      <Column space="$2">
                        <AppText>
                          {i18n.t("sensorFields:sensorPort.description")}
                        </AppText>
                        <SupportImage
                          fileName="enclosure_front.png"
                          altText={i18n.t(
                            "sensorFields:sensorPort.images.enclosure_front.png.caption",
                          )}
                          captionText={i18n.t(
                            "sensorFields:sensorPort.images.enclosure_front.png.caption",
                          )}
                        />
                      </Column>
                    </AlertDialogScrollView>
                  </AlertDialog>
                </React.Fragment>
              }
            >
              <FormControl.Label>{labelText}</FormControl.Label>
            </FormControlLabelContainer>
            <Radio
              isInvalid={isInvalid}
              orientation="horizontal"
              selectedValue={selectedValue}
              options={[
                {
                  label: i18n.t("sensorFields:sensorPort.values.port-1"),
                  value: `${SensorPorts.ONE}`,
                },
                {
                  label: i18n.t("sensorFields:sensorPort.values.port-2"),
                  value: `${SensorPorts.TWO}`,
                },
              ]}
              onChange={handleChange}
            />
            <FormControl.ErrorMessage>{errorMessage}</FormControl.ErrorMessage>
          </FormControl.Provider>
        )
      }}
    />
  )
}

/**
 * Choose switch type
 */
export function ChooseSwitchType({
  sensorName,
}: {
  sensorName: SensorName<"hallSwitch" | "pressureSwitch">
}) {
  const { control } = useFormContext<DeviceConfiguration>()
  const { t } = useTranslation("sensorFields")
  const { required } = useFormValidation()
  const labelText: string = t("switchType.displayName")

  const helpDialog = useOpenState()

  return (
    <Controller
      control={control}
      name={`${sensorName}.switchType`}
      rules={{ required }}
      render={({ field: { onChange, value }, fieldState }) => {
        const errorMessage = fieldState.error?.message
        const isInvalid = Boolean(errorMessage)
        const selectedValue = radioValueToString(value)
        return (
          <FormControl.Provider id="switchType" isInvalid={isInvalid} w="$full">
            <FormControlLabelContainer
              helpContent={
                <React.Fragment>
                  <HelpIconButton onPress={helpDialog.onOpen} />
                  <AlertDialog
                    isOpen={helpDialog.isOpen}
                    titleElement={i18n.t("sensorFields:switchType.displayName")}
                    onClose={helpDialog.onClose}
                  >
                    <AlertDialogScrollView>
                      <AlertDialogBodyText>
                        {i18n.t("sensorFields:switchType.helpBodyText")}
                      </AlertDialogBodyText>
                    </AlertDialogScrollView>
                  </AlertDialog>
                </React.Fragment>
              }
            >
              <FormControl.Label>{labelText}</FormControl.Label>
            </FormControlLabelContainer>
            <Radio
              orientation="horizontal"
              selectedValue={selectedValue}
              textTransform="capitalize"
              options={[
                {
                  label: t("switchType.values.O"),
                  value: `${SwitchTypes.OPEN}`,
                },
                {
                  label: t("switchType.values.C"),
                  value: `${SwitchTypes.CLOSED}`,
                },
              ]}
              onChange={onChange}
            />

            <FormControl.ErrorMessage>{errorMessage}</FormControl.ErrorMessage>
          </FormControl.Provider>
        )
      }}
    />
  )
}

/**
 * Choose reel sprinkler type
 */
export function ChooseSprinklerType(props: FormControlProviderProps) {
  const { control } = useFormContext<DeviceConfiguration>()

  const { required } = useFormValidation()
  const labelText: string = i18n.t("sensorFields:sprinklerType.displayName")

  return (
    <Controller
      control={control}
      name="reel.sprinklerType"
      rules={{ required }}
      render={({ field: { onChange, value }, fieldState }) => {
        const errorMessage = fieldState.error?.message
        const isInvalid = Boolean(errorMessage)
        const selectedValue = radioValueToString(value)
        return (
          <FormControl.Provider
            id="sprinklerType"
            {...props}
            isInvalid={isInvalid}
          >
            <FormControl.Label>{labelText}</FormControl.Label>
            <Radio
              orientation="horizontal"
              selectedValue={selectedValue}
              textTransform="capitalize"
              options={[
                {
                  label: i18n.t("sensorFields:sprinklerType.values.gun"),
                  value: `${SprinklerTypes.GUN}`,
                },
                {
                  label: i18n.t("sensorFields:sprinklerType.values.boom"),
                  value: `${SprinklerTypes.BOOM}`,
                },
              ]}
              onChange={onChange}
            />
            <AlertCard
              bodyText={i18n.t("sensorFields:sprinklerType.description")}
              my="$4"
              severity="info"
            />
            <FormControl.ErrorMessage>{errorMessage}</FormControl.ErrorMessage>
          </FormControl.Provider>
        )
      }}
    />
  )
}
/**
 * Numeric input for sensor fields
 */
export function SensorTextInput({
  _input,
  fieldName,
  helpContent,
  sensorName,
  ...rest
}: FormControlProviderProps & {
  fieldName: SensorConfigKey
  sensorName: SensorName
  _input?: InputProps
  helpContent?: React.JSX.Element | null
}): React.JSX.Element {
  const [isHelpOpen, setIsHelpOpen] = React.useState(false)
  // This component requires a context provider with a device
  // configuration as a parent/grandparent etc.
  const { control } = useFormContext<DeviceConfiguration>()

  // Display changes for metric or US users
  const measurementPreference = useRootSelector(
    getUserMeasurementPreferenceFromState,
  )
  const conversionUnits = getSensorConversionSpec({
    fieldName,
    measurementPreference,
    target: "user",
  })

  const sigDigits = conversionUnits?.sigDigits ?? 0
  const labelText: string = i18n.t(`sensorFields:${fieldName}.displayName`)

  // Get validation rules
  const { decimalPattern, integerPattern, minValue, required } =
    useFormValidation()

  // If significant digits are greater than 0, enforce a decimal regex
  let keyboardType: InputProps["keyboardType"]
  let pattern: { message: string; value: RegExp }
  if (sigDigits > 0) {
    keyboardType = "numeric"
    pattern = decimalPattern
  } else {
    keyboardType = "number-pad"
    pattern = integerPattern
  }

  const fieldPath: Path<DeviceConfiguration> =
    `${sensorName}.${fieldName}` as Path<DeviceConfiguration>

  const isLoading = useIsPending("CreateConfiguration")
  // Unitlabels can be either a string or an object with metric and us props
  const unitLabel = getSensorUnitLabel({
    fieldName,
    labelLength: "long",
    measurementPreference,
  })
  return (
    <Controller
      key={fieldName}
      control={control}
      name={fieldPath}
      rules={{ min: minValue(0), pattern, required }}
      render={({ field: { onBlur, onChange, ref, value }, fieldState }) => {
        // Values are stored as numbers when possible.
        //  If they are blank, the value will be an empty string
        if (typeof value === "number") {
          value = `${value}`
        }
        // Coerce null values to empty string
        if (!Boolean(value)) {
          value = ``
        }
        if (typeof value !== "string") {
          throw new TypeError(`Received invalid value in Sensor Text Input`)
        }

        const errorMessage = fieldState.error?.message

        return (
          <FormControl.Provider
            id={fieldName}
            isDisabled={isLoading}
            isInvalid={Boolean(errorMessage)}
            {...rest}
          >
            <FormControlLabelContainer
              helpContent={
                helpContent ? (
                  <React.Fragment>
                    <HelpIconButton onPress={() => setIsHelpOpen(true)} />
                    <AlertDialog
                      isOpen={isHelpOpen}
                      titleElement={labelText}
                      onClose={() => setIsHelpOpen(false)}
                    >
                      <AlertDialogScrollView>
                        {helpContent}
                      </AlertDialogScrollView>
                    </AlertDialog>
                  </React.Fragment>
                ) : null
              }
            >
              <FormControl.Label>{labelText}</FormControl.Label>
            </FormControlLabelContainer>
            <FormControl.Input
              {..._input}
              ref={ref}
              keyboardType={keyboardType}
              returnKeyLabel="Done"
              returnKeyType="done"
              value={value}
              InputRightElement={
                unitLabel ? (
                  <InputRightAddon>{unitLabel}</InputRightAddon>
                ) : null
              }
              onBlur={onBlur}
              onChangeText={onChange}
            />
            <FormControl.ErrorMessage>{errorMessage}</FormControl.ErrorMessage>
          </FormControl.Provider>
        )
      }}
    />
  )
}

/**
 * Pressure sensor slider which must be used inside of form context
 */
export function PressureSliderConnected(): React.JSX.Element {
  const { control } = useFormContext<DeviceConfiguration>()
  return (
    <Controller
      control={control}
      name="pressure"
      render={({ field }) => {
        // let labelText: string | undefined

        // if (field.value) {
        //   labelText = t("sensorPort.displayNameWithVal", {
        //     ns: "sensorFields",
        //     val: field.value.sensorPort,
        //   })
        // }
        return (
          <React.Fragment>
            {/* {showHeader ? (
              <Row justifyContent="space-between" mb="$4">
                <FormControl.Label>
                  {t("pressureThresholdsStageTitle")}
                </FormControl.Label>
                {showSensorPort ? <Badge>{labelText}</Badge> : null}
              </Row>
            ) : null} */}
            <PressureThresholdSlider
              thresholdPsiLower={
                field.value?.thresholdPsiLower ??
                SENSOR_DEFAULTS.pressure.thresholdPsiLower
              }
              thresholdPsiUpper={
                field.value?.thresholdPsiUpper ??
                SENSOR_DEFAULTS.pressure.thresholdPsiUpper
              }
              onChange={(next) => {
                return field.onChange({ ...field.value, ...next })
              }}
            />
          </React.Fragment>
        )
      }}
    />
  )
}

/**
 *
 */
export function DeviceNameInput({
  _input,
  isRequired,
  ...rest
}: FormControlProviderProps & {
  _input?: InputProps
  isRequired?: boolean
}): React.JSX.Element {
  const { control } = useFormContext<DeviceConfiguration>()
  const { t } = useTranslation()
  const { required } = useFormValidation()
  const isLoading = useIsPending(
    "RenameDevice",
    "CreateConfiguration",
    "RestoreConfigurationDefaults",
  )

  const labelText: string = t("deviceName", { ns: "common" })
  const helpDialog = useOpenState()
  return (
    <Controller
      control={control}
      name="deviceName"
      rules={isRequired === true ? { required } : undefined}
      render={({ field: { onChange, ref, ...field }, fieldState }) => {
        const errorMessage = fieldState.error?.message
        return (
          <FormControl.Provider
            id="deviceName"
            isDisabled={isLoading}
            isInvalid={Boolean(errorMessage)}
            {...rest}
          >
            <FormControlLabelContainer
              helpContent={
                <React.Fragment>
                  <HelpIconButton onPress={helpDialog.onOpen} />
                  <AlertDialog
                    isOpen={helpDialog.isOpen}
                    titleElement={i18n.t(
                      "deviceConfiguration:customizeDeviceNameStageTitle",
                    )}
                    onClose={helpDialog.onClose}
                  >
                    <AlertDialogScrollView>
                      <AlertDialogBodyText>
                        {i18n.t(
                          "deviceConfiguration:customizeDeviceNameInstructions",
                        )}
                      </AlertDialogBodyText>
                    </AlertDialogScrollView>
                  </AlertDialog>
                </React.Fragment>
              }
            >
              <FormControl.Label>{labelText}</FormControl.Label>
            </FormControlLabelContainer>
            <FormControl.Input
              {..._input}
              ref={ref}
              onChangeText={onChange}
              {...field}
            />
            <FormControl.ErrorMessage>{errorMessage}</FormControl.ErrorMessage>
          </FormControl.Provider>
        )
      }}
    />
  )
}

export function FlowMillilitersPerPulseInput(props: FormControlProviderProps) {
  return (
    // TODO: Add help content
    <SensorTextInput fieldName="mlPerPulse" sensorName="flow" {...props} />
  )
}

const labelLength = "long"

export function ReelNozzleDiameterInput() {
  const getUnitLabel = useSensorUnitLabel()
  return (
    <SensorTextInput
      fieldName="nozzleDiameterMm"
      sensorName="reel"
      helpContent={
        <Column space="$2">
          <AppText>
            {i18n.t("sensorFields:nozzleDiameterMm.instructionsWithUnitLabel", {
              unitLabel: getUnitLabel("nozzleDiameterMm", {
                labelLength,
              }),
            })}
          </AppText>
          <SupportImage
            fileName="nozzle_diameter.png"
            resizeMode="cover"
            altText={i18n.t(
              "sensorFields:nozzleDiameterMm.images.nozzle_diameter.png.altText",
            )}
            captionText={i18n.t(
              "sensorFields:nozzleDiameterMm.images.nozzle_diameter.png.caption",
            )}
          />
        </Column>
      }
    />
  )
}

export function ReelNumberOfNozzlesInput() {
  return (
    <SensorTextInput
      fieldName="nNozzles"
      sensorName="reel"
      helpContent={
        <Column space="$2">
          <AppText>{i18n.t("sensorFields:nNozzles.description")}</AppText>
          <SupportImage
            fileName="irrigation_boom.png"
            altText={i18n.t(
              "sensorFields:nNozzles.images.irrigation_boom.png.caption",
            )}
            captionText={i18n.t(
              "sensorFields:nNozzles.images.irrigation_boom.png.caption",
            )}
          />
          <SupportImage
            fileName="irrigation_gun.png"
            altText={i18n.t(
              "sensorFields:nNozzles.images.irrigation_gun.png.caption",
            )}
            captionText={i18n.t(
              "sensorFields:nNozzles.images.irrigation_gun.png.caption",
            )}
          />
        </Column>
      }
    />
  )
}

export function ReelSwathWidthInput() {
  const getUnitLabel = useSensorUnitLabel()
  return (
    <SensorTextInput
      fieldName="swathWidthMm"
      sensorName="reel"
      helpContent={
        <AppText>
          {i18n.t("sensorFields:swathWidthMm.descriptionWithUnitLabel", {
            unitLabel: getUnitLabel("swathWidthMm", { labelLength }),
          })}
        </AppText>
      }
    />
  )
}

export function ReelOuterHoseWrapRadiusInput() {
  return (
    <SensorTextInput
      fieldName="outerHoseWrapRadiusMm"
      sensorName="reel"
      helpContent={
        <Column space="$2">
          <AppText>
            {i18n.t("sensorFields:outerHoseWrapRadiusMm.description")}
          </AppText>
          <AppText>
            {i18n.t(
              "sensorFields:outerHoseWrapRadiusMm.instructions.introduction",
            )}
          </AppText>
          <AppText>
            {i18n.t(
              "sensorFields:outerHoseWrapRadiusMm.instructions.options.1",
            )}
          </AppText>
          <AppText>
            {i18n.t(
              "sensorFields:outerHoseWrapRadiusMm.instructions.options.2",
            )}
          </AppText>
          <SupportImage
            captionText={null}
            fileName="outer_hose_wrap_radius.png"
            altText={i18n.t(
              "sensorFields:outerHoseWrapRadiusMm.images.reel_outer_hose_wraps.png.altText",
            )}
          />
        </Column>
      }
    />
  )
}

/**
 * Input for the number of outer layer wraps for the reel sensor
 */
export function ReelNumberOfOuterLayerWrapsInput() {
  return (
    <SensorTextInput
      fieldName="nWrapsOuterLayer"
      sensorName="reel"
      helpContent={
        <Column space="$2">
          <AppText>
            {i18n.t("sensorFields:nWrapsOuterLayer.description")}
          </AppText>
          <SupportImage
            fileName="reel_outer_hose_wraps.png"
            altText={i18n.t(
              "sensorFields:nWrapsOuterLayer.images.reel_outer_hose_wraps.png.caption",
            )}
            captionText={i18n.t(
              "sensorFields:nWrapsOuterLayer.images.reel_outer_hose_wraps.png.caption",
            )}
          />
        </Column>
      }
    />
  )
}

/**
 * Input for the diameter of the hose for the reel sensor
 */
export function ReelHoseDiameterInput() {
  return (
    <SensorTextInput
      fieldName="hoseDiameterMm"
      sensorName="reel"
      helpContent={
        <Column space="$2">
          <AppText>
            {i18n.t("sensorFields:hoseDiameterMm.instructions.1")}
          </AppText>
          <AppText>
            {i18n.t("sensorFields:hoseDiameterMm.instructions.2")}
          </AppText>
          <SupportImage
            fileName="hose_diameter.png"
            altText={i18n.t(
              "sensorFields:hoseDiameterMm.images.hose_diameter.png.caption",
            )}
            captionText={i18n.t(
              "sensorFields:hoseDiameterMm.images.hose_diameter.png.caption",
            )}
          />
        </Column>
      }
    />
  )
}

/**
 * Input for the width of the reel sensor
 */
export function ReelWidthInput() {
  return (
    <SensorTextInput
      fieldName="widthMm"
      sensorName="reel"
      helpContent={
        <Column space="$2">
          <AppText>{i18n.t("sensorFields:widthMm.instructions")}</AppText>
          <SupportImage
            fileName="reel_width.png"
            style={{ height: SIZES.$96 }}
            altText={i18n.t(
              "sensorFields:widthMm.images.reel_width.png.caption",
            )}
            captionText={i18n.t(
              "sensorFields:widthMm.images.reel_width.png.caption",
            )}
          />
        </Column>
      }
    />
  )
}

/**
 * Select the number of magnets for the reel sensor
 */
export function ReelNumberOfMagnetsSelect() {
  return (
    <MagnetSelect
      sensorName="reel"
      helpContent={
        <Column space="$2">
          <AppText>{i18n.t("sensorFields:nMagnets.description")}</AppText>
          <SupportImage
            fileName="magnet_installation_positions.png"
            altText={i18n.t(
              "sensorFields:nMagnets.images.magnet_installation_positions.png.caption",
            )}
            captionText={i18n.t(
              "sensorFields:nMagnets.images.magnet_installation_positions.png.caption",
            )}
          />
        </Column>
      }
    />
  )
}

export function WheelDiameterInput() {
  // TODO: Add help content
  return <SensorTextInput fieldName="diameterMm" sensorName="wheel" />
}
