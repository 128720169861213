import React from "react"
import { StyleSheet } from "react-native"
import { useStyle } from "react-native-style-utilities"

import { Box } from "./Box"

import type { SpaceToken } from "./theme"

import type { BoxProps } from "./Box"
const styles = StyleSheet.create({
  root: {
    display: "flex",
    flexDirection: "column",
  },
})

export interface ColumnProps extends BoxProps {
  _items?: BoxProps
  space?: SpaceToken
}

const childProps: BoxProps = {
  w: "$full",
}
export function Column({
  _items,
  alignItems = "stretch",
  children,
  justifyContent = "flex-start",
  space,
  style,
  ...rest
}: ColumnProps) {
  let isChildRendered = false
  return (
    <Box
      style={useStyle(() => {
        return [styles.root, { alignItems, justifyContent }, style]
      }, [alignItems, justifyContent, style])}
      {...rest}
    >
      {space
        ? React.Children.map(children, (child, index) => {
            if (child === null) {
              return child
            }

            if (!isChildRendered) {
              isChildRendered = true
              // First child should not have space
              return (
                <Box {...childProps} {..._items} key={index}>
                  {child}
                </Box>
              )
            }

            return (
              <Box {...childProps} {..._items} key={index} mt={space}>
                {child}
              </Box>
            )
          })
        : children}
    </Box>
  )
}
