import React from "react"

import * as CreateConfiguration from "./CreateDeviceConfigurationContext"
import {
  ChooseSensorPort,
  ChooseSprinklerType,
  ReelHoseDiameterInput,
  ReelNozzleDiameterInput,
  ReelNumberOfMagnetsSelect,
  ReelNumberOfNozzlesInput,
  ReelNumberOfOuterLayerWrapsInput,
  ReelOuterHoseWrapRadiusInput,
  ReelSwathWidthInput,
  ReelWidthInput,
} from "./DeviceSensorFields"
import i18n from "./translations/i18n"

const STAGES = [
  // nozzle
  "nozzleDiameterMm",
  "nNozzles",
  "swathWidthMm",
  "sprinklerType",
  // hose
  "outerHoseWrapRadiusMm",
  "nWrapsOuterLayer",
  "hoseDiameterMm",
  // spool
  "widthMm",
  "nMagnets",
  // Misc.
  "sensorPort",
  "pressureSensorYesOrNo",
  "pressureSensorModel",
  "pressureSensorThresholds",

  "deviceName",
  "confirm",
] as const

/**
 * New Create Reel Flow
 */
export function ConfigureReel() {
  const [stage, setStage] = React.useState<(typeof STAGES)[number]>(STAGES[0])

  const stageIndex = STAGES.indexOf(stage)
  const { form, onCancel, onClearInstallationType } =
    CreateConfiguration.useContext()

  const stageProps: CreateConfiguration.StageProps = {
    onPressCancel: () => {
      setStage((prev) => {
        return STAGES[stageIndex - 1] ?? prev
      })
    },
    onPressSubmit: form.handleSubmit(() => {
      setStage((prev) => {
        return STAGES[stageIndex + 1] ?? prev
      })
    }),
  }
  switch (stage) {
    case "nozzleDiameterMm": {
      return (
        <CreateConfiguration.ConfigurationStage
          titleText={i18n.t("sensorFields:nozzleDiameterMm.displayName")}
          {...stageProps}
          cancelText={i18n.t("cancel")}
          onPressCancel={() => {
            if (onClearInstallationType) {
              return onClearInstallationType()
            }
            return onCancel()
          }}
        >
          <ReelNozzleDiameterInput />
        </CreateConfiguration.ConfigurationStage>
      )
    }
    case "nNozzles": {
      return (
        <CreateConfiguration.ConfigurationStage
          titleText={i18n.t("sensorFields:nNozzles.displayName")}
          {...stageProps}
        >
          <ReelNumberOfNozzlesInput />
        </CreateConfiguration.ConfigurationStage>
      )
    }
    case "swathWidthMm": {
      return (
        <CreateConfiguration.ConfigurationStage
          titleText={i18n.t("sensorFields:swathWidthMm.displayName")}
          {...stageProps}
        >
          <ReelSwathWidthInput />
        </CreateConfiguration.ConfigurationStage>
      )
    }
    case "sprinklerType": {
      return (
        <CreateConfiguration.ConfigurationStage
          titleText={i18n.t("sensorFields:sprinklerType.displayName")}
          {...stageProps}
        >
          <ChooseSprinklerType />
        </CreateConfiguration.ConfigurationStage>
      )
    }
    case "outerHoseWrapRadiusMm": {
      return (
        <CreateConfiguration.ConfigurationStage
          titleText={i18n.t("sensorFields:outerHoseWrapRadiusMm.displayName")}
          {...stageProps}
        >
          <ReelOuterHoseWrapRadiusInput />
        </CreateConfiguration.ConfigurationStage>
      )
    }
    case "nWrapsOuterLayer": {
      return (
        <CreateConfiguration.ConfigurationStage
          titleText={i18n.t("sensorFields:nWrapsOuterLayer.displayName")}
          {...stageProps}
        >
          <ReelNumberOfOuterLayerWrapsInput />
        </CreateConfiguration.ConfigurationStage>
      )
    }
    case "hoseDiameterMm": {
      return (
        <CreateConfiguration.ConfigurationStage
          titleText={i18n.t("sensorFields:hoseDiameterMm.displayName")}
          {...stageProps}
        >
          <ReelHoseDiameterInput />
        </CreateConfiguration.ConfigurationStage>
      )
    }
    case "widthMm": {
      return (
        <CreateConfiguration.ConfigurationStage
          titleText={i18n.t("sensorFields:widthMm.displayName")}
          {...stageProps}
        >
          <ReelWidthInput />
        </CreateConfiguration.ConfigurationStage>
      )
    }
    case "nMagnets": {
      return (
        <CreateConfiguration.ConfigurationStage
          titleText={i18n.t("sensorFields:nMagnets.displayName")}
          {...stageProps}
        >
          <ReelNumberOfMagnetsSelect />
        </CreateConfiguration.ConfigurationStage>
      )
    }
    case "sensorPort": {
      return (
        <CreateConfiguration.ConfigurationStage
          titleText={i18n.t("sensorFields:sensorPort.displayName")}
          {...stageProps}
        >
          <ChooseSensorPort sensorName="reel" />
        </CreateConfiguration.ConfigurationStage>
      )
    }
    case "pressureSensorYesOrNo": {
      return (
        <CreateConfiguration.PressureSensorYesOrNoStage
          onCancel={() => {
            return setStage("sensorPort")
          }}
          onValueChange={(hasPressure) => {
            if (hasPressure) {
              setStage("pressureSensorModel")
            } else {
              setStage("deviceName")
            }
          }}
        />
      )
    }
    case "pressureSensorModel": {
      return (
        <CreateConfiguration.ChoosePressureSensorModelStage
          onPressCancel={() => setStage("pressureSensorYesOrNo")}
          onPressSubmit={() => setStage("pressureSensorThresholds")}
        />
      )
    }
    case "pressureSensorThresholds": {
      return (
        <CreateConfiguration.SetPressureThresholdsStage
          onPressCancel={() => setStage("pressureSensorModel")}
          onPressSubmit={() => setStage("deviceName")}
        />
      )
    }
    case "deviceName": {
      return (
        <CreateConfiguration.CustomizeDeviceNameStage
          onPressSubmit={() => setStage("confirm")}
          onPressCancel={() => {
            if (form.watch("pressure")) {
              setStage("pressureSensorThresholds")
            } else {
              setStage("pressureSensorYesOrNo")
            }
          }}
        />
      )
      break
    }
    case "confirm": {
      return (
        <CreateConfiguration.ReviewAndSubmitStage
          sensorNames={["reel", form.watch("pressure") && "pressure"]}
          onPressCancel={() => setStage("deviceName")}
        />
      )
    }
  }
}
