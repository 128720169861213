import React from "react"
import { Platform, StyleSheet } from "react-native"
import { ToastProvider as BaseToastProvider } from "react-native-toast-notifications"

import { MaterialCommunityIcons, MaterialIcons } from "@expo/vector-icons"

import { useEnvironmentVariables } from "../Environment"
import { COLORS } from "./theme/colors"
import { FONT_SIZES, getFontName } from "./theme/fonts"

import type { AcceptsChildren } from "./types"

const ICON_SIZE = 20

const styles = StyleSheet.create({
  text: {
    color: COLORS.$white,
    fontFamily: getFontName("OpenSans_600SemiBold"),
    fontSize: FONT_SIZES.$md,
  },
})

const INFO_ICON = (
  <MaterialCommunityIcons
    color={COLORS.$white}
    name="information"
    size={ICON_SIZE}
  />
)

const SUCCESS_ICON = (
  <MaterialCommunityIcons color={COLORS.$white} name="check" size={ICON_SIZE} />
)

const ERROR_ICON = (
  <MaterialIcons color={COLORS.$white} name="error" size={ICON_SIZE} />
)

export function ToastProvider(props: AcceptsChildren) {
  const { isEndToEndTest } = useEnvironmentVariables()
  return (
    <BaseToastProvider
      swipeEnabled
      dangerIcon={ERROR_ICON}
      duration={isEndToEndTest ? 300 : undefined}
      icon={INFO_ICON}
      normalColor={COLORS.$info[600]}
      successColor={COLORS.$green[600]}
      successIcon={SUCCESS_ICON}
      textStyle={styles.text}
      placement={Platform.select({
        default: "bottom",
        web: "top",
      })}
    >
      {props.children}
    </BaseToastProvider>
  )
}
