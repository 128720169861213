import React from "react"
import { useTranslation } from "react-i18next"
import { StyleSheet } from "react-native"

import { createSelector } from "@reduxjs/toolkit"

import { ActionButtons } from "./ActionButtons"
import { denyPermission } from "./actions"
import { AddPhoneNumberButton } from "./AddPhoneNumberForm"
import {
  AppText,
  Badge,
  Box,
  Button,
  Card,
  FlatList,
  Row,
  Switch,
  Theme,
  View,
} from "./components"
import { AppIcons } from "./components/icons/AppIcons"
import {
  deletePhoneNumberAsync,
  setNotificationStatusForPhoneNumberAsync,
} from "./farmhq-api"
import * as Models from "./models"
import { NoListItems } from "./NoListItems"
import i18n from "./translations/i18n"
import { useBackendRequest } from "./useBackendRequest"
import { getIsDemoModeActiveFromState } from "./user-farms.selectors"
import { getCanUserAddPhoneNumbers } from "./user-phone-numbers.reducer"
import { useRootSelector } from "./useRootSelector"
import { useGenerateSmsVerification } from "./VerifyPhoneNumber"

import type { BadgeProps, FlatListProps, HelpContentStatic } from "./components"
interface ItemType
  extends Pick<
    Models.PhoneNumber,
    "id" | "isVerified" | "notificationsEnabled" | "serialized"
  > {
  index: number
}

export const HELP_CONTENT: HelpContentStatic = {
  bodyElement: i18n.t("phoneNumbers:addPhoneNumberHelpBodyText"),
  subject: "add_phone_number",
  titleElement: i18n.t("phoneNumbers:addPhoneNumberHelpTitle"),
}

function useTexts() {
  return useTranslation("phoneNumbers")
}

const getData = createSelector(Models.phoneNumber.selectAll, (numbers) => {
  return numbers.map(
    ({ id, isVerified, notificationsEnabled, serialized }, index): ItemType => {
      return {
        id,
        index,
        isVerified,
        notificationsEnabled,
        serialized,
      }
    },
  )
})

function ConfirmDelete({
  onCancelDelete,
  upnId,
}: {
  onCancelDelete: () => void
  upnId: number
}): React.JSX.Element {
  const { t } = useTexts()
  const { handleError, isLoading, sendRequest, toasts } = useBackendRequest(
    deletePhoneNumberAsync,
  )

  const handleSubmit = () => {
    sendRequest({ upnId })
      .then(() => toasts.success())
      .catch((error) => handleError(error, { toastMessage: "default" }))
  }

  return (
    <Box id="delete-phone-number-confirmation" maxW="$xs" my="$4">
      <AppText
        style={{ color: Theme.colors.$warning[500], marginLeft: "auto" }}
      >
        {t("deletePhoneNumber")}
      </AppText>
      <ActionButtons
        isDisabled={isLoading}
        isLoading={isLoading}
        my="$2"
        onPressCancel={onCancelDelete}
        onPressSubmit={handleSubmit}
      />
    </Box>
  )
}

function NotificationsToggle(
  props: Pick<ItemType, "id" | "notificationsEnabled">,
) {
  const { dispatch, handleError, sendRequest, toasts } = useBackendRequest(
    setNotificationStatusForPhoneNumberAsync,
  )
  const isDemoEnabled = useRootSelector(getIsDemoModeActiveFromState)
  const handleSubmit = (nextValue: boolean) => {
    if (isDemoEnabled) {
      dispatch(denyPermission({ reason: "REQUEST_BLOCKED_IN_DEMO" }))
    } else {
      sendRequest({
        nextStatus: nextValue ? "enabled" : "disabled",
        upnId: props.id,
      })
        .then(() => toasts.success())
        .catch((error) => handleError(error, { toastMessage: "default" }))
    }
  }
  return (
    <Switch
      value={props.notificationsEnabled}
      onValueChange={handleSubmit}
      onChange={(e) => {
        if (isDemoEnabled) {
          e.preventDefault()
        }
      }}
    />
  )
}

function VerifiedBadge({
  isVerified,
  ...badgeProps
}: BadgeProps & {
  isVerified: boolean
}): React.JSX.Element {
  const { t } = useTranslation()

  if (isVerified) {
    return (
      <Badge
        {...badgeProps}
        StartIconComponent={AppIcons.Success}
        colorScheme="$success"
      >
        {t("verifiedTrue")}
      </Badge>
    )
  }
  return (
    <Badge
      {...badgeProps}
      StartIconComponent={AppIcons.Warning}
      colorScheme="$warning"
    >
      {t("verifiedFalse")}
    </Badge>
  )
}

const styles = StyleSheet.create({
  root: {
    paddingVertical: Theme.spacing.$4,
    position: "relative",
  },
})

/**
 * Show the phone number, verification status and an options menu.
 *
 * When verify is pressed a code is sent. On success, navigate to verify phone
 * number.
 */
function RenderItem({
  id: upnId,
  index,
  isDeleting,
  isVerified,
  notificationsEnabled,
  onCancelDelete,
  onPressDelete,
  onPressVerify,
  serialized,
}: ItemType & {
  isDeleting: boolean
  // itemCount: number
  onCancelDelete: () => void
  onPressDelete: () => void
  onPressVerify: () => unknown
}) {
  const { handleError, handleGenerateCode, isCodeLoading } =
    useGenerateSmsVerification({ upnId })

  return (
    <View id={`phone-numbers-list-item-${index}`} style={styles.root}>
      <Row alignItems="center">
        <AppText>{serialized}</AppText>
        <Box ml="auto" mr="$2">
          <VerifiedBadge isVerified={isVerified} />
        </Box>
      </Row>
      {isVerified ? (
        <Row mt="$4">
          <Box mr="$2">
            <AppText colorScheme="secondary">
              {i18n.t("notificationsEnabled", { ns: "common" })}
            </AppText>
          </Box>

          <NotificationsToggle
            id={upnId}
            notificationsEnabled={notificationsEnabled}
          />
        </Row>
      ) : null}
      <Row mr="auto" mt="$4">
        <Box mr="$8">
          <Button
            IconComponent="TrashCan"
            id="delete-phone-number-btn"
            text={i18n.t("delete")}
            variant="danger"
            onPress={onPressDelete}
          />
        </Box>
        {isVerified ? null : (
          <Button
            IconComponent="VerifyPhoneNumber"
            id="verify-phone-number-btn"
            isDisabled={isCodeLoading}
            text={i18n.t("phoneNumbers:verifyButton")}
            variant="primary"
            onPress={() => {
              handleGenerateCode().then(onPressVerify).catch(handleError)
            }}
          />
        )}
      </Row>
      {isDeleting ? (
        <ConfirmDelete upnId={upnId} onCancelDelete={onCancelDelete} />
      ) : null}
    </View>
  )
}

// TODO: Make this look better

/**
 * List of phone numbers with the ability to add a new one.
 */
export function PhoneNumbersList({
  onPressAdd,
  onPressVerify,
  onTooManyNumbers,
  ...rest
}: Omit<FlatListProps<ItemType>, "data" | "renderItem"> & {
  onPressAdd: () => void
  onPressVerify: (pn: { upnId: number }) => void
  onTooManyNumbers: () => void
}): React.JSX.Element {
  const data = useRootSelector(getData)
  const canAdd = useRootSelector(getCanUserAddPhoneNumbers)
  const [idToDelete, setIdToDelete] = React.useState<number>()

  const addButtonElement = (
    <Box ml="auto">
      <AddPhoneNumberButton
        onPress={() => {
          if (canAdd) {
            onPressAdd()
          } else {
            onTooManyNumbers()
          }
        }}
      />
    </Box>
  )
  return (
    <FlatList
      ListFooterComponent={addButtonElement}
      id="phone-numbers-list"
      ListEmptyComponent={
        <Box m="auto">
          <NoListItems
            action={addButtonElement}
            message={i18n.t("phoneNumbers:listEmptyMessage")}
            severity="warning"
          />
        </Box>
      }
      {...rest}
      data={data}
      renderItem={({ item }) => {
        return (
          <Card my="$2">
            <RenderItem
              key={item.id}
              isDeleting={idToDelete === item.id}
              onCancelDelete={() => setIdToDelete(undefined)}
              onPressDelete={() => setIdToDelete(item.id)}
              onPressVerify={() => onPressVerify({ upnId: item.id })}
              {...item}
            />
          </Card>
        )
      }}
    />
  )
}
