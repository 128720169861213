import { minutesToSeconds } from "date-fns"
import React from "react"
import { Controller, useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { Platform } from "react-native"

import { ActionButtons } from "./ActionButtons"
import {
  AppText,
  Box,
  FormControl,
  KeyboardAvoidingView,
  Row,
} from "./components"
import { SIZES } from "./components/theme"
import { useToasts } from "./components/useToasts"
import { callNamedDeviceActionAsync } from "./farmhq-api"
import { useFormValidation } from "./form-validation"
import * as Models from "./models"
import { useIsPending } from "./requests.reducer"
import { SingleValueSlider } from "./sliders"
import { isValidNumber } from "./type-guards"
import { useErrorHandler } from "./useErrorHandler"
import { useRootDispatch } from "./useRootDispatch"
import { useRootSelector } from "./useRootSelector"

import type { FormControlProviderProps } from "./components/form-control/base"
import type { InputProps } from "./components/form-control/Input"
import type { DeviceIdProps } from "./types"
const SECONDS_DEFAULT = 10
const MINUTES_DEFAULT = 0
export const SET_VFD_SPEED_FORM_ACTION_NAME = "pump_vfd_start_ramp"
/**
 * Set speed for VFD pump, either immediately or using a 'ramp'
 */
export function SetVfdSpeedForm({
  actionId,
  deviceId,
  onClosed,
  onSuccess,
}: DeviceIdProps & {
  actionId: number
  onClosed: () => void
  onSuccess: () => void
}) {
  const { t } = useTranslation("devices")
  const isLoading = useIsPending("CallNamedDeviceAction")

  const defaultSpeedPercent = useRootSelector(
    (state) =>
      Models.deviceEventLast.selectById(state, deviceId)?.vfd
        ?.currentPercentage ?? 0,
  )
  const dispatch = useRootDispatch()
  const handleError = useErrorHandler()
  const toasts = useToasts()

  const validationRules = useFormValidation()

  /**
   * All inputs in this form must be a positive integer or the form
   * cannot be submitted.
   */
  const inputRules = {
    min: validationRules.minValue(0),
    pattern: validationRules.integerPattern,
    required: validationRules.required,
  }

  const formControlProps: FormControlProviderProps = {
    flexGrow: 1,
    isDisabled: isLoading,
    w: "$16",
  }

  const form = useForm({
    defaultValues: {
      minutes: MINUTES_DEFAULT,
      seconds: SECONDS_DEFAULT,
      speedPercent: defaultSpeedPercent,
    },
  })

  const onSubmit = form.handleSubmit(({ minutes, seconds, speedPercent }) => {
    if (!isValidNumber(minutes)) {
      throw new TypeError(
        `Invalid value for minutes: ${minutes as string} (${typeof minutes})`,
      )
    }
    if (!isValidNumber(seconds)) {
      throw new TypeError(
        `Invalid value for seconds: ${seconds as string} (${typeof seconds})`,
      )
    }
    if (
      !isValidNumber(speedPercent) ||
      speedPercent < 0 ||
      speedPercent > 100
    ) {
      throw new TypeError(
        `Invalid value for speedPercent: ${speedPercent} (${typeof speedPercent})`,
      )
    }

    const totalSeconds = minutesToSeconds(minutes) + seconds

    dispatch(
      callNamedDeviceActionAsync({
        actionId,
        arguments: {
          targetRampOverSeconds: totalSeconds,
          targetRampPercentage: speedPercent,
        },
        deviceId,
      }),
    )
      .unwrap()
      .then(() => {
        toasts.info(t("requestSentToDevice", { ns: "common" }))
        return onSuccess()
      })
      .catch((error) => {
        handleError(error, { toastMessage: "default" })
      })
  })
  const keyboardType: InputProps["keyboardType"] = Platform.select({
    android: "number-pad",
    ios: "numeric",
  })
  const commonProps: InputProps = {
    keyboardType,
    returnKeyType: "done",
  }
  return (
    <KeyboardAvoidingView
      id="set-vfd-speed-form"
      style={{ minWidth: SIZES.$xs }}
    >
      <AppText>{t("setVfdSpeedFormFrequencyLabel")}</AppText>
      <Controller
        control={form.control}
        name="speedPercent"
        render={({ field: { onChange, value, ...field }, fieldState }) => {
          const errorMessage = fieldState.error?.message
          const isInvalid = Boolean(errorMessage)
          const percentSign: string = t("percentSign", { ns: "common" })

          return (
            <FormControl.Provider isInvalid={isInvalid}>
              <Row>
                <Box flex={1} mr="$4">
                  <SingleValueSlider
                    maximumValue={100}
                    minimumValue={0}
                    value={value}
                    onValueChange={onChange}
                  />
                </Box>
                <Box w="$20">
                  <FormControl.Input
                    {...field}
                    {...commonProps}
                    value={`${value}`}
                    InputRightElement={
                      <Box mr="$2">
                        <AppText>{percentSign}</AppText>
                      </Box>
                    }
                    onChangeText={(nextValue) => {
                      // If possible, coerce to number
                      const asNumber = Number.parseInt(nextValue)
                      if (isValidNumber(asNumber)) {
                        return onChange(asNumber)
                      }
                      return onChange(nextValue)
                    }}
                  />
                </Box>
              </Row>
              <FormControl.ErrorMessage>
                {errorMessage}
              </FormControl.ErrorMessage>
            </FormControl.Provider>
          )
        }}
        rules={{
          ...inputRules,
          max: validationRules.maxValue(100),
        }}
      />
      <Box>
        <AppText>{t("setVfdSpeedFormRampTimeInstructions")}</AppText>
        <Row>
          <Controller
            control={form.control}
            name="minutes"
            rules={inputRules}
            render={({ field: { onChange, value, ...field }, fieldState }) => {
              const errorMessage = fieldState.error?.message
              return (
                <FormControl.Provider
                  mr="$2"
                  {...formControlProps}
                  isInvalid={Boolean(errorMessage)}
                >
                  <FormControl.Label>
                    {t("setVfdSpeedFormMinutesInputLabel")}
                  </FormControl.Label>
                  <FormControl.Input
                    {...commonProps}
                    value={`${value}`}
                    onChangeText={(nextValue) => {
                      // If possible, coerce to number
                      const asNumber = Number.parseInt(nextValue)
                      if (isValidNumber(asNumber)) {
                        return onChange(asNumber)
                      }
                      return onChange(nextValue)
                    }}
                    {...field}
                  />
                  <FormControl.ErrorMessage>
                    {errorMessage}
                  </FormControl.ErrorMessage>
                </FormControl.Provider>
              )
            }}
          />
          <Controller
            control={form.control}
            name="seconds"
            rules={inputRules}
            render={({ field: { onChange, value, ...field }, fieldState }) => {
              const errorMessage = fieldState.error?.message
              return (
                <FormControl.Provider
                  {...formControlProps}
                  isInvalid={Boolean(errorMessage)}
                >
                  <FormControl.Label>
                    {t("setVfdSpeedFormSecondsInputLabel")}
                  </FormControl.Label>
                  <FormControl.Input
                    {...commonProps}
                    value={`${value}`}
                    onChangeText={(nextValue) => {
                      // If possible, coerce to number
                      const asNumber = Number.parseInt(nextValue)
                      if (isValidNumber(asNumber)) {
                        return onChange(asNumber)
                      }
                      return onChange(nextValue)
                    }}
                    {...field}
                  />
                  <FormControl.ErrorMessage>
                    {errorMessage}
                  </FormControl.ErrorMessage>
                </FormControl.Provider>
              )
            }}
          />
        </Row>
      </Box>
      <ActionButtons
        isLoading={isLoading}
        mt="$4"
        onPressCancel={onClosed}
        onPressSubmit={onSubmit}
      />
    </KeyboardAvoidingView>
  )
}
