import React from "react"
import { useTranslation } from "react-i18next"
import { isRouteErrorResponse, useRouteError } from "react-router-dom"

import { getIsUserAuthenticatedFromState, useRootSelector } from "@fhq/app"
import { useSignOutButton } from "@fhq/app/Auth"
import { testId } from "@fhq/app/components"
import { SPACING } from "@fhq/app/components/theme"
import { isPlainObject } from "@reduxjs/toolkit"
import * as Sentry from "@sentry/react"

import { Environment, logger } from "./base"
import { useCurrentRoutePath } from "./useRoutePath"

import type { DeepPartial } from "utility-types"
const STYLES: { [key in "container"]: React.CSSProperties } = {
  container: {
    alignItems: "center",
    backgroundColor: "#fff",
    display: "flex",
    flex: 1,
    flexDirection: "column",
    justifyContent: "center",
    paddingLeft: SPACING.$4,
    paddingRight: SPACING.$4,
  },
}

/**
 * Allow user to sign out
 */
function SignOutBtn(): React.JSX.Element | null {
  const isAuthenticated = useRootSelector(getIsUserAuthenticatedFromState)

  const { onPress, text } = useSignOutButton()
  return isAuthenticated ? <button onClick={onPress}>{text}</button> : null
}

interface RouteError {
  data?: string
  error?: unknown
  internal?: boolean
  status?: number
  statusText?: string
}
export function PageErrorBoundary() {
  const error = useRouteError()
  const path = useCurrentRoutePath()

  const { t } = useTranslation("errors")

  React.useEffect(() => {
    logger.error("Showing error page boundary...")
    if (!Environment.isEndToEndTest) {
      let exception: Error
      if (error instanceof Error) {
        exception = error
      } else if (isPlainObject(error)) {
        const { message, name, stack } = error as { [key: string]: unknown }
        exception = new Error(JSON.stringify({ message, name, stack }))
      } else {
        exception = new Error(path)
      }
      Sentry.captureException(exception)
    }
  }, [error, path])

  let statusCode = 500
  if (Boolean(error) && typeof error === "object") {
    const e = error as DeepPartial<RouteError>
    if (typeof e.status === "number") {
      statusCode = e.status
    }
  }
  let title: string = t("loadErrorTitle")
  let message: string = t("loadErrorMessage")

  if (isRouteErrorResponse(error)) {
    if (error.status === 404) {
      title = t("notFoundTitle")
      message = t("notFoundMessage")
    } else if (error.status === 401) {
      title = t("permissionDeniedTitle")
      message = t("permissionDeniedMessage")
    }
  }

  return (
    <div data-testid={testId("error")} id="error" style={STYLES.container}>
      <div style={STYLES.container}>
        {typeof error === "string" ? (
          error
        ) : (
          <React.Fragment>
            <h1>{title}</h1>
            <h3 className="text-secondary">{statusCode}</h3>
            <p className="text-secondary">{message}</p>
          </React.Fragment>
        )}
        <SignOutBtn />
      </div>
    </div>
  )
}
