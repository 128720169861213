import {
  configureLogging,
  fileLoggerFactory,
  getLogLevel,
  Loggers,
} from "@fhq/app"
import { COLORS } from "@fhq/app/components/theme"
import { Urls } from "@fhq/app/Internal"

import {
  APP_VERSION,
  IS_END_TO_END_TEST,
  TARGET_DATABASE_NAME,
} from "./sentry"

// INITIALIZE SENTRY

/**
 * Environment variables
 */
export const Environment = {
  appVersion: APP_VERSION,
  getTimezone: () => Intl.DateTimeFormat().resolvedOptions().timeZone,
  isAnalyticsEnabled: TARGET_DATABASE_NAME === "PROD" && !__DEV__,
  isEndToEndTest: IS_END_TO_END_TEST,
  linkUrls: Urls.getWebappUrls(
    TARGET_DATABASE_NAME,
    process.env.REACT_APP_LOCALHOST,
  ),
  localhost: process.env.REACT_APP_LOCALHOST ?? null,
  logLevel: getLogLevel({
    logLevel:
      process.env.LOG_LEVEL ?? process.env.REACT_APP_LOG_LEVEL ?? "error",
    targetDatabaseName: TARGET_DATABASE_NAME,
  }),
  targetDatabaseName: TARGET_DATABASE_NAME,
} as const

// LOGGING
configureLogging({ level: Environment.logLevel })

export const logger = Loggers.WEB
logger.debug(Environment)
export const makeFileLogger = fileLoggerFactory("WEB")

export const SWATH_OUTLINE_STROKE_WEIGHT = 2

export const BASE_SWATH_FILL_OPACITY = 0.5
export const BASE_SWATH_OPTIONS: google.maps.PolygonOptions = {
  fillColor: COLORS.$reelRunSwath.fill,
  fillOpacity: BASE_SWATH_FILL_OPACITY,
  strokeColor: COLORS.$reelRunSwath.outline,
}
