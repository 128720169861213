import React from "react"
import { SectionList, StyleSheet } from "react-native"

import { ActionButtons } from "./ActionButtons"
import { AppText, Divider, Heading, Paper, Row } from "./components"
import { SPACING } from "./components/theme"
import {
  loadFarmPermissionsForUserAsync,
  updateFarmUserPermissionsAsync,
} from "./farmhq-api"
import { SetPermissionListItem } from "./PermissionListItem"
import {
  FARM_USER_PERMISSION_SECTIONS,
  FARM_USER_PERMISSIONS,
} from "./permissions"
import {
  FarmUserPermissionHelpDialog,
  useFarmUserPermissionsHelpDialog,
} from "./PermissionsForm"
import { ResponsiveCard } from "./ResponsiveCard"
import i18n from "./translations/i18n"
import { useBackendRequest } from "./useBackendRequest"
import { getActiveFarmNameFromState } from "./user-farms.selectors"
import { useRootSelector } from "./useRootSelector"

import type { HelpContentStatic } from "./components"

import type { FarmUserPermissionName, FarmUserPermissions } from "./permissions"
// eslint-disable-next-line @typescript-eslint/consistent-type-definitions
export type ManagePermissionsParams = {
  targetUserEmail: string
  targetUserId: string
}
export const HELP_CONTENT: HelpContentStatic = {
  bodyElement: i18n.t("farmUserPermissions:farmUserPermissionsHelpSummary"),
  subject: "farm_users_list",
  titleElement: i18n.t("farmUserPermissions:aboutFarmUserPermissions"),
}
export function useLoadFarmUserPermissionsForUser({
  isFocused,
  targetUserId,
}: {
  isFocused: boolean
  targetUserId?: string
}) {
  const [isError, setIsError] = React.useState(false)
  const { isLoading, sendRequest } = useBackendRequest(
    loadFarmPermissionsForUserAsync,
  )
  const [permissions, setPermissions] = React.useState(
    FARM_USER_PERMISSIONS.allFalse,
  )
  React.useEffect(() => {
    if (isFocused && typeof targetUserId === "string") {
      sendRequest({ targetUserId })
        .then((response) => {
          return setPermissions(response)
        })
        .catch(() => {
          setIsError(true)
        })
    }
  }, [isFocused, sendRequest, targetUserId])

  return {
    isError,
    isLoading,
    onValueChange: (key: FarmUserPermissionName) => {
      return (next: boolean) => setPermissions({ ...permissions, [key]: next })
    },
    permissions,
  }
}

const styles = StyleSheet.create({
  headerText: {
    marginVertical: SPACING["$0.5"],
  },
})

/**
 * Manage permissions for a user
 */
export function ManagePermissionsForm({
  onChange,
  onClose,
  permissions,
  targetUserEmail,
  targetUserId,
}: ManagePermissionsParams & {
  onChange: (name: FarmUserPermissionName) => (nextValue: boolean) => void
  onClose: () => void
  permissions: FarmUserPermissions
}) {
  const farmName = useRootSelector(getActiveFarmNameFromState) ?? ""

  const { handleError, isLoading, sendRequest, toasts } = useBackendRequest(
    updateFarmUserPermissionsAsync,
  )

  const handleSubmit = () => {
    sendRequest({ permissions, targetUserId })
      .then(() => {
        toasts.success()
        return onClose()
      })
      .catch((error) => {
        handleError(error, { toastMessage: "default" })
      })
  }

  const { helpKey, onCloseHelpDialog, setHelpKey } =
    useFarmUserPermissionsHelpDialog()
  return (
    <React.Fragment>
      <FarmUserPermissionHelpDialog
        helpKey={helpKey}
        onClose={onCloseHelpDialog}
      />
      <SectionList
        ItemSeparatorComponent={Divider}
        sections={FARM_USER_PERMISSION_SECTIONS}
        ListHeaderComponent={
          <Row justifyContent="space-between" mb="$0">
            <AppText colorScheme="secondary" style={styles.headerText}>
              {farmName}
            </AppText>
            <AppText style={styles.headerText}>{targetUserEmail}</AppText>
          </Row>
        }
        contentContainerStyle={{
          paddingBottom: SPACING.$toolbar,
        }}
        renderItem={({ item }) => {
          return (
            <SetPermissionListItem
              isEnabled={permissions[item]}
              name={item}
              px="$4"
              onPressHelp={setHelpKey}
              onValueChange={onChange(item)}
            />
          )
        }}
        renderSectionHeader={({ section }) => {
          return (
            <ResponsiveCard isCompact px="$4" py="$2">
              <Heading colorScheme="secondary" variant="h6">
                {section.titleText}
              </Heading>
            </ResponsiveCard>
          )
        }}
      />
      <Paper p="$4">
        <ActionButtons
          isLoading={isLoading}
          mt="$0"
          onPressCancel={onClose}
          onPressSubmit={handleSubmit}
        />
      </Paper>
    </React.Fragment>
  )
}
