import React from "react"
import { StyleSheet } from "react-native"
import { useNavigate } from "react-router"

import { DeviceCycler, DeviceSummaryContext, useRootSelector } from "@fhq/app"
import { getCurrentBreakpointKey } from "@fhq/app/app-state.reducer"
import {
  AppText,
  Box,
  CloseIconButton,
  COLORS,
  Container,
  Divider,
  Heading,
  RADII,
  Row,
  TitleWithIcon,
  useBreakpointValue,
  useIsDarkMode,
  View,
} from "@fhq/app/components"
import { InstallationTypeIndicator } from "@fhq/app/InstallationTypeIndicator"

import { SEGMENT_CLIENT } from "./base/Analytics"
import { DEVICE_PROFILE_BASE_PAGES } from "./DeviceProfileLayout"
import { Tab } from "./Tab"

import type { BoxProps, IconProp } from "@fhq/app/components"

const styles = StyleSheet.create({
  tabContainerDark: {
    backgroundColor: COLORS.$paper.dark,
  },
  tabsContainer: {
    backgroundColor: COLORS.$paper.light,
    borderRadius: RADII.$default,
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
  },
})

function TabLinks() {
  const isDark = useIsDarkMode()
  const navigate = useNavigate()
  const device = DeviceSummaryContext.useContext()
  const basePath = `/devices/${device.codaDeviceAlias}/`
  const currentPath = window.location.pathname
  return (
    <View
      id="nav-menu"
      style={[styles.tabsContainer, isDark && styles.tabContainerDark]}
    >
      {DEVICE_PROFILE_BASE_PAGES.map((page) => {
        let isSelected = false

        if (page.relativePath === "") {
          isSelected =
            currentPath === basePath || `${currentPath}/` === basePath
        } else if (
          currentPath.substring(basePath.length).startsWith(page.relativePath)
        ) {
          isSelected = true
        }

        return (
          <Tab
            key={page.id}
            IconComponent={page.IconComponent}
            id={`${page.id}-link`}
            isSelected={isSelected}
            text={page.name}
            onPress={() => navigate(`${basePath}${page.relativePath}`)}
          />
        )
      })}
    </View>
  )
}

export function DeviceProfilePage({
  HelpContent,
  IconComponent,
  children,
  dialogElement,
  onClose,
  titleText,
  ...rest
}: BoxProps & {
  HelpContent?: React.JSX.Element | null | undefined
  IconComponent?: IconProp
  dialogElement?: React.JSX.Element
  onClose?: () => void
  titleText?: string
  titleTextSecondary?: string
}) {
  const navigate = useNavigate()
  const getValue = useBreakpointValue()
  const device = DeviceSummaryContext.useContext()
  const isMobile = useRootSelector(
    (state) => getCurrentBreakpointKey(state) === "xs",
  )
  return (
    <Box flex={1} maxW="$5xl" mx="auto" w="$full" {...rest}>
      {dialogElement}
      <Container>
        <Row
          alignItems="flex-start"
          flexWrap="nowrap"
          justifyContent="space-between"
          pb="$2"
          pt="$2"
        >
          <Row alignItems="flex-start" flex={1}>
            <Box flex={1} mr="$2">
              <Heading
                isTruncated
                variant={getValue({
                  base: "h5",
                  md: "h4",
                })}
              >
                {device.deviceName}
              </Heading>
              {device.codaDeviceAlias === device.deviceName ? null : (
                <AppText isTruncated colorScheme="secondary" fontSize="$sm">
                  {device.codaDeviceAlias}
                </AppText>
              )}
            </Box>
            <Box mr="$2">
              <InstallationTypeIndicator
                value={device.deviceInstallationType}
              />
            </Box>
          </Row>
          <DeviceCycler
            analyticsClient={SEGMENT_CLIENT}
            deviceId={device.deviceId}
            onPress={({ codaDeviceAlias }) => {
              navigate(`/devices/${codaDeviceAlias}`)
            }}
          />
        </Row>
      </Container>
      {isMobile ? null : ( // <Divider />
        <Container pb="$2">
          <TabLinks />
        </Container>
      )}
      {typeof titleText === "string" ? (
        <Container>
          <TitleWithIcon
            IconComponent={IconComponent}
            colorScheme="secondary"
            helpElement={HelpContent}
            mb="$1"
            titleText={titleText}
            headingVariant={getValue({
              base: "h5",
              lg: "h3",
            })}
            rightElement={
              onClose ? <CloseIconButton onPress={onClose} /> : null
            }
          />
          <Divider />
        </Container>
      ) : null}

      {children}

      {isMobile ? (
        <React.Fragment>
          <Divider />
          <Container pb="$4" pt="$2">
            <TabLinks />
          </Container>
        </React.Fragment>
      ) : null}
    </Box>
  )
}
