import React from "react"
import { useLocation, useNavigate } from "react-router"

import {
  CreateConnection,
  i18n,
  ResponsiveCard,
  useRootDispatch,
  useRootSelector,
} from "@fhq/app"
import {
  AppIcons,
  Box,
  Column,
  Container,
  ScrollView,
  Theme,
} from "@fhq/app/components"

import { PageContainer } from "./base/PageContainer"
import { PageHeader } from "./base/PageHeader"

import type { CreateConnectionRouteParams } from "@fhq/app/CreateDeviceConnections"
export function CreateDeviceConnectionPage() {
  const dispatch = useRootDispatch()
  const navigate = useNavigate()
  const routerState = useLocation().state as
    | CreateConnectionRouteParams
    | undefined
  const initiatorDeviceId = routerState?.initiatorDeviceId

  CreateConnection.useInitiatorDevice({ initiatorDeviceId })

  /**
   * Reset the form when the component is unmounted
   */
  React.useEffect(() => {
    return () => {
      dispatch(CreateConnection.resetCreateConnectionsForm())
    }
  }, [dispatch])
  const isCreatingDevicePair = useRootSelector(
    CreateConnection.isCreatingDevicePair,
  )
  const handleClose = () => navigate(-1)
  const titleText: string = i18n.t("createDeviceConnection:newDeviceConnection")

  return (
    <PageContainer flex={1}>
      <Container>
        <PageHeader
          IconComponent="DeviceConnection"
          helpContent={CreateConnection.HELP_CONTENT}
          titleText={titleText}
        />
      </Container>
      <ScrollView
        style={{ flexGrow: 0 }}
        contentContainerStyle={{
          flexGrow: 0,
          paddingBottom: Theme.spacing.$toolbar,
        }}
      >
        <ResponsiveCard
          titleText={i18n.t("createDeviceConnection:conditionSectionTitle")}
        >
          <Column space="$1">
            <CreateConnection.SourceDeviceSelect />
            <CreateConnection.SourceSensorSelect />
            <CreateConnection.SourceSenseorStatePreviousSelect />
            <CreateConnection.SourceSensorStateCurrentSelect />
          </Column>
        </ResponsiveCard>
        <Box mx="auto" my="$2">
          <AppIcons.ArrowDown />
        </Box>
        <ResponsiveCard
          titleText={i18n.t("createDeviceConnection:actionSectionTitle")}
        >
          <Column space="$1">
            <CreateConnection.TargetDeviceSelect />
            <CreateConnection.TargetActionSelect />
          </Column>
          <CreateConnection.FormErrorMessage />
        </ResponsiveCard>
      </ScrollView>
      <Container pb="$4" variant="pageFooter">
        <CreateConnection.Actions onClose={handleClose} />
        {isCreatingDevicePair ? (
          <CreateConnection.CreatingDevicePairAlert />
        ) : null}
      </Container>
    </PageContainer>
  )
}
