import type { LinearGradientProps } from "expo-linear-gradient"
import { LinearGradient } from "expo-linear-gradient"
import React from "react"
import { StyleSheet } from "react-native"
import { useStyle } from "react-native-style-utilities"

import { AppText } from "./Text"
import { COLORS, RADII, SPACING } from "./theme"

import type { ViewStyle } from "react-native"
import type { AppTextProps } from "./Text"
import type { RadiusProp } from "./theme"
import type { AcceptsChildren } from "./types"

const styles = StyleSheet.create({
  orientationBottom: {
    // alignItems: "flex-end",
    bottom: 0,
    paddingBottom: SPACING.$2,
    paddingTop: SPACING.$8,
  },
  orientationTop: {
    alignItems: "flex-start",
    paddingBottom: SPACING.$8,
    paddingTop: SPACING.$1,
    top: 0,
  },
  root: {
    alignItems: "center",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    left: 0,
    paddingHorizontal: SPACING.$2,
    position: "absolute",
    right: 0,
  },
  text: {
    marginRight: SPACING.$2,
  },
})

const GRADIENTS = {
  bottom: [COLORS.$transparent, COLORS.$black],
  top: [COLORS.$black, COLORS.$transparent],
}
interface ImageCaptionGradientProps extends AcceptsChildren {
  actionElement?: React.JSX.Element
  fontFamily?: AppTextProps["fontFamily"]
  fontSize?: AppTextProps["fontSize"]
  fontWeight?: AppTextProps["fontWeight"]
  justifyContent?: ViewStyle["justifyContent"]
  orientation?: "bottom" | "top"
  rounded?: RadiusProp
  style?: LinearGradientProps["style"]
  textElement?: React.JSX.Element | string
}

export function ImageCaptionGradient({
  actionElement,
  children,
  fontFamily,
  fontSize,
  fontWeight,
  justifyContent = "flex-start",
  orientation = "bottom",
  rounded = "$none",
  style,
  textElement,
}: ImageCaptionGradientProps) {
  return (
    <LinearGradient
      colors={GRADIENTS[orientation]}
      style={useStyle(() => {
        const borderRadius = RADII[rounded]
        return [
          styles.root,
          { justifyContent },
          orientation === "top"
            ? styles.orientationTop
            : styles.orientationBottom,
          borderRadius && orientation === "top"
            ? {
                borderTopLeftRadius: borderRadius,
                borderTopRightRadius: borderRadius,
              }
            : {
                borderBottomLeftRadius: borderRadius,
                borderBottomRightRadius: borderRadius,
              },
          style,
        ]
      })}
    >
      {children}
      {typeof textElement === "string" ? (
        <AppText
          isTruncated
          colorScheme="lightText"
          fontFamily={fontFamily}
          fontSize={fontSize}
          fontWeight={fontWeight}
          style={styles.text}
        >
          {textElement}
        </AppText>
      ) : (
        textElement
      )}
      {actionElement}
    </LinearGradient>
  )
}
