import React from "react"

import i18n from "../translations/i18n"
import {
  AlertDialog,
  AlertDialogBodyText,
  AlertDialogScrollView,
} from "./AlertDialog"
import { CloseButton, HelpIconButton } from "./Button"
import { useOpenState } from "./useOpenState"

import type { SetOptional } from "type-fest"

import type { makeEventTrackerHook } from "../Analytics"
import type { AlertDialogProps } from "./AlertDialog"
import type { ElementOrComponent } from "./types"
/**
 * Hook to track when the help dialog is opened
 */
export function makeUseHelpDialog(
  useTrackEvent: ReturnType<typeof makeEventTrackerHook>,
) {
  return function useHelpDialog({ subject }: { subject: string }) {
    const trackEvent = useTrackEvent()

    const { isOpen, onClose, onOpen } = useOpenState()
    return {
      isHelpOpen: isOpen,
      onCloseHelp: onClose,
      onOpenHelp: () => {
        onOpen()
        trackEvent({
          help_topic: subject,
          name: "open_help_dialog",
        })
      },
    }
  }
}
export interface HelpContentStatic {
  bodyElement: React.JSX.Element | string
  subject: string
  ContentComponent?: ElementOrComponent
  titleElement?: React.JSX.Element | string
}
export function HelpDialog({
  bodyElement,
  children,
  onClose,
  titleElement,
  ...rest
}: SetOptional<AlertDialogProps, "titleElement"> & {
  bodyElement?: React.JSX.Element | string
}) {
  return (
    <AlertDialog
      IconComponent="Info"
      actionElement={<CloseButton onPress={onClose} />}
      id="help-content"
      titleElement={titleElement ?? i18n.t("help")}
      onClose={onClose}
      {...rest}
    >
      <AlertDialogScrollView>
        {typeof bodyElement === "string" ? (
          <AlertDialogBodyText>{bodyElement}</AlertDialogBodyText>
        ) : (
          bodyElement
        )}
        {children}
      </AlertDialogScrollView>
    </AlertDialog>
  )
}

export function makeHelpComponent(
  trackEvent: ReturnType<typeof makeEventTrackerHook>,
) {
  const useHelpDialog = makeUseHelpDialog(trackEvent)
  return function HelpComponent({
    bodyElement,
    subject,
    titleElement: titleText,
  }: HelpContentStatic) {
    const { isHelpOpen, onCloseHelp, onOpenHelp } = useHelpDialog({
      subject,
    })
    return (
      <React.Fragment>
        <HelpIconButton onPress={onOpenHelp} />
        <HelpDialog
          bodyElement={bodyElement}
          isOpen={isHelpOpen}
          titleElement={titleText}
          onClose={onCloseHelp}
        />
      </React.Fragment>
    )
  }
}
