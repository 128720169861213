import type { Reducer } from "@reduxjs/toolkit"
import { createSlice, isAnyOf } from "@reduxjs/toolkit"

import { loadDeviceActivityAsync } from "./farmhq-api"
import { loadActiveFarmAsync } from "./load-app"
import * as Models from "./models"

const initialState: Models.ModelState<Models.DeviceActivity, string> = {
  entities: {},
  ids: [],
}

const slice = createSlice({
  extraReducers: (builder) => {
    builder.addMatcher(
      isAnyOf(loadDeviceActivityAsync.fulfilled, loadActiveFarmAsync.fulfilled),
      (state, action) => {
        Models.deviceActivity.adapter.setAll(
          state,
          action.payload.deviceActivities,
        )
      },
    )
  },
  initialState,
  name: "deviceActivities",
  reducers: {},
})
export const deviceActivitiesReducer: Reducer<typeof initialState> =
  slice.reducer
