import type { Paths } from "type-fest"

const $orange = {
  0: "#fff7ed",
  100: "#ffedd5",
  200: "#fed7aa",
  300: "#fdba74",
  400: "#fb923c",
  500: "#f97316",
  600: "#ea580c",
  700: "#c2410c",
  800: "#9a3412",
  900: "#7c2d12",
}
const $green = {
  0: "#f0fdf4",
  100: "#dcfce7",
  200: "#bbf7d0",
  300: "#86efac",
  400: "#4ade80",
  500: "#22c55e",
  600: "#16a34a",
  700: "#15803d",
  800: "#166534",
  900: "#14532d",
}
const $lightBlue = {
  0: "#f0f9ff",
  100: "#e0f2fe",
  200: "#bae6fd",
  300: "#7dd3fc",
  400: "#38bdf8",
  500: "#0ea5e9",
  600: "#0284c7",
  700: "#0369a1",
  800: "#075985",
  900: "#0c4a6e",
}
const $blue = {
  0: "#eff6ff",
  100: "#dbeafe",
  200: "#bfdbfe",
  300: "#93c5fd",
  400: "#60a5fa",
  500: "#3b82f6",
  600: "#2563eb",
  700: "#1d4ed8",
  800: "#1e40af",
  900: "#1e3a8a",
}
const $cyan = {
  0: "#ecfeff",
  100: "#cffafe",
  200: "#a5f3fc",
  300: "#67e8f9",
  400: "#22d3ee",
  500: "#06b6d4",
  600: "#0891b2",
  700: "#0e7490",
  800: "#155e75",
  900: "#164e63",
}
const PALETTE = {
  $amber: {
    0: "#fffbeb",
    100: "#fef3c7",
    200: "#fde68a",
    300: "#fcd34d",
    400: "#fbbf24",
    500: "#f59e0b",
    600: "#d97706",
    700: "#b45309",
    800: "#92400e",
    900: "#78350f",
  },
  $blue,
  $blueGray: {
    0: "#f8fafc",
    100: "#f1f5f9",
    200: "#e2e8f0",
    300: "#cbd5e1",
    400: "#94a3b8",
    500: "#64748b",
    600: "#475569",
    700: "#334155",
    800: "#1e293b",
    900: "#0f172a",
  },
  $coolGray: {
    0: "#f9fafb",
    100: "#f3f4f6",
    200: "#e5e7eb",
    300: "#d1d5db",
    400: "#9ca3af",
    500: "#6b7280",
    600: "#4b5563",
    700: "#374151",
    800: "#1f2937",
    900: "#111827",
  },
  $cyan,
  $dark: {
    0: "#18181b",
    100: "#27272a",
    200: "#3f3f46",
    300: "#52525b",
    400: "#71717a",
    500: "#a1a1aa",
    600: "#d4d4d8",
    700: "#e4e4e7",
    800: "#f4f4f5",
    900: "#fafafa",
  },
  $darkBlue: {
    0: "#dbf4ff",
    100: "#addbff",
    200: "#7cc2ff",
    300: "#4aa9ff",
    400: "#1a91ff",
    500: "#0077e6",
    600: "#005db4",
    700: "#004282",
    800: "#002851",
    900: "#000e21",
  },
  $emerald: {
    0: "#ecfdf5",
    100: "#d1fae5",
    200: "#a7f3d0",
    300: "#6ee7b7",
    400: "#34d399",
    500: "#10b981",
    600: "#059669",
    700: "#047857",
    800: "#065f46",
    900: "#064e3b",
  },
  $fuchsia: {
    0: "#fdf4ff",
    100: "#fae8ff",
    200: "#f5d0fe",
    300: "#f0abfc",
    400: "#e879f9",
    500: "#d946ef",
    600: "#c026d3",
    700: "#a21caf",
    800: "#86198f",
    900: "#701a75",
  },
  $gray: {
    0: "#fafafa",
    100: "#f4f4f5",
    200: "#e4e4e7",
    300: "#d4d4d8",
    400: "#a1a1aa",
    500: "#71717a",
    600: "#52525b",
    700: "#3f3f46",
    800: "#27272a",
    900: "#18181b",
  },
  $green,
  $indigo: {
    0: "#eef2ff",
    100: "#e0e7ff",
    200: "#c7d2fe",
    300: "#a5b4fc",
    400: "#818cf8",
    500: "#6366f1",
    600: "#4f46e5",
    700: "#4338ca",
    800: "#3730a3",
    900: "#312e81",
  },
  $info: $lightBlue,
  $lightBlue,
  $lime: {
    0: "#f7fee7",
    100: "#ecfccb",
    200: "#d9f99d",
    300: "#bef264",
    400: "#a3e635",
    500: "#84cc16",
    600: "#65a30d",
    700: "#4d7c0f",
    800: "#3f6212",
    900: "#365314",
  },
  $orange,
  $pink: {
    0: "#fdf2f8",
    100: "#fce7f3",
    200: "#fbcfe8",
    300: "#f9a8d4",
    400: "#f472b6",
    500: "#ec4899",
    600: "#db2777",
    700: "#be185d",
    800: "#9d174d",
    900: "#831843",
  },
  $purple: {
    0: "#faf5ff",
    100: "#f3e8ff",
    200: "#e9d5ff",
    300: "#d8b4fe",
    400: "#c084fc",
    500: "#a855f7",
    600: "#9333ea",
    700: "#7e22ce",
    800: "#6b21a8",
    900: "#581c87",
  },
  $red: {
    0: "#fef2f2",
    100: "#fee2e2",
    200: "#fecaca",
    300: "#fca5a5",
    400: "#f87171",
    500: "#ef4444",
    600: "#dc2626",
    700: "#b91c1c",
    800: "#991b1b",
    900: "#7f1d1d",
  },
  $rose: {
    0: "#fff1f2",
    100: "#ffe4e6",
    200: "#fecdd3",
    300: "#fda4af",
    400: "#fb7185",
    500: "#f43f5e",
    600: "#e11d48",
    700: "#be123c",
    800: "#9f1239",
    900: "#881337",
  },
  $success: $green,
  $teal: {
    0: "#f0fdfa",
    100: "#ccfbf1",
    200: "#99f6e4",
    300: "#5eead4",
    400: "#2dd4bf",
    500: "#14b8a6",
    600: "#0d9488",
    700: "#0f766e",
    800: "#115e59",
    900: "#134e4a",
  },
  $text: {
    0: "#fafafa",
    100: "#f5f5f5",
    200: "#e5e5e5",
    300: "#d4d4d4",
    400: "#a3a3a3",
    500: "#737373",
    600: "#525252",
    700: "#404040",
    800: "#262626",
    900: "#171717",
  },
  $trueGray: {
    0: "#fafafa",
    100: "#f5f5f5",
    200: "#e5e5e5",
    300: "#d4d4d4",
    400: "#a3a3a3",
    500: "#737373",
    600: "#525252",
    700: "#404040",
    800: "#262626",
    900: "#171717",
  },
  $violet: {
    0: "#f5f3ff",
    100: "#ede9fe",
    200: "#ddd6fe",
    300: "#c4b5fd",
    400: "#a78bfa",
    500: "#8b5cf6",
    600: "#7c3aed",
    700: "#6d28d9",
    800: "#5b21b6",
    900: "#4c1d95",
  },
  $warmGray: {
    0: "#fafaf9",
    100: "#f5f5f4",
    200: "#e7e5e4",
    300: "#d6d3d1",
    400: "#a8a29e",
    500: "#78716c",
    600: "#57534e",
    700: "#44403c",
    800: "#292524",
    900: "#1c1917",
  },
  $warning: $orange,
  $yellow: {
    0: "#fefce8",
    100: "#fef9c3",
    200: "#fef08a",
    300: "#fde047",
    400: "#facc15",
    500: "#eab308",
    600: "#eab308",
    700: "#a16207",
    800: "#854d0e",
    900: "#713f12",
  },
}
export type ColorSchemeType = keyof typeof PALETTE

const $white = "#FFFFFF"
const $offwhite = "#F5F5F5"
const $black = "#000000"

export const COLORS = {
  ...PALETTE,
  $background: {
    dark: PALETTE.$gray[900],
    light: "#F5F5F5",
  },
  $black,
  $bottomSheet: {
    dark: PALETTE.$dark[100],
    light: $offwhite,
  },
  $buttonPrimary: {
    dark: PALETTE.$yellow[500],
    light: PALETTE.$yellow[500],
  },
  $connectionLine: PALETTE.$green[500],
  $divider: {
    dark: PALETTE.$gray[500],
    light: PALETTE.$gray[300],
  },
  $error: PALETTE.$red,
  $invalid: PALETTE.$red[500],
  $link: {
    dark: $blue[200],
    light: $blue[600],
  },
  $overlay: "rgba(0, 0, 0, 0.5)",
  $paper: {
    // dark: PALETTE.$warmGray[800],
    dark: PALETTE.$gray[800],
    light: $white,
  },
  $primary: PALETTE.$yellow,
  $reelRunHeatmap: {
    base: PALETTE.$blue[600],
    shadow: $black,
  },
  $reelRunSwath: {
    arrow: $cyan[300],
    fill: $blue[300],
    outline: $blue[600],
  },
  $secondary: PALETTE.$teal,
  $tertiary: PALETTE.$emerald,
  $textDark: {
    primary: PALETTE.$text[900],
    secondary: PALETTE.$text[600],
  },
  $textError: PALETTE.$red[500],
  $textLight: {
    primary: PALETTE.$text[0],
    secondary: PALETTE.$text[400],
  },
  $transparent: "#00000000",
  $white,
}

export const NAV_THEME = {
  background: {
    dark: "#010101",
    light: "#f5f5f5",
  },
  border: {
    dark: "#272929",
    light: "#D8D8D8",
  },
  card: {
    dark: "#282929",
    light: $white,
  },
  notification: {
    dark: "#FA3B30",
    light: "#FA453A",
  },
  primary: {
    dark: "#0A84FF",
    light: "#007AFF",
  },
  text: {
    dark: COLORS.$textDark.primary,
    light: COLORS.$textLight.primary,
  },
}
export type ColorPath = Paths<typeof PALETTE>

export type ColorKey = keyof typeof COLORS
