import React from "react"
import { useFormContext } from "react-hook-form"

import { Badge, Divider, getIconForSensor, TitleWithIcon } from "./components"
import {
  ListItemTextPrimary,
  ListItemTextSecondary,
} from "./components/ListItem"
import { Row } from "./components/Row"
import { useMeasurementPreference } from "./selectors"
import {
  formatSensorValue,
  useFormatSensorName,
  useSensorUnitLabel,
} from "./sensor-formatting"
import { isAdminOnlyField } from "./sensors"
import i18n from "./translations/i18n"

import type { Entry } from "type-fest"
import type { DeviceConfiguration } from "./device-configurations.reducer"
import type { SensorConfigKey, SensorName } from "./sensor-configurations"
/**
 * Display the fields of the given sensor with an edit button
 */
export function SensorConfigValuesList({
  sensorName,
}: {
  sensorName: SensorName
}): React.JSX.Element | null {
  const { watch } = useFormContext<DeviceConfiguration>()
  const getUnitLabel = useSensorUnitLabel()
  const measurementPreference = useMeasurementPreference()
  const formatSensorName = useFormatSensorName()
  const values = watch(sensorName)

  if (!values) {
    return null
  }
  const data = Object.entries(values).filter(([key]) => {
    if (key === "sensorPort") {
      return false
    }
    if (isAdminOnlyField(key as SensorConfigKey)) {
      return false
    }
    return true
  })
  const sensorNameFormatted = formatSensorName(sensorName)
  let sensorPortIndicator: React.JSX.Element | null = null
  if ("sensorPort" in values) {
    const sensorPort = values.sensorPort
    if (typeof sensorPort === "number") {
      sensorPortIndicator = (
        <Badge>
          {i18n.t("sensorFields:sensorPort.displayNameWithVal", {
            val: sensorPort,
          })}
        </Badge>
      )
    }
  }
  return (
    <React.Fragment>
      <TitleWithIcon
        IconComponent={getIconForSensor(sensorName)}
        colorScheme="secondary"
        headingVariant="h5"
        rightElement={sensorPortIndicator}
        titleText={`${sensorNameFormatted} ${i18n.t(`sensor`)}`}
      />
      {data.map((item, index) => {
        const [fieldName, rawValue] = item as Entry<typeof values>
        const valueFormatted = formatSensorValue({
          convertValues: false,
          fieldName,
          measurementPreference,
          rawValue,
        })
        const element = (
          <Row
            key={fieldName}
            id={fieldName}
            justifyContent="space-between"
            py="$1"
          >
            <ListItemTextSecondary>
              {i18n.t(`sensorFields:${fieldName}.displayName`)}
            </ListItemTextSecondary>
            <ListItemTextPrimary>
              {`${valueFormatted ?? i18n.t("none")} ${getUnitLabel(fieldName)}`}
            </ListItemTextPrimary>
          </Row>
        )

        return (
          <React.Fragment key={fieldName}>
            {index === data.length - 1 ? (
              element
            ) : (
              <React.Fragment>
                {element}
                <Divider />
              </React.Fragment>
            )}
          </React.Fragment>
        )
      })}
    </React.Fragment>
  )
}
