import account from "./account.json";
import addDevice from "./addDevice.json";
import auth from "./auth.json";
import common from "./common.json";
import createDeviceConnection from "./createDeviceConnection.json";
import createFarmJoinCode from "./createFarmJoinCode.json";
import createField from "./createField.json";
import createSchedule from "./createSchedule.json";
import demoMode from "./demoMode.json";
import dev from "./dev.json";
import deviceActions from "./deviceActions.json";
import deviceAnalytics from "./deviceAnalytics.json";
import deviceComments from "./deviceComments.json";
import deviceConfiguration from "./deviceConfiguration.json";
import deviceConnections from "./deviceConnections.json";
import deviceEventHistory from "./deviceEventHistory.json";
import deviceNotifications from "./deviceNotifications.json";
import deviceOwnership from "./deviceOwnership.json";
import deviceProfile from "./deviceProfile.json";
import devices from "./devices.json";
import errors from "./errors.json";
import farmJoinCodes from "./farmJoinCodes.json";
import farmUserPermissions from "./farmUserPermissions.json";
import farmUsers from "./farmUsers.json";
import feedback from "./feedback.json";
import fieldIrrigationHistory from "./fieldIrrigationHistory.json";
import fields from "./fields.json";
import flowAnalytics from "./flowAnalytics.json";
import noFarms from "./noFarms.json";
import phoneNumbers from "./phoneNumbers.json";
import schedules from "./schedules.json";
import sensorFields from "./sensorFields.json";
import sensorStates from "./sensorStates.json";
import sensors from "./sensors.json";
import setDeviceLocationPermanent from "./setDeviceLocationPermanent.json";
import setLinearPath from "./setLinearPath.json";
import statusMap from "./statusMap.json";
import subscriptions from "./subscriptions.json";
import support from "./support.json";
import termsOfService from "./termsOfService.json";
import validation from "./validation.json";

const locale={ 
	account,
	addDevice,
	auth,
	common,
	createDeviceConnection,
	createFarmJoinCode,
	createField,
	createSchedule,
	demoMode,
	dev,
	deviceActions,
	deviceAnalytics,
	deviceComments,
	deviceConfiguration,
	deviceConnections,
	deviceEventHistory,
	deviceNotifications,
	deviceOwnership,
	deviceProfile,
	devices,
	errors,
	farmJoinCodes,
	farmUserPermissions,
	farmUsers,
	feedback,
	fieldIrrigationHistory,
	fields,
	flowAnalytics,
	noFarms,
	phoneNumbers,
	schedules,
	sensorFields,
	sensorStates,
	sensors,
	setDeviceLocationPermanent,
	setLinearPath,
	statusMap,
	subscriptions,
	support,
	termsOfService,
	validation,
 } as const
export default locale