import React from "react"

import type { AcceptsChildren } from "./components"

import type { SharedEnvironment } from "./types"

type ProviderProps = SharedEnvironment

type ContextValue = SharedEnvironment

const Context = React.createContext<ContextValue | undefined>(undefined)

/**
 * Provides common environment variables across platforms
 */
export function Provider({
  children,
  ...rest
}: AcceptsChildren & ProviderProps): JSX.Element | null {
  return <Context.Provider value={rest}>{children}</Context.Provider>
}

export function useEnvironmentVariables(): ContextValue {
  const ctx = React.useContext(Context)
  if (typeof ctx === "undefined") {
    throw new TypeError(`Context must be used inside of provider`)
  }
  return ctx
}
