import React from "react"
import {
  Controller,
  FormProvider,
  useForm,
  useFormContext,
} from "react-hook-form"
import { Platform, StyleSheet } from "react-native"
import { useStyle } from "react-native-style-utilities"

import { ActionButtons } from "./ActionButtons"
import { ChoosePressureSensorModel } from "./ChoosePressureSensorModel"
import {
  AlertCard,
  AppText,
  Box,
  Button,
  Column,
  Divider,
  Row,
  ScrollView as BaseScrollView,
  useBreakpointValue,
  useIsDarkMode,
  View,
} from "./components"
import { COLORS, RADII, SIZES, SPACING, Theme } from "./components/theme"
import {
  ChooseSensorPort,
  DeviceNameInput,
  PressureSliderConnected,
} from "./DeviceSensorFields"
import { useEnvironmentVariables } from "./Environment"
import { createConfigurationAsync } from "./farmhq-api"
import * as Geo from "./geo"
import { makeFileLogger } from "./logger"
import {
  getIsAdminModeEnabledFromState,
  getUserMeasurementPreferenceFromState,
  useMeasurementPreference,
} from "./selectors"
import {
  CONFIG_SENSOR_NAMES,
  formatInstallationType,
  MUTABLE_SENSOR_NAMES,
  SENSOR_DEFAULTS,
  SensorPorts,
} from "./sensor-configurations"
import {
  convertSensorValue,
  getSensorConversionSpec,
  requiresConversion,
} from "./sensor-conversions"
import { formatSensorName } from "./sensor-formatting"
import { SensorConfigValuesList } from "./SensorConfigValuesList"
import { StageHeading } from "./StageHeading"
import i18n from "./translations/i18n"
import { isValidNumber } from "./type-guards"
import { useBackendRequest } from "./useBackendRequest"
import { useRootSelector } from "./useRootSelector"

import type { GetResponseDataType } from "./send-request"

import type { Entry } from "type-fest"
import type { ActionButtonProps } from "./ActionButtons"
import type { AcceptsChildren, BoxProps } from "./components"
import type {
  ConfigSensorName,
  HardwareGeneration,
  InstallationType,
  SensorConfig,
  SensorConfigKey,
  SensorConfigurations,
  SensorName,
} from "./sensor-configurations"
import type {
  ConversionTarget,
  MeasurementPreferenceProps,
} from "./sensor-conversions"

import type { DeviceConfiguration } from "./device-configurations.reducer"
const fileLogger = makeFileLogger({ fileName: "CreateConfiguration" })

type CreateConfigFormSensorValues = {
  [key in SensorName<
    "flow" | "pressure" | "pressureSwitch" | "reel" | "vfd" | "wheel"
  >]: SensorConfig<key> | null
} & {
  linearPathStopsCoordinates: Geo.Coordinates[] | null | undefined
  linearPathStopsLabels: string[] | null | undefined
  linearSpanHeadingDegrees: number | null
  linearSpanWidthMm: number | null
  pivotCenterGpsLocation: Geo.PointGeoJson | null
  pivotPathStopsCoordinates: Geo.Coordinates[] | null
  pivotPathStopsHeadingsDegrees: number[] | null
  pivotPathStopsLabels: string[] | null
  pivotRadiusMeters: number | null
}

/**
 *
 */
export function useDefaultSensorValues() {
  const { isEndToEndTest } = useEnvironmentVariables()
  return ({
    hardwareGeneration,
    installationType,
  }: {
    hardwareGeneration: HardwareGeneration
    installationType: InstallationType
  }): CreateConfigFormSensorValues => {
    const result: CreateConfigFormSensorValues = {
      flow: null,
      linearPathStopsCoordinates: null,
      linearPathStopsLabels: null,
      linearSpanHeadingDegrees: null,
      linearSpanWidthMm: null,
      pivotCenterGpsLocation: null,
      pivotPathStopsCoordinates: null,
      pivotPathStopsHeadingsDegrees: null,
      pivotPathStopsLabels: null,
      pivotRadiusMeters: null,
      pressure: null,
      pressureSwitch: null,
      reel: null,
      vfd: null,
      wheel: null,
    }
    switch (installationType) {
      case "reel_with_booster_off_only":
      case "reel": {
        result.reel = {
          hoseDiameterMm: null,
          linearSpeedMmHMax: SENSOR_DEFAULTS.reel.linearSpeedMmHMax,
          linearSpeedMmHMin: SENSOR_DEFAULTS.reel.linearSpeedMmHMin,
          nMagnets: 16,
          nNozzles: null,
          nWrapsOuterLayer: null,
          nozzleDiameterMm: null,
          outerHoseWrapRadiusMm: null,
          sensorPort: 1,
          sprinklerType: "gun",
          swathWidthMm: null,
          widthMm: null,
        }
        if (__DEV__ && !isEndToEndTest) {
          result.reel = {
            ...result.reel,
            hoseDiameterMm: 500,
            nNozzles: 1,
            nWrapsOuterLayer: 7,
            nozzleDiameterMm: 120,
            outerHoseWrapRadiusMm: 60,
            swathWidthMm: 80 * 1000,
            widthMm: 2000,
          }
        }
        break
      }
      case "prototype": {
        break
      }
      case "pump_vfd":
      case "pump_on_off":
      case "pump_off_only":
      case "pump": {
        if (hardwareGeneration === "PC1") {
          result.pressureSwitch = {
            ...SENSOR_DEFAULTS.pressureSwitch,
          }
        } else {
          result.pressure = {
            ...SENSOR_DEFAULTS.pressure,
          }
        }
        break
      }
      case "center_pivot":
      case "linear_move":
      case "traveller_soft": {
        result.wheel = {
          diameterMm: null,
          milliRpmFast: null,
          milliRpmSlow: null,
          nMagnets: null,
          sensorPort: 1,
        }
        result.linearSpanWidthMm = 100 * 1000
        result.linearSpanHeadingDegrees = 0
        if (__DEV__ && !isEndToEndTest) {
          result.wheel = {
            ...result.wheel,
            diameterMm: 500,
            milliRpmFast: 1000,
            milliRpmSlow: 500,
            nMagnets: 16,
          }
        }
        break
      }
      case "unconfigured": {
        break
      }
      case "valve": {
        result.pressure = {
          ...SENSOR_DEFAULTS.pressure,
          sensorPort: 1,
          thresholdPsiLower: 20,
          thresholdPsiUpper: 80,
        }
        result.flow = {
          ...SENSOR_DEFAULTS.flow,
          sensorPort: 2,
        }
        break
      }
    }
    return result
  }
}

/**
 *
 */
function coerceRawSensorValueToNumberIfPossible({
  fieldName,
  measurementPreference,
  rawValue,
}: MeasurementPreferenceProps & {
  fieldName: SensorConfigKey
  rawValue: unknown
}): number | undefined {
  // We need to know whether the user sees this value as a fraction or not
  const sigDigits =
    getSensorConversionSpec({
      fieldName,
      measurementPreference,
      target: "user",
    })?.sigDigits ?? 0

  let result: number | undefined
  if (typeof rawValue === "string") {
    try {
      // Most values do not have decimals
      if (sigDigits === 0) {
        result = Number.parseInt(rawValue)
      } else {
        // For those that do, round them to appropriate sig digits
        result = Geo.round(Number.parseFloat(rawValue), sigDigits)
      }
    } catch (error) {
      fileLogger.debug(error)
    }
  } else if (isValidNumber(rawValue)) {
    result = rawValue
  }
  return result
}

/**
 * For each value in the sensor configuration,
 * find out if the value needs to be converted based on the field name.
 * If it does, try to coerce the value to a valid number, then convert it,
 * replacing the 'raw' value in the sensor configuration with the converted
 * value. Otherwise, leave the value as is - it could be null/undefined,
 * be a string (e.g. GPS chip type), or an empty string
 */
export function convertSensorConfiguration<T extends ConfigSensorName>({
  measurementPreference,
  target,
  values,
}: MeasurementPreferenceProps & {
  target: ConversionTarget
  values: Partial<SensorConfigurations[T]>
}) {
  return Object.entries(values).reduce(
    (acc, entry) => {
      const [fieldName, rawValue] = entry as Entry<SensorConfigurations[T]>
      const valueAsNumber = coerceRawSensorValueToNumberIfPossible({
        fieldName,
        measurementPreference,
        rawValue,
      })
      if (requiresConversion(fieldName) && isValidNumber(valueAsNumber)) {
        const convertedValue = convertSensorValue({
          fieldName,
          measurementPreference,
          rawValue: valueAsNumber,
          target,
        })
        return { ...acc, [fieldName]: convertedValue }
      }
      return acc
    },
    { ...values },
  )
}
type ConfigSensorData = Pick<
  DeviceConfiguration,
  | ConfigSensorName
  | "linearPath"
  | "linearPathStopsLabels"
  | "linearSpanHeadingDegrees"
  | "linearSpanWidthMm"
  | "pivotRadiusMeters"
>

/**
 * For each sensor in the configuration, convert values based on the target
 */
export function useConvertConfiguration() {
  const measurementPreference = useRootSelector(
    getUserMeasurementPreferenceFromState,
  )
  return React.useCallback(
    (deviceConfiguration: ConfigSensorData, target: ConversionTarget) => {
      return CONFIG_SENSOR_NAMES.reduce(
        (accumulator, sensorName) => {
          const values = accumulator[sensorName]
          if (values) {
            return {
              ...accumulator,
              [sensorName]: convertSensorConfiguration({
                measurementPreference,
                target,
                values,
              }),
            }
          }
          return accumulator
        },
        {
          ...deviceConfiguration,
          pivotRadiusMeters:
            typeof deviceConfiguration.pivotRadiusMeters === "number"
              ? convertSensorValue({
                  fieldName: "pivotRadiusMeters",
                  measurementPreference,
                  rawValue: deviceConfiguration.pivotRadiusMeters,
                  target,
                })
              : null,
        },
      )
    },
    [measurementPreference],
  )
}

export interface ProviderProps {
  codaDeviceAlias: string

  /**
   * This needs to be provided in order to initialize the form with
   * the current configuration data converted to the user's preferred
   * measurement system which will be held in the form while it is modified
   */
  currentConfigurationData: ConfigSensorData
  deviceId: string
  deviceInstallationType: InstallationType
  deviceName: string | undefined
  hardwareGeneration: HardwareGeneration
  onCancel: () => void
  onClearInstallationType: (() => void) | null
  onSuccess: (response?: GetResponseDataType<"CreateConfiguration">) => void
}

/**
 *
 */
function useCreateConfiguration({
  // SetLinearPathComponent,
  codaDeviceAlias,
  currentConfigurationData,
  deviceId,
  deviceInstallationType,
  deviceName,
  hardwareGeneration,
  onCancel,
  onClearInstallationType,
  onSuccess,
}: ProviderProps) {
  const measurementPreference = useMeasurementPreference()
  const convertConfiguration = useConvertConfiguration()
  const isMetric = useMeasurementPreference() === "metric"
  const isAdminModeEnabled = useRootSelector(getIsAdminModeEnabledFromState)
  const { handleError, isLoading, sendRequest, toasts, ...rest } =
    useBackendRequest(createConfigurationAsync)

  const form = useForm<
    DeviceConfiguration & {
      linearPathEnd: Geo.Coordinates | null | undefined
      linearPathStart: Geo.Coordinates | null | undefined
    }
  >({
    defaultValues: {
      codaDeviceAlias,
      deviceId,
      deviceInstallationType,
      deviceName: deviceName ?? codaDeviceAlias,
      linearSpanHeadingDegrees: 0,
      linearSpanWidthMm: isMetric ? 100 : 300,
      ...convertConfiguration(currentConfigurationData, "user"),
    },
  })

  const onSubmit = form.handleSubmit(
    /**
     * Convert the user input to the database storage format and
     * create a new configuration on the backend.
     *
     * @param formValues - values in the users preferred measurement system
     */
    (formValues) => {
      // Convert the sensor values to the format that the backend/db expect
      const convertedValues = convertConfiguration(formValues, "database")

      /**
       * Initialize the empty sensor data
       */
      const resultSensorData: CreateConfigFormSensorValues = {
        flow: null,
        linearPathStopsCoordinates: null,
        linearPathStopsLabels: null,
        linearSpanHeadingDegrees: null,
        linearSpanWidthMm: null,
        pivotCenterGpsLocation: null,
        pivotPathStopsCoordinates: null,
        pivotPathStopsHeadingsDegrees: null,
        pivotPathStopsLabels: null,
        pivotRadiusMeters: null,
        pressure: null,
        pressureSwitch: null,
        reel: null,
        vfd: null,
        wheel: null,
      }
      resultSensorData.flow = convertedValues.flow ?? null

      switch (deviceInstallationType) {
        case "prototype": {
          if (!isAdminModeEnabled) {
            throw new TypeError(
              `User is attempting to manage configuration for a prototype 
                device while admin mode is not enabled`,
            )
          }
          break
        }
        case "pump_off_only":
        case "pump_on_off":
        case "pump_vfd":
        case "pump": {
          if (hardwareGeneration === "PC1") {
            // PC1 had a pressure switch and no pressure sensor
            if (!convertedValues.pressureSwitch) {
              throw new TypeError(`No values for pressur switch`)
            }
            resultSensorData.pressureSwitch = convertedValues.pressureSwitch
          } else if (convertedValues.pressure) {
            // All other pumps have a pressure sensor
            // if (!convertedValues.pressure) {
            //   throw new TypeError(`No values for pressure sensor`)
            // }
            resultSensorData.pressure = convertedValues.pressure
          }
          if (deviceInstallationType === "pump_vfd") {
            // VFD io pin
            resultSensorData.vfd = {
              i2CAddress: convertedValues.vfd?.i2CAddress,
              ioPin: convertedValues.vfd?.ioPin,
              // maxTrackingPercentage: convertedValues.vfd?.maxTrackingPercentage,
              // minTrackingPercentage: convertedValues.vfd?.minTrackingPercentage,
            }
          }
          break
        }
        case "reel_with_booster_off_only":
        case "reel": {
          if (!convertedValues.reel) {
            throw new TypeError("No values for reel")
          }
          if (typeof convertedValues.reel.sensorPort === "undefined") {
            throw new TypeError("Sensor port cannot be undefined")
          }
          resultSensorData.reel = convertedValues.reel

          if (convertedValues.pressure) {
            resultSensorData.pressure = convertedValues.pressure
          }

          break
        }
        case "linear_move":
        case "center_pivot":
        case "traveller_soft": {
          if (convertedValues.wheel) {
            resultSensorData.wheel = convertedValues.wheel
          }
          if (convertedValues.pressure) {
            resultSensorData.pressure = convertedValues.pressure
          }
          // Handle linear move setup data
          if (formValues.linearPathStart && formValues.linearPathEnd) {
            const labels: string[] = ["Start"]
            const coordinates: Geo.Coordinates[] = [formValues.linearPathStart]
            for (const [index, item] of (
              formValues.linearPath ?? []
            ).entries()) {
              labels.push(item.label ?? `Spot ${index + 1}`)
              coordinates.push(item.coordinates)
            }
            labels.push("End")
            coordinates.push(formValues.linearPathEnd)

            resultSensorData.linearPathStopsCoordinates = coordinates
            resultSensorData.linearPathStopsLabels = labels
            // To save time, we are pretending this is swath width to find
            // the correct conversion factor
            // TODO: Incorporate this into convertSensorValue
            resultSensorData.linearSpanWidthMm = convertSensorValue({
              fieldName: "swathWidthMm",
              measurementPreference,
              rawValue: formValues.linearSpanWidthMm ?? 0,
              target: "database",
            })
            resultSensorData.linearSpanHeadingDegrees =
              formValues.linearSpanHeadingDegrees ?? null
          }
          // Special handling for center pivots
          if (formValues.deviceInstallationType === "center_pivot") {
            // Grab the center from the form
            // if (!formValues.pivotCenterGpsLocation) {
            //   throw new TypeError(
            //     "In useCreateConfiguration: Center pivot location is required",
            //   )
            // }
            resultSensorData.pivotCenterGpsLocation =
              formValues.pivotCenterGpsLocation ?? null

            // Grab the radius from the form
            // if (typeof formValues.pivotRadiusMeters !== "number") {
            //   throw new TypeError(
            //     "In useCreateConfiguration: Pivot radius must be a number",
            //   )
            // }
            resultSensorData.pivotRadiusMeters =
              convertedValues.pivotRadiusMeters ?? null

            // Initialize coordinates and labels...these will be
            // empty arrays for circular pivots
            resultSensorData.pivotPathStopsCoordinates = []
            resultSensorData.pivotPathStopsLabels = []

            // Grab the stop angles from the form if any exist
            const [stopFirst, stopLast] =
              formValues.pivotPathStopsHeadingsDegrees ?? []
            if (
              typeof stopFirst === "number" &&
              typeof stopLast === "number" &&
              stopFirst !== stopLast
            ) {
              // We only need to record these if they are different;
              // meaning that the pivot is not irrigating a full circle
              resultSensorData.pivotPathStopsHeadingsDegrees = [
                Math.round(stopFirst),
                Math.round(stopLast),
              ]
              // Unsure why we need to record these, but we do
              resultSensorData.pivotPathStopsLabels = ["Start", "End"]
            }
            // TODO: Add support for pivot path stops
          }
          break
        }
        case "unconfigured": {
          break
        }
        case "valve": {
          resultSensorData.pressure = convertedValues.pressure ?? null
          resultSensorData.flow = convertedValues.flow ?? null
          break
        }
      }

      sendRequest({
        deviceId,
        deviceInstallationType: formValues.deviceInstallationType,
        deviceName: formValues.deviceName ?? codaDeviceAlias,
        ...resultSensorData,
      })
        .then((response) => {
          toasts.success()
          return onSuccess(response)
        })
        .catch((error) => {
          handleError(error, {
            toastMessage: "default",
          })
        })
    },
  )
  const installationType = form.watch("deviceInstallationType")
  let subtitleText: string
  switch (installationType) {
    case "center_pivot": {
      subtitleText = i18n.t("deviceConfiguration:setUpCenterPivot")
      break
    }
    case "linear_move": {
      subtitleText = i18n.t("deviceConfiguration:setUpLinearMove")
      break
    }

    case "traveller_soft": {
      subtitleText = i18n.t("deviceConfiguration:setUpTravelerSoft")
      break
    }
    case "pump_off_only":
    case "pump_on_off":
    case "pump_vfd":
    case "pump": {
      subtitleText = i18n.t("deviceConfiguration:setUpPump")
      break
    }
    case "reel_with_booster_off_only":
    case "reel": {
      subtitleText = i18n.t("deviceConfiguration:setUpReel")
      break
    }
    case "valve": {
      subtitleText = i18n.t("deviceConfiguration:setUpValve")
      break
    }
    case "unconfigured":
    case "prototype": {
      subtitleText = ""
      break
    }
  }
  return {
    ...rest,
    configuration: currentConfigurationData,
    deviceId,
    form,
    isLoading,
    onCancel,
    onClearInstallationType: onClearInstallationType ?? undefined,
    onSubmit,
    subtitleText,
  }
}

type ContextValue = ReturnType<typeof useCreateConfiguration>
const Context = React.createContext<ContextValue | undefined>(undefined)

/**
 *
 */
export function Provider({
  children,
  ...rest
}: AcceptsChildren & ProviderProps): React.JSX.Element | null {
  const value = useCreateConfiguration(rest)
  return (
    <Context.Provider value={value}>
      <FormProvider {...value.form}>{children}</FormProvider>
    </Context.Provider>
  )
}

/**
 *
 */
export function useContext(): ContextValue {
  const ctx = React.useContext(Context)
  if (typeof ctx === "undefined") {
    throw new TypeError(
      `useCreateConfigurationContext must be used inside of provider`,
    )
  }
  return ctx
}

export function Buttons(
  props: Omit<ActionButtonProps, "onPressSubmit">,
): React.JSX.Element {
  const { isLoading, onSubmit } = useContext()
  return (
    <ActionButtons {...props} isLoading={isLoading} onPressSubmit={onSubmit} />
  )
}

export function ReviewDeviceSetupMetadata() {
  const form = useFormContext<DeviceConfiguration>()
  const instructionsText = i18n.t(
    "deviceConfiguration:reviewAndSubmitInstructions",
  )
  return (
    <Column mb="$4" space="$2">
      <AlertCard
        IconComponent={null}
        bodyText={instructionsText}
        severity="info"
      />
      <Row justifyContent="space-between">
        <AppText colorScheme="secondary">{i18n.t("deviceName")}</AppText>
        <AppText>
          {form.watch("deviceName") ?? form.watch("codaDeviceAlias")}
        </AppText>
      </Row>
      <Divider />
      <Row justifyContent="space-between">
        <AppText colorScheme="secondary">
          {i18n.t("deviceInstallationType")}
        </AppText>
        <AppText>
          {formatInstallationType(form.watch("deviceInstallationType"))}
        </AppText>
      </Row>
    </Column>
  )
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: COLORS.$paper.light,
    marginHorizontal: "auto",
    maxWidth: SIZES.$2xl,
    paddingHorizontal: SPACING.$4,
    width: "100%",
  },
  containerDark: {
    backgroundColor: COLORS.$paper.dark,
  },
  roundBottom: {
    borderBottomLeftRadius: RADII.$default,
    borderBottomRightRadius: RADII.$default,
    paddingBottom: Platform.select({
      default: Theme.spacing.$2,
      web: SPACING.$4,
    }),
  },
  roundTop: {
    borderTopLeftRadius: RADII.$default,
    borderTopRightRadius: RADII.$default,
    paddingTop: SPACING.$4,
  },
  scrollView: {
    flexGrow: Platform.select({
      default: 1,
      web: 0,
    }),
  },
  scrollViewContentContainer: {
    flexGrow: 1,
    flexShrink: 0,
    minHeight: SIZES.$48,
    paddingBottom: SPACING.$4,
    paddingTop: SPACING.$4,
  },
  scrollViewContentContainerMobile: {
    flexGrow: 1,
    paddingBottom: Theme.spacing.$2,
    paddingHorizontal: Theme.spacing.$4,
    paddingTop: Theme.spacing.$2,
  },
})

export const Container: React.FC<
  AcceptsChildren & {
    variant?: "footer" | "header"
  }
> = ({ variant, ...rest }) => {
  const isDark = useIsDarkMode()
  return (
    <View
      style={useStyle(
        () => [
          styles.container,
          isDark && styles.containerDark,
          variant === "footer" && styles.roundBottom,
          variant === "header" && styles.roundTop,
        ],
        [isDark, variant],
      )}
      {...rest}
    />
  )
}

export function ScrollView({
  children,
  roundCorners,
}: AcceptsChildren & {
  roundCorners?: "bottom" | "top"
}) {
  const isDark = useIsDarkMode()
  return (
    <BaseScrollView
      // TODO: Figure out how to prevent layout shift in web
      showsVerticalScrollIndicator={false}
      style={styles.scrollView}
      contentContainerStyle={useStyle(() => {
        if (Platform.OS === "web") {
          return [
            styles.container,
            isDark && styles.containerDark,
            styles.scrollViewContentContainer,
            roundCorners === "top" && styles.roundTop,
            roundCorners === "bottom" && styles.roundBottom,
          ]
        }
        return styles.scrollViewContentContainerMobile
      })}
    >
      {children}
    </BaseScrollView>
  )
}

export function PageWrapper(props: BoxProps) {
  const getValue = useBreakpointValue()
  return <Box flex={1} mt={getValue({ base: "$0", sm: "$4" })} {...props} />
}
export interface StageProps extends Omit<ActionButtonProps, "isLoading"> {
  stageIndex?: number
}

export function ConfigurationStage({
  children,
  stageIndex,
  titleText,
  ...buttonProps
}: AcceptsChildren &
  StageProps & {
    titleText: string
  }) {
  const { isLoading, subtitleText } = useContext()
  return (
    <PageWrapper>
      <Container variant="header">
        <StageHeading
          stageIndex={stageIndex}
          subtitleText={subtitleText}
          titleText={titleText}
        />
      </Container>
      <ScrollView>{children}</ScrollView>
      <Container variant="footer">
        <ActionButtons
          cancelText={i18n.t("back")}
          isLoading={isLoading}
          submitText={i18n.t("next")}
          {...buttonProps}
        />
      </Container>
    </PageWrapper>
  )
}
/**
 * The stage where the user can customize the device name.
 */
export function CustomizeDeviceNameStage(props: StageProps) {
  return (
    <ConfigurationStage
      {...props}
      titleText={i18n.t("deviceConfiguration:customizeDeviceNameStageTitle")}
    >
      <AppText>
        {i18n.t("deviceConfiguration:customizeDeviceNameInstructions")}
      </AppText>
      <DeviceNameInput />
    </ConfigurationStage>
  )
}

/**
 * The stage where the user can choose the pressure sensor port.
 */
export function ChoosePressureSensorPortStage(props: StageProps) {
  const titleText = i18n.t("deviceConfiguration:chooseSensorPortStageTitle")
  return (
    <ConfigurationStage titleText={titleText} {...props}>
      <AppText>
        {i18n.t(
          "deviceConfiguration:chooseSensorPortInstructionsWithSensorName",
          { sensorName: formatSensorName("pressure") },
        )}
      </AppText>
      <Box mt="$4">
        <ChooseSensorPort sensorName="pressure" />
      </Box>
    </ConfigurationStage>
  )
}

/**
 *
 */
export function SetPressureThresholdsStage(props: StageProps) {
  return (
    <ConfigurationStage
      {...props}
      titleText={i18n.t("deviceConfiguration:pressureThresholdsStageTitle")}
    >
      <PressureSliderConnected />
    </ConfigurationStage>
  )
}

export function ChoosePressureSensorModelStage(buttonProps: StageProps) {
  const { form } = useContext()
  return (
    <ConfigurationStage
      {...buttonProps}
      titleText={i18n.t("deviceConfiguration:pressureSensorModelStageTitle")}
    >
      <AppText>
        {i18n.t("deviceConfiguration:choosePressureSensorModelInstructions")}
      </AppText>
      <Controller
        control={form.control}
        name="pressure.sensorModelId"
        render={({ field }) => {
          return (
            <ChoosePressureSensorModel
              selectedValue={field.value}
              onChange={field.onChange}
            />
          )
        }}
      />
    </ConfigurationStage>
  )
}

export function YesOrNoButtons({
  onCancel,
  onValueChange,
}: {
  onCancel: (() => void) | undefined
  onValueChange: (nextValue: boolean) => void
}) {
  return (
    <React.Fragment>
      <Row justifyContent="space-between">
        <Button
          flexGrow={1}
          size="lg"
          style={{ marginRight: SPACING.$4 }}
          text={i18n.t("no")}
          onPress={() => {
            onValueChange(false)
          }}
        />
        <Button
          flexGrow={1}
          id="submit-btn"
          size="lg"
          text={i18n.t("yes")}
          variant="primary"
          onPress={() => {
            onValueChange(true)
          }}
        />
      </Row>
      {onCancel ? (
        <Box mt="$4">
          <Button text={i18n.t("back")} variant="text" onPress={onCancel} />
        </Box>
      ) : null}
    </React.Fragment>
  )
}

export function PressureSensorYesOrNoStage({
  onCancel,
  onValueChange,
  stageIndex,
}: {
  onCancel: (() => void) | undefined
  onValueChange: (nextValue: boolean) => void
  stageIndex?: number
}) {
  const {
    form: { control, watch },
    subtitleText,
  } = useContext()

  // const hasPressure = Boolean(watch("pressure"))
  const deviceInstallationType = watch("deviceInstallationType")
  const hardwareGeneration = watch("hardwareGeneration")
  const getDefaults = useDefaultSensorValues()

  const promptText: string = i18n.t(
    "deviceConfiguration:doesItHaveAPressureSensor",
  )

  return (
    <PageWrapper id="pressure-sensor-yes-or-no">
      <Container variant="header">
        <StageHeading
          stageIndex={stageIndex}
          subtitleText={subtitleText}
          titleText={i18n.t("deviceConfiguration:pressureSensor")}
        />
      </Container>
      <ScrollView>
        <React.Fragment>
          <Box mb="$4">
            <AppText>{promptText}</AppText>
          </Box>
          <Controller
            control={control}
            name="pressure"
            render={({ field: { onChange } }) => {
              const handleChange = (hasPressure: boolean) => {
                onValueChange(hasPressure)
                let pressureConfig: SensorConfig<"pressure"> | null
                if (hasPressure) {
                  let otherValueAsNumber: number | undefined

                  for (const sensorName of MUTABLE_SENSOR_NAMES) {
                    if (sensorName !== "pressure") {
                      // Look for another sensor with a sensor port and
                      // parse its value
                      const otherValue = watch(`${sensorName}.sensorPort`)
                      if (typeof otherValue === "string") {
                        otherValueAsNumber = Number.parseInt(otherValue)
                      } else if (typeof otherValue === "number") {
                        otherValueAsNumber = otherValue
                      }
                    }
                  }
                  const resetValues =
                    getDefaults({
                      hardwareGeneration,
                      installationType: deviceInstallationType,
                    }).pressure ?? SENSOR_DEFAULTS.pressure

                  // If there is another sensor with a sensor port,
                  // make sure this gets initialized to the OTHER port
                  pressureConfig = {
                    ...resetValues,
                    sensorPort:
                      otherValueAsNumber === SensorPorts.ONE
                        ? SensorPorts.TWO
                        : SensorPorts.ONE,
                  }
                } else {
                  pressureConfig = null
                }
                onChange(pressureConfig)
              }

              return (
                <YesOrNoButtons
                  onCancel={onCancel}
                  onValueChange={handleChange}
                />
              )
            }}
          />
        </React.Fragment>
      </ScrollView>
    </PageWrapper>
  )
}

export function ReviewAndSubmitStage({
  sensorNames,
  ...commonProps
}: Omit<StageProps, "onPressSubmit" | "submitText"> & {
  sensorNames: Array<SensorName | false | null | undefined>
}) {
  const { onSubmit } = useContext()
  return (
    <ConfigurationStage
      {...commonProps}
      id="review-choices"
      submitText={i18n.t("submit")}
      titleText={i18n.t("reviewAndSubmit")}
      onPressSubmit={onSubmit}
    >
      <ReviewDeviceSetupMetadata />
      {sensorNames.map((sensorName) => {
        if (typeof sensorName === "string") {
          return (
            <SensorConfigValuesList key={sensorName} sensorName={sensorName} />
          )
        }
        return null
      })}
    </ConfigurationStage>
  )
}
