import React from "react"

import {
  CenterPivotPathVisualization as Base,
  Geo,
  Models,
  turf,
  useRootSelector,
} from "@fhq/app"
import { COLORS } from "@fhq/app/components/theme"
import { Marker, Polygon, Polyline } from "@react-google-maps/api"
import { captureException, withErrorBoundary } from "@sentry/react"

import { BASE_SWATH_OPTIONS } from "./base"
import { pointTransformer } from "./map-utils"

import type { DeviceIdProps } from "@fhq/app"
const plotPivotPath = Base.makePlotPivotPath(pointTransformer)
const plotArrow = Base.makePlotArrow(pointTransformer)

/**
 * getLabelUrl
 *
 * Generate a data URL for an SVG label to mark the completion percentage of
 * the pivot path on the map without rendering a google maps marker.
 */
function getLabelUrl(valuePercent: number | null) {
  const x = 15
  const y = 30
  if (typeof valuePercent === "number") {
    return `data:image/svg+xml;utf8,
    <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50">
      <text x="${x}" y="${y}" font-family="poppins" fill="white">
        ${valuePercent}%
      </text>
    </svg>`
  }
  return undefined
}

/**
 * CenterPivotPathVisualization
 *
 * Display the path of a center pivot device based on the provided device
 * activity
 */
function CenterPivotPathVisualization({
  isClickable,
  onSelect,
  showCompletionPercentage,
  ...deviceActivity
}: Models.DeviceActivity & {
  isClickable: boolean
  showCompletionPercentage: boolean
  onSelect?: (params: DeviceIdProps) => void
}) {
  const configuration = useRootSelector((state) => {
    return Models.deviceConfiguration.selectById(state, deviceActivity.device)
  })

  // if (configuration?.deviceId === "e00fce68875235d012a6ea48") {
  //   const timestamps = deviceActivity.createdAtTimestamps ?? []
  //   const headings = deviceActivity.pivotHeadingsDegrees ?? []
  //   const directions = deviceActivity.travelerDirections ?? []
  //   const pressures = deviceActivity.pressureReadingKpas ?? []
  //   for (let i = 0; i < headings?.length; i++) {
  //     const ts = timestamps[i]

  //     console.log(new Date(ts), headings[i], directions[i])
  //   }
  //   console.log(deviceActivity.id)
  //   console.log(
  //     JSON.stringify(
  //       deviceActivity.pivotHeadingsDegrees?.map((heading) => {
  //         if (typeof heading === "number") {
  //           return Math.round(heading)
  //         }
  //         return heading
  //       }),
  //       null,
  //       2,
  //     ),
  //   )
  // }
  if (
    configuration &&
    configuration.deviceInstallationType === "center_pivot"
  ) {
    const activityPath = plotPivotPath(configuration, deviceActivity)
    const { arrowHeadPath, arrowStemPath } = plotArrow(
      deviceActivity,
      configuration,
    )

    const arrowOptions: google.maps.PolylineOptions = {
      clickable: isClickable,
      strokeColor: COLORS.$reelRunSwath.arrow,
      strokeWeight: 4,
    }

    let activityCenter: google.maps.LatLngLiteral | undefined
    try {
      activityCenter = Geo.point(
        turf.centerOfMass(
          turf.polygon([activityPath?.map((pt) => [pt.lng, pt.lat]) ?? []]),
        ).geometry,
      )?.toGmaps()
    } catch (e) {
      captureException(e)
    }

    // TODO: Calculate the completion percentage of the pivot path

    const valuePercent: number | null = null
    const labelUrl = getLabelUrl(valuePercent)

    const handleSelect = () => {
      onSelect?.({ deviceId: deviceActivity.device })
    }

    return (
      <React.Fragment>
        {/* {frontMidpoint ? <Marker position={frontMidpoint} /> : null} */}
        {activityCenter && typeof labelUrl === "string" ? (
          <Marker
            position={activityCenter}
            icon={{
              labelOrigin: new google.maps.Point(11, 50),
              url: labelUrl,
            }}
            options={{
              clickable: isClickable,
              visible: showCompletionPercentage,
            }}
            onClick={handleSelect}
          />
        ) : null}

        {activityPath && activityPath.length > 3 ? (
          <Polygon
            path={activityPath}
            options={{
              ...BASE_SWATH_OPTIONS,
              clickable: isClickable,
            }}
            onClick={handleSelect}
          />
        ) : null}
        {/* Arrow Head */}
        <Polyline
          options={arrowOptions}
          path={arrowHeadPath}
          onClick={handleSelect}
        />
        {/* Arrow Stem */}
        <Polyline
          options={arrowOptions}
          path={arrowStemPath}
          onClick={handleSelect}
        />
      </React.Fragment>
    )
  }
  return null
}
export default withErrorBoundary(CenterPivotPathVisualization, {})
