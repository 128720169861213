import React from "react"
import { Pressable } from "react-native"

import {
  AppIcons,
  AppText,
  Box,
  Button,
  Divider,
  getIconForInstallationType,
  renderIconFromIconProp,
  Row,
  TitleWithIcon,
} from "./components"
import * as Models from "./models"
import { formatInstallationType } from "./sensor-configurations"
import i18n from "./translations/i18n"
import { useRootSelector } from "./useRootSelector"

import type { BoxProps } from "./components"
import type { DeviceConfiguration } from "./device-configurations.reducer"
import type { InstallationType } from "./sensor-configurations"
import type { DeviceIdProps, PermissionCheckProps } from "./types"
/**
 * List of connections for a device
 */
export function DeviceConnectionsMiniList({
  deviceId,
  onPressCreateConnection,
  onPressDeviceConnection,
  showHeading = true,
  withPermissions,
  ...rest
}: BoxProps &
  DeviceIdProps &
  PermissionCheckProps & {
    onPressCreateConnection: () => void
    onPressDeviceConnection: (params: { connectionId: number }) => void
    showHeading?: boolean
  }) {
  const connections = useRootSelector((state) => {
    const result: Array<{
      description: string
      deviceInstallationType: InstallationType
      id: number
    }> = []
    const configurations = Models.deviceConfiguration.selectEntities(state)
    const allConnections = Models.trigger.selectAll(state)
    for (const connection of allConnections) {
      let otherConfig: DeviceConfiguration | undefined
      if (
        connection.sourceDeviceId === deviceId &&
        typeof connection.targetDeviceId === "string"
      ) {
        otherConfig = configurations[connection.targetDeviceId]
      } else if (connection.targetDeviceId === deviceId) {
        otherConfig = configurations[connection.sourceDeviceId]
      }
      if (otherConfig) {
        const installationTypeFormatted: string = formatInstallationType(
          otherConfig.deviceInstallationType,
        )
        let description = `${
          otherConfig.deviceName ?? otherConfig.codaDeviceAlias
        } (${installationTypeFormatted})`
        // Call the device "self" if it's the same device
        if (otherConfig.deviceId === deviceId) {
          description = `${i18n.t("self")}`
        }
        result.push({
          description,
          deviceInstallationType: otherConfig.deviceInstallationType,
          id: connection.id,
        })
      }
    }
    return result
  })
  const headerElement = showHeading ? (
    <React.Fragment>
      <TitleWithIcon
        IconComponent={AppIcons.DeviceConnection}
        _headingProps={{ variant: "h6" }}
        titleText={i18n.t("deviceConnections:connectedToTitle")}
      />
      <Divider my="$1" />
    </React.Fragment>
  ) : null

  const createConnectionButtonElement = (
    <Button
      IconComponent="Add"
      style={{ minWidth: 270 }}
      text={i18n.t("deviceConnections:connectDevices")}
      variant="primary"
      onPress={withPermissions({
        callback: onPressCreateConnection,
        required: "canManageCustomTriggers",
      })}
    />
  )

  if (connections.length > 0) {
    return (
      <Box flex={1} {...rest}>
        {headerElement}
        {connections.map((connection) => {
          const handlePress = () => {
            onPressDeviceConnection({
              connectionId: connection.id,
            })
          }
          const iconElement = renderIconFromIconProp(
            getIconForInstallationType(connection.deviceInstallationType),
          )
          return (
            <Pressable key={connection.id} onPress={handlePress}>
              <Row flexWrap="nowrap" justifyContent="space-between" my="$1">
                <Row flex={1} flexWrap="nowrap">
                  <Box mr="$2">{iconElement}</Box>
                  <AppText isTruncated flex={1}>
                    {connection.description}
                  </AppText>
                </Row>
                <AppIcons.ListItemEnd />
              </Row>
            </Pressable>
          )
        })}
        <Box ml="auto" mt="auto" pt="$2">
          {createConnectionButtonElement}
        </Box>
      </Box>
    )
  }
  return (
    <Box {...rest}>
      {headerElement}
      <Row>
        <Box mr="$2">
          <AppIcons.Warning />
        </Box>
        <Box flex={1} minW="$64">
          <AppText>{i18n.t("devices:noConnections")}</AppText>
        </Box>
        <Box mt="$2">{createConnectionButtonElement}</Box>
      </Row>
    </Box>
  )
}
