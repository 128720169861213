import React from "react"
import { Pressable, StyleSheet, View } from "react-native"
import { useStyle } from "react-native-style-utilities"

import { MaterialCommunityIcons } from "@expo/vector-icons"

import { testIds } from "./test-id"
import { AppText } from "./Text"
import { COLORS } from "./theme/colors"
import { SIZES } from "./theme/sizes"
import { useIsDarkMode } from "./ThemeProvider"

import type { ViewProps } from "./View"

const styles = StyleSheet.create({
  container: {
    alignItems: "flex-start",
    flexDirection: "row",
    justifyContent: "flex-start",
    // marginHorizontal: 5,
    marginTop: 5,
    // width: 150
  },
  title: {
    fontSize: 16,
    marginLeft: 5,
  },
})

/**
 * Custom checkbox component
 * TODO: Implement sizes
 */
export function Checkbox({
  isChecked,
  isDisabled,
  isInvalid,
  onChange,
  size = "md",
  style,
  title,
}: Pick<ViewProps, "style"> & {
  isChecked: boolean
  onChange: (nextValue: boolean) => void
  isDisabled?: boolean
  isInvalid?: boolean
  size?: "lg" | "md" | "sm"
  title?: string
}) {
  const isDark = useIsDarkMode()

  let sizeFinal: number
  switch (size) {
    case "sm": {
      sizeFinal = SIZES.$6
      break
    }
    case "md": {
      sizeFinal = SIZES.$8
      break
    }
    case "lg": {
      sizeFinal = SIZES.$10
      break
    }
  }
  return (
    <View style={useStyle(() => [styles.container, style], [style])}>
      <Pressable
        {...testIds("checkbox")}
        accessibilityRole="checkbox"
        data-testid="checkbox"
        hitSlop={20}
        accessibilityState={{
          checked: isChecked,
          disabled: isDisabled,
        }}
        onPress={() => onChange(!isChecked)}
      >
        <MaterialCommunityIcons
          name={isChecked ? "checkbox-marked" : "checkbox-blank-outline"}
          size={sizeFinal}
          color={
            isInvalid === true
              ? COLORS.$invalid
              : isDark
              ? COLORS.$primary[500]
              : COLORS.$blue[500]
          }
        />
      </Pressable>
      <AppText style={styles.title}>{title}</AppText>
    </View>
  )
}
