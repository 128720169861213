import React from "react"
import {
  KeyboardAvoidingView as Base,
  Platform,
  StyleSheet,
} from "react-native"
import { useStyle } from "react-native-style-utilities"

import { testIds } from "./test-id"
import { SPACING } from "./theme"

import type { WithOptionalTestId } from "./test-id"
import type { KeyboardAvoidingViewProps } from "react-native"

const styles = StyleSheet.create({
  variantScreenContainer: {
    flex: 1,
    padding: SPACING.$4,
    paddingTop: SPACING.$2,
  },
})

/**
 * Encapsulates a platform-aware keyboard avoiding view.
 */
export function KeyboardAvoidingView({
  id,
  style,
  variant,
  ...rest
}: WithOptionalTestId<
  KeyboardAvoidingViewProps & {
    variant?: "screenContainer"
  }
>) {
  return (
    <Base
      {...testIds(id)}
      behavior={Platform.OS === "ios" ? "padding" : "height"}
      style={useStyle(
        () => [
          variant === "screenContainer" && styles.variantScreenContainer,
          style,
        ],
        [style, variant],
      )}
      {...rest}
    />
  )
}
