import React from "react"
import { Controller, useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"

import {
  AppText,
  Box,
  CancelButton,
  FormControl,
  SubmitButton,
} from "./components"
import { FormError } from "./components/FormError"
import { userJoinFarmWithCodeAsync } from "./farmhq-api"
import { useFormValidation } from "./form-validation"
import { getUserFarmErrorCodeFromState } from "./selectors"
import i18n from "./translations/i18n"
import { useBackendRequest } from "./useBackendRequest"
import { useRootSelector } from "./useRootSelector"

import type { HelpContentStatic } from "./components"
export const HELP_CONTENT: HelpContentStatic = {
  bodyElement: i18n.t("noFarms:joinFarmWithCodeHelpBodyText"),
  subject: "join_farm_with_code",
  titleElement: i18n.t("noFarms:joinFarmWithCodeHelpTitle"),
}
/**
 * Form for joining a farm with a code.
 */
export function JoinFarmForm({
  onCancel,
  onSuccess,
  showInstructions,
}: {
  onCancel: (() => void) | null
  onSuccess: () => void
  showInstructions: boolean
}): React.JSX.Element {
  const { t } = useTranslation("noFarms")

  const form = useForm({
    defaultValues: { codeString: "" },
  })
  const { required } = useFormValidation()
  const errorCode = useRootSelector(getUserFarmErrorCodeFromState)
  const { handleError, isLoading, sendRequest, toasts } = useBackendRequest(
    userJoinFarmWithCodeAsync,
  )

  const onSubmit = form.handleSubmit((values) => {
    sendRequest({
      codeString: values.codeString.toLocaleUpperCase().trim(),
    })
      .then((response) => {
        if (response.codeStatus === "SUCCESS") {
          toasts.success()
          onSuccess()

          if (onCancel) {
            onCancel()
          }
        }
        return response
      })
      .catch((error) =>
        handleError(error, {
          toastMessage: "default",
        }),
      )
  })
  const validationError =
    errorCode === "INVALID"
      ? t("codeInvalid", { ns: "validation" })
      : errorCode === "EXPIRED"
      ? t("codeExpired", { ns: "validation" })
      : errorCode === "ALREADY_JOINED"
      ? t("joinFarmAlreadyJoined", { ns: "noFarms" })
      : undefined
  return (
    <React.Fragment>
      {showInstructions ? (
        <Box mb="$2">
          <AppText>{t("joinFarmDescription")}</AppText>
        </Box>
      ) : null}
      <Controller
        control={form.control}
        name="codeString"
        rules={{ required }}
        render={({ field: { onChange, ref, ...field }, fieldState }) => {
          const errorMessage = fieldState.error?.message
          const isInvalid = Boolean(errorMessage)
          return (
            <FormControl.Provider id="codeString" isInvalid={isInvalid}>
              <FormControl.Label>{t("farmJoinCodeLabel")}</FormControl.Label>
              <FormControl.Input
                ref={ref}
                autoCapitalize="characters"
                autoCorrect={false}
                placeholder={t("codeStringFieldPlaceholder", { ns: "auth" })}
                returnKeyType="done"
                textContentType="oneTimeCode"
                onChangeText={(nextValue) =>
                  onChange(nextValue.toLocaleUpperCase())
                }
                {...field}
              />
              <FormControl.ErrorMessage>
                {errorMessage}
              </FormControl.ErrorMessage>
            </FormControl.Provider>
          )
        }}
      />

      <Box mt="$2">
        <FormError>{validationError}</FormError>
      </Box>
      <Box mt="$4">
        <SubmitButton isLoading={isLoading} onPress={onSubmit} />
        {onCancel ? (
          <Box mt="$4">
            <CancelButton
              isDisabled={isLoading}
              variant="text"
              onPress={onCancel}
            />
          </Box>
        ) : null}
      </Box>
    </React.Fragment>
  )
}
