import React from "react"

import { useToasts } from "./components/useToasts"
import { getFetchStatusByName } from "./requests.reducer"
import { useErrorHandler } from "./useErrorHandler"
import { useRootDispatch } from "./useRootDispatch"
import { useRootSelector } from "./useRootSelector"

import type { FetchStatus } from "./Requests"
import type {
  AnyRequestName,
  BackendRequestName,
  GetActionArgumentsType,
  GetAsyncThunkType,
  GetResponseDataType,
} from "./send-request"
import type { RootDispatch } from "./store"
import type { ErrorOptions } from "./useErrorHandler"
import type { UseToastReturn } from "./components/useToasts"
export interface UseBackendRequestReturnValue<
  N extends BackendRequestName,
  R extends GetResponseDataType<N> = GetResponseDataType<N>,
  A extends GetActionArgumentsType<N> = GetActionArgumentsType<N>,
> {
  dispatch: RootDispatch
  fetchStatus: FetchStatus | undefined
  handleError: (error: unknown, overrides?: ErrorOptions) => void
  isLoading: boolean
  sendRequest: (arg: A) => Promise<R>
  toasts: UseToastReturn
}

export function useBackendRequest<
  N extends BackendRequestName,
  R extends GetResponseDataType<N>,
  A extends GetActionArgumentsType<N>,
>(handler: GetAsyncThunkType<N, R, A>): UseBackendRequestReturnValue<N, R, A> {
  const dispatch = useRootDispatch()
  const requestName = handler.typePrefix as AnyRequestName
  const toasts = useToasts()
  const handleError = useErrorHandler()

  const fetchStatus = useRootSelector((state) =>
    getFetchStatusByName(state, requestName),
  )

  const isLoading = fetchStatus === "pending"

  const sendRequest = React.useCallback(
    async (actionArguments: A) => {
      return await dispatch(handler(actionArguments)).unwrap()
    },
    [dispatch, handler],
  )

  return {
    dispatch,
    fetchStatus,
    handleError,
    isLoading,
    sendRequest,
    toasts,
  }
}
