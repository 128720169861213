import * as Fonts from "expo-font"
import React from "react"
import { RouterProvider } from "react-router-dom"

import { logger, useAppLoader } from "@fhq/app"
import { ICON_SETS } from "@fhq/app/components"
import { PRELOAD_FONTS } from "@fhq/app/components/theme"

import { router } from "./App.Routes"
import { SEGMENT_CLIENT } from "./pages/base/Analytics"
import { Environment } from "./pages/base/base"

/**
 * Load fonts and icons asynchronously
 */
Fonts.loadAsync({ ...ICON_SETS, ...PRELOAD_FONTS }).catch((e) => {
  logger.error(e)
})

/**
 * Entrypoint for FarmHQ web dashboard
 */
export function WebApp(): React.JSX.Element {
  useAppLoader({
    analyticsClient: SEGMENT_CLIENT,
    targetDatabaseName: Environment.targetDatabaseName,
  })
  return <RouterProvider router={router} />
}
