import React from "react"
import { useFormContext } from "react-hook-form"

import { ActionButtons } from "./ActionButtons"
import {
  AlertCard,
  AppText,
  Button,
  Divider,
  Row,
  TextWithIcon,
} from "./components"
import { Box } from "./components/Box"
import * as CreateConfiguration from "./CreateDeviceConfigurationContext"
import {
  ChooseSensorPort,
  ChooseSwitchType,
  DeviceNameInput,
} from "./DeviceSensorFields"
import { StageHeading } from "./StageHeading"
import i18n from "./translations/i18n"

import type { IconProp } from "./components"
import type { BoxProps } from "./components/Box"
import type { StageProps } from "./CreateDeviceConfigurationContext"
import type { InstallationType } from "./sensor-configurations"

import type { DeviceConfiguration } from "./device-configurations.reducer"
enum Stages {
  choosePressureSensorModel = 0,
  chooseSensorPort = 1,
  setThresholds = 2,
  // setTrackingPercentage = 3,
  customizeDeviceName = 3,
  reviewAndSubmit = 4,
}

/**
 *  Pump type option
 */
function PumpTypeOption({
  IconComponent,
  labelText,
  onChange,
  ...rest
}: BoxProps & {
  IconComponent: IconProp
  labelText: string
  onChange: () => void
}) {
  return (
    <Box alignItems="center" flex={1} my="$1" {...rest}>
      <TextWithIcon IconComponent={IconComponent} flex={1} text={labelText} />
      <Box ml="auto" mt="$4">
        <Button id="submit-btn" text={i18n.t("select")} onPress={onChange} />
      </Box>
    </Box>
  )
}

function ChoosePumpType() {
  const {
    form: { setValue },
    onCancel,
  } = CreateConfiguration.useContext()
  const instructionsText = i18n.t(
    "deviceConfiguration:choosePumpTypeInstructions",
  )
  return (
    <CreateConfiguration.PageWrapper>
      <CreateConfiguration.Container variant="header">
        <StageHeading
          subtitleText={i18n.t("deviceConfiguration:setUpPump")}
          titleText={i18n.t("deviceConfiguration:choosePumpTypeTitle")}
        />
        <AlertCard
          IconComponent={null}
          bodyText={instructionsText}
          mt="$2"
          severity="info"
        />
      </CreateConfiguration.Container>
      <CreateConfiguration.ScrollView>
        <PumpTypeOption
          IconComponent="DevicePump"
          id="pump_off_only"
          labelText={i18n.t(
            "deviceConfiguration:choosePumpType_pump_off_only.description",
          )}
          onChange={() => setValue("deviceInstallationType", "pump_off_only")}
        />
        <Divider my="$2" />
        <PumpTypeOption
          IconComponent="VoltageBolt"
          id="pump_on_off"
          labelText={i18n.t(
            "deviceConfiguration:choosePumpType_pump_on_off.description",
          )}
          onChange={() => setValue("deviceInstallationType", "pump_on_off")}
        />
        <Divider my="$2" />
        <PumpTypeOption
          IconComponent="DevicePumpVfd"
          id="pump_vfd"
          labelText={i18n.t(
            "deviceConfiguration:choosePumpType_pump_vfd.description",
          )}
          onChange={() => setValue("deviceInstallationType", "pump_vfd")}
        />
        <Divider />
        <Box mr="auto" mt="$4">
          <Button text={i18n.t("cancel")} variant="text" onPress={onCancel} />
        </Box>
      </CreateConfiguration.ScrollView>
    </CreateConfiguration.PageWrapper>
  )
}

/**
 *  Show currently selected pump type
 */
function PumpTypeIndicator({
  installationType,
}: {
  installationType: InstallationType
}): React.JSX.Element | null {
  let labelText: string | undefined
  switch (installationType) {
    case "pump_off_only": {
      labelText = i18n.t(
        "deviceConfiguration:choosePumpType_pump_off_only.label",
      )
      break
    }
    case "pump_on_off": {
      labelText = i18n.t(
        "deviceConfiguration:choosePumpType_pump_off_only.label",
      )
      break
    }
    case "pump_vfd": {
      labelText = i18n.t(
        "deviceConfiguration:choosePumpType_pump_off_only.label",
      )
      break
    }
    default: {
      return null
    }
  }
  return (
    <Row>
      <Box mr="$2">
        <AppText colorScheme="secondary">
          {i18n.t("deviceConfiguration:pumpType")}
        </AppText>
      </Box>
      <AppText>{labelText}</AppText>
    </Row>
  )
}

function CreatePc1Pump() {
  const {
    form: { setValue, watch },
    isLoading,
    onSubmit,
  } = CreateConfiguration.useContext()

  const handleClearPumpType = () => setValue("deviceInstallationType", "pump")

  const installationType = watch("deviceInstallationType")
  return (
    <CreateConfiguration.PageWrapper>
      <CreateConfiguration.Container variant="header">
        <StageHeading
          subtitleText={i18n.t("deviceConfiguration:setUpPump")}
          titleText={i18n.t("deviceConfiguration:pc1PumpSetupStageTitle")}
        />
      </CreateConfiguration.Container>
      <CreateConfiguration.ScrollView>
        <PumpTypeIndicator installationType={installationType} />
        <DeviceNameInput />
        <Box mt="$4">
          <ChooseSwitchType sensorName="pressureSwitch" />
        </Box>
        <Divider my="$2" />
        <ChooseSensorPort sensorName="pressureSwitch" />
      </CreateConfiguration.ScrollView>
      <CreateConfiguration.Container variant="footer">
        <ActionButtons
          isLoading={isLoading}
          mb="$2"
          mt="$2"
          onPressCancel={handleClearPumpType}
          onPressSubmit={onSubmit}
        />
      </CreateConfiguration.Container>
    </CreateConfiguration.PageWrapper>
  )
}

function CreatePumpStages() {
  const { form } = CreateConfiguration.useContext()
  const [stage, setStage] = React.useState<Stages>(0)

  const commonProps: StageProps = {
    cancelText: i18n.t("back"),
    onPressCancel: () => setStage((prev) => prev - 1),
    onPressSubmit: () => setStage((prev) => prev + 1),
    submitText: i18n.t("next"),
  }

  const isVfd = form.watch("deviceInstallationType") === "pump_vfd"

  switch (stage) {
    case Stages.choosePressureSensorModel: {
      return (
        <CreateConfiguration.ChoosePressureSensorModelStage
          {...commonProps}
          stageIndex={0}
          onPressCancel={() => form.setValue("deviceInstallationType", "pump")}
        />
      )
    }
    case Stages.chooseSensorPort: {
      return (
        <CreateConfiguration.ChoosePressureSensorPortStage
          stageIndex={1}
          {...commonProps}
        />
      )
    }
    case Stages.setThresholds: {
      return (
        <CreateConfiguration.SetPressureThresholdsStage
          stageIndex={2}
          {...commonProps}
        />
      )
    }

    case Stages.customizeDeviceName: {
      return (
        <CreateConfiguration.CustomizeDeviceNameStage
          stageIndex={3}
          {...commonProps}
        />
      )
    }
    case Stages.reviewAndSubmit: {
      return (
        <CreateConfiguration.ReviewAndSubmitStage
          sensorNames={["pressure", isVfd && "vfd"]}
          stageIndex={4}
          {...commonProps}
        />
      )
    }
  }
}
/**
 *
 */
export function CreatePumpConfiguration(): React.JSX.Element {
  const { watch } = useFormContext<DeviceConfiguration>()

  // React.useEffect(() => {
  //   if (__DEV__) {
  //     setValue("deviceInstallationType", "pump_off_only")
  //   }
  // }, [setValue])

  const installationType = watch("deviceInstallationType")

  if (installationType === "pump") {
    return <ChoosePumpType />
  }

  if (watch("hardwareGeneration") === "PC1") {
    return <CreatePc1Pump />
  }

  return <CreatePumpStages />
}
