import React from "react"
import { Controller } from "react-hook-form"

import { ChoosePressureSensorModel } from "./ChoosePressureSensorModel"
import { AlertCard, AppText } from "./components"
import * as CreateConfiguration from "./CreateDeviceConfigurationContext"
import {
  ChooseSensorPort,
  ChooseSprinklerType,
  ChooseSwitchType,
  FlowMillilitersPerPulseInput,
  MagnetSelect,
  PressureSliderConnected,
  ReelHoseDiameterInput,
  ReelNozzleDiameterInput,
  ReelNumberOfMagnetsSelect,
  ReelNumberOfNozzlesInput,
  ReelNumberOfOuterLayerWrapsInput,
  ReelOuterHoseWrapRadiusInput,
  ReelSwathWidthInput,
  ReelWidthInput,
  SensorTextInput,
  WheelDiameterInput,
} from "./DeviceSensorFields"
import { ReelSpeedSliders } from "./ReelSpeedSlider"
import { isMutableSensorName } from "./sensors"
import i18n from "./translations/i18n"

import type { SensorConfigKey, SensorName } from "./sensor-configurations"
/**
 * Show a form for the field that the user is currently editing
 */
export function SelectedSensorField({
  fieldName,
  selectedSensorName,
}: {
  fieldName: SensorConfigKey
  selectedSensorName: SensorName
}) {
  const { form } = CreateConfiguration.useContext()
  switch (fieldName) {
    case "sensorModelId": {
      return (
        <Controller
          control={form.control}
          name="pressure.sensorModelId"
          render={({ field }) => {
            return (
              <React.Fragment>
                <AppText>
                  {i18n.t(
                    "deviceConfiguration:choosePressureSensorModelInstructions",
                  )}
                </AppText>
                <ChoosePressureSensorModel
                  selectedValue={field.value}
                  onChange={field.onChange}
                />
              </React.Fragment>
            )
          }}
        />
      )
    }
    case "thresholdPsiLower":
    case "thresholdPsiUpper": {
      return (
        <React.Fragment>
          <AlertCard
            IconComponent={null}
            my="$2"
            severity="info"
            bodyText={i18n.t(
              "deviceConfiguration:setPressureThresholdInstructions",
            )}
          />
          <PressureSliderConnected />
        </React.Fragment>
      )
    }
    case "sprinklerType": {
      return <ChooseSprinklerType />
    }
    case "switchType": {
      return <ChooseSwitchType sensorName="pressureSwitch" />
    }
    case "sensorPort": {
      if (isMutableSensorName(selectedSensorName)) {
        return <ChooseSensorPort sensorName={selectedSensorName} />
      }
      return null
    }
    case "nMagnets": {
      if (selectedSensorName === "reel") {
        return <ReelNumberOfMagnetsSelect />
      }
      if (selectedSensorName === "wheel") {
        return <MagnetSelect sensorName={selectedSensorName} />
      }
      throw new TypeError(`Invalid sensor name for field ${fieldName}`)
    }
    case "linearSpeedMmHMin":
    case "linearSpeedMmHMax": {
      return <ReelSpeedSliders />
    }
    case "nNozzles": {
      return <ReelNumberOfNozzlesInput />
    }
    case "nozzleDiameterMm": {
      return <ReelNozzleDiameterInput />
    }
    case "hoseDiameterMm": {
      return <ReelHoseDiameterInput />
    }
    case "nWrapsOuterLayer": {
      return <ReelNumberOfOuterLayerWrapsInput />
    }
    case "swathWidthMm": {
      return <ReelSwathWidthInput />
    }
    case "outerHoseWrapRadiusMm": {
      return <ReelOuterHoseWrapRadiusInput />
    }
    case "widthMm": {
      return <ReelWidthInput />
    }
    case "mlPerPulse": {
      return <FlowMillilitersPerPulseInput />
    }
    case "diameterMm": {
      return <WheelDiameterInput />
    }
    case "voltageSlope":
    case "voltageIntercept":
    case "voltageThresholdLowMv":
    case "gpsType":
    case "ioPin":
    case "calibrationIntercept":
    case "calibrationSlope":
    case "installedOrientation":
    case "milliRpmFast":
    case "milliRpmSlow":
    case "threshold":
    case "i2CAddress":
    case "maxTrackingPercentage":
    case "minTrackingPercentage": {
      return (
        <SensorTextInput
          _input={{ keyboardType: "numeric" }}
          fieldName={fieldName}
          sensorName={selectedSensorName}
        />
      )
    }
  }
}
