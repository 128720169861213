import { Navigate, Outlet, useLocation } from "react-router-dom"

import {
  getAppViewFromState,
  getIsDemoModeActiveFromState,
  getRealFarmCountFromState,
  LoadError,
  TermsOfService,
  useRootSelector,
} from "@fhq/app"
import { Box, ScrollView } from "@fhq/app/components"
import { SPACING } from "@fhq/app/components/theme"
import { handleReloadApp } from "@fhq/app/utility"

import { DashboardRoutes } from "./Dashboard.Routes"
import { NoFarmRoutes } from "./NoFarms.Routes"
import {
  AddInitialPhoneNumberPage,
  VerifyInitialPhoneNumberPage,
} from "./pages/AddInitialPhoneNumberPage"
import { Environment } from "./pages/base/base"
import { SeedDatabasePage } from "./pages/SeedDatabasePage"
import { UserNameFormPage } from "./pages/UserNameFormPage"

import type { AcceptsChildren } from "@fhq/app/components"

import type { RouteObject } from "react-router-dom"
/**
 *
 */
function AuthRequired(props: AcceptsChildren): React.JSX.Element {
  const location = useLocation()

  const appView = useRootSelector(getAppViewFromState)
  if (appView === "load-error") {
    return (
      <Box>
        <LoadError
          targetDatabaseName={Environment.targetDatabaseName}
          onPrepareDemoSuccess={handleReloadApp}
        />
      </Box>
    )
  }
  if (appView === "auth") {
    return <Navigate state={{ from: location }} to="/sign-in" />
  }
  if (appView === "accept-terms-of-service") {
    return (
      <Box flex={1} id="terms-of-service">
        <TermsOfService.LogoHeader />
        <ScrollView contentContainerStyle={{ padding: SPACING.$4 }}>
          <TermsOfService.TermsOfServiceText />
        </ScrollView>
        <TermsOfService.Actions />
      </Box>
    )
  }
  if (appView === "userNameForm") {
    return <UserNameFormPage />
  }
  if (appView === "add-initial-phone-number") {
    return <AddInitialPhoneNumberPage />
  }
  if (appView === "verify-initial-phone-number") {
    return <VerifyInitialPhoneNumberPage />
  }

  return props.children as JSX.Element
}

function FarmRequired(props: AcceptsChildren) {
  const location = useLocation()
  const isDemoEnabled = useRootSelector(getIsDemoModeActiveFromState)
  const numFarms = useRootSelector(getRealFarmCountFromState)
  // const numFarms = 0

  if (numFarms === 0 && !isDemoEnabled) {
    return <Navigate state={{ from: location }} to="no-farms" />
  }
  return props.children as JSX.Element
}

export const AUTHENTICATED_ROUTES: RouteObject = {
  children: [
    {
      ...NoFarmRoutes,
      path: "no-farms",
    },
    {
      ...DashboardRoutes,
      element: <FarmRequired>{DashboardRoutes.element}</FarmRequired>,
    },
    {
      element: <SeedDatabasePage />,
      path: "seed-database",
    },
  ],
  element: (
    <AuthRequired>
      <Outlet />
    </AuthRequired>
  ),
}
