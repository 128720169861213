import { Models, useRootSelector } from "@fhq/app"
import {
  CenterPivotColors,
  makePlotPivotOutline,
} from "@fhq/app/CenterPivotPathVisualization"
import { Polygon } from "@react-google-maps/api"
import { withErrorBoundary } from "@sentry/react"

import { BASE_SWATH_OPTIONS } from "./base"
import { pointTransformer } from "./map-utils"

import type { DeviceIdProps } from "@fhq/app"
export const plotOutline = makePlotPivotOutline(pointTransformer)

const options: google.maps.PolygonOptions = {
  ...BASE_SWATH_OPTIONS,
  clickable: false,
  fillOpacity: 0,
  strokeColor: CenterPivotColors.outline.strokeColor,
  strokeWeight: 3,
}

/**
 * Displays the outline of a center pivot swath based on its configuration.
 */
function CenterPivotOutlineVisualization({ deviceId }: DeviceIdProps) {
  const configuration = useRootSelector((state) => {
    return Models.deviceConfiguration.selectById(state, deviceId)
  })
  if (
    configuration &&
    configuration.deviceInstallationType === "center_pivot"
  ) {
    const extentPath = plotOutline(configuration)

    if (extentPath && extentPath.length > 3) {
      // if (configuration.codaDeviceAlias === "timely-turtle") {
      //   console.log(configuration.deviceId)
      // }
      // const {
      //   codaDeviceAlias,
      //   pivotCenterGpsLocation,
      //   pivotPathStopsCoordinates,
      //   pivotPathStopsHeadingsDegrees,
      //   pivotPathStopsLabels,
      //   pivotRadiusMeters,
      // } = configuration
      // if (
      //   ["timely-turtle", "green-goat", "actor-farmer"].includes(
      //     codaDeviceAlias,
      //   )
      // ) {
      //   console.log(
      //     JSON.stringify(
      //       {
      //         codaDeviceAlias,
      //         pivotCenterGpsLocation,
      //         pivotPathStopsCoordinates,
      //         pivotPathStopsHeadingsDegrees,
      //         pivotPathStopsLabels,
      //         pivotRadiusMeters,
      //       },
      //       null,
      //       2,
      //     ),
      //   )
      // }

      return <Polygon options={options} path={extentPath} />
    }
  }
  return null
}

export default withErrorBoundary(CenterPivotOutlineVisualization, {})
