import { createAction } from "@reduxjs/toolkit"

import type { StatusMapFeatureName } from "./constants"
import type { FieldActionName } from "./fields.reducer"

import type { PermissionDeniedPayload } from "./types"

// TODO: Get rid of these
export const clickCancel = createAction("CLICK_CANCEL")

export const denyPermission =
  createAction<PermissionDeniedPayload>("DENY PERMISSION")
export const selectReelRunHistorical = createAction<{
  reelRunId: number | undefined
}>("SELECT_REEL_RUN_HISTORICAL")
export const clearSelectedTrigger = createAction("CLEAR_SELECTED_TRIGGER")

export const setActiveFeature = createAction<
  StatusMapFeatureName | { value: StatusMapFeatureName | undefined } | undefined
>("SET_ACTIVE_FEATURE")

export const setFieldActionsName = createAction<
  FieldActionName | { value: FieldActionName | undefined } | undefined
>("fields/setFieldActionsName")
