import React from "react"

import { AppText } from "@fhq/app/components"
import { useFieldColors } from "@fhq/app/useFieldColors"
import { OverlayView, Polygon as BasePolygon } from "@react-google-maps/api"

import type { Models } from "@fhq/app"
import type { AppTextProps } from "@fhq/app/components"
import type { PolygonProps } from "@react-google-maps/api"

const overlayDivStyle: React.CSSProperties = {
  pointerEvents: "none",
}
/**
 * A label for a field that is displayed on the map.
 */
export function FieldLabel({
  fieldName,
  labelRotation = 0,
  location,
  ...rest
}: Omit<AppTextProps, "children"> & {
  fieldName: string
  labelRotation: number | null | undefined
  location: google.maps.LatLngLiteral | undefined
}): React.JSX.Element | null {
  const labelStyle = {
    color: "white",
    textShadowRadius: 5,
    transform: [{ rotate: `${labelRotation ?? "0"}deg` }],
  }

  if (location) {
    return (
      <OverlayView mapPaneName="overlayMouseTarget" position={location}>
        <div style={overlayDivStyle}>
          <AppText {...rest} style={labelStyle}>
            {fieldName}
          </AppText>
        </div>
      </OverlayView>
    )
  }
  return null
}

export type FieldPolygonBaseProps = Pick<
  Models.FarmField,
  "centerLatLng" | "fieldName" | "isActive" | "labelRotationDegrees" | "path"
>

interface FieldPolygonProps
  extends Pick<PolygonProps, "onLoad" | "onMouseUp">,
    FieldPolygonBaseProps {
  coloration?: "highlight" | "mute"
  fillOpacity?: number
  isEditable?: boolean
  isPressable?: boolean
  onPress?: PolygonProps["onClick"]
  showLabel?: boolean
  strokeOpacity?: number
  strokePosition?: google.maps.StrokePosition
  strokeWeight?: number
}

/**
 *
 */
export function FieldPolygon({
  centerLatLng,
  coloration,
  fieldName,
  fillOpacity = 0.05,
  isActive,
  isEditable,
  isPressable = true,
  labelRotationDegrees,
  onLoad,
  onMouseUp,
  onPress,
  path,
  showLabel = true,
  strokeOpacity,
  strokePosition,
  strokeWeight = 2,
}: FieldPolygonProps) {
  const { fillColor, strokeColor } = useFieldColors()

  const visible = isActive
  switch (coloration) {
    case "highlight": {
      strokeOpacity = (strokeOpacity ?? 0.5) + 0.2
      strokeWeight = 4
      break
    }
    case "mute": {
      strokeWeight = 1
      strokeOpacity = 0.35

      break
    }
    case undefined:
      break
  }

  const polygonOptions = React.useMemo((): google.maps.PolygonOptions => {
    return {
      clickable: isPressable,
      fillColor,
      fillOpacity,
      strokeColor,
      strokeOpacity,
      strokePosition,
      strokeWeight,
      visible,
    }
  }, [
    fillColor,
    fillOpacity,
    isPressable,
    strokeColor,
    strokeOpacity,
    strokePosition,
    strokeWeight,
    visible,
  ])
  if (!path) {
    return null
  }
  if (path.web.length < 4) {
    return null
  }
  return (
    <React.Fragment>
      <BasePolygon
        editable={isEditable}
        options={polygonOptions}
        path={path.web}
        visible={visible}
        onClick={onPress}
        onLoad={onLoad}
        onMouseUp={onMouseUp}
      />
      {showLabel && typeof fieldName === "string" && centerLatLng ? (
        <FieldLabel
          fieldName={fieldName}
          labelRotation={labelRotationDegrees}
          location={centerLatLng.web}
        />
      ) : null}
    </React.Fragment>
  )
}
