import "./Support.css"

import React from "react"
import { useTranslation } from "react-i18next"
import { Pressable } from "react-native"
import Collapsible from "react-native-collapsible"
import { generatePath, useLocation, useNavigate } from "react-router"
import { useReactToPrint } from "react-to-print"

import { isTruthyString, listObjectKeys, Loader } from "@fhq/app"
import { useScreenSizeBreakpoint } from "@fhq/app/app-state.reducer"
import {
  AppIcons,
  AppText,
  Box,
  Center,
  Divider,
  Heading,
  ICON_SIZES,
  IconButton,
  ListItemTextPrimary,
  ListSubHeader,
  Paper,
  renderIconFromIconProp,
  Row,
  Skeleton,
  testIds,
} from "@fhq/app/components"
import { COLORS, SIZES, SPACING, Z_INDEX } from "@fhq/app/components/theme"
import { isValidHardwareGeneration } from "@fhq/app/sensor-configurations"
import { humanize } from "@fhq/app/string-formatting"
import * as Sentry from "@sentry/react"

import { BreadcrumbNavigation } from "./base/BreadCrumbNavigation"
import { ScrollingContent } from "./base/Layout"
import { Paths } from "./base/paths"
import { SUPPORT_PRODUCT_TO_FILE_MAP } from "./base/SupportContext"
import Page404 from "./Page404"
import Page500 from "./Page500"
import { convertHeadingToValidId, RenderMarkdown } from "./RenderMarkdown"
import {
  CloseSidebarIconButton,
  SidebarPage,
  SidebarToggleIconButton,
  useSidebarContext,
} from "./SidebarLayout"
import * as Context from "./SupportPageContext"

import type { StateSetter, Support } from "@fhq/app"
import type {
  BoxProps,
  IconKey,
  SkeletonProps,
  TestId,
} from "@fhq/app/components"
// CONSTANTS
const PRODUCT_PATH = `${Paths.support}/:productId` as const
const PAGE_PATH = `${PRODUCT_PATH}/:pageId` as const

/**
 *
 */
function LoadingSkeleton({ style, ...rest }: SkeletonProps): React.JSX.Element {
  return (
    <Skeleton
      // startColor={COLORS.$gray[300]}
      style={[
        {
          height: SIZES.$6,
          marginTop: SPACING.$2,
          width: "100%",
        },
        style,
      ]}
      {...rest}
    />
  )
}

/**
 *
 */
function PageIsLoading(): React.JSX.Element {
  return (
    <Center alignItems="flex-start" maxW="$full" mt="$12" w="$full">
      <LoadingSkeleton isLoading style={{ height: SIZES.$12, width: 200 }} />
      {Array.from({ length: 3 }).map(
        (_e, i): React.JSX.Element => (
          <React.Fragment key={i}>
            {Array.from({ length: 3 }).map((_, j) => (
              <LoadingSkeleton key={j} isLoading />
            ))}
            <LoadingSkeleton
              isLoading
              style={{
                alignSelf: "center",
                height: 300,
                marginTop: SPACING.$4,
                width: 300,
              }}
            />
            {Array.from({ length: 6 }).map(
              (_, j): React.JSX.Element => (
                <LoadingSkeleton key={j} isLoading />
              ),
            )}
          </React.Fragment>
        ),
      )}
    </Center>
  )
}

function SubtopicsList(props: BoxProps): React.JSX.Element {
  interface ItemType {
    id: string
    title: string
  }

  const { fetchStatus, markdownText } = Context.useContext()
  const { handleCloseSidebar } = useSidebarContext()
  const [subtopicHeadings, setSubtopicHeadings] = React.useState<ItemType[]>([])
  const [currentHeading, setCurrentHeading] = React.useState<
    string | undefined
  >()

  /**
   * Dynamically generate sidebar link text and indent levels
   * by parsing raw markdown
   */
  React.useEffect(() => {
    if (typeof markdownText === "string") {
      const result: ItemType[] = []
      for (let line of markdownText.split(/\n/)) {
        line = line.trim()
        // only headings start with #
        if (line.startsWith("#")) {
          // we need to count the number of # before the first space
          // in order to figure out the level of the heading
          const splitIndex = line.indexOf(" ")
          // Remove the # signs to get the actual title
          const titleText = line.slice(splitIndex + 1)
          if (isTruthyString(titleText)) {
            const id = convertHeadingToValidId(titleText)
            result.push({ id, title: titleText })
          }
        }
      }
      setSubtopicHeadings(result)
      setCurrentHeading(result[0]?.title)
    }
  }, [markdownText])

  return (
    <Box {...props}>
      {fetchStatus === "pending" ? (
        <Loader />
      ) : (
        subtopicHeadings.map(({ id, title }, index) => {
          const showDivider = index < subtopicHeadings.length - 1
          /**
           * Find the heading in the page-content div with the heading we generate
           * here based on the title and try to scroll to it if the component
           * is running in a web context. If the sidebar is open, close it.
           */
          const handlePress = () => {
            setCurrentHeading(id)
            document
              .querySelector(`#${id}`)
              ?.scrollIntoView({ behavior: "smooth" })
            handleCloseSidebar()
          }

          return (
            <Pressable key={title} onPress={handlePress}>
              <Box pl="$2" py="$2">
                <Row my="auto">
                  <ListItemTextPrimary>{title}</ListItemTextPrimary>
                  {currentHeading === id ? (
                    <AppIcons.Dot
                      color={COLORS.$blue[500]}
                      size={ICON_SIZES.$lg}
                    />
                  ) : null}
                </Row>
                {showDivider ? <Divider /> : null}
              </Box>
            </Pressable>
          )
        })
      )}
    </Box>
  )
}

/**
 * Render the topic (page) name. When expanded,
 * display all the page's headings as subtopics
 */
function SidebarTopic({
  children,
  isCollapsed,
  pageId,
  setIsCollapsed,
}: {
  children: React.JSX.Element
  isCollapsed: boolean
  pageId: Support.SupportPageId
  setIsCollapsed: StateSetter<boolean>
}) {
  const navigate = useNavigate()
  const currentPath = useLocation().pathname
  const { currentProductId } = Context.useContext()

  const pagePath = generatePath(PAGE_PATH, {
    pageId,
    productId: currentProductId,
  })
  let bg: string | undefined
  let iconName: IconKey = "ExpandLess"

  const isSelected = pagePath === currentPath

  if (isSelected) {
    iconName = "ExpandMore"
    bg = COLORS.$gray[300]
  }

  const collapsed = !isSelected || isCollapsed
  const handlePress = () => {
    if (isSelected) {
      setIsCollapsed((prev) => !prev)
    } else {
      setIsCollapsed(false)
    }
    return navigate(pagePath)
  }
  return (
    <React.Fragment>
      <Pressable {...testIds(`${pageId}-link` as TestId)} onPress={handlePress}>
        <Row bg={bg} justifyContent="space-between" px="$4" py="$4" w="$full">
          <ListItemTextPrimary
            fontSize="$md"
            fontWeight="bold"
            textTransform="capitalize"
          >
            {humanize(pageId, { textFormat: "titleCase" })}
          </ListItemTextPrimary>
          {renderIconFromIconProp(iconName)}
        </Row>
      </Pressable>
      <Collapsible collapsed={collapsed}>
        {collapsed ? null : children}
      </Collapsible>
    </React.Fragment>
  )
}

/**
 *
 */
function useBreadcrumbPath() {
  const { t } = useTranslation()
  return React.useCallback(
    ({ value }: { value: string }) => {
      if (value === "") {
        return t("home", { ns: "common" })
      }
      value = value.toUpperCase()
      if (isValidHardwareGeneration(value)) {
        return value
      }
      return humanize(value, { textFormat: "titleCase" })
    },
    [t],
  )
}

/**
 *
 */
function SidebarComponent(): React.JSX.Element {
  const { t } = useTranslation("support")

  const { currentProductId, productName } = Context.useContext()

  const [isCollapsed, setIsCollapsed] = React.useState(false)
  const pageOptions = listObjectKeys(
    SUPPORT_PRODUCT_TO_FILE_MAP[currentProductId],
  )

  return (
    <Box flex={1} overflow="scroll">
      <Box minH="$toolbar" px="$4">
        <Row my="$2" w="$full">
          <Heading variant="h4">{productName}</Heading>
          <CloseSidebarIconButton />
        </Row>
        <ListSubHeader>
          {t("topic", { count: pageOptions.length })}
        </ListSubHeader>
      </Box>
      <Box id="sidebar-links">
        {pageOptions.map((pageId, index) => {
          const showDivider = index < pageOptions.length - 1
          return (
            <Box key={pageId}>
              <SidebarTopic
                isCollapsed={isCollapsed}
                pageId={pageId}
                setIsCollapsed={setIsCollapsed}
              >
                <SubtopicsList px="$4" />
              </SidebarTopic>
              {showDivider ? <Divider /> : null}
            </Box>
          )
        })}
      </Box>
    </Box>
  )
}
/**
 *
 */
export default function SupportPage(): React.JSX.Element {
  const { t } = useTranslation("support")
  const { currentPageId, fetchStatus, markdownText, productName } =
    Context.useContext()
  const screenSize = useScreenSizeBreakpoint()

  const isSmallScreen =
    screenSize === "xs" || screenSize === "sm" || screenSize === "md"

  const printAreaRef = React.useRef(null)
  const renderPath = useBreadcrumbPath()
  /**
   * Print the area selected inside of the ref forwarded
   * to the page-content component
   *
   * Note that the printed area is encapsulated by a direct DOM ref
   */
  const handlePrint = useReactToPrint({
    content: () => printAreaRef.current,
    onPrintError: (errorLocation, error) => {
      if (!__DEV__) {
        Sentry.captureException(error, { extra: { errorLocation } })
      }
    },
    removeAfterPrint: true,
    suppressErrors: false,
  })
  const subheaderText = humanize(currentPageId, { textFormat: "titleCase" })
  let pageElement: React.JSX.Element = <Page404 />

  switch (fetchStatus) {
    case "pending": {
      pageElement = <PageIsLoading />
      break
    }
    case "rejected": {
      pageElement = <Page500 />
      break
    }

    case "fulfilled": {
      if (isTruthyString(markdownText)) {
        pageElement = <RenderMarkdown text={markdownText} />
      }
      break
    }
  }

  return (
    <SidebarPage SidebarComponent={<SidebarComponent />}>
      <Box {...testIds(currentPageId, { preserveId: true })} flex={1}>
        <Paper preserveId id="print-table-of-content">
          <Heading fontWeight="bold">{t("title")}</Heading>
          <AppText fontSize="$xl">{subheaderText}</AppText>
        </Paper>
        <BreadcrumbNavigation px="$2" py="$2" renderPath={renderPath} />
        <Paper flex={1} mx="auto" position="relative" w="$full">
          <ScrollingContent ref={printAreaRef}>
            <Box px="$4">
              {isSmallScreen ? (
                <Box mr="auto" mt="$2">
                  <Heading color={COLORS.$textDark.secondary} fontSize="$sm">
                    {productName}
                  </Heading>
                </Box>
              ) : null}
              {pageElement}
            </Box>
          </ScrollingContent>
        </Paper>
        <Row
          bottom="$4"
          id="print-btn"
          ml="auto"
          px="$4"
          right="$4"
          zIndex={Z_INDEX.fab}
        >
          <Box mr="$4">
            <IconButton
              IconComponent="Print"
              id="print-btn"
              size="lg"
              variant="primary"
              onPress={handlePrint}
            />
          </Box>
          <SidebarToggleIconButton size="lg" />
        </Row>
      </Box>
    </SidebarPage>
  )
}
