import i18next from "i18next"

import { AppIcons } from "@fhq/app/components"

export const DEVICE_PROFILE_PAGES = {
  activity: {
    IconComponent: AppIcons.Timeline,
    id: "device-activity",
    name: i18next.t("deviceActivity.linkTo", { ns: "devices" }),
    relativePath: "",
  },
  commandHistory: {
    IconComponent: AppIcons.DeviceCommandsRemote,
    id: "device-command-history",
    name: i18next.t("deviceActionHistoryListTitle", {
      ns: "deviceActions",
    }),
    relativePath: "command-history",
  },
  configuration: {
    IconComponent: AppIcons.DeviceConfiguration,
    id: "device-configuration",
    name: i18next.t("linkTo", { ns: "deviceConfiguration" }),
    pages: {
      createConfiguration: "create",
      sensor: {
        fieldName: ":fieldName",
        root: ":sensorName",
      },
    },
    relativePath: "configuration",
  },
  eventHistory: {
    IconComponent: AppIcons.History,
    id: "device-event-history",
    name: i18next.t("deviceEventHistory:linkTo"),
    relativePath: "event-history",
  },
  notifications: {
    IconComponent: AppIcons.NotificationsOn,
    id: "device-notifications",
    name: i18next.t("deviceNotifications:notificationRules"),
    relativePath: "notifications",
  },
} as const
export const Paths = {
  account: {
    addPhoneNumber: "add-phone-number",
    createFarmJoinCode: "new-farm-join-code",
    manageUsers: {
      managePermissions: ":targetUserEmail/:targetUserId",
      root: "manage-users",
    },
    mapPreferences: "map-preferences",
    phoneNumbersList: "phone-numbers",
    root: "/account",
    viewPermissions: "view-permissions",
    viewTermsOfService: "view-terms-of-service",
  },
  // appInfo: "/app-info",
  appNotifications: "/app-notifications",
  confirmSignUp: "/confirm-sign-up",
  // contactUs: "/contact",
  // createFarm: "/create-farm",
  dev: {
    root: "/dev",
  },
  devices: {
    addDevice: "add",
    profile: {
      pages: DEVICE_PROFILE_PAGES,
      root: `:codaDeviceAlias`,
    },
    root: `/devices`,
  },
  farm: {
    root: "/farm",
  },
  fields: {
    profile: {
      root: ":id",
    },
    root: "/fields",
  },
  joinFarm: "/join-farm",
  noFarms: {
    createFarm: "create-farm",
    root: "no-farms",
  },
  passwordReset: "/password-reset",
  phoneNumbers: "phone-numbers",
  schedules: {
    createRelative: "create",
    rootAbsolute: "/schedules",
  },
  signIn: "/sign-in",
  signOut: "/sign-out",
  signUp: "/sign-up",
  statusMap: "/",
  support: "/support",
} as const

export function navigateToExternalUrl(url: string): void {
  // window.open(url, "_blank")
  window.location.href = url
}

export interface UseLocationReturnType {
  state?: { from?: Location } | null | undefined
}
