import React from "react"
import { StyleSheet } from "react-native"

import { ActionButtons } from "./ActionButtons"
import {
  Box,
  Button,
  Divider,
  FlatList,
  getIconForSensor,
  MenuItemButton,
} from "./components"
import { SPACING } from "./components/theme"
import { TitleWithIcon } from "./components/TitleWithIcon"
import * as CreateDeviceConfiguration from "./CreateDeviceConfigurationContext"
import { isSensorCompatibleWithConfiguration } from "./device-configurations.reducer"
import {
  ChooseSensorPort,
  FlowMillilitersPerPulseInput,
  PressureSliderConnected,
} from "./DeviceSensorFields"
import { CONFIG_SENSOR_NAMES } from "./sensor-configurations"
import { formatSensorName } from "./sensor-formatting"
import { isMutableSensorName } from "./sensors"
import i18n from "./translations/i18n"

import type { FlatListProps } from "react-native"
import type { AcceptsChildren } from "./components"
import type { DeviceConfiguration } from "./device-configurations.reducer"
import type { ConfigSensorName, SensorName } from "./sensor-configurations"
import type { ProtectedButtonProps } from "./types"
export type AddableSensorName = SensorName<"flow" | "pressure">
/**
 *
 */
function FormWrapper({
  children,
  sensorName,
}: AcceptsChildren & { sensorName: AddableSensorName }) {
  const { isLoading, onCancel, onSubmit } =
    CreateDeviceConfiguration.useContext()

  const titleText: string = i18n.t(
    "deviceConfiguration:addSensorTitleWithSensorName",
    { sensorName: formatSensorName(sensorName) },
  )
  return (
    <React.Fragment>
      <TitleWithIcon
        IconComponent={getIconForSensor(sensorName)}
        titleText={titleText}
      />
      {children}
      <ActionButtons
        isLoading={isLoading}
        mt="$4"
        onPressCancel={onCancel}
        onPressSubmit={onSubmit}
      />
    </React.Fragment>
  )
}

function FormComponent({
  selectedSensorName,
}: {
  selectedSensorName: AddableSensorName
}) {
  switch (selectedSensorName) {
    case "flow": {
      return (
        <FormWrapper sensorName="flow">
          <FlowMillilitersPerPulseInput flex={1} minW="$32" />
          <Divider my="$4" />
          <ChooseSensorPort sensorName="flow" />
        </FormWrapper>
      )
    }
    case "pressure": {
      return (
        <FormWrapper sensorName="pressure">
          <Box mb="$4">
            <PressureSliderConnected />
          </Box>
          <ChooseSensorPort sensorName="pressure" />
        </FormWrapper>
      )
    }
  }
}

/**
 *
 */
export function AddSensorForm({
  configuration,
  onClose,
  onSuccess,
  sensorName,
}: {
  configuration: DeviceConfiguration
  onClose: () => void
  onSuccess: () => void
  sensorName: AddableSensorName
}) {
  return (
    <CreateDeviceConfiguration.Provider
      codaDeviceAlias={configuration.codaDeviceAlias}
      deviceId={configuration.deviceId}
      deviceInstallationType={configuration.deviceInstallationType}
      deviceName={configuration.deviceName}
      hardwareGeneration={configuration.hardwareGeneration}
      currentConfigurationData={{
        ...configuration,
      }}
      onCancel={onClose}
      onClearInstallationType={null}
      onSuccess={onSuccess}
    >
      <FormComponent selectedSensorName={sensorName} />
    </CreateDeviceConfiguration.Provider>
  )
}

/**
 *
 */
export function AddSensorLinkButton({
  onPress,
  trackEvent,
  withPermissions,
  ...rest
}: ProtectedButtonProps): React.JSX.Element {
  return (
    <Button
      IconComponent="Add"
      id="add-sensor-btn"
      text={i18n.t("deviceConfiguration:addSensor")}
      onPress={withPermissions({
        callback: () => {
          onPress()
          trackEvent({
            elementName: "add-sensor-btn",
            name: "element_press",
          })
        },
        required: "canManageDeviceConfiguration",
      })}
      {...rest}
    />
  )
}

const styles = StyleSheet.create({
  item: {
    paddingVertical: SPACING.$3,
  },
})

/**
 * Select a sensor to add to the device configuration
 */
export function SelectSensorFlatList({
  configuration,
  onChange,
  onError,
  ...rest
}: Pick<
  FlatListProps<ConfigSensorName>,
  "contentContainerStyle" | "ListHeaderComponent" | "style"
> & {
  configuration: DeviceConfiguration | undefined
  onChange: (sensorName: AddableSensorName) => void
  onError: (text: string) => void
}) {
  return (
    <FlatList
      ItemSeparatorComponent={Divider}
      data={CONFIG_SENSOR_NAMES.filter(isMutableSensorName)}
      renderItem={({ item: sensorName }) => {
        const sensorNameFormatted = formatSensorName(sensorName)
        const handlePress = () => {
          if (
            !isSensorCompatibleWithConfiguration({ configuration, sensorName })
          ) {
            return onError(
              i18n.t("deviceConfiguration:addSensorNotCompatibleErrorMessage"),
            )
          }
          if (configuration && configuration[sensorName]) {
            return onError(
              i18n.t("addSensorAlreadyAddedWithSensorNameErrorMessage", {
                ns: "deviceConfiguration",
                sensorName: sensorNameFormatted,
              }),
            )
          }
          if (sensorName !== "flow" && sensorName !== "pressure") {
            return onError(
              i18n.t(
                "deviceConfiguration:addSensorCannotAddToExistingErrorMessage",
              ),
            )
          }
          return onChange(sensorName)
        }

        return (
          <MenuItemButton
            IconComponent={getIconForSensor(sensorName)}
            px="$4"
            style={styles.item}
            text={sensorNameFormatted}
            onPress={handlePress}
          />
        )
      }}
      {...rest}
    />
  )
}
