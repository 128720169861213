import React from "react"
import { Modal, ScrollView, StyleSheet, TouchableOpacity } from "react-native"
import { SafeAreaView } from "react-native-safe-area-context"
import { useStyle } from "react-native-style-utilities"

import { Box } from "./Box"
import { IconButton } from "./Button"
import { Container } from "./Container"
import { Heading, Subheading } from "./Heading"
import { ICON_SIZES, renderIconFromIconProp } from "./icons"
import { Overlay } from "./Overlay"
import { Row } from "./Row"
import { testIds } from "./test-id"
import { AppText } from "./Text"
import { COLORS, RADII, SPACING } from "./theme"
import { useIsDarkMode } from "./ThemeProvider"
import { View } from "./View"

import type { TestId } from "./test-id"
import type { ViewProps } from "./View"

import type { IconProp } from "./icons"
import type { AppTextProps } from "./Text"
import type { AcceptsChildren } from "./types"
export interface AlertDialogProps extends AcceptsChildren {
  isOpen: boolean
  onClose: (() => void) | undefined
  titleElement: React.JSX.Element | string | null
  IconComponent?: IconProp
  actionElement?: React.JSX.Element | null
  bodyText?: string
  id?: TestId
  isCloseDisabled?: boolean
  maxWidth?: number | string
  subheaderElement?: React.JSX.Element | string | null
}

const styles = StyleSheet.create({
  dialog: {
    backgroundColor: COLORS.$background.light,
    borderRadius: RADII.$default,
    // @ts-ignore
    cursor: "default",
    marginBottom: SPACING.$2,
    // This works for web
    maxHeight: "80%",
    maxWidth: 500,
    minWidth: 200,
    overflow: "hidden",
    width: "90%",
  },
  dialogDark: {
    backgroundColor: COLORS.$background.dark,
  },
  footer: {
    backgroundColor: COLORS.$paper.light,
    display: "flex",
    flexDirection: "row",
    flexWrap: "nowrap",
    justifyContent: "flex-end",
    paddingBottom: SPACING.$4,
    paddingTop: SPACING.$2,
  },
  header: {
    alignItems: "flex-start",
    backgroundColor: COLORS.$paper.light,
    display: "flex",
    flexDirection: "row",
    flexWrap: "nowrap",
    justifyContent: "space-between",
  },
  headerDark: {
    backgroundColor: COLORS.$paper.dark,
  },
  headerPadding: {
    paddingHorizontal: SPACING.$4,
    paddingVertical: SPACING.$2,
  },
  safeArea: {
    flex: 1,
  },
  scrollViewContainer: {
    padding: SPACING.$4,
  },
  textContainer: {
    flex: 1,
  },
})

/**
 * Encapuslated  `Text` component for the body of the dialog
 */
export function AlertDialogBodyText(props: AppTextProps) {
  return <AppText {...props} />
}

function DialogHeader({
  IconComponent,
  isCloseDisabled,
  onClose,
  style,
  subheaderElement,
  titleElement,
  ...rest
}: Pick<
  AlertDialogProps,
  | "IconComponent"
  | "isCloseDisabled"
  | "onClose"
  | "subheaderElement"
  | "titleElement"
> &
  ViewProps) {
  const iconElement = renderIconFromIconProp(IconComponent, {
    size: ICON_SIZES.$md,
  })

  let titleElementFinal =
    typeof titleElement === "string" ? (
      <Heading variant="h4">{titleElement}</Heading>
    ) : (
      titleElement
    )

  if (iconElement) {
    titleElementFinal = (
      <Row flexWrap="nowrap">
        <Box mr="$2">{iconElement}</Box>
        <Box flex={1}>{titleElementFinal}</Box>
      </Row>
    )
  }
  const isDark = useIsDarkMode()
  return (
    <View
      {...rest}
      style={useStyle(
        () => [
          styles.header,
          styles.headerPadding,
          isDark && styles.headerDark,
          style,
        ],
        [isDark, style],
      )}
    >
      <View style={styles.textContainer}>
        {titleElementFinal}
        {typeof subheaderElement === "string" ? (
          <Subheading>{subheaderElement}</Subheading>
        ) : (
          subheaderElement
        )}
      </View>
      <IconButton
        IconComponent="Close"
        id="close-dialog-btn"
        isDisabled={isCloseDisabled}
        size="sm"
        variant="text"
        onPress={onClose}
      />
    </View>
  )
}

export const AlertDialogScrollView = React.forwardRef<
  ScrollView,
  AcceptsChildren
>(function DialogScrollView({ children }, ref) {
  return (
    <ScrollView ref={ref} contentContainerStyle={styles.scrollViewContainer}>
      <View onStartShouldSetResponder={() => true}>{children}</View>
    </ScrollView>
  )
})

export function DialogFooter({ children }: AcceptsChildren) {
  const isDark = useIsDarkMode()
  return (
    <View
      style={[styles.footer, styles.headerPadding, isDark && styles.headerDark]}
    >
      {children}
    </View>
  )
}

/**
 * A modal dialog that can be used to display alerts, confirmations, or other
 */
export function AlertDialog({
  IconComponent,
  actionElement,
  bodyText,
  children,
  id,
  isCloseDisabled,
  isOpen,
  maxWidth,
  onClose,
  subheaderElement,
  titleElement,
}: AlertDialogProps) {
  const isDark = useIsDarkMode()
  const dialogStyle = useStyle(
    () => [
      styles.dialog,
      isDark && styles.dialogDark,
      {
        maxWidth: maxWidth ?? styles.dialog.maxWidth,
      },
    ],
    [isDark, maxWidth],
  )
  if (!isOpen) {
    return null
  }
  return (
    <Modal transparent visible onRequestClose={onClose}>
      <SafeAreaView style={styles.safeArea}>
        <Overlay onPress={onClose}>
          <TouchableOpacity
            {...testIds(id ?? "dialog")}
            activeOpacity={1}
            style={dialogStyle}
          >
            <DialogHeader
              IconComponent={IconComponent}
              isCloseDisabled={isCloseDisabled}
              subheaderElement={subheaderElement}
              titleElement={titleElement}
              onClose={onClose}
            />
            {typeof bodyText === "string" ? (
              <Container pb="$4" pt="$2">
                <AlertDialogBodyText>{bodyText}</AlertDialogBodyText>
              </Container>
            ) : null}
            {children}
            {actionElement && <DialogFooter>{actionElement}</DialogFooter>}
          </TouchableOpacity>
        </Overlay>
      </SafeAreaView>
    </Modal>
  )
}

/**
 * Encapuslated  `Text` component for the title of the dialog
 */
export function AlertDialogBody({ children }: AcceptsChildren) {
  return (
    <Box px="$4" py="$2">
      {children}
    </Box>
  )
}
