import React from "react"

import type { AcceptsChildren } from "./components"
import type { PermissionCheckProps } from "./types"

type ContextValue = PermissionCheckProps["withPermissions"]

const Context = React.createContext<ContextValue | undefined>(undefined)

// TODO: Combine this with makePermissionCheckHook and make sure it is cached
export function Provider({
  children,
  withPermissions,
}: AcceptsChildren & PermissionCheckProps): JSX.Element | null {
  return <Context.Provider value={withPermissions}>{children}</Context.Provider>
}

export function usePermissionCheckContext(): ContextValue {
  const ctx = React.useContext(Context)
  if (typeof ctx === "undefined") {
    throw new TypeError(`Context must be used inside of provider`)
  }
  return ctx
}
