import React from "react"
import { Controller, useFormContext } from "react-hook-form"

import { AppText, Box } from "./components"
import * as CreateConfiguration from "./CreateDeviceConfigurationContext"
import { ChooseSensorPort, SensorTextInput } from "./DeviceSensorFields"
import { useMeasurementPreference } from "./selectors"
import {
  MUTABLE_SENSOR_NAMES,
  SENSOR_DEFAULTS,
  SensorPorts,
} from "./sensor-configurations"
import { StageHeading } from "./StageHeading"
import i18n from "./translations/i18n"

import type { StageProps } from "./CreateDeviceConfigurationContext"
import type { SensorConfig } from "./sensor-configurations"

import type { DeviceConfiguration } from "./device-configurations.reducer"
/**
 *
 */
function DoesItHaveFlowSensor({
  onCancel,
  onValueChange,
}: {
  onCancel: () => void
  onValueChange: (nextValue: boolean) => void
}) {
  const { control, watch } = useFormContext<DeviceConfiguration>()
  const deviceInstallationType = watch("deviceInstallationType")
  const hardwareGeneration = watch("hardwareGeneration")
  const measurementPreference = useMeasurementPreference()
  const promptText: string = i18n.t("deviceConfiguration:doesItHaveAFlowSensor")
  const getDefaults = CreateConfiguration.useDefaultSensorValues()

  return (
    <Controller
      control={control}
      name="flow"
      render={({ field }) => {
        const handleChange = (nextValue: boolean) => {
          let flowConfig: SensorConfig<"flow"> | null
          if (nextValue) {
            let otherValueAsNumber: number | undefined

            for (const sensorName of MUTABLE_SENSOR_NAMES) {
              if (sensorName !== "flow") {
                // Look for another sensor with a sensor port and
                // parse its value
                const otherValue = watch(`${sensorName}.sensorPort`)
                if (typeof otherValue === "string") {
                  otherValueAsNumber = Number.parseInt(otherValue)
                } else if (typeof otherValue === "number") {
                  otherValueAsNumber = otherValue
                }
              }
            }
            const resetValues = CreateConfiguration.convertSensorConfiguration({
              measurementPreference,
              target: "user",
              values:
                getDefaults({
                  hardwareGeneration,
                  installationType: deviceInstallationType,
                }).flow ?? SENSOR_DEFAULTS.flow,
            })

            // If there is another sensor with a sensor port,
            // make sure this gets initialized to the OTHER port
            flowConfig = {
              ...SENSOR_DEFAULTS.flow,
              ...resetValues,
              sensorPort:
                otherValueAsNumber === SensorPorts.ONE
                  ? SensorPorts.TWO
                  : SensorPorts.ONE,
            }
          } else {
            flowConfig = null
          }
          field.onChange(flowConfig)
          return onValueChange(nextValue)
        }

        return (
          <React.Fragment>
            <Box mb="$4">
              <AppText>{promptText}</AppText>
            </Box>
            <CreateConfiguration.YesOrNoButtons
              onCancel={onCancel}
              onValueChange={handleChange}
            />
          </React.Fragment>
        )
      }}
    />
  )
}

// TODO: Fix back button logic
export function CreateValve() {
  const { form, onClearInstallationType, subtitleText } =
    CreateConfiguration.useContext()

  enum Stages {
    pressureSensor = 0,
    pressureSensorModel = 1,
    pressureSensorThresholds = 2,
    pressureSensorPort = 3,
    flowSensor = 4,
    flowMlPerPulse = 5,
    flowSensorPort = 6,
    deviceName = 7,
    reviewAndSubmit = 8,
  }

  const [stage, setStage] = React.useState<Stages>(0)

  const stageProps: StageProps = {
    onPressCancel: () => setStage((prev) => prev - 1),
    onPressSubmit: () => setStage((prev) => prev + 1),
  }
  const hasPressure = form.watch("pressure")
  switch (stage) {
    case Stages.pressureSensor: {
      return (
        <CreateConfiguration.PressureSensorYesOrNoStage
          onCancel={onClearInstallationType}
          onValueChange={(nextValue) => {
            setStage(nextValue ? Stages.pressureSensorModel : Stages.flowSensor)
          }}
        />
      )
    }
    case Stages.pressureSensorModel: {
      return (
        <CreateConfiguration.ChoosePressureSensorModelStage {...stageProps} />
      )
    }

    case Stages.pressureSensorThresholds: {
      return <CreateConfiguration.SetPressureThresholdsStage {...stageProps} />
    }
    case Stages.pressureSensorPort: {
      return (
        <CreateConfiguration.ChoosePressureSensorPortStage {...stageProps} />
      )
    }

    case Stages.flowSensor: {
      return (
        <CreateConfiguration.PageWrapper>
          <CreateConfiguration.Container variant="header">
            <StageHeading
              subtitleText={subtitleText}
              titleText={i18n.t("sensors:flow")}
            />
          </CreateConfiguration.Container>
          <CreateConfiguration.ScrollView roundCorners="bottom">
            <DoesItHaveFlowSensor
              onCancel={() =>
                setStage(
                  hasPressure
                    ? Stages.pressureSensorPort
                    : Stages.pressureSensor,
                )
              }
              onValueChange={(hasFlow) =>
                setStage(hasFlow ? Stages.flowMlPerPulse : Stages.deviceName)
              }
            />
          </CreateConfiguration.ScrollView>
        </CreateConfiguration.PageWrapper>
      )
    }

    case Stages.flowMlPerPulse: {
      return (
        <CreateConfiguration.ConfigurationStage
          {...stageProps}
          titleText={i18n.t("sensorFields:mlPerPulse.displayName")}
        >
          <SensorTextInput fieldName="mlPerPulse" sensorName="flow" />
        </CreateConfiguration.ConfigurationStage>
      )
    }
    case Stages.flowSensorPort: {
      return (
        <CreateConfiguration.ConfigurationStage
          titleText={i18n.t("sensorFields:sensorPort.displayName")}
          {...stageProps}
        >
          <ChooseSensorPort sensorName="flow" />
        </CreateConfiguration.ConfigurationStage>
      )
    }
    case Stages.deviceName: {
      return <CreateConfiguration.CustomizeDeviceNameStage {...stageProps} />
    }
    case Stages.reviewAndSubmit: {
      return (
        <CreateConfiguration.ReviewAndSubmitStage
          {...stageProps}
          sensorNames={["flow", hasPressure && "pressure"]}
        />
      )
    }
  }
}
