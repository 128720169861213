import React from "react"
import { Navigate, Outlet, useLocation, useNavigate } from "react-router-dom"

import {
  AppIsLoading,
  DemoActiveBanner,
  ForceUpdateManager,
  getAppViewFromState,
  getIsTermsOfServiceAcceptedFromState,
  Models,
  NewFarmSuccessDialog,
  useRootSelector,
} from "@fhq/app"
import { Box } from "@fhq/app/components"

import { ACCOUNT_ROUTES } from "./Account.Routes"
import * as Dev from "./Dev.Routes"
import { DEVICES_ROUTES } from "./Devices.Routes"
import { Appbar } from "./pages/base/Appbar"
import { ScrollingContent } from "./pages/base/Layout"
import { PageErrorBoundary } from "./pages/base/PageErrorBoundary"
import { Paths } from "./pages/base/paths"
import { usePermissionCheckedAction } from "./pages/base/usePermissionCheckedAction"
import { ChangeFarmsDialog } from "./pages/ChangeFarmsDialog"
import CreateDeviceActionSchedulePage from "./pages/CreateDeviceActionSchedulePage"
import { CreateDeviceConnectionPage } from "./pages/CreateDeviceConnectionPage"
import CreateFieldPage from "./pages/CreateFieldPage"
import { DeviceConnectionsListPage } from "./pages/DeviceConnectionsListPage"
import DeviceSchedulesListPage from "./pages/DeviceSchedulesListPage"
import * as DevTableOfContentsPage from "./pages/DevTableOfContentsPage"
import { PermissionDeniedDialog } from "./pages/PermissionDeniedDialog"
import { SidebarProvider } from "./pages/SidebarLayout"
import SupportLandingPage from "./pages/SupportLandingPage"
import SupportPage from "./pages/SupportPage"
import { Provider } from "./pages/SupportPageContext"

import type { RouteObject } from "react-router-dom"
/*
 * Lazy load pages
 */
const FlowAnalyticsPage = React.lazy(async () => {
  return import("./pages/FlowAnalyticsPage")
})
const CreateFarmPage = React.lazy(async () => {
  return import("./pages/CreateFarmPage")
})
const JoinFarmPage = React.lazy(async () => {
  return import("./pages/JoinFarmWithCodePage")
})
const StatusMapPage = React.lazy(async () => {
  return import("./pages/StatusMapPage")
})
const AcceptTermsOfServicePage = React.lazy(async () => {
  return import("./pages/AcceptTermsOfServicePage")
})
const AppNotificationsPage = React.lazy(async () => {
  return import("./pages/AppNotificationsPage")
})
const FieldIrrigationHistoryPage = React.lazy(async () => {
  return import("./pages/FieldIrrigationHistoryPage")
})
const FieldRosterPage = React.lazy(async () => {
  return import("./pages/FieldRosterPage")
})

function Root(): React.JSX.Element {
  const location = useLocation()
  const appView = useRootSelector(getAppViewFromState)
  const numFarms = useRootSelector(Models.userFarm.selectTotal)
  const isTermsOfServiceAccepted = useRootSelector(
    getIsTermsOfServiceAcceptedFromState,
  )

  if (appView === "loading") {
    return <AppIsLoading />
  }

  if (!isTermsOfServiceAccepted) {
    return <AcceptTermsOfServicePage />
  }

  if (numFarms === 0) {
    return <Navigate state={{ from: location }} to={Paths.noFarms.root} />
  }
  return (
    <React.Fragment>
      <Appbar />
      <Outlet />
    </React.Fragment>
  )
}

/**
 * Routes for support documentation in-app
 */
const SUPPORT_ROUTES = {
  children: [
    { element: <SupportLandingPage />, index: true },
    {
      children: [
        { element: <Navigate to="device-overview" />, index: true },
        {
          element: (
            <SidebarProvider>
              <Provider>
                <SupportPage />
              </Provider>
            </SidebarProvider>
          ),
          path: ":pageId",
        },
      ],
      path: ":productId",
    },
  ],
  element: (
    <Box flex={1} id="support">
      <Outlet />
    </Box>
  ),
  errorElement: <PageErrorBoundary />,
  id: "support",
}

/**
 * Routes for managing farm fields
 */
const FIELD_ROUTES: RouteObject = {
  children: [
    { element: <FieldRosterPage />, index: true },
    { element: <CreateFieldPage />, path: "create" },
    { element: <FieldIrrigationHistoryPage />, path: ":id" },
  ],
  element: (
    <Box flex={1} id="fields">
      <Outlet />
    </Box>
  ),
  errorElement: <PageErrorBoundary />,
  id: "fields",
}

/**
 * Routes for whole-farm analytics
 */
const FARM_ANALYTICS_ROUTES: RouteObject = {
  element: (
    <ScrollingContent id="farm">
      <Box maxW="$5xl" mx="auto" w="$full">
        <FlowAnalyticsPage />
      </Box>
    </ScrollingContent>
  ),
  id: "farm",
}

/**
 * Routes for managing device schedules
 */
const SCHEDULES_ROUTES: RouteObject = {
  children: [
    {
      element: <DeviceSchedulesListPage />,
      id: "schedules",
      index: true,
    },
    {
      element: <CreateDeviceActionSchedulePage />,
      id: "createSchedule",
      path: "create",
    },
  ],
  element: <Outlet />,
}

const CONNECTIONS_ROUTES: RouteObject = {
  children: [
    {
      element: <DeviceConnectionsListPage />,
      id: "connections",
      index: true,
    },
    {
      element: <CreateDeviceConnectionPage />,
      id: "createConnection",
      path: "create",
    },
  ],
  element: <Outlet />,
}
const Routes: RouteObject[] = [
  {
    element: (
      <SidebarProvider>
        <StatusMapPage />
      </SidebarProvider>
    ),
    index: true,
  },
  { ...CONNECTIONS_ROUTES, path: "device-connections" },
  { ...SCHEDULES_ROUTES, path: "schedules" },
  { ...DEVICES_ROUTES, path: "devices" },
  { ...FIELD_ROUTES, path: "fields" },
  { ...ACCOUNT_ROUTES, id: "account", path: "account" },
  { ...FARM_ANALYTICS_ROUTES, path: "farm" },
  { ...SUPPORT_ROUTES, path: "support" },
  { element: <AppNotificationsPage />, path: "/app-notifications" },
  { element: <CreateFarmPage />, path: "/create-farm" },
  { element: <JoinFarmPage />, path: "/join-farm" },
]
if (__DEV__) {
  Routes.push({
    children: [
      {
        element: <DevTableOfContentsPage.DevTableOfContentsPage />,
        index: true,
      },
      ...Dev.DEV_ROUTES,
    ],
    path: "/dev",
  })
}

const DASHBOARD_STYLE: React.CSSProperties = {
  display: "flex",
  flex: 1,
  flexDirection: "column",
  overflow: "hidden",
}

function NewFarmSuccess() {
  const navigate = useNavigate()
  const { withPermissions } = usePermissionCheckedAction()
  return (
    <NewFarmSuccessDialog
      withPermissions={withPermissions}
      onPressAddDevice={() => navigate("/devices/add")}
      onPressCreateField={() => navigate("/fields/create")}
      onPressSupport={() => navigate("/support")}
    />
  )
}

export const DashboardRoutes: RouteObject = {
  children: Routes,
  element: (
    <div data-testid="dashboard" id="dashboard" style={DASHBOARD_STYLE}>
      <NewFarmSuccess />
      <PermissionDeniedDialog />
      <ChangeFarmsDialog />
      <ForceUpdateManager />
      <Root />
      <DemoActiveBanner />
    </div>
  ),
}
