import { useNavigate } from "react-router"

import { DeviceConnectionsList, i18n } from "@fhq/app"
import { Background, Button, TitleWithIcon } from "@fhq/app/components"

import { Help } from "./base/Help"
import { usePermissionCheckedAction } from "./base/usePermissionCheckedAction"

const STICKY_HEADER = [0]

/**
 * Screen to display all connections between devices
 */
export function DeviceConnectionsListPage() {
  const navigate = useNavigate()
  const { withPermissions } = usePermissionCheckedAction()

  return (
    <DeviceConnectionsList.DeviceConnectionsList
      stickyHeaderIndices={STICKY_HEADER}
      variant="page"
      withPermissions={withPermissions}
      ListHeaderComponent={
        <Background pb="$2" px="$4">
          <TitleWithIcon
            IconComponent="DeviceConnection"
            helpElement={<Help {...DeviceConnectionsList.HELP_CONTENT} />}
            titleText={i18n.t("deviceConnections:connectionsListTitle")}
            rightElement={
              <Button
                IconComponent="Add"
                text={i18n.t("deviceConnections:connectDevices")}
                onPress={() => navigate("create")}
              />
            }
          />
        </Background>
      }
      onPressCreateConnection={() => navigate("create")}
      onPressDevice={(device) => navigate(`/devices/${device.codaDeviceAlias}`)}
    />
  )
}
