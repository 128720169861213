import type { Dictionary, EntityId } from "@reduxjs/toolkit"

import { createSelector } from "reselect"

import { createEntityAdapter } from "@reduxjs/toolkit"
import * as turf from "@turf/turf"

import * as Geo from "./geo"
import { logger } from "./logger"
import i18n from "./translations/i18n"
import { isTruthyString, isValidNumber, notNullish } from "./type-guards"

import type { PivotDirection } from "./constants"
import type {
  DeviceFunctionName,
  RelayAction,
  RelayActionType,
} from "./device-commands.reducer"
import type { DeviceCommentStatus } from "./farmhq-api"
import type { FieldType } from "./fields.reducer"
import type { FarmAccount } from "./geocoding"
import type { DevicePermissions, FarmUserPermissions } from "./permissions"
import type { ReelRunErrorCode } from "./reel-runs.reducer"
import type { FetchStatus } from "./Requests"
import type { SensorEventKey, SensorEvents, SensorState } from "./sensor-events"
import type { SupportedAppLanguageCode } from "./translations/i18n"

import type { DeviceConfiguration } from "./device-configurations.reducer"
import type { ChartData } from "./GraphComponents"

import type { AppIcons } from "./components"
import type { RootState } from "./root.reducer"
import type { AnyRequestName } from "./send-request"
import type {
  ConfigSensorName,
  HardwareGeneration,
  InstallationType,
  ParticlePlatform,
  ParticleProductId,
  SensorName,
  SprinklerType,
} from "./sensor-configurations"
import type { SortDirectionKey } from "./Sorting"
/**
 * Sort comparator for entities with added type safety
 */
export type SortComparator<T> = (
  a: Partial<T> | undefined,
  b: Partial<T> | undefined,
) => number

/**
 * Sort entities in the order of sort comparators provided
 */
export function makePrioritySort<T extends object>(
  direction: SortDirectionKey,
  ...comparators: Array<SortComparator<T>>
): SortComparator<T> {
  return (a, b) => {
    let result = 0

    let left = a
    let right = b

    if (direction === "desc") {
      left = b
      right = a
    }
    // Apply the comparators in the order provided
    for (const comparator of comparators) {
      if (result === 0) {
        result = comparator(left, right)
      }
    }
    return result
  }
}

export interface TrackedRequest<Id extends AnyRequestName = AnyRequestName> {
  id: Id
  fetchStatus?: FetchStatus
  numberOfTries?: number
}

export interface ModelState<T, I extends EntityId = number> {
  entities: Dictionary<T>
  ids: I[]
}
export function getInitialEntityState<T, I extends EntityId>(): ModelState<
  T,
  I
> {
  return { entities: {}, ids: [] }
}

function createModel<T extends object, I extends EntityId = number>({
  getState,
  options,
  selectId,
}: {
  getState: (state: RootState) => ModelState<T, I>
  selectId: (model: T) => I
  options?: {
    enhancer?: (instance: T) => T
    sortComparer?: (
      a: Partial<T> | undefined,
      b: Partial<T> | undefined,
    ) => number
  }
}) {
  const adapter = createEntityAdapter<T>({
    selectId,
    sortComparer: options?.sortComparer,
  })
  const enhancer = options?.enhancer
  const selectors = adapter.getSelectors(getState)

  return {
    adapter,
    ...selectors,
    enhancer,
    selectAll: enhancer
      ? createSelector([selectors.selectAll], (values) => {
          return values.map((value) => enhancer(value))
        })
      : selectors.selectAll,
    selectById: (state: RootState, id: I | undefined) => {
      if (typeof id === "undefined") {
        return undefined
      }
      return selectors.selectById(state, id)
    },

    selectIds: selectors.selectIds as (state: RootState) => I[],
  }
}

export interface UserAccountData {
  isAdmin: boolean | null
  preferredLanguageCodeOverride: SupportedAppLanguageCode | null
  termsOfServiceAccepted: boolean | null
  nameFamily?: string | null
  nameGiven?: string | null
}

export interface NotificationEntity {
  content: string
  id: number
  publishAtTimestamp: string
  readStatus: "not_seen" | "seen" | null
  title: string
  imageDescription?: string | null
  imageUri?: string | null
}

/**
 * User farm accounts
 */
export const userFarm = createModel<FarmAccount>({
  getState: (state) => state.userFarms,
  options: {
    sortComparer: (a, b) => {
      const nameA = a?.name ?? ""
      const nameB = b?.name ?? ""
      return nameA.localeCompare(nameB)
    },
  },
  selectId: (farm) => farm.id,
})

// export interface AppFeatureEntity {
//   featureName: AppFeedbackKey
//   feedbackText?: string | null
//   isLiked?: boolean
// }

// /**
//  * App features with feedback ratings
//  */
// export const appFeature = createModel<AppFeatureEntity, AppFeedbackKey>({
//   getState: (state) => state.appFeedback,
//   selectId: (entity) => entity.featureName,
// })

/**
 * Device Configurations
 */
export const deviceConfiguration = createModel<DeviceConfiguration, string>({
  getState: (state) => state.deviceConfigurations,
  options: {
    sortComparer: (a, b) => {
      const nameA = a?.deviceName ?? a?.codaDeviceAlias ?? ""
      const nameB = b?.deviceName ?? b?.codaDeviceAlias ?? ""
      return nameA.localeCompare(nameB)
    },
  },
  selectId: (dc) => dc.deviceId,
})
export type FarmJoinCodeStatus = "ACTIVE" | "EXPIRED" | "NOT_CREATED"
export interface FarmJoinCode {
  codeStatus: FarmJoinCodeStatus
  codeStr: string
  createDate: string
  expirationDate: string
  id: number | null
  permissions: FarmUserPermissions
  createdByUserId?: string
  permissionExpirationDate?: string | null | undefined
}

export interface DeviceFarmAssociation {
  device: string | undefined
  deviceId: string | undefined

  expirationDate: string
  farmId: number
  farmName: string
  id: number
  isPrimaryOwner: boolean
  permissions: DevicePermissions
  createDate?: string
  createdByUserId?: string
  expiredByUserId?: string | null
}

/**
 * Device-farm association
 * TODO: Delete
 */
export const deviceFarmAssociation = createModel<DeviceFarmAssociation, string>(
  {
    getState: (state) => state.deviceFarmAssociations,
    selectId: (dfa) => dfa.device ?? dfa.deviceId ?? "",
  },
)
export interface DeviceShareCode {
  codeStr: string
  deviceId: string
  expirationDate: string
  farmName: string
  id: number
  ownerFarmId: number
  permissionExpirationDate: string
  permissions: DevicePermissions
}

/**
 * Facilitate device sharing
 * TODO: We don't really use these currently
 */
export const deviceShareCode = createModel<DeviceShareCode, string>({
  getState: (state) => state.deviceShareCodes,
  selectId: (code) => code.deviceId,
})
export interface DeviceUserAssociation {
  deviceId: string
  notificationsSuppressed: boolean
  id?: number
  userId?: string
}
/**
 * Connect devices to users
 *
 * Currently, we only use these for toggling notification status for a user,
 * i.e. blocking notifications to a user's phone number
 */
export const deviceUserAssociation = createModel<DeviceUserAssociation, string>(
  {
    getState: (state) => state.deviceUserAssociations,
    selectId: (dua) => dua.deviceId,
  },
)

/**
 * Device Action Schedules are foreign-keyed to crons
 */
export interface CronSchedule {
  id: number
  raw: string
  farmId?: number | null
  timezone?: string | null
}

export interface ScheduledDeviceAction {
  command: string

  /**
   * NOTE: This is seconds
   */
  executeAtTs: number
  id: number
  isExecuted: boolean
  namedDeviceActionId: number | null
  deviceActionScheduleId?: number | null
  deviceConfigurationId?: number
  executedInDeviceEvent?: number | null
  isActive?: boolean | null
  isScheduled?: boolean
}

export type ObservableDeviceState = "dry" | "unknown" | "wet"

export interface NamedDeviceAction {
  actionType: RelayActionType | null
  arguments: {
    seconds?: number | null
  } | null
  device: string | null
  displayName: string | null
  expectedOutcome: ObservableDeviceState | null
  hardwareGeneration: HardwareGeneration | null
  id: number
  installationType: InstallationType | null
  name: string | null
  sensorId: number | null
  sensorName: SensorName | null
  sensorType: string
  iconKey?: keyof typeof AppIcons
}
export enum DeviceReturnCode {
  SUCCESS = 0,
  FAILURE = 1,
  REFUSAL = 2,
  ERROR = 3,
}

export interface DeviceFunctionCall {
  argumentString: string | null | undefined
  deviceId: string | null | undefined
  deviceReturnCode: DeviceReturnCode | null
  email: string | null | undefined
  functionName: DeviceFunctionName | null | undefined
  httpStatusCode: number | null | undefined
  id: number
  initiatedByTriggerId: number | null | undefined
  initiatedByUser: string | null | undefined
  namedDeviceAction: NamedDeviceAction | null | undefined
  relayAction: RelayAction | null | undefined
  requestSentTimestamp: string | null | undefined
  responseReceivedTimestamp: string | null | undefined
}

// export const scheduledDeviceAction = createModel<ScheduledDeviceAction>({
//   getState: (state) => state.scheduledDeviceActions,
//   selectId: (model) => model.id
// })
export type ScheduleSyncStatus = "FAILED" | "IN_PROGRESS" | "SUCCESS"
export interface DeviceActionSchedule {
  createDate: string | null
  cronSchedule: CronSchedule | null | undefined
  deactivateDate: string | null
  deviceId: string
  executeAtTs: string | null | undefined
  id: number
  isActive: boolean | null
  isSynced: boolean | null
  namedDeviceAction: NamedDeviceAction | null
  namedDeviceActionId: number
  scheduleEnd: string | null | undefined
  scheduleStart: string | null | undefined
  createdByUserId?: string | null
  isEnabled?: boolean | null
  syncStatus?: ScheduleSyncStatus | null
}

export const deviceActionSchedules = createModel<DeviceActionSchedule>({
  getState: (state) => state.deviceActionSchedules,
  selectId: (model) => model.id,
})
export interface DeviceEvent extends SensorEvents {
  deviceEventTimestamp: string
  deviceId: string
  devicePlatform: ParticlePlatform | null
  deviceProductId: ParticleProductId | null
  firmwareVersion: number
  id: number
  particleEventTimestamp: string
  scheduledDeviceActions: ScheduledDeviceAction[]
}

/**
 * Most recent event for each device, if any
 */
export const deviceEventLast = createModel<DeviceEvent, string>({
  getState: (state) => state.deviceEventLast,
  options: {
    sortComparer: (a, b) => {
      return (a?.deviceEventTimestamp ?? "").localeCompare(
        b?.deviceEventTimestamp ?? "",
      )
    },
  },
  selectId: (event) => event.deviceId,
})
// export interface InspectorEvent extends DeviceEvent {
//   index: number
// }
export interface DeviceTimeseriesData<
  S extends SensorName,
  K extends SensorEventKey<S>,
> extends ChartData {
  key: K
  sensorName: S
}

export type DeviceTimeSeriesUnion =
  | DeviceTimeseriesData<"battery", "internalSoc">
  | DeviceTimeseriesData<"battery", "voltageMv">
  | DeviceTimeseriesData<"flow", "rateLpmAvg">
  | DeviceTimeseriesData<"flow", "volumeLTotal">
  | DeviceTimeseriesData<"pressure", "readingKpa">

/**
 * Date for device profile timeseries key
 */
export const deviceTimeseries = createModel<
  DeviceTimeSeriesUnion,
  DeviceTimeSeriesUnion["key"]
>({
  getState: (state) => state.deviceProfile.timeseries,
  selectId: (model) => model.key,
})

export interface OtherFarmUser {
  associationId: number
  email: string
  isAdmin: boolean
  isFake: boolean
  userId: string
}

export interface FieldSvgData {
  points: string | undefined
  viewbox: string | undefined
}
export interface FieldDatabaseValue {
  center: Geo.PointGeoJson | undefined
  farmId: number
  fieldName: string
  fieldType: FieldType | null | undefined
  id: number
  isActive: boolean
  polygon: Geo.PolygonGeoJson
  rowDirectionAzimuthDegrees: number | null
  createDate?: string | null
  createdByUserId?: string | null
  fillColor?: string
  labelRotationDegrees?: number | null
}
export interface FarmField extends FieldDatabaseValue {
  areaAcres: number | undefined
  areaText: string | undefined
  centerLatLng: Geo.MultiPoint | null | undefined
  fieldId: number
  fieldType: FieldType
  labelRotationDegrees: number
  path: Geo.MultiPath | undefined
  rowDirectionAzimuthDegrees: number
  svgInfo: FieldSvgData
}

export function calculateFieldSvgPath(
  f: { polygon: Geo.PolygonGeoJson } | undefined,
): FieldSvgData {
  let points: string | undefined
  let viewbox: string | undefined
  const polygon = Geo.polygon(f?.polygon.coordinates[0])
  if (notNullish(polygon)) {
    const boundingBox = polygon.addBuffer(200)?.getBoundingBox()
    if (boundingBox) {
      const boundingBoxHeight = boundingBox.north - boundingBox.south
      const boundingBoxWidth = boundingBox.east - boundingBox.west
      viewbox = `0 0 ${boundingBoxWidth} ${boundingBoxHeight}`
      points = ""
      for (const [lng, lat] of polygon.getOuterRing()) {
        if (isValidNumber(lng) && isValidNumber(lat)) {
          const distanceFromNorth = boundingBox.north - lat
          const distanceFromWest = lng - boundingBox.west
          points = `${points} ${distanceFromWest}, ${distanceFromNorth}`
        }
      }
    }
  }
  return { points, viewbox }
}
export function calculateFieldArea(polygon: Geo.PolygonGeoJson) {
  try {
    const cleaned = Geo.polygon(polygon)?.toJson().coordinates
    if (cleaned) {
      const areaMetersSquare = turf.area(turf.polygon(cleaned))
      return Geo.round(turf.convertArea(areaMetersSquare, "meters", "acres"), 1)
    }
  } catch (error) {
    logger.error(error)
  }
  return undefined
}

/**
 * Cleans the field and adds extra properties
 */
export function prepareField(fieldData: FieldDatabaseValue): FarmField {
  const {
    center,
    fieldType,
    labelRotationDegrees,
    polygon,
    rowDirectionAzimuthDegrees,
  } = fieldData

  const polygonWrapper = Geo.polygon(polygon)
  const centerPoint = center ?? polygonWrapper?.getCenter()
  const [centerLng, centerLat] = centerPoint?.coordinates ?? []
  const areaAcres = calculateFieldArea(polygon)

  const svgInfo = calculateFieldSvgPath({ polygon })

  // calculate native and web centers
  let centerLatLng: FarmField["centerLatLng"] | undefined
  if (isValidNumber(centerLng) && isValidNumber(centerLat)) {
    centerLatLng = {
      native: { latitude: centerLat, longitude: centerLng },
      web: { lat: centerLat, lng: centerLng },
    }
  }

  // calculate native and web paths
  let path: FarmField["path"] | undefined
  const pathGoogle = polygonWrapper?.toGmaps()
  const pathNative = polygonWrapper?.toNative()

  if (pathGoogle && pathNative) {
    path = {
      native: pathNative,
      web: pathGoogle,
    }
  }
  // Try to close the polygon if necessary and if possible
  const polygonCleaned = polygonWrapper?.toJson() ?? fieldData.polygon
  return {
    ...fieldData,
    areaAcres,
    areaText: isValidNumber(areaAcres)
      ? `${areaAcres} ${i18n.t("acres", { ns: "common" })}`
      : undefined,
    center: Geo.point(centerPoint?.coordinates)?.toJson(),
    centerLatLng,
    fieldId: fieldData.id,
    fieldType: fieldType ?? "polygon",
    labelRotationDegrees: labelRotationDegrees ?? 0,
    path,
    polygon: polygonCleaned,
    rowDirectionAzimuthDegrees: rowDirectionAzimuthDegrees ?? 0,
    svgInfo,
  }
}

/**
 * Field polygons for farm
 */
export const field = createModel<FarmField>({
  getState: (state) => state.fields,
  options: {
    sortComparer: (a, b) => {
      const nameA = a?.fieldName ?? ""
      const nameB = b?.fieldName ?? ""
      return nameA.localeCompare(nameB)
    },
  },
  selectId: (model) => model.id,
})
export interface EventActionTriggerIds {
  sourceDeviceId: string
  targetDeviceId: string | null
}

export const namedDeviceAction = createModel<NamedDeviceAction>({
  getState: (state) => state.namedDeviceActions,
  selectId: (model) => model.id,
})

export type EventActionTriggerProperties<
  SourceSensor extends SensorName = SensorName,
> = EventActionTriggerIds & {
  namedDeviceAction: NamedDeviceAction | null
  notify: boolean | null
  sourceSensor: SourceSensor
  sourceSensorStateCurrent: SensorState<SourceSensor>
  sourceSensorStatePrevious: SensorState<SourceSensor>
  notificationString?: string | null
}

export type EventActionTrigger<S extends ConfigSensorName = ConfigSensorName> =
  EventActionTriggerProperties<S> & {
    id: number
    createDate?: string
    expirationDate?: string
    expiredByUserId?: string
  }
export type DevicePair = EventActionTriggerProperties<"reel" | "wheel">

/**
 * Event action triggers, including connections and notifications
 */
export const trigger = createModel<EventActionTrigger>({
  getState: (state) => state.triggers,
  selectId: (t) => t.id,
})

export interface PhoneNumberDigits {
  areaCode: string
  countryCode: string
  prefix: string
  suffix: string
}

export interface PhoneNumber {
  codeExpirationDate: string | null
  codeString: string | null
  digits: PhoneNumberDigits | { [K in keyof PhoneNumberDigits]: null }
  e164: string
  id: number
  isVerified: boolean
  notificationsEnabled: boolean
  serialized: string
  userId: string
}

/**
 * Registered phone number for user
 */
export const phoneNumber = createModel<PhoneNumber>({
  getState: (state) => state.userPhoneNumbers,
  selectId: (number) => number.id,
})
interface ReelRunBase {
  deviceConfigurationId: number | null
  deviceEventTimestamps: Array<string | null>
  deviceId: string | null
  directionOverrideAzimuthDegrees: number | null
  // distanceMmMax: number | null
  distanceReportsMm: Array<number | null>
  endPoint: Geo.PointGeoJson | null
  endTimestamp: string | null
  inProgress: boolean
  lastEventTimestamp: string | null
  reelRunId: number
  speedReportsMmpm: Array<number | null>
  startTimestamp: string
  pressureReportsKpa?: Array<number | null>
}
export interface ReelRun extends ReelRunBase {
  applicationRateReportsMm: Array<number | null> | null | undefined
  cartLocationPoint: number | null
  completionPct: number | null
  deviceConfigurationId: number | null
  distanceMmCurrent: number | null | undefined
  distanceMmMax: number
  duration: number | null
  errorCode: ReelRunErrorCode | null
  extendHeadingDegrees: number | null
  farmId: number
  fieldId: number | null
  reelNNozzles: number | null
  reelNozzleDiameterMm: number | null
  reelSprinklerType: SprinklerType | null
  reelSwathWidthMm: number | null
  startPoint: Geo.PointGeoJson | null
  flowRateReportsLpm?: Array<number | null> | null | undefined
}

/**
 * Put reel runs in order
 * @param a {ReelRun}
 * @param b {ReelRun}
 * @returns number
 */
function sortReelRuns(
  a: Partial<ReelRun> | undefined,
  b: Partial<ReelRun> | undefined,
): number {
  const timestampA = a?.lastEventTimestamp
  const timestampB = b?.lastEventTimestamp
  if (isTruthyString(timestampA) && isTruthyString(timestampB)) {
    return timestampB.localeCompare(timestampA)
  }
  return 0
}

/**
 * Reel runs that are in progress
 */
export const reelRunsActive = createModel<ReelRun>({
  getState: (state) => state.reelRunsActive,
  options: { sortComparer: sortReelRuns },
  selectId: (run) => run.reelRunId,
})

// /**
//  *
//  */
// export const deviceProfileReelRuns = createModel<ReelRun>({
//   getState: (state) => state.deviceProfile.reelRuns,
//   options: { sortComparer: sortReelRuns },
//   selectId: (run) => run.reelRunId
// })

/**
 * Reel runs for selected field
 */
export const fieldProfileReelRuns = createModel<ReelRun>({
  getState: (state) => state.fieldIrrigationHistory,
  options: { sortComparer: sortReelRuns },
  selectId: (run) => run.reelRunId,
})

/**
 * Global app notifications
 */
export const appNotifications = createModel<NotificationEntity>({
  getState: (state) => state.notifications,
  options: {
    sortComparer: (a, b) => {
      const timestampA = a?.publishAtTimestamp
      const timestampB = b?.publishAtTimestamp
      if (typeof timestampA === "string" && typeof timestampB === "string") {
        const dateA = new Date(timestampA).getTime()
        const dataB = new Date(timestampB).getTime()
        return dataB - dateA
      }

      return 0
    },
  },
  selectId: (entity) => entity.id,
})

/**
 * Track requests to backend api
 */
export const requests = createModel<TrackedRequest, AnyRequestName>({
  getState: (state) => state.requests,
  selectId: (request) => request.id,
})

export interface DeviceCommandTracker {
  deviceId: string
  actionId?: number
  actionType?: RelayActionType
  forceUpdateCount?: number
}

/**
 * Track device command invocations
 */
export const deviceCommandTracker = createModel<DeviceCommandTracker, string>({
  getState: (state) => state.deviceCommands,
  options: {
    sortComparer: (a, b) => {
      const countA = a?.forceUpdateCount ?? 0
      const countB = b?.forceUpdateCount ?? 0
      const idA = a?.deviceId ?? ""
      const idB = b?.deviceId ?? ""
      if (isValidNumber(countA) && isValidNumber(countB)) {
        return countB - countA
      }
      return idA.localeCompare(idB)
    },
  },
  selectId: (a) => a.deviceId,
})
export interface DeviceComment {
  contentText: string | null
  createDate: string
  createdByUser: {
    email: string | null
    nameFamily: string
    nameGiven: string
  }
  device: string
  id: number
  status: DeviceCommentStatus | null
}
export const deviceComment = createModel<DeviceComment, string>({
  getState: (state) => state.deviceComments,
  selectId: (model) => model.device,
})
type ActivityArray<
  T extends number | string | null | undefined =
    | number
    | string
    | null
    | undefined,
> = T[] | null | undefined

export type ObservableIrrigationState = "dry" | "wet"

export interface DeviceActivity {
  createDate: string
  createdAtTimestamps: Array<string | null | undefined> | null | undefined
  /**
   * Note: this is the device id
   */
  device: string
  finishDate: string | null | undefined
  finishReason: string | null | undefined
  flowRateLpmAvgs: ActivityArray
  gpsHeadingsDegrees: ActivityArray
  gpsLocations: Array<Geo.PointGeoJson | null | undefined> | null | undefined
  gpsSpeedMetersPerHour: ActivityArray
  id: number
  isMarkedFinished: boolean | null | undefined
  lastCreatedAtTimestamp: string | null | undefined
  linearDistancesMeters: ActivityArray
  observableStates: Array<ObservableIrrigationState | null | undefined>
  pivotHeadingsDegrees: ActivityArray<number | null>
  pivotSpeedsHoursPerRevolution: ActivityArray
  pressureReadingKpas: ActivityArray
  reelRunDistanceMmCurrents: ActivityArray
  reelRunDistanceMmMax: number | null | undefined
  trace: string
  travelerDistancesMetersToNextStop: ActivityArray
  travelerNextStopExpectedTimestamps: ActivityArray
  travelerSpeedsMetersPerHour: ActivityArray
  vfdCurrentPercentages: ActivityArray
  wheelSpeedMmpms: ActivityArray
  travelerDirections?: Array<PivotDirection | null>
}
export const deviceActivity = createModel<DeviceActivity, string>({
  getState: (state) => state.deviceActivities,

  /**
   * 'device' property is the device id
   */
  selectId: (activity) => activity.device,
})

export interface LoadDeviceActivityParams {
  activeFarmId: number
}
export interface LoadDeviceActivityResponse {
  comments: DeviceComment[]
  deviceActivities: DeviceActivity[]
  deviceEvents: DeviceEvent[]
  reelRuns: ReelRun[]
}

export interface LoadActiveFarmResponse extends LoadDeviceActivityResponse {
  appNotifications: NotificationEntity[]
  deviceConfigurations: DeviceConfiguration[]
  deviceEvents: DeviceEvent[]
  deviceFarmAssociations: DeviceFarmAssociation[]
  deviceShareCodes: DeviceShareCode[]
  deviceUserAssociations: DeviceUserAssociation[]
  farmJoinCode: FarmJoinCode | null
  fields: FarmField[]
  namedDeviceActions: NamedDeviceAction[]
  reelRuns: ReelRun[]
  triggers: EventActionTrigger[]
  userPermissions: FarmUserPermissions
  errorCode?: "NO FARM ASSOCIATION"
}
// export function buildRelayAction(actionType: RelayActionType): RelayAction {
//   if (actionType === "RLYAS") {
//     return { actionType, seconds: 10 }
//   }
//   return { actionType, seconds: null }
// }

// /**
//  * Convert relay action to human-readable format
//  *
//  * @param relayAction
//  * @param inputValue
//  */
// export function humanizeRelayAction(
//   inputValue: RelayAction | RelayActionType,
// ): string {
//   let relayAction: RelayAction
//   if (typeof inputValue === "string") {
//     relayAction = buildRelayAction(inputValue)
//   } else {
//     relayAction = inputValue
//   }
//   const actionType = relayAction.actionType
//   switch (actionType) {
//     case "RLYAS": {
//       if (isValidNumber(relayAction.seconds)) {
//         return `activate its switch for ${relayAction.seconds} seconds`
//       }
//       return humanizeRelayAction(relayAction)
//     }
//     case "RLYA":
//       return `activate its switch`
//     case "RLYD":
//       return "deactivate its switch"
//     default:
//       return "take action"
//   }
// }
