import { Platform } from "react-native"

import { createAsyncThunk } from "@reduxjs/toolkit"
import { captureException, setTag } from "@sentry/core"

import { denyPermission } from "./actions"
import { trackEvent } from "./Analytics"
import * as AppStorage from "./async-storage"
import { logger, makeFileLogger } from "./logger"
import * as Models from "./models"
import { getIsAdminModeEnabledFromState, isDevicePair } from "./selectors"
import { createHandler, sendRequest } from "./send-request"
import i18n from "./translations/i18n"
import { getActiveFarmIdFromState, isDemoFarmId } from "./user-farms.selectors"

import type { AddDeviceResponse } from "./AddDevice"
import type { AddDeviceInputMode, SeedDatabaseOptions } from "./constants"
import type * as Geo from "./geo"

import type { FarmAccount, FarmAccountProperties } from "./geocoding"
import type {
  DevicePermissions,
  FarmUserPermissionName,
  FarmUserPermissions,
  FarmWithPermissions,
} from "./permissions"
import type { RootThunkExtra } from "./Requests"
import type { RootState, RootThunkConfig } from "./root.reducer"
import type {
  InstallationType,
  MutableSensorName,
  SensorConfig,
  SensorName,
} from "./sensor-configurations"

import type { SupportedAppLanguageCode } from "./translations/i18n"
import type { TriggerFilterKey } from "./triggers.reducer"

import type {
  CodaDeviceAliasProps,
  DeviceIdProps,
  FieldIdProps,
  FormValidationInfo,
  ListResponse,
  MinDateMaxDate,
  ReelRunIdProps,
  Union,
} from "./types"

import type { JoinFarmErrorCode } from "./user-farms.reducer"

import type { DeviceConfiguration } from "./device-configurations.reducer"

import type {
  DeviceEvent,
  DeviceFarmAssociation,
  DeviceFunctionCall,
  DeviceShareCode,
  DeviceTimeSeriesUnion,
  DeviceUserAssociation,
  EventActionTrigger,
  EventActionTriggerProperties,
  FarmField,
  FarmJoinCode,
  LoadActiveFarmResponse,
  LoadDeviceActivityParams,
  LoadDeviceActivityResponse,
  NotificationEntity,
  PhoneNumber,
} from "./models"
import type { NotificationsStatus } from "./user-phone-numbers.reducer"

import type { FlowAnalyticsData, FlowAnalyticsSource } from "./farm.reducer"
import type { FieldType } from "./fields.reducer"
import type {
  BackendRequest,
  GetActionArgumentsType,
  GetResponseDataType,
} from "./send-request"
import type { SensorState } from "./sensor-events"
const fileLogger = makeFileLogger({ fileName: "@app/farmhq-api.ts" })

/**
 *
 */
export const acceptTermsOfServiceAsync = createHandler("AcceptTermsOfService", {
  demoMode: {
    allowRequest: true,
  },
})

/**
 *
 */
export const activateFieldsAsync = createHandler("ActivateFields", {
  requiredPermissions: "canManageFields",
})

/**
 *
 */
export const addDeviceAsync = createHandler("AddDevice", {
  transformArguments: ({ actionArguments }) => {
    return {
      ...actionArguments,
      value: actionArguments.value
        .toLocaleLowerCase()
        .trim()
        .replace(/\s+/g, "-")
        .replace(/_/g, "-"),
    }
  },
})

/**
 *
 */
export const archiveFieldsAsync = createHandler("ArchiveFields", {
  requiredPermissions: "canManageFields",
})

/**
 *
 */
export const changeFarmLocationAsync = createHandler("ChangeFarmLocation")

/**
 *
 */
export const completeSmsVerificationAsync = createHandler(
  "CompleteSmsVerification",
)

/**
 *
 */
export const createDeviceShareCodeAsync = createHandler("CreateDeviceShareCode")

//   requiredPermissions: ["canManageDevicePairs"],
//  *
//  */
// /**
// export const createDevicePairAsync = createHandler("CreateDevicePair", {
// })
/**
 *
 */
export const createCustomAutomationAsync = createHandler(
  "CreateCustomAutomation",
  {
    requiredPermissions: ["canManageCustomTriggers"],
  },
)

/**
 *
 */
export const createCustomNotificationAsync = createHandler(
  "CreateCustomNotification",
  {
    requiredPermissions: ["canManageDeviceNotifications"],
  },
)

/**
 *
 */
export const createFarmAsync = createHandler("CreateFarm")

/**
 *
 */
export const createFarmJoinCodeAsync = createHandler("CreateFarmJoinCode", {
  requiredPermissions: "canManageJoinCodes",
})

/**
 *
 */
export const generateSmsVerificationCodeAsync = createHandler(
  "GenerateSmsVerificationCode",
  {
    demoMode: {
      allowRequest: false,
    },
    transformArguments: ({ actionArguments }) => {
      if (Platform.OS === "web") {
        return {
          ...actionArguments,
          isTest: Boolean(window.Cypress),
        }
      }
      return actionArguments
    },
  },
)

/**
 *
 */
export const createPhoneNumberInternationalAsync = createHandler(
  "CreatePhoneNumberInternational",
  {
    demoMode: {
      allowRequest: false,
    },
  },
)

/**
 *
 */
export const createConfigurationAsync = createHandler("CreateConfiguration", {
  requiredPermissions: "canManageDeviceConfiguration",
})

/**
 *
 */
export const createFieldAsync = createHandler("CreateField", {
  requiredPermissions: "canManageFields",
})

/**
 *
 */
export const createSupportTicketAsync = createAsyncThunk<
  GetResponseDataType<"CreateSupportTicket">,
  GetActionArgumentsType<"CreateSupportTicket">,
  RootThunkConfig
>(
  "CreateSupportTicket",
  async ({ body, email, subject }, { extra, getState }) => {
    const activeFarmId = getActiveFarmIdFromState(getState())
    try {
      // Only actually create tickets in production or beta
      if (
        extra.targetDatabaseName === "PROD" ||
        extra.targetDatabaseName === "BETA" ||
        extra.isEndToEndTest
      ) {
        await sendRequest("CreateSupportTicket", {
          actionArguments: { body, email, subject },
          activeFarmId,
          ...extra,
        })
        return { requestSkipped: false }
      }
      return { requestSkipped: true }
    } catch (error) {
      fileLogger.error(error)
      throw error
    } finally {
      trackEvent(extra.analyticsClient, {
        activeFarmId,
        environmentInfo: extra.environmentInfo,
        name: "create_support_ticket",
        subject,
      })
    }
  },
)

/**
 *
 */
export const customizeNotificationStringAsync = createHandler(
  "CustomizeNotificationString",
  { requiredPermissions: "canManageDeviceNotifications" },
)

/**
 *
 */
export const deleteEventActionTriggersAsync = createHandler(
  "DeleteEventActionTriggers",
  {
    requiredPermissions: (actionArguments, state) => {
      const required: FarmUserPermissionName[] = []
      for (const triggerId of actionArguments.ids) {
        const trigger = Models.trigger.selectById(state, triggerId)
        if (trigger) {
          // if (isDevicePair(trigger)) {
          //   required.push("canManageDevicePairs")
          // } else
          if (trigger.namedDeviceAction !== null) {
            required.push("canManageCustomTriggers")
          } else if (
            (trigger as EventActionTriggerProperties).notify === true
          ) {
            required.push("canManageDeviceNotifications")
          }
        }
      }
      return required
    },
  },
)

/**
 *
 */
export const deleteFieldAsync = createHandler("DeleteField", {
  requiredPermissions: "canManageFields",
})

/**
 *
 */
export const deletePhoneNumberAsync = createHandler("DeletePhoneNumber", {})

/**
 *
 */
export const loadFarmPermissionsForUserAsync = createHandler(
  "GetFarmPermissionsForUser",
  {
    demoMode: {
      allowRequest: true,
    },
  },
)

/**
 *
 */
export const loadFarmsBorrowingDeviceAsync = createHandler(
  "GetFarmsBorrowingDevice",
  {
    demoMode: {
      allowRequest: true,
    },
  },
)

/**
 *
 */
export const loadLastEventForDeviceAsync = createHandler(
  "GetLastEventForDevice",
  {
    demoMode: {
      allowRequest: true,
    },
  },
)

/**
 *
 */
export const invalidateDeviceShareCodeAsync = createHandler(
  "InvalidateDeviceShareCode",
  {
    requiredPermissions: ["canManageDevicePermissions"],
  },
)

/**
 *
 */
export const loadAppNotificationsAsync = createHandler("GetNotifications", {
  demoMode: {
    allowRequest: true,
  },
})

/**
 *
 */
export const loadDeviceActivityAsync = createHandler("LoadDeviceActivity", {
  demoMode: {
    allowRequest: true,
  },
})

/**
 *
 */
export const loadDeviceFunctionCallsAsync = createHandler(
  "LoadDeviceFunctionCalls",
  {
    demoMode: {
      allowRequest: true,
    },
  },
)

export const loadDeviceTimeseriesDataAsync = createHandler(
  "LoadDeviceTimeseriesData",
  {
    demoMode: {
      allowRequest: true,
    },
  },
)

/**
 *
 */
export const loadDeviceFlowHistoryDataAsync = createHandler(
  "LoadDeviceFlowHistoryData",
  {
    demoMode: {
      allowRequest: true,
    },
  },
)

/**
 *
 */
export const loadEventsForDeviceAsync = createHandler("LoadEventsForDevice", {
  demoMode: {
    allowRequest: true,
  },
})

/**
 *
 */
export const loadNotificationsAsync = createHandler("GetNotifications", {
  demoMode: {
    allowRequest: true,
  },
})

/**
 *
 */
export const loadFieldIrrigationHistoryAsync = createHandler(
  "LoadFieldIrrigationHistory",
  {
    demoMode: {
      allowRequest: true,
    },
  },
)

/**
 *
 */
export const removeDeviceFromFarmAsync = createHandler("RemoveDeviceFromFarm", {
  requiredPermissions: "canManageDevicePermissions",
})

/**
 *
 */
export const renameDeviceAsync = createHandler("RenameDevice", {
  requiredPermissions: "canManageDeviceConfiguration",
})

/**
 *
 */
export const renameFieldAsync = createHandler("RenameField", {
  requiredPermissions: "canManageFields",
})

export const replaceDeviceAsync = createHandler("ReplaceDevice", {
  requiredPermissions: "canManageDeviceConfiguration",
})
/**
 *
 */
export const requestUpdateFromDeviceAsync = createHandler(
  "RequestUpdateFromDevice",
)

/**
 * Return true if provided object's device id matches the source or target
 * of the trigger.
 */
export function isDeviceInTrigger({
  device: { deviceId },
  trigger: { sourceDeviceId, targetDeviceId },
}: {
  device: DeviceIdProps
  trigger: Pick<EventActionTrigger, "sourceDeviceId" | "targetDeviceId">
}): boolean {
  const isSource =
    typeof sourceDeviceId === "string" && deviceId === sourceDeviceId
  const isTarget =
    typeof targetDeviceId === "string" && deviceId === targetDeviceId
  return isSource || isTarget
}

/**
 *
 */
export const restoreConfigurationDefaultsAsync = createAsyncThunk(
  "RestoreConfigurationDefaults",
  async ({ deviceId }: DeviceIdProps, { dispatch, extra, getState }) => {
    try {
      const state = getState() as RootState
      const activeFarmId = getActiveFarmIdFromState(state)
      const extraArgs = extra as RootThunkExtra

      const triggers = Models.trigger.selectAll(state).filter((trigger) =>
        isDeviceInTrigger({
          device: { deviceId },
          trigger,
        }),
      )
      const hasTriggers = triggers.length > 0
      const permissions = state.permissions.farmUserPermissions

      // let hasPairs = false
      let hasAutomations = false
      for (const trigger of triggers) {
        if (isDevicePair(trigger)) {
          // hasPairs = true
        } else {
          hasAutomations = true
        }
      }

      // if (hasPairs && !Boolean(permissions?.canManageDevicePairs)) {
      //   dispatch(
      //     denyPermission({
      //       missingPermissions: "canManageDevicePairs",
      //       reason: "missing permissions",
      //     }),
      //   )
      //   return Promise.resolve()
      // }

      if (hasAutomations && !Boolean(permissions?.canManageCustomTriggers)) {
        dispatch(
          denyPermission({
            missingPermissions: "canManageCustomTriggers",
            reason: "missing permissions",
          }),
        )
        return Promise.resolve()
      }
      if (hasTriggers) {
        sendRequest("DeleteEventActionTriggers", {
          ...extraArgs,
          actionArguments: {
            deviceId,
            ids: triggers.map((trigger) => trigger.id),
            kind: "pair",
          },
          activeFarmId,
        }).catch((error) => {
          captureException(error)
        })
      }
      const result = await sendRequest("RestoreConfigurationDefaults", {
        ...extraArgs,
        actionArguments: { deviceId },
        activeFarmId,
      }).catch((error) => {
        captureException(error)
      })

      return {
        deviceId,
        result,
        status: "success",
      }
    } catch (error) {
      logger.error(error)
      throw error
    }
  },
)

/**
 *
 */
export const sendTestSmsAsync = createHandler("SendTestSms")

/**
 *
 */
export const setDeviceLocationPermanentAsync = createHandler(
  "SetDeviceLocationPermanent",
  { requiredPermissions: "canManageDeviceConfiguration" },
)

/**
 *
 */
export const setFieldRowDirectionAsync = createHandler("SetFieldRowDirection", {
  requiredPermissions: "canManageFields",
})

/**
 *
 */
export const setNotificationStatusForPhoneNumberAsync = createHandler(
  "SetNotificationStatusForPhoneNumber",
)

/**
 *
 */
export const setReelDistanceAsync = createHandler("SetReelDistance")

/**
 *
 */
export const setReelPumpPairRelayToggleDurationAsync = createHandler(
  "SetReelPumpPairRelayToggleDuration",
)

/**
 *
 */
export const setReelRunDirectionAsync = createHandler("SetReelRunDirection")

/**
 *
 */
export const submitAppFeatureFeedbackAsync = createHandler(
  "SubmitAppFeatureFeedback",
)

/**
 *
 */
export const toggleNotificationsFromDevicesAsync = createHandler(
  "ToggleNotificationsFromDevices",
)

/**
 *
 */
export const updateFarmUserPermissionsAsync = createHandler(
  "UpdateFarmUserPermissions",
  {
    demoMode: {
      allowRequest: false,
    },
    requiredPermissions: "canManageUserPermission",
  },
)

/**
 *
 */
export const updateFieldBoundaryAsync = createHandler("UpdateFieldBoundary", {
  requiredPermissions: "canManageFields",
})

/**
 *
 */
export const userJoinFarmWithCodeAsync = createAsyncThunk<
  GetResponseDataType<"UserJoinFarmWithCode">,
  GetActionArgumentsType<"UserJoinFarmWithCode">,
  RootThunkConfig
>("UserJoinFarmWithCode", async ({ codeString }, { extra }) => {
  try {
    const response = await sendRequest("UserJoinFarmWithCode", {
      actionArguments: { codeString },
      activeFarmId: undefined,
      ...extra,
    })

    return response
  } catch (error) {
    fileLogger.error(error)
    throw error
  }
})

/**
 *
 */
export const callNamedDeviceActionAsync = createHandler(
  "CallNamedDeviceAction",
  {
    thunkOptions: {
      condition: ({ deviceId }, { getState }) => {
        const prevState = getState().deviceCommands.entities[deviceId]
        if ((prevState?.forceUpdateCount ?? 0) > 0) {
          return false
        }
        return undefined
      },
      dispatchConditionRejection: false,
    },
  },
)

/**
 *
 */
export const setNotificationReadStatusAsync = createHandler(
  "SetNotificationReadStatus",
  {
    demoMode: {
      allowRequest: true,
    },
  },
)

export type SmsVerificationStatusCode = "INVALID" | "SUCCESS"
export const loadOtherFarmUsersAsync = createHandler("LoadOtherFarmUsers", {
  demoMode: {
    allowRequest: true,
  },
})

export const loadActiveUserAsync = createAsyncThunk<
  GetResponseDataType<"LoadActiveUser">,
  GetActionArgumentsType<"LoadActiveUser">,
  RootThunkConfig
>("LoadActiveUser", async (_, { extra }) => {
  const response = await sendRequest("LoadActiveUser", {
    ...extra,
    actionArguments: undefined,
    activeFarmId: undefined,
  })

  const {
    farms,
    userData: { isAdmin, preferredLanguageCodeOverride },
  } = response

  if (!Boolean(isAdmin)) {
    fileLogger.debug("Removing admin mode from storage...")
    AppStorage.removeItem("isAdminModeEnabled").catch((error) =>
      fileLogger.error(error),
    )
  }
  const { nameFamily, nameGiven } = response.userData
  setTag("user_name_family", nameFamily)
  setTag("user_name_given", nameGiven)

  if (typeof preferredLanguageCodeOverride === "string") {
    setTag("preferred_language_code_override", preferredLanguageCodeOverride)
    fileLogger.debug(`Setting language to ${preferredLanguageCodeOverride}`)
    i18n.changeLanguage(preferredLanguageCodeOverride).catch((error) =>
      captureException(error, {
        tags: {
          description: "attempting to override device locale",
          requested_action: "LoadActiveUser",
        },
      }),
    )
  }
  if (farms.length === 0) {
    fileLogger.warn("Clearing stored farm ids...")
    AppStorage.clearStoredFarmIds().catch((error) => fileLogger.error(error))
  }
  return response
})

/**
 *
 */
export const prepareDemoEnvironmentAsync = createAsyncThunk<
  { activeFarmId: number },
  void,
  RootThunkConfig
>("PrepareDemoEnvironment", async (_, { dispatch, extra }) => {
  try {
    const response = await sendRequest("PrepareDemoEnvironment", {
      actionArguments: undefined,
      ...extra,
      activeFarmId: undefined,
    })

    const demoFarmId = response.activeFarmId
    if (!isDemoFarmId(demoFarmId)) {
      throw new TypeError(
        `Farm ID ${demoFarmId} is not a demo farm id: ${demoFarmId}`,
      )
    }
    // This causes demo mode to persist
    await AppStorage.setItem({
      key: "demoFarmId",
      value: demoFarmId,
    })
    // This ensures that the user has an association to the demo farm
    // when the request is finished
    await dispatch(loadActiveUserAsync())
    return {
      activeFarmId: demoFarmId,
    }
  } catch (error) {
    fileLogger.error(error)
    throw error
  }
})
/**
 *
 */
export const loadDeviceActionsAsync = createHandler("LoadDeviceActions", {
  demoMode: {
    allowRequest: true,
  },
})

export const loadFlowMeteringAnalyticsAsync = createHandler(
  "LoadFlowMeteringAnalytics",
  {
    demoMode: {
      allowRequest: true,
    },
  },
)

export const loadFlowMeteringReportAsync = createHandler(
  "LoadFlowMeteringReport",
  {
    demoMode: {
      allowRequest: true,
    },
  },
)

export const createScheduledDeviceActionAsync = createHandler(
  "CreateScheduledDeviceAction",
  {
    requiredPermissions: "canManageSchedules",
  },
)

export const createDeviceActionScheduleAsync = createHandler(
  "CreateDeviceActionSchedule",
  {
    requiredPermissions: "canManageSchedules",
  },
)
export const createPumpOnForDurationScheduleAsync = createHandler(
  "CreatePumpOnForDurationSchedule",
  {
    requiredPermissions: "canManageSchedules",
  },
)
export const deleteScheduledDeviceActionAsync = createHandler(
  "DeleteDeviceActionSchedule",
  {
    requiredPermissions: "canManageSchedules",
  },
)
export const updateScheduledDeviceActionAsync = createHandler(
  "UpdateScheduledDeviceAction",
  {
    requiredPermissions: "canManageSchedules",
  },
)

export const loadDeviceSchedulesAsync = createHandler(
  "LoadDeviceSchedulesForFarm",
)
export const updateUserAccountAsync = createHandler("UpdateUserAccount")
export const updateDeviceConnectionAsync = createHandler(
  "UpdateDeviceConnection",
)
export const createDeviceCommentAsync = createHandler("CreateDeviceComment")
export const clearCommentsForDeviceAsync = createHandler(
  "ClearCommentsForDevice",
)
export const getAllDevicesByFarmAsync = createHandler("GetAllDevicesByFarm", {
  thunkOptions: {
    condition: (_, { getState }) => {
      return getIsAdminModeEnabledFromState(getState())
    },
  },
})

export const loadMultifarmAnalyticsAsync = createHandler(
  "LoadMultifarmAnalytics",
  {
    demoMode: {
      allowRequest: true,
    },
  },
)
interface MinDateMaxDateBackend {
  maxDateMs: number
  minDateMs: number
}

interface WithCronString {
  cronScheduleRaw: string
  scheduleEndMs: number
  scheduleStartMs: number
  executionTimeMs?: undefined
}

interface WithExecutionTime {
  executionTimeMs: number
  cronScheduleRaw?: undefined
  scheduleEndMs?: undefined
  scheduleStartMs?: undefined
}

export type DeviceCommentStatus = "active" | "suppressed"
export type MultifarmAnalyticsArgs = MinDateMaxDate & {
  _todoDeleteMe?: never
}
export interface MultifarmAnalyticsResponse {
  _todoDeleteMe?: never
}

export interface RequestNameToHandler {
  AcceptTermsOfService: BackendRequest
  ActivateFields: BackendRequest<{
    ids: number[]
  }>
  AddDevice: BackendRequest<
    {
      inputMode: AddDeviceInputMode
      value: string
    },
    AddDeviceResponse
  >
  ArchiveFields: BackendRequest<{
    ids: number[]
  }>
  CallNamedDeviceAction: BackendRequest<
    DeviceIdProps & {
      actionId: number
      arguments:
        | {
            pidDerivativeCoeff: number | undefined
            pidGainCoeff: number | undefined
            pidIntegralCoeff: number | undefined
            pidSensorId: 20
            pidSensorSetpoint: number
            pidSensorType: "P"
          }
        | {
            targetRampOverSeconds: number
            targetRampPercentage: number
            pidSensorType?: undefined
          }
        | null
    },
    {
      result?: DeviceFunctionCall | null | undefined
    }
  >
  ChangeFarmLocation: BackendRequest<{
    activeFarmId: number
    locationGeojson: Geo.PointGeoJson
  }>
  ClearCommentsForDevice: BackendRequest<{ deviceId: string }>
  CompleteSmsVerification: BackendRequest<
    {
      codeString: string
      upnId: number
    },
    {
      status: SmsVerificationStatusCode
    }
  >
  CreateConfiguration: BackendRequest<
    DeviceIdProps & {
      [key in MutableSensorName]: SensorConfig<key> | null | undefined
    } & {
      deviceInstallationType: InstallationType
      deviceName: string
      linearPathStopsCoordinates: Geo.Coordinates[] | null | undefined
      linearPathStopsLabels: string[] | null | undefined
      linearSpanHeadingDegrees: number | null | undefined
      linearSpanWidthMm: number | null | undefined
      pivotCenterGpsLocation?: Geo.PointGeoJson | null
      pivotPathStopsCoordinates?: Geo.Coordinates[] | null
      pivotPathStopsHeadingsDegrees?: number[] | null
      pivotPathStopsLabels?: string[] | null
      pivotRadiusMeters?: number | null
    },
    DeviceConfiguration
  >
  CreateCustomAutomation: BackendRequest<
    {
      namedDeviceActionId: number
      notify: boolean
      sourceDeviceId: string
      sourceSensor: SensorName
      sourceSensorStateCurrent: SensorState
      sourceSensorStatePrevious: SensorState
      targetDeviceId: string | null
    },
    EventActionTrigger
  >
  CreateCustomNotification: BackendRequest<
    {
      sourceDeviceId: string
      sourceSensor: SensorName
      sourceSensorStateCurrent: SensorState
      sourceSensorStatePrevious: SensorState
    },
    EventActionTrigger
  >
  CreateDeviceActionSchedule: BackendRequest<
    Union<WithCronString, WithExecutionTime> & {
      deviceId: string
      executionTimezone: string
      isEnabled: boolean
      namedDeviceActionId: number
      shouldSyncSchedule: boolean
    },
    FormValidationInfo<Models.DeviceActionSchedule>
  >
  CreateDeviceComment: BackendRequest<
    DeviceIdProps & { contentText: string },
    Models.DeviceComment
  >
  CreateDeviceShareCode: BackendRequest<
    DeviceIdProps & {
      permissions: DevicePermissions
    },
    DeviceShareCode
  >
  CreateFarm: BackendRequest<
    FarmAccountProperties & {
      gpsLocation: Geo.PointGeoJson | null
    },
    { farm: FarmWithPermissions }
  >
  CreateFarmJoinCode: BackendRequest<
    { permissions: FarmUserPermissions },
    FarmJoinCode
  >
  CreateField: BackendRequest<
    {
      fieldName: string
      fieldType: FieldType
      polygon: Geo.PolygonGeoJson
      rowDirectionAzimuthDegrees: number
    },
    Models.FieldDatabaseValue
  >

  CreatePhoneNumberInternational: BackendRequest<
    { phoneNumberE164Str: string },
    PhoneNumber
  >

  CreatePumpOnForDurationSchedule: BackendRequest<
    Union<WithCronString, WithExecutionTime> & {
      deviceId: string
      displayName: string
      durationMs: number
      executionTimezone: string | null | undefined
    },
    FormValidationInfo<Models.DeviceActionSchedule>
  >
  CreateScheduledDeviceAction: BackendRequest<
    {
      deviceId: string
      executeAtTsMilliseconds: number
      isScheduled: boolean
      namedDeviceActionId: number
      shouldSyncSchedule: boolean
    },
    Models.ScheduledDeviceAction
  >
  CreateSupportTicket: BackendRequest<
    {
      body: string
      email: string
      subject: string
    },
    {
      request?: unknown
      requestSkipped?: boolean | null
      ticket?: unknown
    }
  >
  CustomizeNotificationString: BackendRequest<{
    notificationString: string
    triggerId: number
  }>
  DeleteDeviceActionSchedule: BackendRequest<{
    deviceActionScheduleId: number
    shouldSyncSchedule: boolean
  }>
  DeleteEventActionTriggers: BackendRequest<
    DeviceIdProps & {
      ids: number[]
      kind: TriggerFilterKey
    }
  >
  DeleteField: BackendRequest<FieldIdProps>
  DeletePhoneNumber: BackendRequest<{
    upnId: number
  }>
  GenerateSmsVerificationCode: BackendRequest<
    {
      upnId: number
    },
    {
      body: string
      to_: string
    }
  >
  GetAllDevicesByFarm: BackendRequest<
    {
      maxDateMs: number
      minDateMs: number
    },
    {
      [farmId: number]: {
        events: Array<
          Pick<
            DeviceConfiguration,
            | "deviceId"
            | "deviceInstallationType"
            | "deviceName"
            | "firmwareVersion"
            | "hardwareGeneration"
          > & {
            deviceLocation: Geo.PointGeoJson | null | undefined
          }
        >
        farmId: number
        farmLocation: Geo.PointGeoJson | null
        farmName: string
      }
    }
  >
  GetFarmPermissionsForUser: BackendRequest<
    {
      targetUserId: string
    },
    FarmUserPermissions
  >
  GetFarmsBorrowingDevice: BackendRequest<
    DeviceIdProps,
    ListResponse<DeviceFarmAssociation>
  >
  GetLastEventForDevice: BackendRequest<
    DeviceIdProps & { previousEventId: number | null },
    DeviceIdProps &
      Union<
        CodaDeviceAliasProps & {
          event: DeviceEvent
          status: "SUCCESS"
          deviceName?: string
        },
        {
          status: "NO EVENTS" | "NOT NEW"
          event?: undefined
        }
      >
  >

  GetNotifications: BackendRequest<
    {
      activeFarmId: number | null
    },
    ListResponse<NotificationEntity>
  >
  InvalidateDeviceShareCode: BackendRequest<
    DeviceIdProps & {
      codeId: number
    },
    DeviceShareCode
  >
  LoadActiveUser: BackendRequest<
    void,
    {
      appFeatureFeedback: Array<{
        featureName: string | null | undefined
        isLiked?: boolean | null
      }>
      farms: FarmAccount[]
      phoneNumbers: Models.PhoneNumber[]
      userData: Models.UserAccountData
    }
  >
  LoadApp: BackendRequest<LoadDeviceActivityParams, LoadActiveFarmResponse>
  LoadDeviceActions: BackendRequest<
    DeviceIdProps,
    ListResponse<Models.NamedDeviceAction>
  >
  LoadDeviceActivity: BackendRequest<
    LoadDeviceActivityParams,
    LoadDeviceActivityResponse
  >
  LoadDeviceFlowHistoryData: BackendRequest<
    DeviceIdProps & MinDateMaxDateBackend,
    ListResponse<DeviceTimeSeriesUnion>
  >
  LoadDeviceFunctionCalls: BackendRequest<
    DeviceIdProps,
    ListResponse<DeviceFunctionCall>
  >
  LoadDeviceSchedulesForFarm: BackendRequest<
    {
      dateMsMax?: number | null
      dateMsMin?: number | null
      deviceIds?: string[] | null
    },
    {
      activeDeviceActionSchedules: Models.DeviceActionSchedule[]
      pastScheduledDeviceActions: Models.ScheduledDeviceAction[]
    }
  >
  // LoadDeviceProfile: LoadDeviceProfile;
  LoadDeviceTimeseriesData: BackendRequest<
    DeviceIdProps & {
      maxDateMs: number
      minDateMs: number
      maxEventCount?: number
    },
    ListResponse<DeviceTimeSeriesUnion>
  >

  LoadEventsForDevice: BackendRequest<
    DeviceIdProps & { limit: number; offset: number },
    ListResponse<DeviceEvent>
  >
  LoadFieldIrrigationHistory: BackendRequest<
    FieldIdProps & {
      options: MinDateMaxDateBackend
    },
    ListResponse<Models.ReelRun>
  >
  LoadFlowMeteringAnalytics: BackendRequest<
    MinDateMaxDate & {
      source: FlowAnalyticsSource
    },
    FlowAnalyticsData | null
  >
  LoadFlowMeteringReport: BackendRequest<
    {
      maxDateMs: number
      minDateMs: number
    },
    { data: string }
  >
  LoadMultifarmAnalytics: BackendRequest<
    MultifarmAnalyticsArgs,
    MultifarmAnalyticsResponse
  >
  LoadOtherFarmUsers: BackendRequest<void, ListResponse<Models.OtherFarmUser>>
  PrepareDemoEnvironment: BackendRequest<void, { activeFarmId: number }>
  RemoveDeviceFromFarm: BackendRequest<DeviceIdProps>
  RenameDevice: BackendRequest<
    Pick<DeviceConfiguration, "deviceId" | "deviceName">,
    { id: number }
  >
  RenameField: BackendRequest<
    FieldIdProps & Pick<FarmField, "fieldName" | "labelRotationDegrees">,
    FarmField
  >
  ReplaceDevice: BackendRequest<
    {
      codaDeviceAliasNew: string
      deviceIdOld: string
    },
    AddDeviceResponse
  >
  RequestUpdateFromDevice: BackendRequest<
    DeviceIdProps & {
      // Need this for request matching purposes only, this will never be defined
      actionId?: undefined
    },
    {
      result?: DeviceFunctionCall | null | undefined
    }
  >
  RestoreConfigurationDefaults: BackendRequest<
    DeviceIdProps,
    DeviceConfiguration
  >
  SeedTestDatabase: BackendRequest<
    SeedDatabaseOptions,
    {
      farmData: GetResponseDataType<"LoadApp"> | null | undefined
      userData: GetResponseDataType<"LoadActiveUser"> | null | undefined
    }
  >
  SendTestSms: BackendRequest<{ upnId: number }>
  SetDeviceLocationPermanent: BackendRequest<
    DeviceIdProps & { locationPermanent: Geo.PointGeoJson | null }
  >
  SetFieldRowDirection: BackendRequest<FieldIdProps & { value: number }>
  SetLanguagePreferenceOverrideForUser: BackendRequest<{
    languageCode: SupportedAppLanguageCode
  }>
  SetNotificationReadStatus: BackendRequest<{ ids: number[] }, void>
  SetNotificationStatusForPhoneNumber: BackendRequest<{
    nextStatus: NotificationsStatus
    upnId: number
  }>
  SetReelDistance: BackendRequest<
    {
      deviceId: string
      distanceMmCurrent: number
      distanceMmMax: number
    },
    DeviceFunctionCall
  >
  SetReelPumpPairRelayToggleDuration: BackendRequest
  SetReelRunDirection: BackendRequest<
    ReelRunIdProps & {
      value: number
    }
  >
  SubmitAppFeatureFeedback: BackendRequest<{
    featureName: string
    isLiked: boolean | null | undefined

    feedbackText?: string
  }>

  ToggleNotificationsFromDevices: BackendRequest<
    {
      action: "ENABLE" | "SUPPRESS"
      ids: string[]
    },
    ListResponse<DeviceUserAssociation>
  >
  UpdateDeviceConnection: BackendRequest<{
    connectionId: number
    notify?: boolean
  }>
  UpdateFarmUserPermissions: BackendRequest<{
    permissions: FarmUserPermissions
    targetUserId: string
  }>
  UpdateFieldBoundary: BackendRequest<
    FieldIdProps & {
      polygon: Geo.PolygonGeoJson
    }
  >
  UpdateScheduledDeviceAction: BackendRequest<
    Union<
      {
        executeAtTsMilliseconds: number
        isScheduled?: boolean
      },
      {
        isScheduled: boolean
        executeAtTsMilliseconds?: number
      }
    > & {
      scheduledDeviceActionId: number
    },
    Models.ScheduledDeviceAction
  >
  UpdateUserAccount: BackendRequest<
    {
      nameFamily?: string
      nameGiven?: string
      termsOfServiceAccepted?: boolean
    },
    Models.UserAccountData
  >
  UserJoinFarmWithCode: BackendRequest<
    {
      codeString: string
    },
    | {
        codeStatus: "SUCCESS"
        farm: FarmWithPermissions
        errorMessage?: undefined
      }
    | {
        codeStatus: JoinFarmErrorCode
        errorMessage: string
        farm: undefined
      }
  >
}
