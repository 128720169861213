import React from "react"
import { useNavigate } from "react-router"

import {
  CreateDeviceConfigurationContext,
  DeviceSummaryContext,
  Models,
  SetLinearPath,
  useRootSelector,
} from "@fhq/app"
import { NotFound404 } from "@fhq/app/NotFound404"

import { LinearPathConfiguration } from "./pages/SetLinearPathPage"

export function SetLinearPathPage() {
  const {
    codaDeviceAlias,
    deviceId,
    deviceInstallationType,
    deviceName,
    hardwareGeneration,
  } = DeviceSummaryContext.useContext()
  const navigate = useNavigate()
  const configuration = useRootSelector((state) =>
    Models.deviceConfiguration.selectById(state, deviceId),
  )
  if (configuration) {
    const handleCancel = () => {
      navigate(-1)
    }
    const handleSuccess = () => {
      navigate(`/devices/${codaDeviceAlias}`)
    }
    return (
      <CreateDeviceConfigurationContext.Provider
        codaDeviceAlias={codaDeviceAlias}
        deviceId={deviceId}
        deviceInstallationType={deviceInstallationType}
        deviceName={deviceName}
        hardwareGeneration={hardwareGeneration}
        currentConfigurationData={{
          ...configuration,
          linearPath: null,
          linearPathStopsLabels: null,
          linearSpanHeadingDegrees: null,
          linearSpanWidthMm: null,
        }}
        onCancel={handleCancel}
        onClearInstallationType={null}
        onSuccess={handleSuccess}
      >
        <SetLinearPath.Provider onCancel={handleCancel}>
          <LinearPathConfiguration />
        </SetLinearPath.Provider>
      </CreateDeviceConfigurationContext.Provider>
    )
  }
  return <NotFound404 />
}
