import React from "react"
import { Controller } from "react-hook-form"
import { useTranslation } from "react-i18next"

import * as turf from "@turf/turf"

import { ActionButtons } from "./ActionButtons"
import {
  AppText,
  Box,
  Button,
  FormControl,
  Row,
  TitleWithIcon,
} from "./components"
import { COLORS } from "./components/theme"
import * as CreateConfiguration from "./CreateDeviceConfigurationContext"
import { getDeviceStatusDataByDeviceIdFromState } from "./device-event-last.reducer"
import { useFormValidation } from "./form-validation"
import * as Geo from "./geo"
import * as Models from "./models"
import { useIsPending } from "./requests.reducer"
import { useMeasurementPreference } from "./selectors"
import { useConvertSensorValue } from "./sensor-conversions"
import { useSensorUnitLabel } from "./sensor-formatting"
import { SingleValueSlider } from "./sliders"
import { isValidNumber } from "./type-guards"
import { getActiveFarmLat, getActiveFarmLng } from "./user-farms.selectors"
import { useRootSelector } from "./useRootSelector"

import type { ValuesType } from "utility-types"
import type { AcceptsChildren } from "./components"
interface LinearPathStop {
  coordinates: Geo.Coordinates
  label?: string | null
}

export interface LinearPathOutlineParams {
  linearPathStops: LinearPathStop[] | null | undefined
  linearSpanHeadingDegrees: number | null | undefined
  linearSpanWidthMm: number | null | undefined
}

export function createLinearPathVisualization({
  linearPathStops,
  linearSpanHeadingDegrees,
  linearSpanWidthMm,
}: LinearPathOutlineParams) {
  if (!linearPathStops || linearPathStops.length < 2) {
    return undefined
  }
  const pointEnd = linearPathStops[linearPathStops.length - 1]?.coordinates
  const pointStart = linearPathStops[0]?.coordinates
  if (
    pointStart &&
    pointEnd &&
    typeof linearSpanHeadingDegrees === "number" &&
    typeof linearSpanWidthMm === "number"
  ) {
    const swathOutline: Geo.Coordinates[] = [pointStart, pointEnd]

    const swathWidthMeters = linearSpanWidthMm / 1000

    const endProjected = Geo.point(
      turf.destination(
        turf.point(pointEnd),
        swathWidthMeters,
        linearSpanHeadingDegrees,
        { units: "meters" },
      ).geometry.coordinates,
    )?.getCoords()

    const startProjected = Geo.point(
      turf.destination(
        turf.point(pointStart),
        swathWidthMeters,
        linearSpanHeadingDegrees,
        { units: "meters" },
      ).geometry.coordinates,
    )?.getCoords()

    /**
     * Add the perpendicular points to the polygon
     * NOTE: Order matters for drawing polygon
     */
    if (endProjected && startProjected) {
      swathOutline[2] = endProjected
      swathOutline[3] = startProjected
    }
    return swathOutline
  }
  return undefined
}

const STAGES = {
  "parkingSpots": "parkingSpots",
  "pathEnd": "pathEnd",
  "pathStart": "pathStart",
  "swath": "swath",
} as const
type Stages = ValuesType<typeof STAGES>

export const LINEAR_PATH_COLORS = {
  path: {
    stroke: COLORS.$orange[500],
  },
  swath: {
    fill: COLORS.$lightBlue[300],
    stroke: COLORS.$cyan[400],
  },
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const DEFAULT_VALUES: {
  [key in "end" | "start"]: Geo.Coordinates | undefined
} = {
  end: [-122.29731779098509, 48.223944231595965],
  start: [-122.30789642333983, 48.223801275905736],
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const USE_DEFAULTS = false as boolean

interface ProviderProps extends AcceptsChildren {
  onCancel: () => void
}
/**
 * Logic for setting a linear path configuration for a device
 * NOTE: This must called in a child of CreateConfiguration.Provider
 */
function useSetLinearPath({ onCancel }: ProviderProps) {
  const { t } = useTranslation("setLinearPath")
  const { deviceId, form, onSubmit } = CreateConfiguration.useContext()
  const convert = useConvertSensorValue("swathWidthMm")
  const deviceMarkerData = useRootSelector((state) =>
    getDeviceStatusDataByDeviceIdFromState(state, deviceId),
  )
  const initialLat = deviceMarkerData?.gpsLocation?.native.latitude
  const initialLng = deviceMarkerData?.gpsLocation?.native.longitude
  const activeFarmLng = useRootSelector(getActiveFarmLng)
  const activeFarmLat = useRootSelector(getActiveFarmLat)

  const [stage, setStage] = React.useState<Stages>(STAGES.pathStart)

  const pointStart = form.watch("linearPathStart")
  const pointEnd = form.watch("linearPathEnd")
  const parkingSpots = form.watch("linearPath") ?? []

  // Extract and convert swath width on the fly
  let linearSpanWidthMm = 0
  const formWidthValue = form.watch("linearSpanWidthMm")
  let formWidthValueAsNumber: number | undefined
  if (typeof formWidthValue === "number") {
    formWidthValueAsNumber = formWidthValue
  } else if (typeof formWidthValue === "string") {
    const asNumber = Number(formWidthValue)
    if (!Number.isNaN(asNumber)) {
      formWidthValueAsNumber = asNumber
    }
  }
  if (
    typeof formWidthValueAsNumber === "number" &&
    !Number.isNaN(formWidthValueAsNumber)
  ) {
    linearSpanWidthMm = convert(formWidthValueAsNumber, "database")
  }

  let isSubmitDisabled: boolean
  let isUndoDisabled = true
  let onPressCancel: () => void
  let onPressSubmit: () => void
  let drawingMode: "marker" | null = "marker"
  let subheaderText: string
  let instructionsText: string
  let cancelText: string
  let submitText = t("next", { ns: "common" })

  switch (stage) {
    case STAGES.pathStart: {
      cancelText = t("stagePathStart.cancelText")
      subheaderText = t("stagePathStart.title")
      instructionsText = t("stagePathStart.instructions")
      isSubmitDisabled = !pointStart
      isUndoDisabled = !pointStart
      onPressCancel = onCancel
      onPressSubmit = () => setStage(STAGES.pathEnd)
      break
    }
    case STAGES.pathEnd: {
      cancelText = t("stagePathEnd.cancelText")
      subheaderText = t("stagePathEnd.title")
      instructionsText = t("stagePathStart.instructions")
      isSubmitDisabled = !pointEnd
      isUndoDisabled = !pointEnd
      onPressCancel = () => setStage(STAGES.pathStart)
      onPressSubmit = () => setStage(STAGES.swath)
      break
    }
    case STAGES.swath: {
      cancelText = t("stageSwath.cancelText")
      subheaderText = t("stageSwath.title")
      instructionsText = t("stagePathStart.instructions")
      isSubmitDisabled = false
      drawingMode = null
      /**
       * Parking spots are cleared if we go back to swath
       */
      onPressCancel = () => {
        form.setValue("linearPath", [])
        // setParkingSpots([])
        return setStage(STAGES.pathEnd)
      }
      onPressSubmit = form.handleSubmit(() => {
        return setStage(STAGES.parkingSpots)
      })
      break
    }
    case STAGES.parkingSpots: {
      submitText = t("submit", { ns: "common" })
      cancelText = t("stageParkingSpots.cancelText")
      subheaderText = t("stageParkingSpots.title")
      instructionsText = t("stagePathStart.instructions")
      isSubmitDisabled = false
      onPressCancel = () => setStage(STAGES.swath)
      onPressSubmit = onSubmit
      if (parkingSpots.length > 0) {
        isUndoDisabled = false
      }
      break
    }
  }
  return {
    activeFarmLat,
    activeFarmLng,
    cancelText,
    deviceId,
    deviceMarkerData,
    drawingMode,
    /**
     * Flip the swath visualization
     */
    handleFlipSwath: () => {
      const current = form.watch("linearSpanHeadingDegrees")
      // return setIsSwathFlipped((prev) => !prev)
      if (typeof current === "number") {
        form.setValue("linearSpanHeadingDegrees", (current + 180) % 360)
      }
    },

    /**
     * Place a marker on the map
     *
     * Can be used to place a start, end, or parking spot,
     * including dragging a parking spot to a new location
     * when an index is provided
     */
    handlePlaceMarker: (marker: {
      latLng: Geo.AnyLatLng | undefined
      replacePointAtIndex?: number
    }) => {
      const asPoint = Geo.point(marker.latLng)?.getCoords()
      if (asPoint) {
        if (stage === STAGES.pathStart) {
          form.setValue("linearPathStart", asPoint)
        } else if (stage === STAGES.pathEnd) {
          form.setValue("linearPathEnd", asPoint)
          const startPoint = form.watch("linearPathStart")
          const perendicularDirection = startPoint
            ? turf.bearing(startPoint, asPoint)
            : null
          if (typeof perendicularDirection === "number") {
            form.setValue(
              "linearSpanHeadingDegrees",
              perendicularDirection + 90,
            )
          }
        } else {
          if (!pointStart) {
            throw new TypeError("pointStart is undefined")
          }
          if (!pointEnd) {
            throw new TypeError("pointEnd is undefined")
          }
          const intersection = Geo.point(
            turf.nearestPointOnLine(
              turf.lineString([pointStart, pointEnd]),
              asPoint,
            ).geometry.coordinates,
          )?.getCoords()

          const nextPoint = intersection ?? asPoint
          const nextSpots = [...parkingSpots]
          if (typeof marker.replacePointAtIndex === "number") {
            nextSpots[marker.replacePointAtIndex] = {
              coordinates: nextPoint,
              label: `Spot ${marker.replacePointAtIndex + 1}`,
            }
            form.setValue("linearPath", nextSpots)
          } else {
            nextSpots.push({
              coordinates: nextPoint,
              label: `Spot ${nextSpots.length + 1}`,
            })
          }
          form.setValue("linearPath", nextSpots)
          // recalculate the swath heading
        }
      }
    },

    /**
     * Remove the last parking spot or start/end point
     */
    handlePressUndo: () => {
      if (parkingSpots.length > 0) {
        form.setValue("linearPath", parkingSpots.slice(0, -1))
        // setParkingSpots(parkingSpots.slice(0, -1))
      } else if (pointEnd) {
        form.setValue("linearPathEnd", null)
        // setPointEnd(undefined)
      } else if (pointStart) {
        form.setValue("linearPathStart", null)
        // setPointStart(undefined)
      }
    },

    initialLat,

    initialLng,

    instructionsText,
    isLoading: useIsPending("CreateConfiguration"),
    isSubmitDisabled,
    isSwathVisible: stage === STAGES.swath || stage === STAGES.parkingSpots,
    isUndoDisabled,
    linearSpanHeadingDegrees: form.watch("linearSpanHeadingDegrees"),
    linearSpanWidthMm,
    onPressCancel,
    onSubmit: onPressSubmit,
    parkingSpots,
    pointEnd,
    pointStart,
    stage,
    subheaderText,
    submitText,
  }
}

type ContextValue = ReturnType<typeof useSetLinearPath>

const Context = React.createContext<ContextValue | undefined>(undefined)

export function Provider({
  children,
  ...rest
}: AcceptsChildren & ProviderProps): JSX.Element | null {
  const value = useSetLinearPath(rest)
  return <Context.Provider value={value}>{children}</Context.Provider>
}

export function useContext(): ContextValue {
  const ctx = React.useContext(Context)
  if (typeof ctx === "undefined") {
    throw new TypeError(`Context must be used inside of provider`)
  }
  return ctx
}

export function LinearSpanWidthField() {
  const { t } = useTranslation("sensorFields")
  const { form } = CreateConfiguration.useContext()
  const { integerPattern, minValue, required } = useFormValidation()
  const getLabel = useSensorUnitLabel()
  const measurementPreference = useMeasurementPreference()

  const placeholder: string = t("swathWidthMm.placeholder")
  const fieldNameText: string = t("swathWidthMm.displayName")
  const labelText = `${fieldNameText} (${getLabel("swathWidthMm")})`
  return (
    <Controller
      control={form.control}
      name="linearSpanWidthMm"
      render={({ field: { onChange, value, ...field }, fieldState }) => {
        const errorMessage = fieldState.error?.message

        return (
          <FormControl.Provider isInvalid={Boolean(errorMessage)}>
            <FormControl.Label>{labelText}</FormControl.Label>
            <Row>
              <Box w="$32">
                <FormControl.Input
                  {...field}
                  keyboardType="numeric"
                  placeholder={placeholder}
                  returnKeyType="done"
                  value={`${value ?? ""}`}
                  onSubmitEditing={field.onBlur}
                  onChangeText={(nextValue) => {
                    const asNumber = Number.parseInt(nextValue)
                    if (isValidNumber(asNumber)) {
                      onChange(asNumber)
                    } else {
                      onChange(nextValue)
                    }
                  }}
                />
              </Box>
              <Box flex={1} ml="$4">
                <SingleValueSlider
                  value={Number(value)}
                  maximumValue={
                    measurementPreference === "metric" ? 1000 : 3000
                  }
                  onValueChange={(nextValue) => onChange(nextValue)}
                />
              </Box>
            </Row>
            <FormControl.ErrorMessage>{errorMessage}</FormControl.ErrorMessage>
          </FormControl.Provider>
        )
      }}
      rules={{
        min: minValue(0),
        pattern: integerPattern,
        required,
      }}
    />
  )
}

export function TitleWithStage() {
  const { subheaderText } = useContext()
  const { t } = useTranslation("setLinearPath")

  const titleText = t("setLinearPath")

  return (
    <TitleWithIcon
      IconComponent="DeviceLinearMove"
      subheaderElement={subheaderText}
      titleText={titleText}
    />
  )
}

export function Instructions() {
  const {
    handleFlipSwath,
    handlePressUndo,
    instructionsText,
    isUndoDisabled,
    stage,
  } = useContext()

  const { t } = useTranslation()
  return (
    <Row mb="$4">
      <AppText flex={1}>{instructionsText}</AppText>
      <Box ml="$2">
        {stage === "swath" ? (
          <Button
            IconComponent="SwapVertical"
            text={t("flipSwath")}
            onPress={handleFlipSwath}
          />
        ) : (
          <Button
            IconComponent="Undo"
            isDisabled={isUndoDisabled}
            text={t("undo")}
            onPress={handlePressUndo}
          />
        )}
      </Box>
    </Row>
  )
}

export function Actions() {
  const {
    cancelText,
    isLoading,
    isSubmitDisabled,
    onPressCancel,
    onSubmit,
    submitText,
  } = useContext()

  return (
    <ActionButtons
      cancelText={cancelText}
      isLoading={isLoading}
      isSubmitDisabled={isSubmitDisabled}
      submitText={submitText}
      onPressCancel={onPressCancel}
      onPressSubmit={onSubmit}
    />
  )
}

export function useLinearPathDataForDevice({ deviceId }: { deviceId: string }) {
  return useRootSelector((state) => {
    const configuration = Models.deviceConfiguration.selectById(state, deviceId)

    if (configuration?.linearPath) {
      return {
        linearPathStops: configuration.linearPath,
        linearSpanHeadingDegrees: configuration.linearSpanHeadingDegrees,
        linearSpanWidthMm: configuration.linearSpanWidthMm,
      }
    }
    return undefined
  })
}
