import React from "react"
import { Outlet, useParams } from "react-router-dom"

import {
  DeviceSummaryContext,
  getDeviceStatusDataByDeviceIdFromState,
  Models,
  useRootSelector,
} from "@fhq/app"
import { Box } from "@fhq/app/components"
import { Loader } from "@fhq/app/Loader"

import { DeviceConfigurationRoutes } from "./DeviceConfiguration.Routes"

import type { RouteObject } from "react-router"

const CreateCustomNotificationPage = React.lazy(async () => {
  return import("./pages/CreateCustomNotificationPage")
})

const NotificationsListPage = React.lazy(async () => {
  return import("./pages/DeviceNotificationRulesListPage")
})

const DeviceActionHistoryPage = React.lazy(async () => {
  return import("./pages/DeviceActionHistoryPage")
})

const DeviceActivityPage = React.lazy(async () => {
  return import("./pages/DeviceProfileLandingPage")
})

const EventHistoryPage = React.lazy(async () => {
  return import("./pages/DeviceEventHistoryInspectorPage")
})

const SetPermanentLocationPage = React.lazy(async () => {
  return import("./SetDeviceLocationPermanentPage")
})
/**
 * Common layout for device profile pages
 */
function Root(): React.JSX.Element | null {
  const params = useParams<{ codaDeviceAlias: string }>()

  const numConfigs = useRootSelector(Models.deviceConfiguration.selectTotal)
  const deviceStatus = useRootSelector((state) =>
    getDeviceStatusDataByDeviceIdFromState(
      state,
      Models.deviceConfiguration
        .selectAll(state)
        .find((dc) => params.codaDeviceAlias === dc.codaDeviceAlias)?.deviceId,
    ),
  )
  // If the device configurations are loaded into the app, we should always
  // be able to find one with the given alias
  if (!deviceStatus) {
    if (numConfigs > 0) {
      throw new TypeError(
        `Device with alias ${params.codaDeviceAlias ?? ""} not found`,
      )
    }
    // App loading must be in progress in this case
    return <Loader />
  }

  // Configuration is found; show its device profile
  return (
    <DeviceSummaryContext.Provider {...deviceStatus}>
      <Box flex={1} id="device-profile">
        <Outlet />
      </Box>
    </DeviceSummaryContext.Provider>
  )
}

export const DEVICE_PROFILE_ROUTES: RouteObject = {
  children: [
    {
      element: <DeviceActivityPage />,
      index: true,
    },
    {
      ...DeviceConfigurationRoutes,
      path: "configuration",
    },
    {
      element: <DeviceActionHistoryPage />,
      path: "command-history",
    },
    {
      element: <SetPermanentLocationPage />,
      path: "set-permanent-location",
    },
    {
      children: [
        {
          element: <NotificationsListPage />,
          index: true,
        },
        {
          element: <CreateCustomNotificationPage />,
          path: "create",
        },
      ],
      element: <Outlet />,
      id: "device-notifications",
      path: "notifications",
    },
    {
      element: <EventHistoryPage />,
      id: "event-history",
      path: "event-history",
    },
  ],
  element: <Root />,
}
