import type { FarmAccount } from "./geocoding"
import i18n from "./translations/i18n"

import type { IconProp } from "./components"

export const PERMISSION_KEYS = {
  device: [
    "canControlDevicesRemotely",
    "canManageCustomTriggers",
    "canManageDeviceConfiguration",
    "canManageDeviceNotifications",
    "canManageSchedules",
    // "canManageDevicePairs",
    "canManageDevicePermissions",
  ],
  farm: [
    "canManageFarmAccount",
    "canManageFields",
    "canManageUserPermission",
    "canManageJoinCodes",
  ],
} as const
const FARM_USER_PERMISSION_KEYS = [
  ...PERMISSION_KEYS.device,
  ...PERMISSION_KEYS.farm,
] as const

/**
 * Factory function for subset of permissions
 */
function makePermissions<K extends (typeof FARM_USER_PERMISSION_KEYS)[number]>({
  IconComponent,
  keys,
  titleText,
}: {
  IconComponent: IconProp
  keys: readonly K[]
  titleText: string
}) {
  const initializer = {} as { [key in K]: boolean }
  const mapKeysToValue = (value: boolean) => {
    return keys.reduce((acc, key) => ({ ...acc, [key]: value }), {
      ...initializer,
    })
  }

  return {
    allFalse: () => mapKeysToValue(false),
    allTrue: () => mapKeysToValue(true),
    getDetails: () => {
      return {
        IconComponent,
        items: [
          ...keys.map((key) => {
            const displayName = i18n.t(`${key}.displayName`, {
              ns: "farmUserPermissions",
            })
            const extendedDescription = i18n.t(`${key}.extendedDescription`, {
              ns: "farmUserPermissions",
            })

            return { displayName, extendedDescription, key }
          }),
        ],
        titleText,
      }
    },
    getKeys: () => [...keys],
  }
}

export const DEVICE_PERMISSIONS = makePermissions({
  IconComponent: "DeviceGeneric",
  keys: PERMISSION_KEYS.device,
  titleText: i18n.t("devicePermissionsTitle", { ns: "farmUserPermissions" }),
})

export const FARM_PERMISSIONS = makePermissions({
  IconComponent: "Field",
  keys: PERMISSION_KEYS.farm,
  titleText: i18n.t("farmPermissionsTitle", { ns: "farmUserPermissions" }),
})

export const FARM_USER_PERMISSIONS = makePermissions({
  IconComponent: "Permissions",
  keys: [...PERMISSION_KEYS.device, ...PERMISSION_KEYS.farm],
  // TODO: This keeps giving a typescript error
  // @ts-ignore
  titleText: i18n.t("farmUserPermissionsTitle"),
})

export function getPermissionsBySection() {
  return [DEVICE_PERMISSIONS.getDetails(), FARM_PERMISSIONS.getDetails()]
}

export type DevicePermissions = {
  [K in ReturnType<typeof DEVICE_PERMISSIONS.getKeys>[number]]: boolean
}

export type FarmUserPermissions = {
  [K in ReturnType<typeof FARM_USER_PERMISSIONS.getKeys>[number]]: boolean
}
export type FarmUserPermissionName = keyof FarmUserPermissions
export type RequiredPermissions =
  | FarmUserPermissionName
  | FarmUserPermissionName[]
  | readonly FarmUserPermissionName[]

export interface FarmWithPermissions extends FarmAccount {
  permissions: FarmUserPermissions
}

export function identifyMissingPermissions({
  provided,
  required,
}: {
  provided: FarmUserPermissions | null | undefined
  required: RequiredPermissions | null | undefined
}) {
  let asArray: FarmUserPermissionName[]
  if (typeof required === "string") {
    asArray = [required]
  } else if (Array.isArray(required)) {
    asArray = required
  } else {
    asArray = []
  }
  const missingPermissions: FarmUserPermissionName[] = []
  // For each required permission
  if (provided && asArray.length > 0) {
    for (const key of asArray) {
      if (!provided[key]) {
        missingPermissions.push(key)
      }
    }
  }
  return missingPermissions
}

export const FARM_USER_PERMISSION_SECTIONS = [
  {
    IconComponent: "DeviceGeneric" as const,
    data: DEVICE_PERMISSIONS.getKeys(),
    descriptionText: i18n.t("farmUserPermissions:devicePermissionsDescription"),
    titleText: i18n.t("farmUserPermissions:devicePermissionsTitle"),
  },
  {
    IconComponent: "Farm" as const,
    data: FARM_PERMISSIONS.getKeys(),
    descriptionText: i18n.t("farmUserPermissions:farmPermissionsDescription"),
    titleText: i18n.t("farmUserPermissions:farmPermissionsTitle"),
  },
]
