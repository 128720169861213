import React from "react"
import { View } from "react-native"

import { setHideUnknownFieldsWarning } from "./async-storage"
import {
  AlertBodyText,
  AlertCard,
  AlertDialog,
  AlertDialogScrollView,
  AppText,
  Box,
  Button,
  Checkbox,
  Divider,
  IconButton,
} from "./components"
import { Row } from "./components/Row"
import { logger } from "./logger"
import { closeUnknownFieldsDialog } from "./reel-runs.reducer"
import { getMissingFieldsForActiveRuns } from "./selectors"
import i18n from "./translations/i18n"
import { useRootDispatch } from "./useRootDispatch"
import { useRootSelector } from "./useRootSelector"

import type { PermissionCheckProps } from "./types"

import type { PointInput } from "./geo"
/**
 * Dialog that is shown when there are active runs without fields.
 */
export function UnknownFieldsDialog({
  onPressCreateField,
  withPermissions,
}: PermissionCheckProps & {
  onPressCreateField: (params?: { initialLocation: PointInput }) => void
}): React.JSX.Element {
  const [isChecked, setIsChecked] = React.useState(false)
  const runsWithoutFields = useRootSelector(getMissingFieldsForActiveRuns)
  const isOpen = useRootSelector((state) => {
    const dialogState = state.reelRunsActive.missingFieldsDialog
    if (
      runsWithoutFields.length > 0 &&
      dialogState !== "dismissed" &&
      dialogState !== "suppressed"
    ) {
      return true
    }
    return false
  })

  const dispatch = useRootDispatch()
  const onClose = () => {
    dispatch(closeUnknownFieldsDialog())
  }
  const handleToggleCheckbox = (value: boolean) => {
    setIsChecked(value)
    dispatch(setHideUnknownFieldsWarning(value)).catch((e) => {
      logger.error(e)
      throw e
    })
  }

  const checkboxText = i18n.t("doNotShowThisAgain")

  return (
    <AlertDialog
      id="unknown-fields"
      isOpen={isOpen}
      titleElement={i18n.t("statusMap:unknownFields.title")}
      onClose={onClose}
    >
      <AlertDialogScrollView>
        <View>
          <AlertCard severity="warning">
            <AlertBodyText flex={1}>{i18n.t("warning")}</AlertBodyText>
          </AlertCard>
          {runsWithoutFields.map((run, i): React.JSX.Element => {
            const element = (
              <Row id={`unknown-fields-list-item-${i}`} px="$2" py="$2">
                <AppText flex={1}>{run.deviceName}</AppText>
                <IconButton
                  IconComponent="Draw"
                  id="create-field-btn"
                  onPress={withPermissions({
                    callback: () => {
                      const initialLocation = run.location?.coordinates
                      onPressCreateField({ initialLocation })
                      return onClose()
                    },
                    required: "canManageFields",
                  })}
                />
              </Row>
            )
            return (
              <React.Fragment key={run.reelRunId}>
                {i < runsWithoutFields.length - 1 ? (
                  <React.Fragment>
                    {element}
                    <Divider />
                  </React.Fragment>
                ) : (
                  element
                )}
              </React.Fragment>
            )
          })}
          <AppText>{i18n.t("statusMap:unknownFields.description")}</AppText>
        </View>

        <Box my="$2">
          <Divider />
        </Box>
        <Row flex={1} flexWrap="nowrap" my="$2">
          <AppText flex={1}>{checkboxText}</AppText>
          <Box mt="$2">
            <Checkbox
              isChecked={isChecked}
              onChange={() => handleToggleCheckbox(!isChecked)}
            />
          </Box>
        </Row>
        <Box ml="auto">
          <Button text={i18n.t("close")} onPress={onClose} />
        </Box>
      </AlertDialogScrollView>
    </AlertDialog>
  )
}
