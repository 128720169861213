import React from "react"
import { SectionList } from "react-native"

import {
  Container,
  Divider,
  Heading,
  HelpIconButton,
  TitleWithIcon,
} from "./components"
import { ViewPermissionListItem } from "./PermissionListItem"
import {
  FARM_USER_PERMISSION_SECTIONS,
  FARM_USER_PERMISSIONS,
} from "./permissions"
import {
  FarmUserPermissionHelpDialog,
  useFarmUserPermissionsHelpDialog,
} from "./PermissionsForm"
import { ResponsiveCard } from "./ResponsiveCard"
import i18n from "./translations/i18n"
import { getActiveFarmNameFromState } from "./user-farms.selectors"
import { getFarmUserPermissionsFromState } from "./user-permissions.reducer"
import { useRootSelector, useShallowEqualSelector } from "./useRootSelector"

import type { HelpContentStatic } from "./components"
export const HELP_CONTENT: HelpContentStatic = {
  bodyElement: i18n.t("farmUserPermissions:farmUserPermissionsHelpSummary"),
  subject: "view_permissions",
  titleElement: i18n.t("farmUserPermissions:aboutFarmUserPermissions"),
}

/**
 * Display farm user permissions between the active user and active farm.
 */
export function ViewPermissions(): React.JSX.Element {
  const farmName = useRootSelector(getActiveFarmNameFromState) ?? ""
  const permissions =
    useShallowEqualSelector(getFarmUserPermissionsFromState) ??
    FARM_USER_PERMISSIONS.allFalse()
  const { helpKey, onCloseHelpDialog, setHelpKey } =
    useFarmUserPermissionsHelpDialog()
  return (
    <React.Fragment>
      <FarmUserPermissionHelpDialog
        helpKey={helpKey}
        onClose={onCloseHelpDialog}
      />
      <SectionList
        ItemSeparatorComponent={Divider}
        sections={FARM_USER_PERMISSION_SECTIONS}
        ListHeaderComponent={
          <Container py="$2">
            <TitleWithIcon
              IconComponent="Farm"
              colorScheme="secondary"
              headingVariant="h6"
              titleText={farmName}
            />
          </Container>
        }
        renderItem={({ item }) => (
          <ViewPermissionListItem
            isEnabled={permissions[item]}
            name={item}
            px="$4"
            py="$1"
            helpElement={
              <HelpIconButton size="sm" onPress={() => setHelpKey(item)} />
            }
          />
        )}
        renderSectionHeader={({ section }) => {
          return (
            <ResponsiveCard isCompact>
              <Heading variant="h6">{section.titleText}</Heading>
            </ResponsiveCard>
          )
        }}
      />
    </React.Fragment>
  )
}
