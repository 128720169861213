import { createCachedSelector } from "re-reselect"
import { createSelector } from "reselect"

import * as turf from "@turf/turf"

import * as Geo from "./geo"
import { makeFileLogger } from "./logger"
import * as Models from "./models"
import { isTruthyString } from "./type-guards"
import { getActiveFarmCoordinatesFromState } from "./user-farms.selectors"
import { useRootSelector } from "./useRootSelector"

import type { AcceptsChildren } from "./components"
import type { SensorState } from "./sensor-events"

import type { TargetDatabaseName } from "./Internal"

import type { DeviceSortKey, SortDirectionKey } from "./Sorting"
import type { CodaDeviceAliasProps } from "./types"

import type {
  DeviceConfiguration,
  DeviceSummary,
} from "./device-configurations.reducer"

import type {
  DeviceEvent,
  EventActionTrigger,
  EventActionTriggerIds,
} from "./models"

import type { RootState } from "./root.reducer"

const fileLogger = makeFileLogger({ fileName: "selectors.ts" })

export interface LocationValidationArgs {
  farmLocation: Geo.PointInput | null | undefined
  gpsStateCurrent?: SensorState<"gps"> | null
}

/**
 * Use this to filter out locations that are super far away from the farm.
 * This happens sometimes due to problems with our gps
 */
export function isValidGpsLocation<T extends Geo.AnyPoint>(
  targetLocation: T | null | undefined,
  { farmLocation, gpsStateCurrent }: LocationValidationArgs,
): targetLocation is T {
  const farmCoords = Geo.point(farmLocation)?.getCoords()
  const locationCoords = Geo.point(targetLocation)?.getCoords()

  let isValidGpsState = true
  let farmDistanceMiles = 0

  if (typeof gpsStateCurrent === "string") {
    isValidGpsState = gpsStateCurrent === "GLK" || gpsStateCurrent === "GST"
  }
  if (isValidGpsState && locationCoords && farmCoords) {
    if (targetLocation) {
      try {
        farmDistanceMiles = turf.distance(
          turf.point(farmCoords),
          turf.point(locationCoords),
          { units: "miles" },
        )
      } catch (error) {
        fileLogger.error(error)
        return false
      }
      return farmDistanceMiles < 300
    }
  }

  return false
}

export function makeLocationValidator({
  farmLocation,
}: {
  farmLocation: Geo.AnyPoint | null | undefined
}) {
  return <T extends Geo.AnyPoint>(
    location: T | null | undefined,
    gpsStateCurrent?: SensorState<"gps"> | null,
  ): location is T => {
    const asGeoJson = Geo.point(farmLocation)?.toJson()
    if (asGeoJson) {
      return isValidGpsLocation(location, { farmLocation, gpsStateCurrent })
    }
    return false
  }
}

export function makeDeviceSummary({
  configuration,
  event,
  farmLocation,
}: {
  configuration: DeviceConfiguration
  event: DeviceEvent | null | undefined
  farmLocation: Geo.AnyPoint | null | undefined
}): DeviceSummary {
  const { firmwareVersion, gps } = event ?? {}
  const isLocationValid = makeLocationValidator({ farmLocation })

  // Location permanent overrides event location
  const locationResolved = configuration.locationPermanent ?? gps?.location
  const gpsLocation = isLocationValid(locationResolved)
    ? Geo.createMultiPoint(locationResolved)
    : undefined
  return {
    codaDeviceAlias: configuration.codaDeviceAlias,
    configurationId: configuration.id,
    deviceId: configuration.deviceId,
    deviceInstallationType: configuration.deviceInstallationType,
    deviceName: configuration.deviceName ?? configuration.codaDeviceAlias,
    firmwareVersion: firmwareVersion ?? null,
    gpsLocation,
    hardwareGeneration: configuration.hardwareGeneration,
  }
}

// export function getDeviceIdFromProps(
//   _state: RootState,
//   deviceId: string | undefined,
// ): string | undefined {
//   return deviceId
// }

// export function makeDeviceCacheKey(
//   _state: RootState,
//   deviceId: string | undefined = "NONE",
// ): string {
//   return deviceId
// }

/**
 * Selector result will be recalculated when the device id,
 * last event id, or configuration id change
 */
export function makeDeviceEventCacheKey(
  state: RootState,
  deviceId: string | undefined = "None",
): string {
  const event = state.deviceEventLast.entities[deviceId]
  const configuration = state.deviceConfigurations.entities[deviceId]
  return `device=${deviceId}/event=${event?.id ?? "None"}/configuration=${
    configuration?.id ?? "None"
  }`
}

// export function getActiveUserIdFromState(state: RootState): string | undefined {
//   return state.auth.userId
// }
/**
 *
 */
export function getActiveUserEmailFromState(
  state: RootState,
): string | undefined {
  return state.auth.email
}

export function getUserMeasurementPreferenceFromState(state: RootState) {
  return state.userPreferences.measurement ?? "us"
}
export function useMeasurementPreference() {
  return useRootSelector(getUserMeasurementPreferenceFromState)
}

export function getUserIsAdminFromState(state: RootState): boolean {
  return state.permissions.isAdmin === true
}

export function getIsAdminModeEnabledFromState(state: RootState): boolean {
  return (
    getUserIsAdminFromState(state) &&
    state.permissions.isAdminModeEnabled === true
  )
}

// export const getLastEventLocationByDeviceIdFromState: (
//   state: RootState,
//   deviceId: string | undefined,
// ) => DeviceLocation = createCachedSelector(
//   [
//     Models.deviceConfiguration.selectById,
//     Models.deviceEventLast.selectById,
//     getActiveFarmCoordinatesFromState,
//   ],
//   (configuration, event, farmLocation): DeviceLocation => {
//     const stateCurrent = event?.gps?.stateCurrent

//     const parseLocation = (
//       value: Geo.PointGeoJson | null | undefined,
//     ): DeviceLocation => {
//       if (!value) {
//         return { errorCode: "no location" }
//       }
//       if (
//         !isValidGpsLocation(value, {
//           farmLocation,
//           gpsStateCurrent: stateCurrent,
//         })
//       ) {
//         return { errorCode: "invalid location" }
//       }
//       return { value }
//     }
//     if (configuration && configuration.locationPermanent) {
//       return parseLocation(configuration.locationPermanent)
//     }
//     if (typeof event === "undefined") {
//       return { errorCode: "no event" }
//     }
//     const sensorData = event.gps
//     if (!sensorData) {
//       return { errorCode: "no gps sensor data" }
//     }
//     return parseLocation(sensorData.location)
//   },
// )(makeDeviceEventCacheKey)

export function getMapTypeIdFromState(state: RootState): Geo.MapTypeId {
  return state.userPreferences.mapTypeId
}

export function isDevicePair(trigger: EventActionTrigger): boolean {
  if (
    typeof trigger.targetDeviceId === "string" &&
    trigger.namedDeviceAction?.actionType === "RLYAS"
  ) {
    if (trigger.sourceSensor === "reel") {
      return (
        trigger.sourceSensorStateCurrent === "RS" &&
        trigger.sourceSensorStatePrevious === "RR"
      )
    } else if (trigger.sourceSensor === "wheel") {
      return (
        trigger.sourceSensorStateCurrent === "WF" &&
        trigger.sourceSensorStatePrevious === "WL"
      )
    }
  }
  return false
}
export function isCustomNotificationTrigger(trigger: EventActionTrigger) {
  if (
    trigger.notify === true &&
    !Boolean(trigger.targetDeviceId) &&
    !trigger.namedDeviceAction
  ) {
    return true
  }
  return false
}

export function getIsTermsOfServiceAcceptedFromState(state: RootState) {
  return state.permissions.termsOfServiceAccepted === true
}

export function getDeviceSortKeyFromState(state: RootState): DeviceSortKey {
  return state.deviceConfigurations.deviceRoster.sortKey
}
export function getDeviceSortDirectionFromState(
  state: RootState,
): SortDirectionKey {
  return state.deviceConfigurations.deviceRoster.sortDirection
}

export const getEventsNearFieldByFieldIdFromState: (
  state: RootState,
  fieldId: number | undefined,
) => string[] | undefined = createCachedSelector(
  Models.deviceEventLast.selectAll,
  Models.field.selectById,
  (events, field) => {
    return field
      ? events
          .filter((de): boolean => {
            const location = de.gps?.location
            if (location) {
              return turf.booleanPointInPolygon(
                location,
                turf.buffer(field.polygon, 0, { units: "meters" }),
              )
            }
            return false
          })
          .map((de) => de.deviceId)
      : []
  },
)((_state, fieldId) => fieldId ?? "None")

export function getAreReelRunOutlinesVisibleFromState(
  state: RootState,
): boolean {
  return state.fieldIrrigationHistory.showOutlines === true
}

export interface PairLineProps extends EventActionTriggerIds {
  key: string
  linePath: Geo.MultiPath | undefined
  sourceLocation: Geo.MultiPoint | undefined
  targetLocation: Geo.MultiPoint | undefined
  triggerId: number
}

export const getConnectionLinesFromState = createSelector(
  Models.trigger.selectAll,
  Models.deviceEventLast.selectEntities,
  getActiveFarmCoordinatesFromState,
  (triggers, events, farmLocation): PairLineProps[] => {
    const acc = [] as PairLineProps[]
    for (const trigger of triggers) {
      const targetDeviceId = trigger.targetDeviceId
      const sourceDeviceId = trigger.sourceDeviceId
      const sourceEvent = events[sourceDeviceId]
      let values: PairLineProps | undefined
      if (isTruthyString(targetDeviceId)) {
        const targetEvent = events[targetDeviceId]
        const sourceGeoJson = sourceEvent?.gps?.location
        const targetGeoJson = targetEvent?.gps?.location
        if (
          isValidGpsLocation(sourceGeoJson, {
            farmLocation,
            gpsStateCurrent: sourceEvent?.gps?.stateCurrent,
          }) &&
          isValidGpsLocation(targetGeoJson, {
            farmLocation,
            gpsStateCurrent: targetEvent?.gps?.stateCurrent,
          })
        ) {
          const sourceLocation = Geo.createMultiPoint(sourceGeoJson)
          const targetLocation = Geo.createMultiPoint(targetGeoJson)
          if (sourceLocation && targetLocation) {
            values = {
              key: `${trigger.id}`,
              linePath: {
                native: [sourceLocation.native, targetLocation.native],
                web: [sourceLocation.web, targetLocation.web],
              },
              sourceDeviceId,
              sourceLocation,
              targetDeviceId,
              targetLocation,
              triggerId: trigger.id,
            }
          }
        }
      }
      if (values) {
        acc.push(values)
      }
    }
    return acc
  },
)

function makeDeviceSummaryCacheKey(
  state: RootState,
  deviceId: string | undefined,
): string {
  const configuration: DeviceConfiguration | undefined =
    Models.deviceConfiguration.selectById(state, deviceId)
  const eventId = Models.deviceEventLast.selectById(state, deviceId)?.id
  return `${deviceId ?? ""}/${configuration?.id ?? ""}/${
    configuration?.deviceName ?? ""
  }/${eventId ?? ""}`
}

function getAliasFromProps(
  _state: RootState,
  props: CodaDeviceAliasProps,
): string {
  return props.codaDeviceAlias
}

export const getDeviceSummaryByAliasFromState: (
  state: RootState,
  props: CodaDeviceAliasProps,
) => DeviceSummary | undefined = createCachedSelector(
  Models.deviceConfiguration.selectAll,
  Models.deviceEventLast.selectEntities,
  getAliasFromProps,
  getActiveFarmCoordinatesFromState,
  (configurations, events, alias, farmLocation) => {
    const configuration = configurations.find(
      (dc) => dc.codaDeviceAlias === alias,
    )
    const event = configuration ? events[configuration.deviceId] : undefined
    if (configuration) {
      return makeDeviceSummary({ configuration, event, farmLocation })
    }
    return undefined
  },
)(getAliasFromProps)
/**
 * Get alias, deviceId, installation type and name for a device configuration if
 * it exists in state
 *
 * @param id
 */
export const getDeviceSummaryByDeviceIdFromState: (
  state: RootState,
  deviceId: string | undefined,
) => DeviceSummary | undefined = createCachedSelector(
  Models.deviceConfiguration.selectById,
  Models.deviceEventLast.selectById,
  getActiveFarmCoordinatesFromState,
  (configuration, event, farmLocation): DeviceSummary | undefined => {
    if (configuration) {
      return makeDeviceSummary({
        configuration,
        event,
        farmLocation,
      })
    }
    return undefined
  },
)(makeDeviceSummaryCacheKey)
export interface DeviceSummaryProviderOwnProps extends AcceptsChildren {
  codaDeviceAlias: string | undefined
  deviceId: string | undefined
}

export const getDeviceAnalyticsDateValue = {
  // MAX DATE
  getMax: (state: RootState) => state.deviceProfile.analytics.dateMsMax,

  // MIN DATE
  getMin: (state: RootState) => state.deviceProfile.analytics.dateMsMin,
}

export const getMissingFieldsForActiveRuns = createSelector(
  [
    Models.reelRunsActive.selectAll,
    Models.field.selectEntities,
    Models.deviceConfiguration.selectEntities,
    Models.deviceEventLast.selectEntities,
    getActiveFarmCoordinatesFromState,
  ],
  (runs, fields, configurations, events) =>
    runs
      .filter(
        /**
         * Only include runs with no field data associated
         */
        ({ fieldId }) => {
          // The run has no associated field - user should draw one
          if (
            fieldId === null ||
            typeof fieldId === "undefined" ||
            !fields[fieldId]
          ) {
            return true
          }
          return false
        },
      )
      .map(
        /**
         * Add device name to display in list, and event location to focus map
         */
        (runData) => {
          const deviceId = runData.deviceId ?? ""
          const { codaDeviceAlias, deviceName } = configurations[deviceId] ?? {}
          const { location } = events[deviceId]?.gps ?? {}

          return {
            ...runData,
            deviceName: deviceName ?? codaDeviceAlias,
            location,
          }
        },
      ),
)

export function getUserFarmErrorCodeFromState(state: RootState) {
  return state.userFarms.errorCode
}

/**
 * We should never attempt to seed the database in production or beta
 * (demo mode works differently)
 */
export function canSeedTestDatabase(
  targetDatabaseName: TargetDatabaseName | null | undefined,
) {
  if (!targetDatabaseName) {
    return false
  }
  if (targetDatabaseName === "PROD") {
    return false
  }
  if (targetDatabaseName === "BETA") {
    return false
  }
  return true
}

export function getUserNameGivenFromState(state: RootState) {
  return state.auth.userNameGiven
}
export function getUserNameFamilyFromState(state: RootState) {
  return state.auth.userNameFamily
}

/**
 * Find the first reel run that has a device id matching the provided device id
 *
 * @param {RootState} state
 * @param {string|undefined} deviceId
 * @returns the id of the run
 */
export const getReelRunByDeviceId: (
  state: RootState,
  deviceId: string | undefined,
) => Models.ReelRun | undefined = createSelector(
  Models.reelRunsActive.selectAll,
  (_state: RootState, id: string | undefined) => id,
  (reelRuns, deviceId) =>
    reelRuns.find((reelRun) => {
      return reelRun.deviceId === deviceId
    }),
)

/**
 * Find the first reel run that has a device id matching the provided device id
 *
 * @param {RootState} state
 * @param {string|undefined} deviceId
 * @returns the id of the run
 */
export const getReelRunIdByDeviceId: (
  state: RootState,
  deviceId: string | undefined,
) => number | undefined = createSelector(
  getReelRunByDeviceId,
  (run) => run?.reelRunId,
)

export function findFieldForDevice({
  event,
  fields,
}: {
  event: Models.DeviceEvent | null | undefined
  fields: Models.FarmField[]
}) {
  let result: Models.FarmField | undefined
  if (event) {
    const coordinates = event.gps?.location?.coordinates
    if (coordinates) {
      result = fields.find((field): boolean => {
        try {
          return turf.inside(
            turf.point(coordinates),
            turf.polygon(field.polygon.coordinates),
          )
        } catch (error) {
          return false
        }
      })
    }
  }
  return result ?? null
}
