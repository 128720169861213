import { CloseIconButton, TitleWithIcon } from "@fhq/app/components"

import { Help } from "./Help"

import type { HelpContentStatic, TitleWithIconProps } from "@fhq/app/components"
export interface PageHeaderProps extends TitleWithIconProps {
  helpContent?: HelpContentStatic | undefined
  onClose?: () => void
}

/**
 * PageHeader
 */
export function PageHeader({
  IconComponent,
  helpContent,
  onClose,
  titleText,
  ...rest
}: PageHeaderProps): React.JSX.Element {
  return (
    <TitleWithIcon
      IconComponent={IconComponent}
      id="page-header"
      titleText={titleText}
      helpElement={
        helpContent ? (
          <Help
            bodyElement={helpContent.bodyElement}
            subject={helpContent.subject}
            titleElement={helpContent.titleElement}
          />
        ) : null
      }
      {...rest}
      rightElement={
        onClose ? (
          <CloseIconButton
            id="close-page-btn"
            variant="text"
            onPress={onClose}
          />
        ) : undefined
      }
    />
  )
}
