/* eslint-disable @typescript-eslint/no-non-null-assertion */
import "@fhq/app/translations"
import "react-datepicker/dist/react-datepicker.css"
import "react-medium-image-zoom/dist/styles.css"
import "./index.css"

import React from "react"
import ReactDOM from "react-dom/client"
import { SafeAreaProvider } from "react-native-safe-area-context"
import { Provider } from "react-redux"

import {
  AppIsLoading,
  EnvironmentVariables,
  Geo,
  GOOGLE_MAPS_API_KEY,
  Loader,
} from "@fhq/app"
import { AnalyticsProvider } from "@fhq/app/Analytics"
import { ThemeProvider, ToastProvider } from "@fhq/app/components"
import { LoadScriptNext } from "@react-google-maps/api"
import { ErrorBoundary } from "@sentry/react"

import { SEGMENT_CLIENT } from "./pages/base/Analytics"
import { Environment } from "./pages/base/base"
import { STORE } from "./pages/base/store"
import { WebApp } from "./WebApp"

// TODO: Reimplement strict mode:
// https://github.com/GeekyAnts/NativeBase/issues/5272
ReactDOM.createRoot(document.getElementById("root")!).render(
  <Provider store={STORE}>
    <AnalyticsProvider client={SEGMENT_CLIENT}>
      <SafeAreaProvider>
        <ErrorBoundary showDialog>
          <EnvironmentVariables.Provider {...Environment}>
            <ToastProvider>
              <ThemeProvider>
                <React.Suspense fallback={<Loader />}>
                  <LoadScriptNext
                    googleMapsApiKey={GOOGLE_MAPS_API_KEY}
                    libraries={Geo.GOOGLE_MAPS_LIBRARIES}
                    loadingElement={<AppIsLoading />}
                  >
                    <WebApp />
                  </LoadScriptNext>
                </React.Suspense>
              </ThemeProvider>
            </ToastProvider>
          </EnvironmentVariables.Provider>
        </ErrorBoundary>
      </SafeAreaProvider>
    </AnalyticsProvider>
  </Provider>,
)
