import React from "react"
import { Image as ImageBase } from "react-native"

import type { Overwrite } from "utility-types"

import type {
  ImageProps as ImageBaseProps,
  ImageSourcePropType,
} from "react-native"

export type ImageProps = Overwrite<
  ImageBaseProps,
  {
    source: ImageSourcePropType | string
  }
>

/**
 * Encapsulated `Image` component
 */
export function Image({ source, ...props }: ImageProps) {
  return (
    <ImageBase
      source={typeof source === "string" ? { uri: source } : source}
      {...props}
    />
  )
}
