import React from "react"
import { useTranslation } from "react-i18next"

import { ActionButtons } from "./ActionButtons"
import {
  AlertDialog,
  AlertDialogBodyText,
  AlertDialogScrollView,
  Box,
  Divider,
  FlatList,
} from "./components"
import { Heading } from "./components/Heading"
import { AppText } from "./components/Text"
import { SetPermissionListItem } from "./PermissionListItem"
import { DEVICE_PERMISSIONS, FARM_PERMISSIONS } from "./permissions"
import i18n from "./translations/i18n"

import type { FlatListProps } from "./components"
import type { FarmUserPermissionName } from "./permissions"

export function useFarmUserPermissionsHelpDialog() {
  const [helpKey, setHelpKey] = React.useState<FarmUserPermissionName>()
  return {
    helpKey,
    onCloseHelpDialog: () => setHelpKey(undefined),
    setHelpKey,
  }
}

export function FarmUserPermissionHelpDialog({
  helpKey,
  onClose,
}: {
  helpKey: FarmUserPermissionName | undefined
  onClose: () => void
}) {
  const { t } = useTranslation("farmUserPermissions")
  let bodyText: string | undefined
  if (helpKey) {
    bodyText = t(`${helpKey}.extendedDescription`)
    return (
      <AlertDialog
        IconComponent="Permissions"
        isOpen={Boolean(helpKey)}
        titleElement={t("aboutFarmUserPermissions")}
        onClose={onClose}
      >
        <AlertDialogScrollView>
          <AlertDialogBodyText>
            {t("farmUserPermissionsAllowYouTo")}
          </AlertDialogBodyText>
          <Divider my="$4" />
          <AppText colorScheme="secondary">{t(`thisPermission`)}</AppText>
          <Heading variant="h4">{t(`${helpKey}.displayName`)}</Heading>
          <Box mt="$2" />
          <AlertDialogBodyText>{bodyText}</AlertDialogBodyText>
        </AlertDialogScrollView>
      </AlertDialog>
    )
  }
  return null
}
interface PermissionsFormProps<K extends FarmUserPermissionName>
  extends Pick<FlatListProps<K>, "contentContainerStyle"> {
  isLoading: boolean
  onPressHelp: (item: K) => void
  onSubmit: (values: {
    [key in K]: boolean
  }) => void
  cancelText?: string
  defaultValues?: {
    [key in K]?: boolean | null | undefined
  }
  onCancel?: () => void
  subheaderText?: string
  submitText?: string
}

const spacing = "$4"

const ItemSeparatorComponent = () => <Divider my="$2" />
export function createPermissionsForm<K extends FarmUserPermissionName>({
  keys,
  titleText,
}: {
  keys: K[]
  titleText?: string
}) {
  return function Form({
    cancelText,
    defaultValues,
    isLoading,
    onCancel,
    onPressHelp,
    onSubmit,
    subheaderText,
    submitText,
    ...rest
  }: PermissionsFormProps<K>) {
    type ValuesType = {
      [key in K]: boolean
    }

    const [values, setValues] = React.useState<ValuesType>(() => {
      return keys.reduce((acc, key) => {
        const defaultValue = defaultValues?.[key] ?? false
        return {
          ...acc,
          [key]: defaultValue,
        }
      }, {} as ValuesType)
    })

    return (
      <FlatList
        {...rest}
        ItemSeparatorComponent={ItemSeparatorComponent}
        data={keys}
        ListFooterComponent={
          <ActionButtons
            cancelText={cancelText}
            isLoading={isLoading}
            mt={spacing}
            orientation="horizontal"
            submitText={submitText}
            onPressCancel={onCancel}
            onPressSubmit={() => onSubmit(values)}
          />
        }
        ListHeaderComponent={
          typeof titleText === "string" ? (
            <Box mb={spacing}>
              <Heading variant="h4">{titleText}</Heading>
              <AppText colorScheme="secondary">{subheaderText}</AppText>
            </Box>
          ) : null
        }
        renderItem={({ item }) => {
          return (
            <SetPermissionListItem
              isEnabled={values[item]}
              name={item}
              onPressHelp={() => onPressHelp(item)}
              onValueChange={(nextValue: boolean) => {
                setValues((previous) => {
                  return { ...previous, [item]: nextValue }
                })
              }}
            />
          )
        }}
      />
    )
  }
}
export const DevicePermissionsForm = createPermissionsForm({
  keys: DEVICE_PERMISSIONS.getKeys(),
  titleText: i18n.t("farmUserPermissions:devicePermissionsTitle"),
})

export const FarmPermissionsForm = createPermissionsForm({
  keys: FARM_PERMISSIONS.getKeys(),
  titleText: i18n.t("farmUserPermissions:farmPermissionsTitle"),
})
