import convert from "convert-units"
import React from "react"
import { Controller } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { StyleSheet, View } from "react-native"
import { useNavigate } from "react-router"

import {
  ActionButtons,
  CreateDeviceConfigurationContext,
  DeviceNameText,
  DeviceSummaryContext,
  Geo,
  getActiveFarmLatLngFromState,
  getDeviceStatusDataByDeviceIdFromState,
  Models,
  SetCenterPivotPath,
  useMeasurementPreference,
  useRootSelector,
} from "@fhq/app"
import { makeSectorFinder } from "@fhq/app/CenterPivotPathVisualization"
import {
  Box,
  Divider,
  Heading,
  IconButton,
  Paper,
  Row,
} from "@fhq/app/components"
import { COLORS } from "@fhq/app/components/theme"
import { useFieldColors } from "@fhq/app/useFieldColors"
import {
  DrawingManager,
  GoogleMap,
  Marker,
  Polygon,
} from "@react-google-maps/api"

import { BASE_SWATH_OPTIONS } from "./base/base"
import { DeviceMarker } from "./base/DeviceMarker"
import { getMapTypeValueFromState } from "./base/selectors"

import type { DeviceIdProps } from "@fhq/app"
import type { GoogleMapProps } from "@react-google-maps/api"
const getSector = makeSectorFinder((pt) => {
  return Geo.point(pt)?.toGmaps()
})

const styles = StyleSheet.create({
  root: {
    flex: 1,
  },
})

const MAP_CONTAINER_STYLE: GoogleMapProps["mapContainerStyle"] = {
  flex: 2,
}

function Main({ deviceId }: DeviceIdProps) {
  const { t } = useTranslation("devices")
  const fieldColor = useFieldColors()

  const mapRef = React.useRef<google.maps.Map>()
  const getMapRef: GoogleMapProps["onLoad"] = (map) => {
    mapRef.current = map
  }

  const deviceStatus = useRootSelector((state) => {
    return getDeviceStatusDataByDeviceIdFromState(state, deviceId)
  })

  const { form, isLoading, onCancel, onSubmit } =
    CreateDeviceConfigurationContext.useContext()

  const [azimuthDegreesStart, azimuthDegreesEnd] =
    form.watch("pivotPathStopsHeadingsDegrees") ?? []

  const center = form.watch("pivotCenterGpsLocation")
  const centerLatLng = Geo.point(center)?.toGmaps()

  // The radius in the form is stored according to user input, so we may need
  // to convert it to meters for the sector path calculation
  const measurementPreference = useMeasurementPreference()
  let radiusMeters = form.watch("pivotRadiusMeters")
  if (measurementPreference === "us" && typeof radiusMeters === "number") {
    radiusMeters = convert(radiusMeters).from("ft").to("m")
  }
  // Calculate the sector path based on the form values
  const sectorPath = getSector({
    azimuthEnd: azimuthDegreesEnd ?? 0,
    azimuthStart: azimuthDegreesStart ?? 0,
    centerCoordinates: center?.coordinates,
    direction: "pivot_clockwise",
    radiusMeters,
  })

  const activeFarmLocation = useRootSelector(
    (state) => getActiveFarmLatLngFromState(state)?.web,
  )

  const mapOptions: GoogleMapProps["options"] = {
    disableDefaultUI: true,
    mapTypeId: useRootSelector(getMapTypeValueFromState),
  }

  const handleZoomToDevice = () => {
    if (deviceStatus?.gpsLocation?.web) {
      mapRef.current?.panTo(deviceStatus.gpsLocation.web)
    }
  }

  return (
    <View style={styles.root}>
      <GoogleMap
        center={deviceStatus?.gpsLocation?.web ?? activeFarmLocation}
        mapContainerStyle={MAP_CONTAINER_STYLE}
        options={mapOptions}
        zoom={15}
        onLoad={getMapRef}
      >
        <Polygon
          path={sectorPath}
          options={{
            ...BASE_SWATH_OPTIONS,
            strokeColor: fieldColor.strokeColor,
          }}
        />
        {deviceStatus ? (
          <DeviceMarker
            isClickable={false}
            showCenterPivotOutline={false}
            showDeviceActivityVisualizations={false}
            showParkingSpots={false}
            {...deviceStatus}
          />
        ) : null}
        {centerLatLng ? (
          <Marker
            position={centerLatLng}
            label={{
              color: COLORS.$white,
              fontFamily: "Poppins",
              text: t("setCenterPivotPathCenterMarkerLabel"),
            }}
          />
        ) : null}
        <Controller
          control={form.control}
          name="pivotCenterGpsLocation"
          render={({ field }) => {
            return (
              <DrawingManager
                drawingMode={"marker" as google.maps.drawing.OverlayType.MARKER}
                options={{ drawingControl: false }}
                onMarkerComplete={(marker) => {
                  marker.setMap(null)
                  const latLng = Geo.point(
                    marker.getPosition()?.toJSON(),
                  )?.toJson()
                  field.onChange(latLng)
                }}
              />
            )
          }}
        />
      </GoogleMap>
      <Paper p="$4" pb="$8">
        <Box m="auto" maxW="$xl" w="$full">
          <Row justifyContent="space-between">
            <Heading colorScheme="secondary" variant="h5">
              {t("setCenterPivotPathTitle")}
            </Heading>
            <Row>
              <Box mr="$4">
                <IconButton
                  IconComponent="Target"
                  isDisabled={!deviceStatus?.gpsLocation?.web}
                  size="sm"
                  onPress={handleZoomToDevice}
                />
              </Box>
              {deviceStatus ? (
                <DeviceNameText
                  codaDeviceAlias={deviceStatus.codaDeviceAlias}
                  deviceName={deviceStatus.deviceName}
                  isUpdating={deviceStatus.isUpdating}
                />
              ) : null}
            </Row>
          </Row>
          <Divider my="$2" />
          <Box px="$2">
            <SetCenterPivotPath.RadiusInput />
          </Box>
          <Row>
            <Box flexGrow={1} minW="$48" px="$2">
              <SetCenterPivotPath.AzimuthStartInput />
            </Box>
            <Box flexGrow={1} minW="$48" px="$2">
              <SetCenterPivotPath.AzimuthEndInput />
            </Box>
          </Row>
          <ActionButtons
            isLoading={isLoading}
            mt="$4"
            onPressCancel={onCancel}
            onPressSubmit={onSubmit}
          />
        </Box>
      </Paper>
    </View>
  )
}

export function SetCenterPivotPathPage() {
  const navigate = useNavigate()

  const { deviceId } = DeviceSummaryContext.useContext()

  const device = useRootSelector((state) => {
    return Models.deviceConfiguration.selectById(state, deviceId)
  })

  if (!device) {
    return null
  }

  return (
    <CreateDeviceConfigurationContext.Provider
      codaDeviceAlias={device.codaDeviceAlias}
      currentConfigurationData={{ ...device }}
      deviceId={device.deviceId}
      deviceInstallationType={device.deviceInstallationType}
      deviceName={device.deviceName}
      hardwareGeneration={device.hardwareGeneration}
      onCancel={() => navigate(-1)}
      onClearInstallationType={null}
      onSuccess={() => navigate("..")}
    >
      <Main deviceId={device.deviceId} />
    </CreateDeviceConfigurationContext.Provider>
  )
}
