import React from "react"
import { StyleSheet } from "react-native"
import { useStyle } from "react-native-style-utilities"

import { COLORS } from "./theme/colors"
import { getFontName } from "./theme/fonts"
import { SIZES } from "./theme/sizes"
import { SPACING } from "./theme/spacing"
import { useIsDarkMode } from "./ThemeProvider"
import { View } from "./View"

import type { ViewProps } from "./View"
import type { RadioOption, RadioProps } from "./Radio"

const styles = StyleSheet.create({
  buttonWithSpacing: {
    marginLeft: SPACING.$4,
  },
  input: {
    height: SIZES.$6,
    width: SIZES.$6,
  },
  label: {
    fontFamily: getFontName("OpenSans_400Regular"),
    margin: SPACING.$1,
  },
  labelContainer: {
    alignItems: "center",
    display: "flex",
    flexDirection: "row",
  },
  root: {
    display: "flex",
  },
  rootHorizontal: {
    alignItems: "center",
    flexDirection: "row",
    flexWrap: "nowrap",
  },
})

const DEFAULT_RADIO_SIZE = 28

function RenderItem({
  buttonSize,
  id,
  isChecked,
  label,
  onChange,
  style,
  value,
}: Pick<ViewProps, "style"> &
  RadioOption & {
    isChecked: boolean
    onChange: (value: string) => void
    buttonSize?: number
  }) {
  const isDark = useIsDarkMode()
  return (
    <View key={value} id={id} style={[styles.labelContainer, style]}>
      <input
        checked={isChecked}
        id={label}
        type="radio"
        value={value}
        style={{
          ...(typeof buttonSize === "number"
            ? {
                ...styles.input,
                height: buttonSize,
                width: buttonSize,
              }
            : styles.input),
          // TODO: This doesn't work
          // borderColor: invalid ? COLORS.$invalid : undefined,
          // borderWidth: 2,
        }}
        onChange={(e) => onChange(e.target.value)}
      />
      <label
        htmlFor={label}
        style={{
          ...styles.label,
          color: isDark ? COLORS.$textLight.primary : COLORS.$textDark.primary,
        }}
      >
        {label}
      </label>
    </View>
  )
}
export function Radio({
  buttonSize,
  onChange,
  options,
  orientation = "vertical",
  selectedValue,
  style,
  ...rest
}: RadioProps) {
  if (typeof buttonSize === "undefined") {
    buttonSize = DEFAULT_RADIO_SIZE
  }

  return (
    <View
      {...rest}
      accessibilityRole="radiogroup"
      style={useStyle(
        () => [
          styles.root,
          orientation === "horizontal" ? styles.rootHorizontal : undefined,
          style,
        ],
        [orientation, style],
      )}
    >
      {options.map((option, index) => {
        const props = {
          buttonSize,
          isChecked: option.value === selectedValue,
          onChange,
          ...option,
        }

        if (index > 0 && orientation === "horizontal") {
          return (
            <RenderItem
              {...props}
              key={option.value}
              style={styles.buttonWithSpacing}
            />
          )
        }

        return <RenderItem {...props} key={option.value} />
      })}
    </View>
  )
}
