import React from "react"

export function useOpenState() {
  const [isOpen, setIsOpen] = React.useState(false)
  return {
    isOpen,
    onClose: () => setIsOpen(false),
    onOpen: () => setIsOpen(true),
  }
}
