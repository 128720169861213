import React from "react"
import { useTranslation } from "react-i18next"

import { ActionButtons } from "./ActionButtons"
import {
  AlertBodyText,
  AlertCard,
  AppIcons,
  AppText,
  Box,
  Checkbox,
  CloseButton,
  Column,
  Divider,
  Row,
  TitleWithIcon,
  useToasts,
} from "./components"
import {
  callNamedDeviceActionAsync,
  loadDeviceActionsAsync,
} from "./farmhq-api"
import { useIsPending } from "./requests.reducer"
import { formatInstallationType } from "./sensor-configurations"
import i18n from "./translations/i18n"
import { isValidNumber } from "./type-guards"
import { useBackendRequest } from "./useBackendRequest"

import type { BoxProps, ButtonProps, HelpContentStatic } from "./components"
import type { RowProps } from "./components/Row"
import type { DeviceSummary } from "./device-configurations.reducer"
import type { InstallationType } from "./sensor-configurations"

import type { NamedDeviceAction } from "./models"
export const HELP_CONTENT: HelpContentStatic = {
  bodyElement: i18n.t("deviceActions:controlPanelHelpContent"),
  subject: "device_control_panel",
  titleElement: i18n.t("deviceActions:aboutDeviceControls"),
}
/**
 * Header for device commands options
 */
export function Header({
  buttonSize = "sm",
  helpElement,
  onClose,
  ...rest
}: RowProps & {
  buttonSize?: ButtonProps["size"]
  helpElement?: React.JSX.Element
  onClose?: () => void
}): React.JSX.Element {
  const { t } = useTranslation("deviceActions")
  return (
    <Row flexWrap="nowrap" {...rest}>
      <TitleWithIcon
        IconComponent={AppIcons.DeviceCommandsRemote}
        titleText={t("controls")}
        _headingProps={{
          colorScheme: "secondary",
        }}
      />
      <Row ml="auto">
        {helpElement ? <Box>{helpElement}</Box> : null}
        {onClose ? (
          <Box ml="$2">
            <CloseButton
              id="switch-controls-close-btn"
              size={buttonSize}
              onPress={onClose}
            />
          </Box>
        ) : null}
      </Row>
    </Row>
  )
}
/**
 * This loads the actions for a given device
 */
export function useLoadDeviceActions({
  deviceId,
}: Pick<DeviceSummary, "deviceId">) {
  const [namedDeviceActions, setNamedDeviceActions] = React.useState<
    NamedDeviceAction[]
  >([])

  const { handleError, isLoading, sendRequest } = useBackendRequest(
    loadDeviceActionsAsync,
  )
  const handleFetch = React.useCallback(() => {
    if (typeof deviceId === "string" && deviceId.length > 0) {
      sendRequest({ deviceId })
        .then((response) => {
          return setNamedDeviceActions(response.items)
        })
        .catch(handleError)
    }
  }, [deviceId, handleError, sendRequest])

  return {
    handleFetch,
    isLoading,
    namedDeviceActions,
  }
}

export function useSubmitCommand({
  deviceId,
  onSuccess,
  selectedActionId,
}: {
  deviceId: string
  onSuccess: () => void
  selectedActionId: number | null | undefined
}) {
  const { handleError, isLoading, sendRequest, toasts } = useBackendRequest(
    callNamedDeviceActionAsync,
  )
  return {
    isLoading,
    onSubmit: () => {
      if (!isValidNumber(selectedActionId)) {
        throw new TypeError(`Selected action id is undefined.`)
      }
      sendRequest({ actionId: selectedActionId, arguments: null, deviceId })
        .then(() => {
          onSuccess()
          return toasts.success()
        })
        .catch((error) => {
          handleError(error, { toastMessage: "default" })
        })
    },
  }
}

export function useSwitchControlSubmit({
  deviceId,
  deviceName,
  namedDeviceActionId,
  onClosed,
}: Pick<DeviceSummary, "deviceId" | "deviceName"> & {
  namedDeviceActionId: number | undefined
  onClosed?: () => void
}) {
  const [loadingId, setLoadingId] = React.useState<number>()
  const { handleError, isLoading, sendRequest } = useBackendRequest(
    callNamedDeviceActionAsync,
  )
  const toast = useToasts()

  return {
    isLoading,
    loadingId,
    onSubmit: () => {
      if (typeof namedDeviceActionId === "undefined") {
        throw new TypeError(`No action id`)
      }
      setLoadingId(namedDeviceActionId)
      sendRequest({
        actionId: namedDeviceActionId,
        arguments: null,
        deviceId,
      })
        .then(() => {
          if (onClosed) {
            onClosed()
          }
          return toast.success(
            i18n.t(
              "deviceActions:confirmDeviceActionSuccessToastWithDeviceName",
              {
                deviceName,
              },
            ),
          )
        })
        .catch((error) => handleError(error, { toastMessage: "default" }))
        .finally(() => setLoadingId(undefined))
    },
  }
}

function TextRow({
  labelText,
  valueText,
}: {
  labelText: string
  valueText: string
}): React.JSX.Element {
  // const variant = "h4"
  return (
    <Row alignItems="center" flexWrap="nowrap" justifyContent="space-between">
      <AppText
        colorScheme="secondary"
        textTransform="capitalize"
        // variant={variant}
      >
        {labelText}
      </AppText>
      <AppText>{valueText}</AppText>
    </Row>
  )
}
/**
 * Header for the confirmation screen
 */
export function ConfirmationHeader({
  deviceInstallationType,
  deviceName,
  displayName,
}: Pick<NamedDeviceAction, "displayName"> & {
  deviceInstallationType: InstallationType | undefined
  deviceName: string
}): React.JSX.Element {
  return (
    <Column>
      <TextRow labelText={`${i18n.t("device")}: `} valueText={deviceName} />
      {typeof deviceInstallationType === "string" ? (
        <React.Fragment>
          <Divider my="$2" />
          <TextRow
            labelText={`${i18n.t("deviceInstallationType")}: `}
            valueText={formatInstallationType(deviceInstallationType)}
          />
        </React.Fragment>
      ) : null}
      {typeof displayName === "string" ? (
        <React.Fragment>
          <Divider my="$2" />
          <TextRow
            labelText={`${i18n.t("action")}: `}
            valueText={displayName}
          />
        </React.Fragment>
      ) : null}
    </Column>
  )
}

/**
 * Warn user of the seriousness of using switch controls and inability
 * to cancel the action once submitted.
 */
export function ConfirmationWarning(): React.JSX.Element {
  return (
    <AlertCard
      IconComponent="Warning"
      py="$1"
      severity="warning"
      titleText={i18n.t("warning")}
    >
      <AlertBodyText>
        {i18n.t("deviceActions:confirmDeviceActionWarningDescription")}
      </AlertBodyText>
      <Divider my="$2" />
      <AlertBodyText fontFamily="OpenSans_700Bold_Italic">
        {i18n.t("deviceActions:confirmDeviceActionCannotCancelWarning")}
      </AlertBodyText>
    </AlertCard>
  )
}

/**
 * Display a checkbox to get user's consent and cancel/submit buttons
 */
export function ConfirmationActions({
  onCancel,
  onSubmit,
  ...rest
}: BoxProps & {
  onCancel: () => void
  onSubmit: () => unknown
}): React.JSX.Element {
  const [isChecked, setIsChecked] = React.useState(false)
  const isLoading = useIsPending("CallNamedDeviceAction")

  return (
    <Box {...rest}>
      <Row>
        <Box flex={1}>
          <AppText fontSize="$md" fontWeight="bold">
            {i18n.t("deviceActions:confirmDeviceActionIAcceptText")}
          </AppText>
        </Box>
        <Checkbox
          isChecked={isChecked}
          size="lg"
          onChange={() => setIsChecked(!isChecked)}
        />
      </Row>
      <ActionButtons
        isLoading={isLoading}
        isSubmitDisabled={!isChecked}
        mt="$4"
        onPressCancel={onCancel}
        onPressSubmit={onSubmit}
      />
    </Box>
  )
}
