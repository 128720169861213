import { Dimensions, Platform } from "react-native"

import { createSlice } from "@reduxjs/toolkit"

import { signOutAsync } from "./auth.reducer"
import { getBreakpointForWidth } from "./components/theme/breakpoints"
import {
  loadActiveUserAsync,
  submitAppFeatureFeedbackAsync,
} from "./farmhq-api"
import { useRootSelector } from "./useRootSelector"

import type { BreakpointToken } from "./components/theme/breakpoints"
import type { RootState } from "./root.reducer"

import type { PayloadAction, Reducer } from "@reduxjs/toolkit"
interface AppState {
  breakpointKey: BreakpointToken
  isAppLiked?: boolean | null
  isShareAvailable?: boolean
  isTimedOut?: boolean
}

const initialState: AppState = {
  breakpointKey: getBreakpointForWidth(Dimensions.get("window").width),
}

const slice = createSlice({
  extraReducers: (builder) => {
    return builder
      .addCase(signOutAsync.fulfilled, (state) => {
        state.isTimedOut = false
      })
      .addCase(submitAppFeatureFeedbackAsync.pending, (state, action) => {
        if (typeof action.meta.arg.isLiked === "boolean") {
          state.isAppLiked = action.meta.arg.isLiked
        }
      })
      .addCase(loadActiveUserAsync.fulfilled, (state, { payload }) => {
        state.isAppLiked = payload.appFeatureFeedback.find(
          (feature) => feature.featureName === "app",
        )?.isLiked
      })
  },
  initialState,
  name: "appState",
  reducers: {
    hideTimedOutScreen: (state) => {
      state.isTimedOut = false
    },
    onShareAvailable: (state) => {
      state.isShareAvailable = true
    },
    setBreakpointKey: (state, action: PayloadAction<BreakpointToken>) => {
      state.breakpointKey = action.payload
    },
  },
})

export const { hideTimedOutScreen, onShareAvailable, setBreakpointKey } =
  slice.actions

const appState: Reducer<typeof initialState> = slice.reducer
export default appState

export function getCurrentBreakpointKey(state: RootState) {
  return state.appState.breakpointKey
}
export function useScreenSizeBreakpoint() {
  return useRootSelector(getCurrentBreakpointKey)
}
export function useIsSmallScreen() {
  return useScreenSizeBreakpoint() === "xs"
}

export function getIsShareAvailable(state: RootState) {
  if (Platform.OS === "web") {
    return state.appState.isShareAvailable === true
  }
  return true
}
