import React from "react"
import { useTranslation } from "react-i18next"
import { ActivityIndicator, Pressable, StyleSheet, View } from "react-native"
import {
  VictoryAxis,
  VictoryChart,
  VictoryLegend,
  VictoryLine,
  VictoryVoronoiContainer,
} from "victory-native"

import { selectReelRunHistorical } from "./actions"
import {
  setFieldProfileAutoZoom,
  setRunHeatmapContrast,
  setShowRunOutlines,
} from "./async-storage"
import {
  AppIcons,
  AppText,
  Box,
  Button,
  CloseIconButton,
  CycleButton,
  Divider,
  Heading,
  IconButton,
  Paper,
  renderIconFromIconProp,
  Row,
  Switch,
} from "./components"
import {
  ListItemTextPrimary,
  ListItemTextSecondary,
} from "./components/ListItem"
import { ListNumber } from "./components/ListNumber"
import { COLORS, SPACING, Z_INDEX } from "./components/theme"
import {
  getHistoricalReelRunIndexByIdFromState,
  getReelRunGraphDataByIdFromState,
  getSelectedHistoricalRunIdFromState,
  getTotalApplicationForSelectedField,
  toggleUiSettingsMenu,
} from "./field-irrigation-history.reducer"
import { EmptyGraph, useTimeseriesGraph } from "./GraphComponents"
import { logger } from "./logger"
import * as Models from "./models"
import { NoListItems } from "./NoListItems"
import {
  getIsFieldIrrigationAutozoomEnabled,
  getShouldBoostHeatmapContrastFromState,
  getSwathGeometryCachedByReelRunPropertiesFromState,
} from "./reel-runs.reducer"
import { useIsPending } from "./requests.reducer"
import { getAreReelRunOutlinesVisibleFromState } from "./selectors"
import { useSensorUnitLabel } from "./sensor-formatting"
import { REEL_RUN_GRAPH_KEYS } from "./sensors"
import { isNullish, isValidNumber } from "./type-guards"
import { useRootDispatch } from "./useRootDispatch"
import { useRootSelector, useShallowEqualSelector } from "./useRootSelector"

import type { BlockProps } from "victory"
import type {
  AcceptsChildren,
  BreakpointInputValue,
  ButtonProps,
  IconButtonProps,
  IconButtonStyleProps,
  IconProp,
  NoChildren,
  PaperProps,
  RowProps,
  SwitchProps,
} from "./components"
import type { calculateMetricsForSelectedRun } from "./field-irrigation-history.reducer"

import type { ReelRunVariant } from "./reel-runs.reducer"
import type { ReelRunGraphKey } from "./sensors"

import type { MinDateMaxDate, SelectReelRunHandler, StateSetter } from "./types"
const styles = StyleSheet.create({
  mapTitle: {
    flexDirection: "row",
    flexWrap: "nowrap",
    justifyContent: "space-between",
    left: 0,
    paddingHorizontal: SPACING.$2,
    paddingVertical: SPACING.$1,
    position: "absolute",
    right: 0,
    top: 0,
  },
  uiSettings: {
    paddingLeft: SPACING.$1,
    paddingVertical: SPACING.$1,
    zIndex: Z_INDEX.fab + 1,
  },
})

export function CycleButtons(): React.JSX.Element {
  const { t } = useTranslation()
  const dispatch = useRootDispatch()
  const { nextRunId, previousRunId } = useShallowEqualSelector((state) => {
    const runIds = Models.fieldProfileReelRuns.selectIds(state)
    const selectedRunId = getSelectedHistoricalRunIdFromState(state)
    const selectedRunIndex =
      getHistoricalReelRunIndexByIdFromState(state, selectedRunId ?? 0) ?? 0

    return {
      nextRunId: runIds[selectedRunIndex + 1],
      previousRunId: runIds[selectedRunIndex - 1],
    }
  })
  const isNextValid = isValidNumber(nextRunId)
  const isPreviousValid = isValidNumber(previousRunId)
  return (
    <Row ml="auto">
      <Button
        isDisabled={!isPreviousValid}
        size="sm"
        style={{ marginRight: SPACING.$2 }}
        text={t("previous", { ns: "common" })}
        onPress={() => {
          if (isPreviousValid) {
            dispatch(selectReelRunHistorical({ reelRunId: previousRunId }))
          }
        }}
      />
      <Button
        isDisabled={!isNextValid}
        size="sm"
        text={t("next", { ns: "common" })}
        onPress={() => {
          if (isNextValid) {
            dispatch(selectReelRunHistorical({ reelRunId: nextRunId }))
          }
        }}
      />
    </Row>
  )
}
export function RenderRunsListItem({
  applicationAcreInchesTotal,
  deviceName,
  index,
  reelRunId,
  textPrimary,
}: {
  applicationAcreInchesTotal: number | null | undefined
  deviceName: string
  index: number
  reelRunId: number
  textPrimary: string | undefined
}) {
  const { t } = useTranslation()
  const dispatch = useRootDispatch()
  return (
    <Pressable onPress={() => dispatch(selectReelRunHistorical({ reelRunId }))}>
      <Row alignItems="center" py="$1">
        <Box mr="$2">
          <ListNumber value={index} />
        </Box>
        <Box flex={1}>
          <ListItemTextPrimary>{textPrimary}</ListItemTextPrimary>
          <Row>
            <ListItemTextSecondary isTruncated fontSize="$sm">
              {deviceName}
            </ListItemTextSecondary>
            {isValidNumber(applicationAcreInchesTotal) ? (
              <Box ml="$3">
                <ListItemTextSecondary fontSize="$sm">
                  {`${applicationAcreInchesTotal} ${t("acreInches")}`}
                </ListItemTextSecondary>
              </Box>
            ) : null}
          </Row>
        </Box>
        <AppIcons.ListItemEnd />
      </Row>
    </Pressable>
  )
}
export function RunsListEmptyComponent() {
  const isLoading = useIsPending("LoadFieldIrrigationHistory")
  const { t } = useTranslation("fieldIrrigationHistory")

  if (isLoading) {
    return <ActivityIndicator style={{ flex: 1, margin: "auto" }} />
  }
  return (
    <Box mt="$4" zIndex={-1}>
      <NoListItems message={t("noReelRuns")} severity="info" />
    </Box>
  )
}

export function RunFooter(props: RowProps): React.JSX.Element {
  const itemCount = useRootSelector(Models.fieldProfileReelRuns.selectTotal)
  const totalApplication = useRootSelector(getTotalApplicationForSelectedField)

  const { t } = useTranslation("fieldIrrigationHistory")
  return (
    <Row justifyContent="space-between" {...props}>
      <AppText>{t("totalRunsWithItemCount", { itemCount })}</AppText>
      <AppText>
        {t("totalApplicationWithVal", { val: totalApplication })}
      </AppText>
    </Row>
  )
}

export function FieldIrrigationOptionsButton({
  ...rest
}: IconButtonStyleProps): React.JSX.Element | null {
  const dispatch = useRootDispatch()
  const isVisible = useRootSelector(
    (state) => Models.fieldProfileReelRuns.selectTotal(state) > 0,
  )

  if (isVisible) {
    return (
      <IconButton
        IconComponent="DotsThreeVertical"
        bg="white"
        onPress={() => dispatch(toggleUiSettingsMenu())}
        {...rest}
      />
    )
  }
  return null
}

function UiSettingsToggle({
  iconName,
  label,
  ...rest
}: NoChildren<SwitchProps> & {
  iconName: IconProp
  label: string
}) {
  return (
    <Row justifyContent="space-between">
      {renderIconFromIconProp(iconName)}
      <AppText fontSize="$sm">{label}</AppText>
      <Switch {...rest} />
    </Row>
  )
}

export function UiSettings({ ...props }: PaperProps): React.JSX.Element | null {
  const { t } = useTranslation("fieldIrrigationHistory")
  const isOpen = useRootSelector(
    (state) => state.fieldIrrigationHistory.isSettingsOpen,
  )

  const dispatch = useRootDispatch()
  const onClose = () => dispatch(toggleUiSettingsMenu())
  const isAutozoomEnabled = useRootSelector(getIsFieldIrrigationAutozoomEnabled)
  const isContrastBoosted = useRootSelector(
    getShouldBoostHeatmapContrastFromState,
  )
  const showAllBorders = useRootSelector(getAreReelRunOutlinesVisibleFromState)

  if (isOpen) {
    return (
      <Paper style={styles.uiSettings} {...props}>
        <Row alignItems="flex-start">
          <Box>
            <UiSettingsToggle
              iconName="Contrast"
              label={t("contrast")}
              value={isContrastBoosted}
              onValueChange={(nextValue) => {
                dispatch(
                  setRunHeatmapContrast(nextValue ? "high" : "default"),
                ).catch((e) => {
                  logger.error(e)
                  throw e
                })
              }}
            />
            <Divider />
            <UiSettingsToggle
              iconName="Borders"
              label={t("showBorders")}
              value={showAllBorders}
              onValueChange={(nextValue) => {
                dispatch(setShowRunOutlines(nextValue)).catch((e) => {
                  logger.error(e)
                  throw e
                })
              }}
            />
            <Divider />
            <UiSettingsToggle
              iconName="ZoomIn"
              label={t("autoZoom")}
              value={isAutozoomEnabled}
              onValueChange={(nextValue) => {
                dispatch(setFieldProfileAutoZoom(nextValue)).catch((e) => {
                  logger.error(e)
                  throw e
                })
              }}
            />
          </Box>
          <CloseIconButton size="sm" onPress={onClose} />
        </Row>
      </Paper>
    )
  }
  return null
}

export function ReelRunCycleButton({
  onChange,
  source,
  targetId,
  ...rest
}: IconButtonProps & {
  onChange: SelectReelRunHandler
  source: ReelRunVariant
  targetId: number | null | undefined
}): React.JSX.Element | null {
  const isDisabled = isNullish(targetId)
  const runData = useShallowEqualSelector((state) => {
    if (isValidNumber(targetId)) {
      const model =
        source === "active"
          ? Models.reelRunsActive
          : Models.fieldProfileReelRuns

      const run = model.selectById(state, targetId)
      if (run) {
        return {
          deviceId: run.deviceId,
          fieldId: run.fieldId,
          reelRunId: targetId,
          ...getSwathGeometryCachedByReelRunPropertiesFromState(state, {
            azimuthOverride: undefined,
            id: targetId,
            variant: source,
          }),
        }
      }
    }
    return undefined
  })
  const handlePress = () => {
    if (runData) {
      onChange({
        centerLatLng: runData.centerLatLng,
        reelRunId: runData.reelRunId,
      })
    }
  }

  return <CycleButton isDisabled={isDisabled} onPress={handlePress} {...rest} />
}

function ReelRunHeader({
  children,
  title,
}: AcceptsChildren & { title: string }): React.JSX.Element {
  return (
    <Paper>
      <Row py="$1">
        <Heading fontSize="$sm">{title}</Heading>
        {children}
      </Row>
    </Paper>
  )
}

export function SelectedRunHeader({
  buttonSize = "sm",
  onChange,
  onClose,
  selectedRunId: selectedId,
  ...rest
}: {
  onChange: SelectReelRunHandler
  onClose: () => void
  selectedRunId: number
  buttonSize?: ButtonProps["size"]
}): React.JSX.Element {
  const currentRunIndex = useRootSelector((state) =>
    getHistoricalReelRunIndexByIdFromState(state, selectedId),
  )
  const olderId = useRootSelector((state) => {
    if (isValidNumber(currentRunIndex)) {
      return Models.fieldProfileReelRuns.selectAll(state)[currentRunIndex - 1]
        ?.reelRunId
    }
    return undefined
  })
  const newerId = useRootSelector((state) => {
    if (isValidNumber(currentRunIndex)) {
      return Models.fieldProfileReelRuns.selectAll(state)[currentRunIndex + 1]
        ?.reelRunId
    }
    return undefined
  })
  const runCount = useRootSelector(Models.fieldProfileReelRuns.selectTotal)

  return (
    <ReelRunHeader
      {...rest}
      title={`Reel Run ${
        isValidNumber(currentRunIndex)
          ? `${currentRunIndex + 1} of ${runCount}`
          : ""
      }`}
    >
      <Row ml="auto">
        <Box mr="$2">
          <ReelRunCycleButton
            // iconName="arrow-left"
            IconComponent="ArrowLeft"
            size={buttonSize}
            source="historical"
            targetId={olderId}
            onChange={onChange}
          />
        </Box>
        <Box mx="$2">
          <ReelRunCycleButton
            IconComponent="ArrowRight"
            size={buttonSize}
            source="historical"
            targetId={newerId}
            onChange={onChange}
          />
        </Box>
        <Box ml="$2">
          <IconButton
            IconComponent="Close"
            size={buttonSize}
            onPress={onClose}
          />
        </Box>
      </Row>
    </ReelRunHeader>
  )
}

// TODO: Primary/secondary text
export function SelectedRunMetrics({
  applicationAverage,
  applicationTotal,
  psiAverage,
  runDuration,
  speedAverage,
  startDate,
}: NonNullable<
  ReturnType<typeof calculateMetricsForSelectedRun>
>): React.JSX.Element | null {
  return (
    <React.Fragment>
      <Row justifyContent="space-between" w="$full">
        <ListItemTextPrimary>{startDate}</ListItemTextPrimary>
        <ListItemTextPrimary>{runDuration}</ListItemTextPrimary>
      </Row>
      <ListItemTextPrimary>{psiAverage}</ListItemTextPrimary>
      <ListItemTextPrimary>{speedAverage}</ListItemTextPrimary>
      <ListItemTextPrimary>{applicationTotal}</ListItemTextPrimary>
      <ListItemTextPrimary>{applicationAverage}</ListItemTextPrimary>
    </React.Fragment>
  )
}

export const REEL_RUN_GRAPH_LINE_COLOR = COLORS.$darkBlue[600]

/**
 * TODO:Document
 */
export const ReelRunGraph = React.memo(function ReelRunGraph({
  graphKey,
  padding,
  selectedId,
  setGraphKey,
}: {
  graphKey: ReelRunGraphKey
  selectedId: number
  setGraphKey: StateSetter<ReelRunGraphKey>
  padding?: BreakpointInputValue<BlockProps>
}) {
  const { t } = useTranslation("sensorFields")

  const getUnitLabel = useSensorUnitLabel()
  const unitLabel = getUnitLabel(graphKey)

  const graphData = useShallowEqualSelector((state) =>
    getReelRunGraphDataByIdFromState(state, selectedId, graphKey),
  )
  const dates: MinDateMaxDate = {
    dateMsMax: graphData?.dateMsMax ?? Date.now(),
    dateMsMin: graphData?.dateMsMin ?? Date.now(),
  }

  const graph = useTimeseriesGraph({ ...dates, padding })

  if (!graphData) {
    return <EmptyGraph {...dates} />
  }

  return (
    <View>
      <Row>
        {REEL_RUN_GRAPH_KEYS.map(
          (key): React.JSX.Element => (
            <Button
              key={key}
              flexGrow={1}
              size="sm"
              style={{ marginVertical: SPACING.$1 }}
              text={t(`${key}.displayName`)}
              variant={key === graphKey ? "primary" : "outline"}
              w="1/3"
              onPress={() => setGraphKey(key)}
            />
          ),
        )}
      </Row>
      <VictoryChart
        {...graph.chart}
        containerComponent={
          <VictoryVoronoiContainer
            {...graph.getVoronoiContainer({ unitLabel })}
          />
        }
      >
        <VictoryLegend
          {...graph.legend}
          data={[
            {
              name: t(`${graphKey}.displayName`),
              // name: `${formatKey(graphKey)} (${unitLabel})`,
              symbol: { fill: REEL_RUN_GRAPH_LINE_COLOR },
            },
          ]}
        />
        <VictoryLine
          {...graph.getLine({
            maxValue: graphData.maxValue,
            stroke: REEL_RUN_GRAPH_LINE_COLOR,
          })}
          data={graphData.values}
        />
        <VictoryAxis {...graph.axis.getX()} />
        <VictoryAxis {...graph.axis.getY(graphData)} />
      </VictoryChart>
    </View>
  )
})

export function MapTitle({
  fieldCycler,
  fieldName,
  onFetchRunHistory,
}: {
  fieldCycler: React.JSX.Element | null
  fieldName: string
  onFetchRunHistory: () => void
}) {
  const { t } = useTranslation("fieldIrrigationHistory")

  return (
    <Row
      // TODO: FIGURE OUT LINEAR GRADIENT
      // background={{ linearGradient: GRADIENTS.mapHeader.linearGradient }}
      style={styles.mapTitle}
    >
      <Box flex={1}>
        <Heading colorScheme="lightText" fontSize="$md">
          {fieldName}
        </Heading>
        <AppText colorScheme="lightText">{t("title")}</AppText>
      </Box>
      <Row alignItems="center" ml="auto">
        <Box mr="$2">{fieldCycler}</Box>
        <Box ml="$2">
          <IconButton
            IconComponent="Restart"
            size="sm"
            variant="primary"
            onPress={onFetchRunHistory}
          />
        </Box>
      </Row>
    </Row>
  )
}
