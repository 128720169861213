import { addDays, hoursToMilliseconds } from "date-fns"

import { createSelector } from "@reduxjs/toolkit"

import { createMultiPoint, point } from "./geo"
import * as Models from "./models"

import type { Coordinates } from "./geo"
import type { RootState } from "./root.reducer"

/**
 * Is this provided farm id associated with a demo farm account?
 */
export function isDemoFarmId(farmId: number | null | undefined) {
  return typeof farmId === "number" && farmId < 0
}

/**
 * Load user's active farm id from app state, which in tu∏rn reads it from
 * the browser or phone's respective localstorage implementation. An undefined
 * value here means that the storage hasn't been read yet, while null indicates
 * that it has been read and the user does not have an active farm id stored.
 * @param state
 * @returns {number | null | undefined}
 */
export function getActiveFarmIdFromState({
  userFarms: { activeFarmId, demoFarmId },
}: RootState) {
  return demoFarmId ?? activeFarmId
}

/**
 *
 * @param state
 */
export const getActiveFarmFromState = createSelector(
  Models.userFarm.selectEntities,
  getActiveFarmIdFromState,
  (farms, id) => {
    if (typeof id === "number") {
      return farms[id]
    }
    return undefined
  },
)

/**
 *
 * @param state
 */
export function getIsDemoModeActiveFromState(state: RootState) {
  const isDemoActive = getActiveFarmFromState(state)?.isDemo === true

  return isDemoActive
}

/**
 *
 * @param state
 */
export function getActiveFarmNameFromState(state: RootState) {
  return getActiveFarmFromState(state)?.name
}

export const getActiveFarmCoordinatesFromState = createSelector(
  getActiveFarmFromState,
  (farm): Coordinates | undefined => {
    return point(farm?.gpsLocation ?? undefined)?.getCoords()
  },
)
export const getActiveFarmLng = createSelector(
  getActiveFarmCoordinatesFromState,
  (coord) => coord?.[0],
)
export const getActiveFarmLat = createSelector(
  getActiveFarmCoordinatesFromState,
  (coord) => coord?.[1],
)
export const getActiveFarmLatLngFromState = createSelector(
  getActiveFarmCoordinatesFromState,
  (location) => createMultiPoint(location),
)
/**
 * Demo farms should have a flag marking them as demo farms
 * and also have a negative id.
 */
export const getAllRealFarmsFromState = createSelector(
  Models.userFarm.selectAll,
  (farms) => farms.filter((f) => f.isDemo === false && !isDemoFarmId(f.id)),
)
export const getRealFarmCountFromState = createSelector(
  getAllRealFarmsFromState,
  (farms) => farms.length,
)
export const getIsMultifarmUserFromState = createSelector(
  getRealFarmCountFromState,
  (count) => count > 1,
)

/**
 * Start warning users this many days before the due date
 */
export const WARNING_PERIOD_BEFORE_DUE_DATE_DAYS = 10

/**
 * This many days after the due date, the account is locked
 */
export const GRACE_PERIOD_DAYS_AFTER_DUE_DATE = 10

export function getFarmAccountSubscriptionLockDate(dueDate: Date | number) {
  return addDays(dueDate, GRACE_PERIOD_DAYS_AFTER_DUE_DATE)
}

export const getActiveFarmSubscriptionDueDate = createSelector(
  getActiveFarmFromState,
  (farm) => {
    // return subDays(new Date(), 40)
    const subscriptionRenewalDueDate = farm?.subscriptionRenewalDueDate
    if (typeof subscriptionRenewalDueDate === "string") {
      return new Date(subscriptionRenewalDueDate).getTime()
    }
    return undefined
  },
)

export function isDateWithinSubscriptionWarningThreshold(
  farmSubscriptionDueDate: number,
) {
  // If the subscription date is within X days of today, show the warning
  const msInFuture = farmSubscriptionDueDate - new Date().getTime()

  const hoursUntilDueDate = WARNING_PERIOD_BEFORE_DUE_DATE_DAYS * 24
  const msUntilDueDate = hoursToMilliseconds(hoursUntilDueDate)
  if (msInFuture < msUntilDueDate) {
    return true
  }

  return false
}
