import React from "react"
import { Controller, useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { Alert } from "react-native"

import { captureMessage } from "@sentry/core"

import { ActionButtons } from "./ActionButtons"
import {
  AppIcons,
  Box,
  Button,
  Checkbox,
  Divider,
  FormControl,
  Heading,
  LinkText,
  Pressable,
  Row,
  Switch,
  View,
} from "./components"
import { AppText } from "./components/Text"
import { useToasts } from "./components/useToasts"
import { ADD_DEVICE_INPUT_MODE } from "./constants"
import { getDeviceStatusDataByDeviceIdFromState } from "./device-event-last.reducer"
import { DeviceListItem } from "./DevicesListItem"
import { addDeviceAsync, replaceDeviceAsync } from "./farmhq-api"
import { useFormValidation } from "./form-validation"
import * as Models from "./models"
import { useIsPending } from "./requests.reducer"
import { useFormatInstallationType } from "./sensor-configurations"
import { SuccessHandler } from "./SuccessHandler"
import i18n from "./translations/i18n"
import { UnconfiguredDeviceWarning } from "./UnconfiguredDeviceWarning"
import { useErrorHandler } from "./useErrorHandler"
import { useRootDispatch } from "./useRootDispatch"
import { useRootSelector } from "./useRootSelector"

import type {
  AcceptsChildren,
  ButtonProps,
  RowProps,
  ViewProps,
  HelpContentStatic,
} from "./components"
import type { DeviceConfiguration } from "./device-configurations.reducer"
import type { TargetDatabaseName } from "./Internal"

import type { AddDeviceErrorCode, AddDeviceInputMode } from "./constants"

import type {
  CodaDeviceAliasProps,
  DeviceIdProps,
  PermissionCheckProps,
} from "./types"
interface ResponseValid {
  deviceConfiguration: DeviceConfiguration | null
  deviceConnections: Models.EventActionTrigger[] | null
  deviceEvent: Models.DeviceEvent | null
  deviceFarmAssociation: Models.DeviceFarmAssociation | null
  status: "VALID"
  deviceShareCode?: Models.DeviceShareCode | null
}

interface ResponseInvalid {
  status: AddDeviceErrorCode
}

export type AddDeviceResponse = ResponseInvalid | ResponseValid

function cleanInput(value: string) {
  return value
    .toLocaleLowerCase()
    .trim()
    .replace(/\s+/g, "-")
    .replace(/_/g, "-")
}

interface AddDeviceState {
  inputMode: AddDeviceInputMode
  isConsentChecked: boolean
  isReplacingDevice: boolean
  newlyAddedDeviceId?: string
}

function getInitialState(
  defaultInputMode?: AddDeviceInputMode,
): AddDeviceState {
  return {
    inputMode: defaultInputMode ?? ADD_DEVICE_INPUT_MODE.coda_device_alias,
    isConsentChecked: false,
    isReplacingDevice: false,
  }
}

interface ProviderProps extends PermissionCheckProps {
  onCancel: () => void
  onPressCreateConfiguration: (
    params: CodaDeviceAliasProps & DeviceIdProps,
  ) => void
  onPressHelp: (inputMode: AddDeviceInputMode) => void
  targetDatabaseName: TargetDatabaseName

  defaultInputMode?: AddDeviceInputMode

  defaultValue?: string
}

function useAddDeviceForm({
  defaultInputMode,
  defaultValue,
  onPressHelp,
  ...rest
}: ProviderProps) {
  const { t } = useTranslation("addDevice")
  const toasts = useToasts()
  const dispatch = useRootDispatch()
  const handleError = useErrorHandler()

  const form = useForm<{
    deviceIdOld: string | undefined
    value: string | undefined
  }>({
    defaultValues: {
      deviceIdOld: "",
      value: defaultValue ?? "",
    },
  })

  const isLoading = useIsPending("AddDevice", "ReplaceDevice")

  const [
    { inputMode, isConsentChecked, isReplacingDevice, newlyAddedDeviceId },
    setState,
  ] = React.useState<AddDeviceState>(getInitialState(defaultInputMode))
  const isAddingByAlias = inputMode === "coda_device_alias"

  const newlyAddedDevice = useRootSelector((rootState) => {
    return getDeviceStatusDataByDeviceIdFromState(rootState, newlyAddedDeviceId)
  })
  const isSuccess = Boolean(newlyAddedDevice)
  const isAwaitingConsent =
    inputMode === "coda_device_alias" && isReplacingDevice && !isConsentChecked

  const handleResponse = (response: AddDeviceResponse) => {
    if (response.status === "VALID") {
      toasts.success(t("success", { ns: "common" }))
      setState((previous) => ({
        ...previous,
        newlyAddedDeviceId: response.deviceConfiguration?.deviceId,
      }))
    } else {
      // The request succeeded but the code didn't match
      // or something else went wrong
      let message: string | undefined
      const code = response.status
      switch (code) {
        case "ALREADY_ADDED": {
          message = t("ALREADY_ADDED")
          break
        }
        case "ALREADY_CLAIMED": {
          message = t("ALREADY_CLAIMED")
          break
        }
        case "EXPIRED": {
          message = t("EXPIRED")
          break
        }
        case "INVALID": {
          if (isAddingByAlias) {
            message = t("INVALID_ALIAS")
          } else {
            message = t("INVALID_SHARE_CODE")
          }
          break
        }
        default: {
          captureMessage(
            `Unhandled response from add device: code=(${JSON.stringify(
              code,
            )})`,
          )
        }
      }

      form.setError("value", { message })
    }
    return undefined
  }

  const replacementDeviceId = form.watch("deviceIdOld")
  const replacementDeviceAlias = useRootSelector((rootState) => {
    return Models.deviceConfiguration.selectById(rootState, replacementDeviceId)
      ?.codaDeviceAlias
  })

  let texts: {
    helpLink: string
    instructions: string
    label: string
    placeholder: string
    toggle: string
  }
  if (inputMode === "coda_device_alias") {
    texts = {
      helpLink: t("codaDeviceAliasHelpLink"),
      instructions: t("codaDeviceAliasInstructions"),
      label: t("codaDeviceAliasFieldLabel"),
      placeholder: t("codaDeviceAliasInputPlaceholder"),
      toggle: t("codaDeviceAliasToggleText"),
    }
  } else {
    texts = {
      helpLink: t("deviceShareCodeHelpLink"),
      instructions: t("deviceShareCodeInstructions"),
      label: t("deviceShareCodeFieldLabel"),
      placeholder: t("deviceShareCodeInputPlaceholder"),
      toggle: t("deviceShareCodeToggleText"),
    }
  }

  let isReplacementDeviceSameAsNewDevice = false
  if (isAddingByAlias && isReplacingDevice) {
    const newDeviceAlias = form.watch("value") ?? ""
    if (
      newDeviceAlias.length > 0 &&
      replacementDeviceAlias === newDeviceAlias
    ) {
      isReplacementDeviceSameAsNewDevice = true
    }
  }
  return {
    form,
    inputMode,
    isAddingByAlias,
    isConsentChecked,
    isLoading,
    isReplacementDeviceSameAsNewDevice,
    isReplacingDevice,
    isSubmitDisabled: isLoading || isSuccess || isAwaitingConsent,
    newlyAddedDevice,
    onPressAddMoreDevices: (): void => {
      setState(getInitialState(defaultInputMode))
      form.setValue("value", "")
      form.clearErrors("value")
    },
    onPressHelp: () => onPressHelp(inputMode),
    onSubmit: form.handleSubmit(({ deviceIdOld, value }) => {
      if (typeof value === "undefined") {
        throw new TypeError("value is undefined")
      }
      if (isReplacingDevice) {
        if (typeof deviceIdOld === "undefined") {
          throw new TypeError("deviceIdOld is undefined")
        }
        dispatch(
          replaceDeviceAsync({
            codaDeviceAliasNew: value,
            deviceIdOld,
          }),
        )
          .unwrap()
          .then(handleResponse)
          .catch((error) => handleError(error, { toastMessage: "default" }))
      } else {
        dispatch(
          addDeviceAsync({
            inputMode,
            value: cleanInput(value),
          }),
        )
          .unwrap()
          .then(handleResponse)
          .catch((error) => handleError(error, { toastMessage: "default" }))
      }
    }),
    onToggleInputMode: (): void => {
      form.clearErrors("value")
      form.setValue("value", "")
      form.setValue("deviceIdOld", "")
      if (isAddingByAlias) {
        setState(getInitialState("device_share_code"))
      } else {
        setState(getInitialState("coda_device_alias"))
      }
    },
    replacementDeviceAlias,

    setState,
    t,
    texts,

    ...rest,
  }
}

type ContextValue = ReturnType<typeof useAddDeviceForm>

const Context = React.createContext<ContextValue | undefined>(undefined)

function Provider({
  children,
  ...rest
}: AcceptsChildren & ProviderProps): JSX.Element | null {
  const value = useAddDeviceForm(rest)
  return <Context.Provider value={value}>{children}</Context.Provider>
}

function useContext(): ContextValue {
  const ctx = React.useContext(Context)
  if (typeof ctx === "undefined") {
    throw new TypeError(`Context must be used inside of provider`)
  }
  return ctx
}

/**
 *
 */

function HelpLink(): React.JSX.Element {
  const { onPressHelp, texts } = useContext()

  return (
    <Pressable id="help-btn" onPress={onPressHelp}>
      <Row>
        <Box mr="$2">
          <AppIcons.Info />
        </Box>
        <AppText colorScheme="secondary">{texts.helpLink}</AppText>
      </Row>
    </Pressable>
  )
}

/**
 * User selects a replacement device
 */
function ReplacementDeviceFields() {
  const validation = useFormValidation()

  const {
    form,
    isConsentChecked,
    isReplacementDeviceSameAsNewDevice,
    replacementDeviceAlias,
    setState,
    t,
  } = useContext()
  const formatInstallationType = useFormatInstallationType()

  const replacementDeviceOptions = useRootSelector((rootState) => {
    return Models.deviceConfiguration
      .selectAll(rootState)
      .sort((a, b) => {
        if (a.hardwareGeneration === b.hardwareGeneration) {
          return a.codaDeviceAlias.localeCompare(b.codaDeviceAlias)
        }
        return a.hardwareGeneration.localeCompare(b.hardwareGeneration)
      })
      .map((dc) => {
        return {
          label: `${dc.codaDeviceAlias} (${formatInstallationType(
            dc.deviceInstallationType,
          )} - ${dc.hardwareGeneration})`,
          value: dc.deviceId,
        }
      })
  })

  const replacementDeviceDescription = t(
    "replacementDeviceDescriptionWithOldDeviceAliasAndNewDeviceAlias",
    {
      newDeviceAlias: (form.watch("value") ?? "") || t("enterNewDeviceAlias"),
      oldDeviceAlias: (replacementDeviceAlias ?? "") || t("selectOldDevice"),
    },
  )
  const labelText = t("deviceToReplace")
  const placeholderText = t("replacementDevicePlaceholder")
  const handleShowNewDeviceToReplace = () => {
    Alert.alert(t("noDevicesToReplace"), t("noDevicesToReplaceMessage"))
  }

  return (
    <Box>
      <Controller
        control={form.control}
        name="deviceIdOld"
        render={({ field, fieldState }) => {
          const errorMessage = fieldState.error?.message
          return (
            <FormControl.Provider isInvalid={Boolean(errorMessage)}>
              <FormControl.Label>{labelText}</FormControl.Label>
              <FormControl.Select
                label={labelText}
                options={replacementDeviceOptions}
                placeholder={placeholderText}
                selectedValue={field.value ?? ""}
                onFocusWhenEmptyNative={handleShowNewDeviceToReplace}
                onValueChange={(nextValue) => field.onChange(nextValue)}
              />
              <FormControl.ErrorMessage>
                {errorMessage}
              </FormControl.ErrorMessage>
            </FormControl.Provider>
          )
        }}
        rules={{
          required: validation.required,
          validate: () => {
            if (isReplacementDeviceSameAsNewDevice) {
              return t("MUST_NOT_MATCH")
            }
            return true
          },
        }}
      />
      <Box mt="$4">
        <Heading variant="h5">{t("note")}</Heading>
        <AppText fontSize="$sm">{replacementDeviceDescription}</AppText>
        <Divider mt="$4" />
        <Row alignItems="center" justifyContent="space-between">
          <Box mr="$4">
            <AppText fontWeight="bold">{t("iUnderstand")}</AppText>
          </Box>
          <Checkbox
            isChecked={isConsentChecked}
            onChange={(nextValue) => {
              setState((prev) => {
                return {
                  ...prev,
                  isConsentChecked: nextValue,
                }
              })
            }}
          />
        </Row>
      </Box>
    </Box>
  )
}

function Success(rest: ViewProps): React.JSX.Element | null {
  const {
    newlyAddedDevice,
    onPressAddMoreDevices,
    onPressCreateConfiguration,
    t,
    targetDatabaseName,
    withPermissions,
  } = useContext()
  if (!newlyAddedDevice) {
    return null
  }
  return (
    <View id="add-device-form" {...rest}>
      <SuccessHandler
        message={t("successDescription")}
        title={t("success", { ns: "common" })}
      />
      <DeviceListItem
        showComment
        targetDatabaseName={targetDatabaseName}
        {...newlyAddedDevice}
        index={1}
      />
      <UnconfiguredDeviceWarning
        deviceId={newlyAddedDevice.deviceId}
        isDismissable={false}
        withPermissions={withPermissions}
        onPressCreateConfiguration={() => {
          onPressCreateConfiguration(newlyAddedDevice)
        }}
      />
      <Box ml="auto" mt="$2">
        <Button
          IconComponent="Add"
          id="add-more-devices-btn"
          text={t("addMoreDevicesButton")}
          onPress={onPressAddMoreDevices}
        />
      </Box>
    </View>
  )
}

function ReplacementDeviceToggle(props: RowProps): React.JSX.Element {
  const { isReplacingDevice, setState, t } = useContext()
  return (
    <Row {...props}>
      <Row>
        <Box mr="$4">
          <AppText colorScheme="secondary">
            {t("isReplacementDevice", { ns: "addDevice" })}
          </AppText>
        </Box>
        <Switch
          value={isReplacingDevice}
          onValueChange={(nextValue) => {
            setState((prev) => ({
              ...prev,
              isReplacingDevice: nextValue,
            }))
          }}
        />
      </Row>
    </Row>
  )
}

/**
 * Main user input for entering alias or share code
 */
function UserInput() {
  const validation = useFormValidation()

  const {
    form,
    inputMode,
    isAddingByAlias,
    isReplacementDeviceSameAsNewDevice,
    texts,
  } = useContext()
  return (
    <Controller
      control={form.control}
      name="value"
      render={({ field: { onChange, ref, ...field }, fieldState }) => {
        const errorMessage = fieldState.error?.message
        const isInvalid = Boolean(errorMessage)

        return (
          <FormControl.Provider
            id={inputMode}
            isInvalid={isInvalid}
            mb="$2"
            mt="$4"
          >
            <FormControl.Label>{texts.label}</FormControl.Label>
            <FormControl.Input
              ref={ref}
              placeholder={texts.placeholder}
              onChangeText={onChange}
              {...(isAddingByAlias
                ? { autoCapitalize: "none" }
                : { autoCapitalize: "characters" })}
              {...field}
            />

            <FormControl.ErrorMessage>{errorMessage}</FormControl.ErrorMessage>
            <Box ml="auto" mt="$2">
              <HelpLink />
            </Box>
          </FormControl.Provider>
        )
      }}
      rules={{
        required: validation.required,
        validate: () => {
          if (isReplacementDeviceSameAsNewDevice) {
            return false
          }
          return true
        },
      }}
    />
  )
}
/**
 * User adds device using either device alias or share code
 */
export function Main(rest: ViewProps): React.JSX.Element {
  const {
    isAddingByAlias,
    isLoading,
    isReplacingDevice,
    isSubmitDisabled,
    newlyAddedDevice,
    onCancel,
    onSubmit,
    onToggleInputMode,
    texts,
  } = useContext()

  if (newlyAddedDevice) {
    return <Success />
  }

  return (
    <View id="add-device-form" {...rest}>
      <AppText flex={1}>{texts.instructions}</AppText>
      <UserInput />
      {isReplacingDevice ? <ReplacementDeviceFields /> : null}
      <ActionButtons
        buttonSize="lg"
        isLoading={isLoading}
        isSubmitDisabled={isSubmitDisabled}
        my="$4"
        onPressCancel={onCancel}
        onPressSubmit={onSubmit}
      />
      <Divider my="$2" />
      {isAddingByAlias ? (
        <ReplacementDeviceToggle
          justifyContent="space-between"
          mb="$4"
          mt="$2"
        />
      ) : null}
      <Box mt="$2">
        <Pressable onPress={onToggleInputMode}>
          <LinkText>{texts.toggle}</LinkText>
        </Pressable>
      </Box>
    </View>
  )
}
export function AddDeviceForm({
  defaultInputMode,
  defaultValue,
  onCancel,
  onPressCreateConfiguration,
  onPressHelp,
  targetDatabaseName,
  withPermissions,
  ...viewProps
}: ProviderProps & ViewProps) {
  return (
    <Provider
      defaultInputMode={defaultInputMode}
      defaultValue={defaultValue}
      targetDatabaseName={targetDatabaseName}
      withPermissions={withPermissions}
      onCancel={onCancel}
      onPressCreateConfiguration={onPressCreateConfiguration}
      onPressHelp={onPressHelp}
    >
      <Main {...viewProps} />
    </Provider>
  )
}
export function AddDeviceLinkButton(
  props: Omit<ButtonProps, "text">,
): React.JSX.Element {
  const { t } = useTranslation("addDevice")
  return (
    <Button
      IconComponent="Add"
      accessibilityRole="link"
      id="add-device-btn"
      size="sm"
      text={t("addDeviceButton")}
      {...props}
    />
  )
}

export const HELP_CONTENT: { [key in AddDeviceInputMode]: HelpContentStatic } =
  {
    coda_device_alias: {
      bodyElement: i18n.t("addDevice:codaDeviceAliasHelpText"),
      subject: "add_device",
      titleElement: i18n.t("addDevice:codaDeviceAliasHelpTitle"),
    },
    device_share_code: {
      bodyElement: i18n.t("addDevice:deviceShareCodeHelpText"),
      subject: "add_device",
      titleElement: i18n.t("addDevice:deviceShareCodeHelpTitle"),
    },
  }

export function useHelpContent() {
  const [helpKey, setHelpKey] = React.useState<AddDeviceInputMode>()
  const isHelpOpen = Boolean(helpKey)
  const onCloseHelp = () => setHelpKey(undefined)
  return {
    helpContent:
      HELP_CONTENT[
        helpKey === "coda_device_alias"
          ? "coda_device_alias"
          : "device_share_code"
      ],
    isHelpOpen,
    onCloseHelp,
    onOpenHelp: setHelpKey,
  }
}
