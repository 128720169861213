import React from "react"

import { Box, Row, TitleWithIcon } from "./components"

import type {
  ButtonProps,
  ElementOrComponent,
  InvertableProps,
} from "./components"

export interface RosterHeaderProps {
  itemCount: number
  onPressAdd: () => void
  HelpComponent?: React.JSX.Element

  isInverted?: boolean
  isTitleHidden?: boolean
}

/**
 * Layout for list headers (devices, fields etc.)
 */
export function RosterHeader({
  AddButtonComponent,
  HelpComponent,
  SortDirectionButtonComponent,
  SortMenuComponent,
  isInverted = false,
  isTitleHidden = false,
  itemCount,
  onPressAdd,
  titleText,
}: RosterHeaderProps & {
  AddButtonComponent: ElementOrComponent<ButtonProps>
  SortDirectionButtonComponent: ElementOrComponent<InvertableProps>
  SortMenuComponent: ElementOrComponent<InvertableProps>
  titleText: string | null
}) {
  const rightElement = (
    <Row ml="auto" pb="$2">
      {itemCount > 0 ? (
        <React.Fragment>
          {typeof AddButtonComponent === "function" ? (
            <AddButtonComponent
              key="create-btn"
              size="sm"
              onPress={onPressAdd}
            />
          ) : (
            AddButtonComponent
          )}
          <Box ml="$2">
            {typeof SortMenuComponent === "function" ? (
              <SortMenuComponent key="sort-direction" isInverted={isInverted} />
            ) : (
              SortMenuComponent
            )}
          </Box>
          <Box ml="$2">
            {typeof SortDirectionButtonComponent === "function" ? (
              <SortDirectionButtonComponent
                key="sort-btn"
                isInverted={isInverted}
              />
            ) : (
              SortDirectionButtonComponent
            )}
          </Box>
        </React.Fragment>
      ) : null}
    </Row>
  )
  if (typeof titleText === "string" && !isTitleHidden) {
    return (
      <TitleWithIcon
        helpElement={HelpComponent}
        rightElement={rightElement}
        titleText={titleText}
      />
    )
  }
  return rightElement
}
