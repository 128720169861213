import React from "react"
import { StyleSheet } from "react-native"

import {
  AppText,
  Box,
  getIconForInstallationType,
  renderIconFromIconProp,
  useIsDarkMode,
  View,
} from "./components"
import { COLORS, RADII, SPACING } from "./components/theme"
import { formatInstallationType } from "./sensor-configurations"

import type { AppIconProps, AppTextProps, ViewProps } from "./components"

import type { InstallationType } from "./sensor-configurations"

const styles = StyleSheet.create({
  root: {
    alignItems: "center",
    borderColor: COLORS.$divider.light,
    borderRadius: RADII.$default,
    borderWidth: 1,
    display: "flex",
    flexDirection: "row",
    padding: 2,
    paddingHorizontal: SPACING.$2,
  },
})

export interface InstallationTypeIndicatorProps extends ViewProps {
  value: InstallationType
}

export function InstallationTypeIndicator({
  value = "unconfigured",
  ...rest
}: InstallationTypeIndicatorProps): React.JSX.Element | null {
  const isDark = useIsDarkMode()
  let backgroundColor: string | undefined = COLORS.$paper.light
  let textColorScheme: AppTextProps["colorScheme"] = "secondary"
  let iconColor: AppIconProps["color"] | undefined = undefined

  if (isDark) {
    backgroundColor = COLORS.$paper.dark
  }

  if (value === "unconfigured") {
    backgroundColor = COLORS.$warning[300]
    textColorScheme = "darkText"
    iconColor = COLORS.$textDark.secondary
  }

  const text = formatInstallationType(value)
  return (
    <View
      id="installation-type-indicator"
      style={[styles.root, { backgroundColor }]}
      {...rest}
    >
      <Box mr="$1">
        {renderIconFromIconProp(getIconForInstallationType(value), {
          color: iconColor,
        })}
      </Box>
      <AppText colorScheme={textColorScheme} fontSize="$sm">
        {text}
      </AppText>
    </View>
  )
}
