import React from "react"
import { Controller } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { View } from "react-native"

import { ActionButtons } from "./ActionButtons"
import { AuthSuccess } from "./Auth"
import {
  confirmSignUpAsync,
  resendSignUpAsync,
  useAuthFormHelpers,
} from "./auth.reducer"
import { Box, FormControl, Row } from "./components"
import { Button } from "./components/Button"
import { FormError } from "./components/FormError"
import { AppText } from "./components/Text"
import { useToasts } from "./components/useToasts"
import { useFormValidation } from "./form-validation"
import { useIsPending } from "./requests.reducer"
import { useErrorHandler } from "./useErrorHandler"
import { useRootDispatch } from "./useRootDispatch"

/**
 *
 */
export function EnterEmail({
  onCancel,
  onSuccess,
}: {
  onCancel: () => void
  onSuccess: (values: { email: string }) => void
}) {
  const { t } = useTranslation("auth")
  const dispatch = useRootDispatch()
  const toasts = useToasts()
  const {
    form: { control, handleSubmit },
    formErrorMessage,
    handleError,
  } = useAuthFormHelpers({
    defaultValues: { email: "" },
  })
  const { required } = useFormValidation()
  const isLoading = useIsPending("resendSignUp")
  const onSubmit = handleSubmit((values) => {
    dispatch(resendSignUpAsync(values))
      .unwrap()
      .then(() => {
        toasts.info(t("codeSent", { ns: "common" }))
        return onSuccess(values)
      })
      .catch(handleError)
  })
  const labelText = t("emailFieldLabel")
  const placeholderText = t("emailFieldPlaceholder")
  return (
    <View>
      <AppText>{t("enterYourEmail")}</AppText>
      <Controller
        control={control}
        name="email"
        rules={{ required }}
        render={({ field: { onBlur, onChange, ref, value }, fieldState }) => {
          const errorMessage = fieldState.error?.message
          const isInvalid = Boolean(errorMessage)
          return (
            <FormControl.Provider
              isDisabled={isLoading}
              isInvalid={isInvalid}
              mt="$2"
            >
              <FormControl.Label>{labelText}</FormControl.Label>
              <FormControl.Input
                ref={ref}
                autoFocus
                autoCapitalize="none"
                autoComplete="email"
                autoCorrect={false}
                importantForAutofill="yes"
                keyboardType="email-address"
                placeholder={placeholderText}
                textContentType="emailAddress"
                value={value}
                onBlur={onBlur}
                onChangeText={onChange}
              />
              <FormControl.ErrorMessage>
                {errorMessage}
              </FormControl.ErrorMessage>
            </FormControl.Provider>
          )
        }}
      />
      <Box mt="$2">
        <FormError>{formErrorMessage}</FormError>
      </Box>
      <ActionButtons
        isLoading={isLoading}
        onPressCancel={onCancel}
        onPressSubmit={onSubmit}
      />
    </View>
  )
}

/**
 *
 */
export function EnterCodeForm({
  email,
  onCancel,
  onPressSignIn,
}: {
  email: string
  onCancel: () => void
  onPressSignIn: () => void
}) {
  const { t } = useTranslation("auth")
  const dispatch = useRootDispatch()
  const toasts = useToasts()
  const handleError = useErrorHandler()

  const [isSuccess, setIsSuccess] = React.useState(false)

  const isResendingCode = useIsPending("resendSignUp")
  const isSubmitLoading = useIsPending("confirmSignUp")
  // const req = useRootSelector((state) => state.requests.entities)

  const {
    form: { control, handleSubmit },
    formErrorMessage,
    handleError: handleAuthError,
  } = useAuthFormHelpers({
    defaultValues: { email: "" },
  })
  const { required } = useFormValidation()

  const onSubmit = handleSubmit((values) => {
    dispatch(confirmSignUpAsync({ ...values, email }))
      .unwrap()
      .then(() => setIsSuccess(true))
      .catch(handleAuthError)
  })

  const handleResendCode = () => {
    dispatch(resendSignUpAsync({ email }))
      .unwrap()
      .then(() => toasts.info(t("codeSent", { ns: "common" })))
      .catch((error) => handleError(error, { toastMessage: "default" }))
  }

  const labelText = t("codeStringFieldLabel")
  const placeholderText = t("codeStringFieldPlaceholder")

  if (isSuccess) {
    return <AuthSuccess onPressSignIn={onPressSignIn} />
  }
  return (
    <View>
      <AppText>{t("enterCodeFromYourEmail")}</AppText>
      <Controller
        control={control}
        name="codeString"
        rules={{ required }}
        render={({ field: { onChange, ref, ...field }, fieldState }) => {
          const errorMessage = fieldState.error?.message
          const isInvalid = Boolean(errorMessage)
          return (
            <FormControl.Provider
              id="codeString"
              isDisabled={isSubmitLoading}
              isInvalid={isInvalid}
              mt="$2"
            >
              <FormControl.Label>{labelText}</FormControl.Label>
              <Row>
                <FormControl.Input
                  ref={ref}
                  autoCapitalize="characters"
                  autoComplete="sms-otp"
                  autoCorrect={false}
                  keyboardType="number-pad"
                  placeholder={placeholderText}
                  textContentType="oneTimeCode"
                  style={{
                    flex: 1,
                    minWidth: 180,
                  }}
                  onChangeText={onChange}
                  {...field}
                />
                <Box h="$10" ml="auto" mt="$2" pl="$2">
                  <Button
                    id="resend-code-btn"
                    isDisabled={isResendingCode || isSubmitLoading}
                    isLoading={isResendingCode}
                    size="sm"
                    text={t("resendCode", { ns: "common" })}
                    variant="text"
                    onPress={handleResendCode}
                  />
                </Box>
              </Row>
              <FormControl.ErrorMessage>
                {errorMessage}
              </FormControl.ErrorMessage>
            </FormControl.Provider>
          )
        }}
      />

      <ActionButtons
        isDisabled={isSubmitLoading || isResendingCode}
        isLoading={isSubmitLoading}
        mt="$4"
        onPressCancel={onCancel}
        onPressSubmit={onSubmit}
      />
      <Box mt="$2">
        <FormError>{formErrorMessage}</FormError>
      </Box>
    </View>
  )
}
