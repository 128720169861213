import React from "react"
import { useTranslation } from "react-i18next"

import { createSelector } from "@reduxjs/toolkit"

import { setActiveFarmId } from "./async-storage"
import {
  AlertBodyText,
  AlertCard,
  AppIcons,
  Badge,
  Box,
  Button,
  Divider,
} from "./components"
import { ListItemTextPrimary } from "./components/ListItem"
import { Row } from "./components/Row"
import * as Models from "./models"
import { useExitDemoButton } from "./PrepareDemoButton"
import { useErrorHandler } from "./useErrorHandler"
import {
  getActiveFarmFromState,
  getActiveFarmIdFromState,
  getIsDemoModeActiveFromState,
  isDemoFarmId,
} from "./user-farms.selectors"
import { useRootDispatch } from "./useRootDispatch"
import { useRootSelector, useShallowEqualSelector } from "./useRootSelector"

/**
 * Get all farm accounts for the user that are not the current active farm
 * and are not demo farms
 */
export const getNonActiveFarmsFromState = createSelector(
  Models.userFarm.selectAll,
  getActiveFarmIdFromState,
  (farms, activeFarmId) => {
    return farms.filter((farm) => {
      if (isDemoFarmId(farm.id)) {
        return false
      }
      if (activeFarmId === farm.id) {
        return false
      }
      return true
    })
  },
)

export function useChangeActiveFarm() {
  const dispatch = useRootDispatch()
  const handleError = useErrorHandler()
  return ({ id }: { id: number }) => {
    dispatch(setActiveFarmId(id)).catch(handleError)
  }
}

interface ItemType {
  name: string
}

export function RenderFarmListItem({
  endElement,
  name,
}: ItemType & {
  endElement?: React.JSX.Element
}) {
  if (typeof endElement === "undefined") {
    endElement = <AppIcons.ListItemEnd />
  }
  return (
    <Row justifyContent="space-between" py="$2">
      {/* <ListNumber value={id} /> */}
      <ListItemTextPrimary>{name}</ListItemTextPrimary>
      {endElement}
    </Row>
  )
}
export function ActiveFarm(): React.JSX.Element | null {
  const { t } = useTranslation("account")
  const activeFarm = useShallowEqualSelector(getActiveFarmFromState)
  const isDemoActive = useRootSelector(getIsDemoModeActiveFromState)
  if (isDemoActive || typeof activeFarm === "undefined") {
    return null
  }
  return (
    <React.Fragment>
      <RenderFarmListItem
        name={activeFarm.name}
        endElement={
          <Badge colorScheme="$success">
            {t("activeFarmTitle", { ns: "account" })}
          </Badge>
        }
      />
      <Box my="$0">
        <Divider />
      </Box>
    </React.Fragment>
  )
}
export function DemoModeActive(): React.JSX.Element | null {
  const { t } = useTranslation("demoMode")
  const exitDemoButton = useExitDemoButton()
  const isDemoActive = useRootSelector(getIsDemoModeActiveFromState)

  if (isDemoActive) {
    return (
      <AlertCard titleText={t("isActiveText")}>
        <Box key="demo" flex={1}>
          <AlertBodyText key="body">
            {t("warnings.someFeaturesDisabled")}
          </AlertBodyText>
        </Box>
        <Box ml="auto" mt="$4">
          <Button key="exit" {...exitDemoButton} IconComponent="SignOut" />
        </Box>
      </AlertCard>
    )
  }
  return null
}
