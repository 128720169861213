import React from "react"

import { ActionButtons } from "./ActionButtons"
import {
  AlertBodyText,
  AppIcons,
  AppText,
  Badge,
  Box,
  Button,
  Column,
  Divider,
  Row,
  TextWithIcon,
  TitleWithIcon,
} from "./components"
import { setDeviceLocationPermanentAsync } from "./farmhq-api"
import { getActiveFieldsFromState } from "./fields.reducer"
import * as Geo from "./geo"
import * as Models from "./models"
import i18n from "./translations/i18n"
import { useBackendRequest } from "./useBackendRequest"
import { getActiveFarmLatLngFromState } from "./user-farms.selectors"
import { useRootSelector } from "./useRootSelector"

import type { RootState } from "./root.reducer"
import type {
  AcceptsChildren,
  BoxProps,
  HelpContentStatic,
  TitleWithIconProps,
} from "./components"
import type { DeviceIdProps } from "./types"
export const HELP_CONTENT: HelpContentStatic = {
  bodyElement: (
    <Box flex={1}>
      <Column space="$4">
        <AlertBodyText>
          {i18n.t("pinningExplanation", { ns: "setDeviceLocationPermanent" })}
        </AlertBodyText>

        <TextWithIcon IconComponent="Dot" alignItems="baseline">
          {i18n.t("setDeviceLocationPermanent:locationPinnedExplanation")}
        </TextWithIcon>
        <TextWithIcon IconComponent="Dot" alignItems="flex-start">
          {i18n.t("setDeviceLocationPermanent:locationUnpinnedExplanation")}
        </TextWithIcon>
      </Column>
    </Box>
  ),
  subject: "set_device_location_permanent",
  titleElement: i18n.t("title", { ns: "setDeviceLocationPermanent" }),
}

export function getIsLocationPinnedByDeviceId(
  state: RootState,
  deviceId: string,
): boolean {
  return Boolean(
    Models.deviceConfiguration.selectById(state, deviceId)?.locationPermanent,
  )
}
type ProviderProps = DeviceIdProps & {
  onClose: () => void
}

/**
 * Shared logic for permanent device location setting
 */
function useSetDeviceLocationPermanent({ deviceId, ...rest }: ProviderProps) {
  const { handleError, isLoading, sendRequest, toasts } = useBackendRequest(
    setDeviceLocationPermanentAsync,
  )
  const [pinnedLocation, setPinnedLocation] = React.useState<
    Geo.MultiPoint | undefined
  >()

  const [isChanged, setIsChanged] = React.useState(false)
  const isLocationPinned = useRootSelector((state) =>
    Boolean(
      Models.deviceConfiguration.selectById(state, deviceId)?.locationPermanent,
    ),
  )
  const onSubmit = (nextValue: Geo.PointGeoJson | null) => {
    sendRequest({ deviceId, locationPermanent: nextValue })
      .then(() => setIsChanged(false))
      .then(() => setPinnedLocation(undefined))
      .then(() => toasts.success())

      .catch((error) =>
        handleError(error, {
          toastMessage: "default",
        }),
      )
  }

  const handleUnpin = () => onSubmit(null)
  const asGeoJson = Geo.point(pinnedLocation?.native)?.toJson()

  return {
    asGeoJson,
    farmLocation: useRootSelector(getActiveFarmLatLngFromState),
    fields: useRootSelector(getActiveFieldsFromState),
    handleUnpin,
    isLoading,
    isLocationPinned,
    isSubmitDisabled: !isChanged || !Boolean(asGeoJson),
    onPlaceMarker: (coordinate: Geo.PointInput | undefined) => {
      const nextLocation = Geo.createMultiPoint(coordinate)
      if (nextLocation) {
        setIsChanged(true)
        setPinnedLocation(nextLocation)
      }
    },
    onSubmitPinnedLocation: () => {
      if (typeof asGeoJson === "undefined") {
        throw new TypeError("invalid location")
      }
      return onSubmit(asGeoJson)
    },
    pinnedLocation,
    ...rest,
  }
}

type ContextValue = ReturnType<typeof useSetDeviceLocationPermanent>

const Context = React.createContext<ContextValue | undefined>(undefined)

export function Provider({
  children,
  ...rest
}: AcceptsChildren & ProviderProps): JSX.Element | null {
  const value = useSetDeviceLocationPermanent(rest)
  return <Context.Provider value={value}>{children}</Context.Provider>
}

export function useContext(): ContextValue {
  const ctx = React.useContext(Context)
  if (typeof ctx === "undefined") {
    throw new TypeError(`Context must be used inside of provider`)
  }
  return ctx
}

export function LocationPinnedBadge() {
  return (
    <Badge StartIconComponent={AppIcons.LocationPinned} colorScheme="$success">
      {i18n.t("setDeviceLocationPermanent:locationPinned")}
    </Badge>
  )
}

export function LocationPinnedIndicator(props: BoxProps) {
  const { handleUnpin, isLoading, isLocationPinned } = useContext()
  if (isLocationPinned) {
    // TODO: Make this look better
    return (
      <Row {...props} my="$2" space="$1">
        <LocationPinnedBadge />
        <Button
          IconComponent="TrashCan"
          isDisabled={isLoading}
          // size="sm"
          text={i18n.t("setDeviceLocationPermanent:unpinButton")}
          variant="text"
          onPress={handleUnpin}
        />
      </Row>
    )
  }
  return (
    <Box {...props}>
      <Badge StartIconComponent={AppIcons.LocationUnpinned} colorScheme="$gray">
        {i18n.t("setDeviceLocationPermanent:locationNotPinned")}
      </Badge>
    </Box>
  )
}

export function Controls({
  helpElement,
}: Pick<TitleWithIconProps, "helpElement">) {
  const { isLoading, isSubmitDisabled, onClose, onSubmitPinnedLocation } =
    useContext()
  return (
    <React.Fragment>
      <TitleWithIcon
        IconComponent="Target"
        headingVariant="h5"
        helpElement={helpElement}
        titleText={i18n.t("setDeviceLocationPermanent:title")}
      />
      <Box mr="auto" mt="$2">
        <LocationPinnedIndicator />
      </Box>
      <Divider my="$2" />
      <Box my="$1">
        <AppText>
          {i18n.t("setDeviceLocationPermanent:tapTheMapToSetLocation")}
        </AppText>
      </Box>
      <ActionButtons
        cancelText={i18n.t("exit")}
        isDisabled={isLoading}
        isLoading={isLoading}
        isSubmitDisabled={isSubmitDisabled}
        mb="$4"
        mt="$2"
        submitText={i18n.t("save")}
        onPressCancel={onClose}
        onPressSubmit={onSubmitPinnedLocation}
      />
    </React.Fragment>
  )
}
