import React from "react"
import { Controller, useFormContext } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { Platform } from "react-native"

import {
  getIsUserAuthenticatedFromState,
  signOutAsync,
  useValidatePassword,
} from "./auth.reducer"
import {
  AppIcons,
  AppText,
  Box,
  Button,
  FormControl,
  Heading,
  ICON_SIZES,
  Link,
  Pressable,
  renderIconFromIconProp,
  Row,
} from "./components"
import { ListItem } from "./components/ListItem"
import { testIds } from "./components/test-id"
import { useFormValidation } from "./form-validation"
import { SuccessHandler } from "./SuccessHandler"
import { isTruthyString } from "./type-guards"
import { useErrorHandler } from "./useErrorHandler"
import { useRootDispatch } from "./useRootDispatch"
import { useRootSelector } from "./useRootSelector"

import type {
  AppTextProps,
  ButtonProps,
  HeadingProps,
  IconProp,
  LinkProps,
  AcceptsChildren,
} from "./components"
import type { FormControlProviderProps } from "./components/form-control/base"
import type { InputProps } from "./components/form-control/Input"
import type { FormControlLabelProps } from "./components/form-control/Label"
import type { TestId } from "./components/test-id"

import type { AuthFormData } from "./auth.reducer"

import type { StateSetter } from "./types"

import type { ListItemProps } from "./components/ListItem"

/**
 * AuthLink
 */
export function AuthLink({
  id,
  onPress,
  text,
  to,
}: LinkProps & { text: string }): React.JSX.Element {
  return (
    <Box my="$4">
      <Link id={id} text={text} to={to} onPress={onPress} />
    </Box>
  )
}

export function SignInLink(props: LinkProps): React.JSX.Element {
  const { t } = useTranslation("auth")

  return (
    <AuthLink
      id="sign-in-link"
      text={t("signIn")}
      to="/sign-in"
      onPress={props.onPress}
    />
  )
}

interface UseSignOutButtonProps {
  onSuccess?: () => void
}

export function useSignOutButton(props?: UseSignOutButtonProps): {
  IconComponent: IconProp
  id: TestId
  onPress: () => unknown
  text: string
} {
  const { t } = useTranslation()
  const dispatch = useRootDispatch()
  const handleError = useErrorHandler()

  return {
    IconComponent: "SignOut",
    id: "sign-out-link",
    onPress: () => {
      dispatch(signOutAsync()).then(props?.onSuccess).catch(handleError)
    },
    text: t("signOut"),
  }
}

export function SignOutButton({
  onSuccess,
  ...rest
}: Omit<ButtonProps, "id" | "onPress" | "text"> &
  UseSignOutButtonProps): React.JSX.Element | null {
  const isAuthenticated = useRootSelector(getIsUserAuthenticatedFromState)
  const texts = useSignOutButton({ onSuccess })

  if (isAuthenticated) {
    return <Button {...rest} {...texts} />
  }
  return null
}

/**
 *
 */
export function SignOutListItem({
  onSuccess,
  ...rest
}: ListItemProps & UseSignOutButtonProps): React.JSX.Element {
  const { id, onPress, text } = useSignOutButton({ onSuccess })
  return (
    <Pressable {...testIds(id)} onPress={onPress}>
      <ListItem IconComponent="SignOut" text={text} {...rest} />
    </Pressable>
  )
}

// /**
//  *
//  */
// export function useOpenContactFormButton(): {
//   IconComponent: IconProp
//   id: TestId
//   onPress: () => void
//   text: string
// } {
//   const { t } = useTranslation("support")
//   const dispatch = useRootDispatch()
//   return {
//     IconComponent: "CustomerService",
//     id: "contact-us-link",
//     onPress: () => {
//       return dispatch(showContactUs())
//     },
//     text: t("contactUs"),
//   }
// }

interface AuthFieldProps extends FormControlProviderProps {
  _input?: InputProps
  label?: string
}

function useAuthField() {
  const { required } = useFormValidation()

  const { control, setValue, watch } = useFormContext<AuthFormData>()
  return {
    control,
    defaultRules: { required },
    onFocus: () => setValue("errorCode", undefined),
    watch,
  }
}
function usePasswordField(): {
  isVisible: boolean
  setIsVisible: StateSetter<boolean>
  visibilityProps: InputProps
} {
  const [isVisible, setIsVisible] = React.useState(false)

  const visibilityProps: InputProps = {}
  if (isVisible) {
    visibilityProps.textContentType = "none"
    visibilityProps.secureTextEntry = false
  }

  return { isVisible, setIsVisible, visibilityProps }
}

export function PasswordVisibilityButton({
  isVisible,
  setIsVisible,
}: {
  isVisible: boolean
  setIsVisible: StateSetter<boolean>
}) {
  const IconComponent = isVisible
    ? AppIcons.VisibilityOff
    : AppIcons.VisibilityOn
  return (
    <Pressable onPress={() => setIsVisible((prev) => !prev)}>
      <Box mr="$3">
        {renderIconFromIconProp(IconComponent, { size: ICON_SIZES.$sm })}
      </Box>
    </Pressable>
  )
}

function FieldLabel({ children, ...rest }: FormControlLabelProps) {
  if (isTruthyString(children)) {
    return <FormControl.Label {...rest}>{children}</FormControl.Label>
  }
  return null
}

export function EmailField({
  _input,
  label,
  ...formControlProps
}: AuthFieldProps): React.JSX.Element {
  const { control, defaultRules, onFocus } = useAuthField()
  const { t } = useTranslation("auth")
  const labelText = label ?? t("emailFieldLabel")
  const placeholderText = t("emailFieldPlaceholder")
  return (
    <Controller
      control={control}
      name="email"
      rules={defaultRules}
      render={({
        field: { onBlur, onChange, ref, value, ...field },
        fieldState,
      }) => {
        const errorMessage = fieldState.error?.message
        const isInvalid = Boolean(errorMessage)
        return (
          <FormControl.Provider
            id="email"
            isInvalid={isInvalid}
            {...formControlProps}
          >
            <FieldLabel>{labelText}</FieldLabel>
            <FormControl.Input
              {..._input}
              ref={ref}
              autoCapitalize="none"
              autoComplete="username"
              // autoCorrect={false}
              importantForAutofill="yes"
              keyboardType="email-address"
              placeholder={placeholderText}
              textContentType="emailAddress"
              value={value}
              onBlur={onBlur}
              onChangeText={onChange}
              onFocus={onFocus}
              {...field}
            />
            <FormControl.ErrorMessage>{errorMessage}</FormControl.ErrorMessage>
          </FormControl.Provider>
        )
      }}
    />
  )
}

/**
 * CurrentPasswordField
 */
export function CurrentPasswordField({
  _input,
  label,
  ...formControlProps
}: AuthFieldProps): React.JSX.Element {
  const { control, defaultRules, onFocus } = useAuthField()

  const { isVisible, setIsVisible, visibilityProps } = usePasswordField()
  const { t } = useTranslation("auth")
  const labelText = label ?? t("currentPasswordFieldLabel")
  const placeholderText = t("currentPasswordFieldPlaceholder")
  return (
    <Controller
      control={control}
      name="password"
      rules={defaultRules}
      render={({
        field: { onBlur, onChange, ref, value, ...field },
        fieldState,
      }) => {
        const errorMessage = fieldState.error?.message
        const isInvalid = Boolean(errorMessage)

        return (
          <FormControl.Provider
            {...formControlProps}
            id="password"
            isInvalid={isInvalid}
          >
            <FieldLabel>{labelText}</FieldLabel>
            <FormControl.Input
              {..._input}
              ref={ref}
              autoCapitalize="none"
              autoComplete="password"
              importantForAutofill="yes"
              placeholder={placeholderText}
              textContentType="password"
              value={value}
              InputRightElement={
                <PasswordVisibilityButton
                  isVisible={isVisible}
                  setIsVisible={setIsVisible}
                />
              }
              onBlur={onBlur}
              onChangeText={onChange}
              onFocus={onFocus}
              {...field}
              {...visibilityProps}
            />
            <FormControl.ErrorMessage>{errorMessage}</FormControl.ErrorMessage>
          </FormControl.Provider>
        )
      }}
    />
  )
}

export function NewPasswordField({
  _input,
  label,
  ...props
}: AuthFieldProps): React.JSX.Element {
  const { control, defaultRules, onFocus } = useAuthField()
  const { isVisible, setIsVisible, visibilityProps } = usePasswordField()
  const { t } = useTranslation("auth")
  const validatePassword = useValidatePassword()
  const labelText = label ?? t("newPasswordFieldLabel")
  const placeholderText = t("newPasswordFieldPlaceholder")
  return (
    <Controller
      control={control}
      name="password"
      render={({ field: { onChange, ref, ...field }, fieldState }) => {
        const errorMessage = fieldState.error?.message
        const isInvalid = Boolean(errorMessage)
        return (
          <FormControl.Provider
            {...props}
            id="new-password"
            isInvalid={isInvalid}
          >
            <FieldLabel>{labelText}</FieldLabel>
            <FormControl.Input
              ref={ref}
              {..._input}
              autoCapitalize="none"
              autoComplete="password-new"
              autoCorrect={false}
              keyboardType="default"
              placeholder={placeholderText}
              textContentType="newPassword"
              InputRightElement={
                <PasswordVisibilityButton
                  isVisible={isVisible}
                  setIsVisible={setIsVisible}
                />
              }
              onChangeText={onChange}
              onFocus={onFocus}
              {...visibilityProps}
              {...field}
            />
            <FormControl.ErrorMessage>{errorMessage}</FormControl.ErrorMessage>
          </FormControl.Provider>
        )
      }}
      rules={{
        ...defaultRules,
        validate: validatePassword,
      }}
    />
  )
}
export function ConfirmPasswordField({
  _input,
  label,
  ...formControlProps
}: AuthFieldProps): React.JSX.Element {
  const { control, defaultRules, onFocus, watch } = useAuthField()
  const { isVisible, setIsVisible, visibilityProps } = usePasswordField()
  const { t } = useTranslation("auth")
  const labelText = label ?? t("confirmPasswordFieldLabel")
  const placeholderText = t("confirmPasswordFieldPlaceholder")
  return (
    <Controller
      control={control}
      name="confirmPassword"
      render={({ field: { onChange, ref, ...field }, fieldState }) => {
        const errorMessage = fieldState.error?.message

        const isInvalid = Boolean(errorMessage)
        return (
          <FormControl.Provider
            {...formControlProps}
            id="confirm-password"
            isInvalid={isInvalid}
          >
            <FieldLabel>{labelText}</FieldLabel>
            <FormControl.Input
              ref={ref}
              {..._input}
              autoCapitalize="none"
              placeholder={placeholderText}
              textContentType="password"
              InputRightElement={
                <PasswordVisibilityButton
                  isVisible={isVisible}
                  setIsVisible={setIsVisible}
                />
              }
              onChangeText={onChange}
              onFocus={onFocus}
              {...visibilityProps}
              {...field}
            />
            <FormControl.ErrorMessage>{errorMessage}</FormControl.ErrorMessage>
          </FormControl.Provider>
        )
      }}
      rules={{
        ...defaultRules,
        validate: (value) => {
          if (value !== watch("password")) {
            return t("passwordRulesMustMatch", { ns: "validation" })
          }
          return undefined
        },
      }}
    />
  )
}

export function CodeStringField({
  _button,
  _input,
  label,
  ...rest
}: AuthFieldProps & {
  _button?: ButtonProps
}): React.JSX.Element {
  const { control, defaultRules, onFocus } = useAuthField()
  const { t } = useTranslation("auth")
  return (
    <Controller
      control={control}
      name="codeString"
      rules={defaultRules}
      render={({ field: { onChange, ref, ...field }, fieldState }) => {
        const errorMessage = fieldState.error?.message
        const isInvalid = Boolean(errorMessage)
        const placeholderText = t("codeStringFieldPlaceholder", { ns: "auth" })
        return (
          <FormControl.Provider {...rest} id="codeString" isInvalid={isInvalid}>
            <FieldLabel>{label}</FieldLabel>
            <Row justifyContent="space-between">
              <FormControl.Input
                ref={ref}
                autoCapitalize="characters"
                autoComplete="sms-otp"
                autoCorrect={false}
                keyboardType="number-pad"
                placeholder={placeholderText}
                returnKeyType="done"
                style={{ flexGrow: 1, minWidth: 180 }}
                textContentType="oneTimeCode"
                onFocus={onFocus}
                onChangeText={(nextValue) =>
                  onChange(nextValue.toLocaleUpperCase())
                }
                {...field}
                {..._input}
              />
              {_button?.onPress ? (
                <Button
                  IconComponent="Refresh"
                  id="resend-code-btn"
                  size="md"
                  variant="text"
                  {..._button}
                  text={t("resendCode", { ns: "common" })}
                />
              ) : null}
            </Row>
            <FormControl.ErrorMessage>{errorMessage}</FormControl.ErrorMessage>
          </FormControl.Provider>
        )
      }}
    />
  )
}

export function AuthSuccess({
  children,
  onPressSignIn,
}: AcceptsChildren & {
  onPressSignIn: () => void
}): React.JSX.Element | null {
  const { t } = useTranslation("auth")
  const action = (
    <Link id="sign-in-link">
      <Button
        id="sign-in-link"
        text={t("signIn")}
        w="full"
        onPress={onPressSignIn}
      />
    </Link>
  )
  return (
    <SuccessHandler message={t("credentialsSuccess")}>
      {action}
      {children}
    </SuccessHandler>
  )
}
export function PageTitle(props: HeadingProps) {
  if (Platform.OS === "web") {
    return <Heading id="page-title" variant="h2" {...props} />
  }
  return null
}
export function InstructionsText(props: AppTextProps): React.JSX.Element {
  return (
    <Box mb="$4">
      <AppText {...props} />
    </Box>
  )
}
