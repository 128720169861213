/* eslint-disable @typescript-eslint/no-use-before-define */
import React from "react"
import { StyleSheet, View } from "react-native"
import { useStyle } from "react-native-style-utilities"

import { renderIconFromIconProp } from "./icons"
import { AppText } from "./Text"
import { COLORS } from "./theme/colors"
import { getFontName } from "./theme/fonts"
import { RADII } from "./theme/radii"
import { SPACING } from "./theme/spacing"
import { useIsDarkMode } from "./ThemeProvider"

import type { ViewProps } from "react-native"
import type { IconProp } from "./icons"
import type { AppTextProps } from "./Text"
import type { ColorSchemeType } from "./theme/colors"

export interface BadgeProps
  extends ViewProps,
    Pick<AppTextProps, "fontSize" | "isTruncated"> {
  EndIconComponent?: IconProp
  StartIconComponent?: IconProp
  bg?: string
  colorScheme?: ColorSchemeType
}

export function Badge({
  EndIconComponent,
  StartIconComponent,
  bg,
  children,
  colorScheme = "$gray",
  fontSize = "$sm",
  isTruncated,
  style,
  ...rest
}: BadgeProps) {
  const isDark = useIsDarkMode()
  return (
    <View
      style={useStyle(() => {
        let backgroundColor: string | undefined
        const colors = COLORS[colorScheme] as
          | (typeof COLORS)[typeof colorScheme]
          | undefined
        if (colors) {
          backgroundColor = colors[isDark ? 700 : 300]
        }
        if (typeof bg === "string") {
          backgroundColor = bg
        } else {
          switch (colorScheme) {
            case "$success": {
              if (isDark) {
                //
                backgroundColor = COLORS.$success[700]
              } else {
                backgroundColor = COLORS.$success[300]
              }
              break
            }
            default: {
              break
            }
          }
        }

        return [styles.root, { backgroundColor }, style]
      })}
      {...rest}
    >
      {renderIconFromIconProp(StartIconComponent, { style: styles.startIcon })}
      <AppText
        fontSize={fontSize}
        isTruncated={isTruncated}
        style={styles.text}
      >
        {children}
      </AppText>
      {renderIconFromIconProp(EndIconComponent, { style: styles.endIcon })}
    </View>
  )
}
const styles = StyleSheet.create({
  endIcon: {
    marginLeft: "auto",
  },
  root: {
    alignItems: "center",
    borderRadius: RADII.$default,
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    paddingHorizontal: SPACING.$2,
    paddingVertical: SPACING.$0,
  },
  startIcon: {
    marginRight: "auto",
  },
  text: {
    fontFamily: getFontName("Poppins_500Medium"),
    paddingHorizontal: SPACING.$1,
    textTransform: "uppercase",
  },
})
