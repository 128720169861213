import i18next from "i18next"
import { ScrollView } from "react-native"

import {
  ButtonsDemo,
  CreateDeviceConfigurationContext,
  Models,
  SetLinearPath,
  TermsOfService,
  useRootSelector,
} from "@fhq/app"
import { AppIsLoading } from "@fhq/app/AppIsLoading"
import { AppText, Box, Row } from "@fhq/app/components"
import {
  FarmSvgIcon,
  PumpSvgIcon,
  ReelSvgIcon,
} from "@fhq/app/components/icons/CustomIcons"
import { AlertsDemo, FontDemo } from "@fhq/app/Development"

import CreateDeviceActionSchedulePage from "./pages/CreateDeviceActionSchedulePage"
import { CustomComponents } from "./pages/CustomComponents"
import DeviceSchedulesListPage from "./pages/DeviceSchedulesListPage"
import { MultifarmAnalyticsPage } from "./pages/MultifarmAnalytics"
import Page404 from "./pages/Page404"
import { ScratchPage } from "./pages/ScratchPage"
import { LinearPathConfiguration } from "./pages/SetLinearPathPage"
import { TablesPage } from "./pages/TablesPage"

import type { RouteObject } from "react-router"

function LinearPathTest() {
  const device = useRootSelector((state) =>
    Models.deviceConfiguration
      .selectAll(state)
      .find((dc) => dc.codaDeviceAlias === "francis_linear"),
  )
  if (!device) {
    return null
  }

  return (
    <CreateDeviceConfigurationContext.Provider
      // SetLinearPathComponent={LinearPathConfiguration}
      codaDeviceAlias={device.codaDeviceAlias}
      deviceId={device.deviceId}
      deviceInstallationType="linear_move"
      deviceName="test"
      hardwareGeneration="TC3"
      currentConfigurationData={{
        wheel: device.wheel,
      }}
      onCancel={() => {
        // eslint-disable-next-line no-alert
        alert("cancel")
      }}
      onClearInstallationType={() => {
        // eslint-disable-next-line no-alert
        alert("clear")
      }}
      onSuccess={(response) => {
        const asString = JSON.stringify(response, null, 2)
        // eslint-disable-next-line no-console
        console.log(asString)
      }}
    >
      <SetLinearPath.Provider
        onCancel={() => {
          // eslint-disable-next-line no-alert
          alert("cancel")
        }}
      >
        <LinearPathConfiguration />
      </SetLinearPath.Provider>
    </CreateDeviceConfigurationContext.Provider>
  )
}

export const DEV_ROUTES: RouteObject[] = [
  {
    element: <MultifarmAnalyticsPage />,
    id: "multifarm-analytics",
    path: "multifarm-analytics",
  },
  {
    element: <Page404 />,
    id: "page404",
    path: "page404",
  },
  {
    element: <ScratchPage />,
    id: "scratch",
    path: "scratch",
  },
  {
    element: <LinearPathTest />,
    id: "linear",
    path: "linear",
  },
  {
    element: <CustomComponents />,
    id: "UI",
    path: "ui",
  },
  {
    element: <TablesPage />,
    id: "tables",
    path: "tables",
  },
  {
    children: [
      {
        element: <DeviceSchedulesListPage />,
        index: true,
      },
      {
        element: <CreateDeviceActionSchedulePage />,
        id: "createScheduleDev",
        path: "create",
      },
    ],
    id: "schedulesDev",
    path: "schedules",
  },
  {
    element: <AppIsLoading />,
    id: "app loading",
    path: "app-is-loading",
  },
  {
    element: (
      <ScrollView>
        <TermsOfService.TermsOfServiceText />
      </ScrollView>
    ),
    id: "terms of service",
    path: "tos",
  },
  {
    element: (
      <Box m="auto" px="$4">
        <Row justifyContent="space-between">
          <Box mr="$4">
            <AppText>{i18next.t("deviceInstallationTypes.reel")}</AppText>
          </Box>
          <ReelSvgIcon />
        </Row>
        <Row justifyContent="space-between">
          <Box mr="$4">
            <AppText>{i18next.t("deviceInstallationTypes.pump")}</AppText>
          </Box>
          <PumpSvgIcon />
        </Row>
        <Row justifyContent="space-between">
          <Box mr="$4">
            <AppText>{i18next.t("common:farm")}</AppText>
          </Box>
          <FarmSvgIcon />
        </Row>
      </Box>
    ),
    id: "custom icons",
    path: "custom-icons",
  },

  {
    element: (
      <ScrollView>
        <ButtonsDemo />
      </ScrollView>
    ),
    id: "buttons",
    path: "buttons",
  },
  {
    element: (
      <ScrollView>
        <AlertsDemo />
      </ScrollView>
    ),
    id: "alerts",
    path: "alerts",
  },
  {
    element: (
      <ScrollView>
        <FontDemo />
      </ScrollView>
    ),
    id: "Font Demo",
    path: "fonts",
  },
]
