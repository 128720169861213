import React from "react"

import { ActionButtons } from "./ActionButtons"
import { Accordion, AppText, Box, Button, Column, Row } from "./components"
import { Checkbox } from "./components/Checkbox"
import { Radio } from "./components/Radio"
import { SPACING } from "./components/theme"
import { SEED_DATABASE_DEFAULTS, SEED_DATABASE_PHONE_NUMBER } from "./constants"
import { SetPermissionListItem } from "./PermissionListItem"
import { DEVICE_PERMISSIONS, FARM_PERMISSIONS } from "./permissions"
import { seedTestDatabaseAsync } from "./seedTestDatabase"
import { canSeedTestDatabase } from "./selectors"
import { useBackendRequest } from "./useBackendRequest"
import { handleReloadApp } from "./utility"

import type { ButtonProps } from "./components"
import type { SeedDatabaseOptions } from "./constants"
import type { TargetDatabaseName } from "./Internal"
import type { FarmUserPermissionName } from "./permissions"
export type SeedDatabasePreset = "demo" | "no-farms"

// export interface SeedDatabaseButtonProps {
//   onPress: (() => void) | undefined
//   targetDatabaseName: TargetDatabaseName | null | undefined
// }

/**
 * Admin only form for overriding seed database defaults
 */
export function SeedDatabaseOptionsForm({
  onClose,
  onSuccess,
  targetDatabaseName,
}: {
  onClose: () => void
  targetDatabaseName: TargetDatabaseName
  onSuccess?: () => void
}): React.JSX.Element {
  const [state, setState] = React.useState<SeedDatabaseOptions>(() => ({
    ...SEED_DATABASE_DEFAULTS,
  }))

  const { handleError, isLoading, sendRequest, toasts } = useBackendRequest(
    seedTestDatabaseAsync,
  )

  const handleSubmit = () => {
    if (!canSeedTestDatabase(targetDatabaseName)) {
      throw new TypeError(
        `Cannot seed database in this environment: ${targetDatabaseName}`,
      )
    }
    sendRequest(state)
      .then(() => toasts.success("Database seeded"))
      .then(onSuccess)
      .then(handleReloadApp)
      .catch((error) => {
        handleError(error, {
          toastMessage: "Failed to seed database",
        })
      })
  }
  const handleChangePermission = (
    key: FarmUserPermissionName,
    nextValue: boolean,
  ) => {
    return setState((prev) => ({
      ...prev,
      activeUserPermissions: {
        ...prev.activeUserPermissions,
        [key]: nextValue,
      },
    }))
  }

  return (
    <Column flex={1} justifyContent="space-between">
      <Box>
        <AppText>Preset</AppText>
        <Row key="presets" mb="$2">
          <Radio
            orientation="horizontal"
            selectedValue={state.presetName}
            options={[
              { label: "Default", value: "demo" },
              { label: "No Farms", value: "no-farms" },
            ]}
            onChange={(nextValue) =>
              setState((previous) => ({
                ...previous,
                presetName: nextValue as SeedDatabasePreset,
              }))
            }
          />
        </Row>
      </Box>
      <Box>
        {(
          [
            {
              key: "activeUserIsAdmin",
              label: "Active user is admin",
            },
            {
              key: "termsOfServiceAccepted",
              label: "Terms of service accepted",
            },
            {
              key: "usesMetricSystem",
              label: "Uses metric system",
            },
          ] as const
        ).map(
          ({ key, label }): React.JSX.Element => (
            <Checkbox
              key={key}
              isChecked={state[key] === true}
              title={label}
              onChange={() => {
                return setState((previous) => ({
                  ...previous,
                  [key]: !Boolean(previous[key]),
                }))
              }}
            />
          ),
        )}
      </Box>
      <Row>
        <AppText style={{ paddingRight: SPACING.$2 }}>
          Initial Phone Number
        </AppText>
        <Checkbox
          isChecked={Boolean(state.userPhoneNumbers)}
          onChange={(nextValue) => {
            if (nextValue) {
              setState((prev) => ({
                ...prev,
                userPhoneNumbers: [{ ...SEED_DATABASE_PHONE_NUMBER }],
              }))
            } else {
              setState((prev) => ({ ...prev, userPhoneNumbers: null }))
            }
          }}
        />
      </Row>
      {state.presetName === "demo" ? (
        <React.Fragment>
          <Accordion defaultIsOpen={false} titleText="Device Permissions">
            {DEVICE_PERMISSIONS.getKeys().map((key) => {
              return (
                <SetPermissionListItem
                  key={key}
                  isEnabled={state.activeUserPermissions[key]}
                  name={key}
                  onValueChange={(nextValue) =>
                    handleChangePermission(key, nextValue)
                  }
                />
              )
            })}
          </Accordion>
          <Accordion defaultIsOpen={false} titleText="Farm Permissions">
            {FARM_PERMISSIONS.getKeys().map((key) => {
              return (
                <SetPermissionListItem
                  key={key}
                  isEnabled={state.activeUserPermissions[key]}
                  name={key}
                  onValueChange={(nextValue) =>
                    handleChangePermission(key, nextValue)
                  }
                />
              )
            })}
          </Accordion>
        </React.Fragment>
      ) : null}
      <ActionButtons
        isLoading={isLoading}
        onPressCancel={onClose}
        onPressSubmit={handleSubmit}
      />
    </Column>
  )
}

export function SeedDatabaseButton({
  targetDatabaseName,
  ...props
}: ButtonProps & {
  targetDatabaseName: TargetDatabaseName
}) {
  if (!canSeedTestDatabase(targetDatabaseName)) {
    return null
  }
  return <Button IconComponent="Database" text="Seed Database" {...props} />
}
