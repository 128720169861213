import { Geo } from "@fhq/app"
import { getFontName, Theme } from "@fhq/app/components"

// Function to check if a coordinate is in the current bounds
export function isCoordinateInBounds({
  bounds,
  coordinate,
}: {
  bounds: google.maps.LatLngBoundsLiteral | null | undefined
  coordinate: google.maps.LatLngLiteral | null | undefined
}): boolean {
  if (!bounds || !coordinate) {
    return false
  }

  const { east, north, south, west } = bounds
  const { lat: markerLat, lng: markerLng } = coordinate

  return (
    markerLat >= south &&
    markerLat <= north &&
    markerLng >= west &&
    markerLng <= east
  )
}

export const pointTransformer: Geo.PointTransformer<
  google.maps.LatLngLiteral
> = (pt) => Geo.point(pt)?.toGmaps()

export const MARKER_LABEL_OPTIONS: Omit<google.maps.MarkerLabel, "text"> = {
  color: Theme.colors.$textLight.primary,
  fontFamily: getFontName("OpenSans_400Regular"),
  fontSize: `${Theme.fontSizes.$sm}px`,
}
