import convert from "convert-units"
import { addMinutes } from "date-fns"
import _ from "lodash"
import { createCachedSelector } from "re-reselect"

import { createSelector, createSlice, isAnyOf } from "@reduxjs/toolkit"

import { readLocalStorageAsync } from "./async-storage"
import { signOutAsync } from "./auth.reducer"
import { COLORS } from "./components/theme"
import { hexToRgb, parseRgbValues } from "./components/theme-utils"
import { loadDeviceActivityAsync, setReelRunDirectionAsync } from "./farmhq-api"
import * as Geo from "./geo"
import { loadActiveFarmAsync } from "./load-app"
import { logger } from "./logger"
import * as Models from "./models"
import { isValidGpsLocation } from "./selectors"
import { formatSensorValue, getSensorUnitLabel } from "./sensor-formatting"
import i18n from "./translations/i18n"
import { isTruthyString, isValidNumber } from "./type-guards"
import { getActiveFarmCoordinatesFromState } from "./user-farms.selectors"
import { getStandardDeviation } from "./utility"

import type { Reducer } from "@reduxjs/toolkit"
import type { ModelState } from "./models"
import type { RootState } from "./root.reducer"
import type { LocationValidationArgs } from "./selectors"
import type { MeasurementPreferenceProps } from "./sensor-conversions"
import type { SensorState } from "./sensor-events"
import type { SelectReelRunPayload } from "./types"

import type { RgbValues } from "./components/theme-utils"

import type { GeoPoint } from "./geo"
export type ReelRunVariant = "active" | "historical"

export type MapElementColoration = "highlight" | "mute"

const adapter = Models.reelRunsActive.adapter

interface ReelRunsActiveState extends ModelState<Models.ReelRun> {
  missingFieldsDialog?: "dismissed" | "suppressed"
}

const initialState: ReelRunsActiveState = { entities: {}, ids: [] }

const reelRunsActiveSlice = createSlice({
  extraReducers: (builder) =>
    builder
      .addCase(loadActiveFarmAsync.pending, (state) => {
        state.missingFieldsDialog = undefined
      })
      .addCase(setReelRunDirectionAsync.fulfilled, (state, action) => {
        adapter.updateOne(state, {
          changes: {
            extendHeadingDegrees: action.meta.arg.value,
          },
          id: action.meta.arg.reelRunId,
        })
      })
      .addCase(readLocalStorageAsync.fulfilled, (state, { payload }) => {
        if (payload.hideMissingFieldsDialog === true) {
          state.missingFieldsDialog = "suppressed"
        } else {
          state.missingFieldsDialog = undefined
        }
      })
      .addMatcher(
        isAnyOf(
          loadActiveFarmAsync.fulfilled,
          loadDeviceActivityAsync.fulfilled,
        ),
        (state, { payload }) => {
          adapter.setAll(state, payload.reelRuns)
        },
      )

      .addMatcher(isAnyOf(signOutAsync.fulfilled), () => ({ ...initialState })),

  initialState,
  name: `reelRunsActive`,
  reducers: {
    closeUnknownFieldsDialog: (state) => {
      state.missingFieldsDialog = "dismissed"
    },
  },
})

export const reelRunsActiveReducer: Reducer<typeof initialState> =
  reelRunsActiveSlice.reducer

export const { closeUnknownFieldsDialog } = reelRunsActiveSlice.actions

/**
 *
 */
export function drawSwathPolygon(params: {
  completionFraction: number
  distanceMmMax: number
  endPoint: Geo.PointGeoJson
  reelToGunAzimuth: number
  semicircle: boolean
  swathWidthMm: number
}): Geo.PolygonGeoJson | undefined {
  const halfSwathWidthMeters = params.swathWidthMm / 2000
  const metersMax = params.distanceMmMax / 1000
  const towardGun = params.reelToGunAzimuth

  const reelLocation = Geo.point(params.endPoint.coordinates)

  const gunMaxPosition = reelLocation?.project({
    direction: towardGun,
    distanceMeters: metersMax,
  })
  const gunCurrentPosition = reelLocation?.project({
    direction: towardGun,
    distanceMeters: metersMax * (1 - params.completionFraction),
  })

  let cap: Geo.GeoPoint[] = []
  if (params.semicircle) {
    for (const offset of _.range(90, -90, -5)) {
      const nextPoint = gunMaxPosition?.project({
        direction: towardGun + offset,
        distanceMeters: halfSwathWidthMeters,
      })
      if (nextPoint) {
        cap.push(nextPoint)
      }
    }
  } else if (gunMaxPosition) {
    cap = [gunMaxPosition]
  }

  if (gunMaxPosition && gunCurrentPosition) {
    const point1 = gunMaxPosition.project({
      direction: towardGun + 90,
      distanceMeters: halfSwathWidthMeters,
    })
    const point2 = gunMaxPosition.project({
      direction: towardGun - 90,
      distanceMeters: halfSwathWidthMeters,
    })
    const point3 = gunCurrentPosition.project({
      direction: towardGun - 90,
      distanceMeters: halfSwathWidthMeters,
    })
    const point4 = gunCurrentPosition.project({
      direction: towardGun + 90,
      distanceMeters: halfSwathWidthMeters,
    })
    const points = [point1, ...cap, point2, point3, point4]

    const asCoordinates = points.reduce((acc, pt) => {
      const coordinates = pt?.getCoords()
      if (coordinates) {
        return [...acc, coordinates]
      }
      return acc
    }, [] as Geo.Coordinates[])

    return Geo.polygon(asCoordinates)?.toJson()
  }
  return undefined
}

/**
 *
 */
export function generateRunProgressFractionText({
  measurementPreference,
  runDistanceMmElapsed,
  runDistanceMmMax,
}: MeasurementPreferenceProps & {
  runDistanceMmElapsed: number
  runDistanceMmMax: number
}) {
  const formatValue = (rawValue: number): string | undefined =>
    formatSensorValue({
      fieldName: "runDistanceMmCurrent",
      measurementPreference,
      rawValue,
    })
  const elapsedFormatted = formatValue(runDistanceMmElapsed)
  const maxFormatted = formatValue(runDistanceMmMax)

  if (isTruthyString(elapsedFormatted) && isTruthyString(maxFormatted)) {
    return i18n.t("runProgressFractionWithUnitLabel", {
      distanceElapsed: elapsedFormatted,
      distanceMax: maxFormatted,
      ns: "devices",
      unitLabel: getSensorUnitLabel({
        fieldName: "runDistanceMmCurrent",
        measurementPreference,
      }),
    })
  }
  return undefined
}

export interface RunCompletion {
  runCompletionPct: number
  runDistanceMmCurrent: number
  runDistanceMmElapsed: number
  runDistanceMmMax: number
}
/**
 *
 */
export function calculateRunCompletion(
  reelEvent:
    | {
        runDistanceMmCurrent: number | null | undefined
        runDistanceMmMax: number | null | undefined
      }
    | null
    | undefined,
): RunCompletion | undefined {
  if (!reelEvent) {
    return undefined
  }
  let runDistanceMmCurrent = reelEvent.runDistanceMmCurrent
  const runDistanceMmMax = reelEvent.runDistanceMmMax
  if (
    !isValidNumber(runDistanceMmCurrent) ||
    !isValidNumber(runDistanceMmMax)
  ) {
    return undefined
  }

  let runCompletionPct: number
  if (runDistanceMmCurrent > runDistanceMmMax) {
    runDistanceMmCurrent = runDistanceMmMax
  }
  let runDistanceMmElapsed = runDistanceMmMax - runDistanceMmCurrent
  if (runDistanceMmElapsed > runDistanceMmMax) {
    runDistanceMmElapsed = runDistanceMmMax
  }
  if (runDistanceMmMax === 0) {
    runCompletionPct = 0
  } else {
    runCompletionPct = (runDistanceMmElapsed / runDistanceMmMax) * 100
    // if (runCompletionPct > 100) {
    //   runCompletionPct = 100
    // }
  }

  return {
    runCompletionPct: isValidNumber(runCompletionPct)
      ? Math.round(runCompletionPct)
      : runCompletionPct,
    runDistanceMmCurrent,
    runDistanceMmElapsed,
    runDistanceMmMax,
  }
}

export type ReelRunStatus =
  | "complete"
  | "extending"
  | "retracting"
  | "stopped short"
  | "stopped"

/**
 *
 */
export function calculateReelRunStatus({
  runCompletionPct,
  stateCurrent,
}: {
  runCompletionPct: number | null | undefined
  stateCurrent: SensorState<"reel"> | null | undefined
}): ReelRunStatus | undefined {
  switch (stateCurrent) {
    case "RE": {
      return "extending"
    }
    case "RR": {
      return "retracting"
    }
    case "RS": {
      if (isValidNumber(runCompletionPct)) {
        if (runCompletionPct > 98) {
          return "complete"
        } else if (runCompletionPct > 2) {
          return "stopped short"
        }
      }
      return "stopped"
    }
    case null:
    case undefined:
    case "NONE": {
      break
    }
  }
  return undefined
}

/**
 *
 */
export interface RunEtaParams {
  runDistanceMmCurrent: number | null | undefined
  runSpeedMmpm: number | null | undefined
}

/**
 *
 */
export function calculateReelRunEta({
  runDistanceMmCurrent,
  runSpeedMmpm,
}: RunEtaParams): number | undefined {
  if (
    isValidNumber(runSpeedMmpm) &&
    isValidNumber(runDistanceMmCurrent) &&
    runSpeedMmpm > 0
  ) {
    const minutesRemaining = runDistanceMmCurrent / runSpeedMmpm
    return addMinutes(new Date(), minutesRemaining).getTime()
  }
  return undefined
}
export type ReelRunErrorCode =
  | "distance current negative"
  | "distance current null"
  | "distance max null"
  | "distance max zero"
  | "invalid location"
  | "no device event"
  | "no reel event"
  | "swath width null"
export interface SwathGeometryResult {
  centerLatLng: Geo.MultiPoint | undefined
  completionPct: number | null | undefined
  errorCode: ReelRunErrorCode | undefined
  outlineGeoJson: Geo.PolygonGeoJson | undefined
  outlinePath: Geo.MultiPath | undefined
  progressGeoJson: Geo.PolygonGeoJson | undefined
  progressPath: Geo.MultiPath | undefined
}

export function getDistancesForReelRun({
  distanceMmCurrent,
  distanceMmMax,
  distanceReportsMm,
}: Pick<
  Models.ReelRun,
  "distanceMmCurrent" | "distanceMmMax" | "distanceReportsMm"
>) {
  if (!isValidNumber(distanceMmCurrent)) {
    distanceMmCurrent = distanceReportsMm[distanceReportsMm.length - 1]
  }

  return {
    distanceMmCurrent,
    distanceMmElapsed:
      isValidNumber(distanceMmCurrent) && isValidNumber(distanceMmMax)
        ? distanceMmMax - distanceMmCurrent
        : null,
    distanceMmMax,
  }
}
/**
 *
 */
export function getSwathGeometry({
  distanceReportsMm,
  endPoint,
  extendHeadingDegrees,
  farmLocation,
  gpsStateCurrent,
  reelSprinklerType,
  reelSwathWidthMm,
  ...rest
}: LocationValidationArgs &
  Pick<
    Models.ReelRun,
    | "distanceMmCurrent"
    | "distanceMmMax"
    | "distanceReportsMm"
    | "endPoint"
    | "extendHeadingDegrees"
    | "reelSprinklerType"
    | "reelSwathWidthMm"
  >): SwathGeometryResult {
  let outlineGeoJson: Geo.PolygonGeoJson | undefined
  let progressGeoJson: Geo.PolygonGeoJson | undefined
  let completionPct: number | undefined
  let elapsed: number | undefined
  let errorCode: ReelRunErrorCode | undefined

  const distances = getDistancesForReelRun({
    distanceMmCurrent: rest.distanceMmCurrent,
    distanceMmMax: rest.distanceMmMax,
    distanceReportsMm,
  })
  const distanceMmMax = distances.distanceMmMax
  let distanceMmCurrent = distances.distanceMmCurrent

  if (
    gpsStateCurrent &&
    Boolean(farmLocation) &&
    !isValidGpsLocation(endPoint, { farmLocation, gpsStateCurrent })
  ) {
    errorCode = "invalid location"
  }

  if (isValidNumber(distanceMmMax) && isValidNumber(distanceMmCurrent)) {
    elapsed = distances.distanceMmElapsed ?? undefined

    if (distanceMmCurrent < 0) {
      distanceMmCurrent = 0
    }
    if (distanceMmMax === 0) {
      errorCode = "distance max zero"
    } else if (isValidNumber(elapsed) && distanceMmCurrent < 0) {
      errorCode = "distance current negative"
    } else if (reelSwathWidthMm === null) {
      errorCode = "swath width null"
    }
    completionPct = calculateRunCompletion({
      runDistanceMmCurrent: distanceMmCurrent,
      runDistanceMmMax: distanceMmMax,
    })?.runCompletionPct

    if (
      endPoint &&
      isValidNumber(reelSwathWidthMm) &&
      isValidNumber(extendHeadingDegrees)
    ) {
      outlineGeoJson = drawSwathPolygon({
        completionFraction: 1,
        distanceMmMax,
        endPoint,
        reelToGunAzimuth: extendHeadingDegrees,
        semicircle: reelSprinklerType === "gun",
        swathWidthMm: reelSwathWidthMm,
      })
      if (isValidNumber(completionPct)) {
        // Draw swath polygon takes the completion fraction as a decimal between 0 and 1
        const completionFraction = completionPct / 100
        progressGeoJson = drawSwathPolygon({
          completionFraction,
          distanceMmMax,
          endPoint,
          reelToGunAzimuth: extendHeadingDegrees,
          semicircle: reelSprinklerType === "gun",
          swathWidthMm: reelSwathWidthMm,
        })
      }
    }
  }

  const centerGeoJson = outlineGeoJson
    ? Geo.polygon(outlineGeoJson)?.getCenter()
    : undefined
  return {
    centerLatLng: Geo.createMultiPoint(centerGeoJson),
    completionPct,
    errorCode,
    outlineGeoJson,
    outlinePath: outlineGeoJson
      ? Geo.createMultiPath(outlineGeoJson)
      : undefined,
    progressGeoJson,
    progressPath: progressGeoJson
      ? Geo.createMultiPath(progressGeoJson)
      : undefined,
  }
}
/**
 *
 */
export function getMapElementColoration<T>(values: {
  selectedId: T | undefined
  thisId: T
}): MapElementColoration | undefined {
  let coloration: MapElementColoration | undefined
  if (typeof values.selectedId !== "undefined") {
    if (values.selectedId === values.thisId) {
      coloration = "highlight"
    } else {
      coloration = "mute"
    }
  }
  return coloration
}

interface RunInputProps {
  azimuthOverride: number | undefined
  id: number
  variant: ReelRunVariant
}

function getReelRunModelByVariant(
  variant: ReelRunVariant,
): typeof Models.fieldProfileReelRuns | typeof Models.reelRunsActive {
  if (variant === "active") {
    return Models.reelRunsActive
  }
  return Models.fieldProfileReelRuns
}

/**
 *
 */
export function getShouldBoostHeatmapContrastFromState(
  state: RootState,
): boolean {
  return state.fieldIrrigationHistory.heatmapSettings.contrast === "high"
}

/**
 *
 */
export function getIsFieldIrrigationAutozoomEnabled(state: RootState): boolean {
  return state.fieldIrrigationHistory.isAutozoomEnabled === true
}

/**
 *
 */
export const getSwathGeometryCachedByReelRunPropertiesFromState: (
  state: RootState,
  props: RunInputProps,
) => SwathGeometryResult | undefined = createCachedSelector(
  [
    (
      state: RootState,
      { id, variant, ...rest }: RunInputProps,
    ):
      | (LocationValidationArgs & Models.ReelRun & RunInputProps)
      | undefined => {
      const runData = getReelRunModelByVariant(variant).selectById(state, id)
      const farmLocation = getActiveFarmCoordinatesFromState(state)
      let gpsStateCurrent: SensorState<"gps"> | null | undefined

      if (variant === "active") {
        gpsStateCurrent = Models.deviceEventLast.selectById(
          state,
          runData?.deviceId ?? undefined,
        )?.gps?.stateCurrent
      } else {
        gpsStateCurrent = "GLK"
      }
      if (runData) {
        return {
          id,
          variant,
          ...rest,
          ...runData,
          farmLocation,
          gpsStateCurrent,
        }
      }
      return undefined
    },
    (_state: RootState, props: RunInputProps): number | undefined => {
      return props.azimuthOverride
    },
  ],
  (runData, azimuthOverride): SwathGeometryResult | undefined => {
    if (runData) {
      return getSwathGeometry({
        ...runData,
        extendHeadingDegrees: azimuthOverride ?? runData.extendHeadingDegrees,
      })
    }
    return undefined
  },
)((state: RootState, { id, variant }: RunInputProps): string => {
  const runData = getReelRunModelByVariant(variant).selectById(state, id)
  const eventCount = runData?.deviceEventTimestamps.length ?? 0
  return `id=${id}/${variant}/${eventCount}`
})

/**
 *
 */
export const getReelRunsActive = createSelector(
  Models.reelRunsActive.selectAll,
  Models.deviceConfiguration.selectEntities,
  getActiveFarmCoordinatesFromState,
  (allRuns, events, farmLocation) => {
    const result: Array<
      Pick<Models.ReelRun, "reelRunId"> &
        SelectReelRunPayload &
        SwathGeometryResult
    > = []
    for (const runData of allRuns) {
      if (isTruthyString(runData.deviceId)) {
        const event = events[runData.deviceId]

        if (event && event.gps && event.reel) {
          const geometry = getSwathGeometry({
            distanceMmCurrent: runData.distanceMmCurrent,
            distanceMmMax: runData.distanceMmMax,
            distanceReportsMm: runData.distanceReportsMm,
            endPoint: runData.endPoint,
            extendHeadingDegrees: runData.extendHeadingDegrees,
            farmLocation,
            reelSprinklerType: runData.reelSprinklerType,
            reelSwathWidthMm: runData.reelSwathWidthMm,
          })
          result.push({
            ...runData,
            ...geometry,
            reelRunId: runData.reelRunId,
          })
        }
      }
    }
    return result
  },
)

/**
 *
 */
function drawRectangle(props: {
  destination: Geo.AnyPoint | undefined
  direction: number
  origin: Geo.AnyPoint | undefined
  widthMm: number
  shouldDrawEndcap?: boolean
}): Geo.PolygonGeoJson | undefined {
  const originWrapped = Geo.point(props.origin)
  const destinationWrapped = Geo.point(props.destination)
  if (
    typeof originWrapped === "undefined" ||
    typeof destinationWrapped === "undefined"
  ) {
    return undefined
  }
  const plotCorner = (
    pt: GeoPoint,
    directionOffset: number,
  ): Geo.Coordinates | undefined => {
    return pt
      .project({
        direction: props.direction + directionOffset,
        distanceMeters: props.widthMm / 2000,
      })
      ?.getCoords()
  }

  const values = [
    originWrapped.getCoords(),
    plotCorner(originWrapped, 90),
    plotCorner(destinationWrapped, 90),
  ]
  if (props.shouldDrawEndcap === true) {
    for (const offset of _.range(90, -90, -10)) {
      values.push(plotCorner(destinationWrapped, offset))
    }
  }

  values.push(
    plotCorner(destinationWrapped, -90),
    plotCorner(originWrapped, -90),
  )
  if (values.length >= 5) {
    return Geo.polygon(
      values.filter((coords): coords is Geo.Coordinates => Boolean(coords)),
    )?.toJson()
  }
  return undefined
}

// HEATMAPS

export interface HeatmapOptions {
  shouldBoostContrast: boolean
}

const PARTIAL_RUN_DIFF_THRESHOLD_MM = 15000

export interface HeatmapPolygonPaneValue<
  T extends Geo.AnyLatLng = google.maps.LatLngLiteral,
> extends RgbValues {
  fillColor: string
  fillOpacity: number
  path: T[] | undefined
}

/**
 *
 */
export function makeCalculateHeatMapProps<T extends Geo.AnyLatLng>(
  transformPolygon: Geo.PolygonTransformer<T>,
) {
  return ({
    applicationRateReportsMm,
    distanceMmMax,
    distanceReportsMm,
    endPoint,
    extendHeadingDegrees,
    isSelected,
    reelSprinklerType,
    reelSwathWidthMm,
    shouldBoostContrast,
  }: HeatmapOptions &
    Pick<
      Models.ReelRun,
      | "applicationRateReportsMm"
      | "distanceMmMax"
      | "distanceReportsMm"
      | "endPoint"
      | "extendHeadingDegrees"
      | "reelSprinklerType"
      | "reelSwathWidthMm"
    > & {
      isSelected: boolean
    }): Array<HeatmapPolygonPaneValue<T>> | undefined => {
    const result: Array<HeatmapPolygonPaneValue<T>> = []

    const zThreshold = 0.3

    const lowestReportedDistance = distanceReportsMm.slice(-1)[0]
    const highestReportedDistance = [...distanceReportsMm][0] ?? distanceMmMax
    const { getStandardDeviationsFromMean } = getStandardDeviation(
      applicationRateReportsMm ?? [],
    )

    if (
      !(
        isValidNumber(lowestReportedDistance) &&
        isValidNumber(extendHeadingDegrees) &&
        isValidNumber(reelSwathWidthMm)
      )
    ) {
      return undefined
    }

    let index = distanceReportsMm.length - 1
    let origin = Geo.point(endPoint)?.projectMm({
      direction: extendHeadingDegrees,
      distance: lowestReportedDistance,
    })
    let distanceMmCurrent = lowestReportedDistance
    let destination: Geo.GeoPoint | undefined
    let distanceMmElapsed: number | undefined
    let loopCount = 0

    // loop backwards through the array (like how the reel extends) and draw a
    // polygon for each distance array
    while (index >= 0) {
      // default opacity
      let opacityMin = 0.1
      let opacityMax = 0.4
      let standardAdjustment = 0.1
      // increase the opacity when the run is selected
      if (isSelected) {
        opacityMax = 0.8
      }
      // show maximum contrast to reveal underwatered areas
      if (shouldBoostContrast) {
        opacityMax = 1
        opacityMin = 0
        standardAdjustment = 0.25
      }

      const nextDistance = distanceReportsMm[index]
      if (isValidNumber(nextDistance)) {
        // calculate how far this distance report is from the last one
        distanceMmElapsed = nextDistance - distanceMmCurrent
        // plot a point at the given distance from the last point
        destination = origin?.projectMm({
          direction: extendHeadingDegrees,
          distance: distanceMmElapsed,
        })
        let isPartialRun = false
        if (
          isValidNumber(distanceMmMax) &&
          isValidNumber(highestReportedDistance) &&
          distanceMmMax - highestReportedDistance >
            PARTIAL_RUN_DIFF_THRESHOLD_MM
        ) {
          isPartialRun = true
        }
        const shouldDrawEndcap =
          index === 0 && reelSprinklerType === "gun" && !isPartialRun
        /*
       draw a rectangle that looks like this:
       corner ---------------- corner
       |                            |
       origin             destination    # maybe a semicircle over here
       |                            |
       corner ---------------- corner

       extend heading going this way ->

       */

        const path = transformPolygon(
          drawRectangle({
            destination,
            direction: extendHeadingDegrees,
            origin,
            shouldDrawEndcap,
            widthMm: reelSwathWidthMm,
          }),
        )
        if (path) {
          const fillRgb = parseRgbValues(hexToRgb(COLORS.$lightBlue[600]))

          let fillOpacity = 0
          let zScore = 0

          // grab the corresponding application rate
          // in order to adjust the opacity
          const applicationRate = (applicationRateReportsMm ?? [])[index]
          const isValidApplicationRate = isValidNumber(applicationRate)

          if (isValidApplicationRate) {
            fillOpacity = applicationRate / 100
            zScore = getStandardDeviationsFromMean(applicationRate)
          }
          // when selected, we want to make the run very visible because it is
          // isolated
          if (isSelected) {
            fillOpacity *= 2
          }

          if (shouldBoostContrast) {
            // adjust base on standard deviation (z-score)
            if (zScore > zThreshold) {
              fillOpacity += standardAdjustment
            }
            if (zScore < -zThreshold) {
              fillOpacity -= standardAdjustment
            }
          }
          // smooth out gaps in between observation reports
          if (!isValidNumber(distanceMmElapsed) || distanceMmElapsed < 500) {
            opacityMin = 0.1
          }
          // truncate the opacity value
          if (isValidApplicationRate) {
            if (fillOpacity > opacityMax) {
              fillOpacity = opacityMax
            }
            if (fillOpacity < opacityMin) {
              fillOpacity = opacityMin
            }
          }
          if (fillRgb) {
            result.push({
              ...fillRgb,
              fillColor: `rgb(${fillRgb.red}, ${fillRgb.green}, ${fillRgb.blue})`,
              fillOpacity,
              path,
            })
          } else {
            logger.error(
              `Run polygon skippd due to invalid Rgb values in parseRgbValues`,
            )
          }
        }
        // advance the distance by the length of the observation report
        distanceMmCurrent += distanceMmElapsed

        // kill the component if it infinite loops for whatever reason
        loopCount++
        if (loopCount > 3000) {
          throw new TypeError(`Too many distance reports to render`)
        }
      }
      index--
      // on the next loop we will start from where we left off
      origin = destination
    }

    return result
  }
}
export function makeHeatmapSelector<T extends Geo.AnyLatLng>(
  transformPolygon: Geo.PolygonTransformer<T>,
): (
  state: RootState,
  id: number | undefined,
  isSelected: boolean,
) => Array<HeatmapPolygonPaneValue<T>> | undefined {
  const calculateHeatMapProps = makeCalculateHeatMapProps(transformPolygon)
  /**
   * Cache a heatmap array for each reel run so that we only calculate once
   */
  return createCachedSelector(
    Models.fieldProfileReelRuns.selectById,
    getShouldBoostHeatmapContrastFromState,
    (
      _state: RootState,
      _id: number | undefined,
      isSelected: boolean,
    ): boolean => isSelected,
    (
      runData,
      shouldBoostContrast,
      isSelected,
    ): Array<HeatmapPolygonPaneValue<T>> | undefined => {
      if (runData) {
        try {
          return calculateHeatMapProps({
            ...runData,
            isSelected,
            shouldBoostContrast,
          })
        } catch (error) {
          logger.error(error)
        }
      }
      return undefined
    },
  )(
    // make a unique cache key to save results
    (state: RootState, id: number | undefined, isSelected: boolean): string => {
      const shouldBoostContrast = getShouldBoostHeatmapContrastFromState(state)
      const key = `${id ?? "undefined"}/${
        shouldBoostContrast ? "boosted" : ""
      }/${isSelected ? "iso" : ""}`
      return key
    },
  )
}

export type CalculateReelRunApplicationParams = Pick<
  Models.ReelRun,
  | "applicationRateReportsMm"
  | "distanceMmCurrent"
  | "distanceMmMax"
  | "distanceReportsMm"
  | "reelSwathWidthMm"
>

function convertMillimetersSquareToAcreInches(rawValue: number) {
  return Geo.round(convert(rawValue).from("mm2").to("ac"), 2)
}
/**
 *
 */
export function calculateReelRunApplication(
  runData: CalculateReelRunApplicationParams,
) {
  let irrigatedAcresTotal: number | undefined
  let applicationAcreInchesTotal: number | undefined
  const applicationRateMmAverage = _.mean(runData.applicationRateReportsMm)
  const { distanceMmElapsed } = getDistancesForReelRun(runData)
  if (
    isValidNumber(distanceMmElapsed) &&
    isValidNumber(runData.reelSwathWidthMm)
  ) {
    irrigatedAcresTotal = convertMillimetersSquareToAcreInches(
      runData.reelSwathWidthMm * distanceMmElapsed,
    )
  }

  if (
    isValidNumber(irrigatedAcresTotal) &&
    isValidNumber(applicationRateMmAverage)
  ) {
    applicationAcreInchesTotal =
      irrigatedAcresTotal *
      convert(applicationRateMmAverage).from("mm").to("in")
    if (isValidNumber(applicationAcreInchesTotal)) {
      applicationAcreInchesTotal = Geo.round(applicationAcreInchesTotal, 2)
    }
  }

  return {
    applicationAcreInchesTotal,
    applicationRateMmAverage,
    irrigatedAcresTotal,
  }
}
