import React from "react"
import { StyleSheet } from "react-native"
import { useStyle } from "react-native-style-utilities"

import { Card, useBreakpointValue } from "./components"
import { RADII } from "./components/theme"

import type { CardProps } from "./components"
type ResponsiveCardProps = CardProps

const styles = StyleSheet.create({
  largeScreen: {
    borderRadius: RADII.$default,
  },
  smallScreen: {
    borderRadius: 0,
  },
})

export function ResponsiveCard({ style, ...rest }: ResponsiveCardProps) {
  const getValue = useBreakpointValue()

  return (
    <Card
      {...rest}
      style={useStyle(() => {
        return [
          style,
          getValue({
            base: styles.smallScreen,
            sm: styles.largeScreen,
          }),
        ]
      }, [getValue, style])}
    />
  )
}
