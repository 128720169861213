import React from "react"

import { AlertBodyText, AlertCard, AppText } from "./components"
import { Theme } from "./components/theme"
import * as CreateConfiguration from "./CreateDeviceConfigurationContext"
import {
  ChooseSensorPort,
  MagnetSelect,
  WheelDiameterInput,
} from "./DeviceSensorFields"
import i18n from "./translations/i18n"

import type { AppTextProps } from "./components"

function InstructionsText(props: AppTextProps) {
  return <AppText style={{ marginBottom: Theme.spacing.$4 }} {...props} />
}

/**
 * Initial configuration form for travelers, center-pivots, and linear-move
 */
export function CreateTraveler(): React.JSX.Element {
  enum Stages {
    diameterMm = 0,
    nMagnets = 1,
    sensorPort = 2,
    pressureSensor = 3,
    pressureSensorModel = 4,
    pressureSensorThresholds = 5,
    deviceName = 6,
    reviewAndSubmit = 7,
  }
  const { form, onClearInstallationType } = CreateConfiguration.useContext()

  const [stage, setStage] = React.useState<Stages>(0)

  const hasPressure = Boolean(form.watch("pressure"))

  const stageProps: CreateConfiguration.StageProps = {
    onPressCancel: () => setStage((prev) => prev - 1),
    onPressSubmit: form.handleSubmit(() => setStage((prev) => prev + 1)),
  }
  switch (stage) {
    case Stages.diameterMm: {
      return (
        <CreateConfiguration.ConfigurationStage
          {...stageProps}
          stageIndex={0}
          titleText={i18n.t("deviceConfiguration:wheelDiameterStageTitle")}
          onPressCancel={onClearInstallationType}
        >
          <InstructionsText>
            {i18n.t("deviceConfiguration:wheelDiameterMmInstructions")}
          </InstructionsText>
          <WheelDiameterInput />
        </CreateConfiguration.ConfigurationStage>
      )
    }
    case Stages.nMagnets: {
      return (
        <CreateConfiguration.ConfigurationStage
          {...stageProps}
          stageIndex={1}
          titleText={i18n.t("deviceConfiguration:wheelMagnetsStageTitle")}
        >
          <InstructionsText>
            {i18n.t("deviceConfiguration:wheelNMagnetsInstructions")}
          </InstructionsText>
          <MagnetSelect sensorName="wheel" />
        </CreateConfiguration.ConfigurationStage>
      )
    }
    case Stages.sensorPort: {
      return (
        <CreateConfiguration.ConfigurationStage
          {...stageProps}
          stageIndex={2}
          titleText={i18n.t("deviceConfiguration:wheelSensorPortStageTitle")}
        >
          <InstructionsText>
            {i18n.t("deviceConfiguration:wheelSensorPortInstructions")}
          </InstructionsText>
          <AlertCard
            mb="$4"
            severity="info"
            titleText={i18n.t("deviceConfiguration:hintWithColon")}
          >
            <AlertBodyText fontSize="$sm">
              {i18n.t("deviceConfiguration:sensorPortHint")}
            </AlertBodyText>
          </AlertCard>
          <ChooseSensorPort sensorName="wheel" />
        </CreateConfiguration.ConfigurationStage>
      )
    }
    case Stages.pressureSensor: {
      return (
        <CreateConfiguration.PressureSensorYesOrNoStage
          stageIndex={3}
          onCancel={() => setStage(Stages.sensorPort)}
          onValueChange={(value) => {
            setStage(value ? Stages.pressureSensorModel : Stages.deviceName)
          }}
        />
      )
    }
    case Stages.pressureSensorModel: {
      return (
        <CreateConfiguration.ChoosePressureSensorModelStage
          {...stageProps}
          stageIndex={4}
        />
      )
    }

    case Stages.pressureSensorThresholds: {
      return (
        <CreateConfiguration.SetPressureThresholdsStage
          {...stageProps}
          stageIndex={5}
        />
      )
    }

    case Stages.deviceName: {
      return (
        <CreateConfiguration.CustomizeDeviceNameStage
          {...stageProps}
          stageIndex={hasPressure ? 6 : 4}
        />
      )
    }
    case Stages.reviewAndSubmit: {
      return (
        <CreateConfiguration.ReviewAndSubmitStage
          {...stageProps}
          sensorNames={["wheel", hasPressure && "pressure"]}
          stageIndex={hasPressure ? 7 : 5}
        />
      )
    }
  }
}
