import React from "react"
import { useTranslation } from "react-i18next"
import { Alert, Share } from "react-native"

import { getIsShareAvailable } from "./app-state.reducer"
import { AppIcons, Badge, Box, Button, Row, useClipboard } from "./components"
import { AppText, TextWithIcon } from "./components/Text"
import { SPACING } from "./components/theme"
import { getFarmJoinCodeFromState } from "./farm.reducer"
import { logger } from "./logger"
import i18n from "./translations/i18n"
import { getActiveFarmNameFromState } from "./user-farms.selectors"
import { useRootSelector, useShallowEqualSelector } from "./useRootSelector"

import type { PermissionCheckProps } from "./types"

import type {
  BadgeProps,
  HelpContentStatic,
  IconButtonProps,
  IconButtonStyleProps,
  RowProps,
} from "./components"
import type { FarmJoinCode, FarmJoinCodeStatus } from "./models"
export const HELP_CONTENT: HelpContentStatic = {
  bodyElement: i18n.t("farmJoinCodes:farmJoinCodeHelpSummary"),
  subject: "farm_join_codes",
  titleElement: i18n.t("farmJoinCodes:aboutFarmJoinCodes"),
}

function FarmJoinCodeStatusBadge({
  codeStatus,
}: {
  codeStatus: FarmJoinCode["codeStatus"] | null | undefined
}) {
  const { t } = useTranslation("farmJoinCodes")

  let text: string
  let colorScheme: BadgeProps["colorScheme"] = "$green"
  switch (codeStatus) {
    case "ACTIVE": {
      text = t("farmJoinCodeStatus.ACTIVE")
      break
    }
    case "EXPIRED": {
      text = t("farmJoinCodeStatus.EXPIRED")
      colorScheme = "$warning"
      break
    }
    case "NOT_CREATED": {
      text = t("farmJoinCodeStatus.NOT_CREATED")
      colorScheme = "$gray"
      break
    }
    case null:
    case undefined: {
      return null
    }
  }

  return <Badge colorScheme={colorScheme}>{text}</Badge>
}

type CopyButtonProps = IconButtonStyleProps & {
  codeStatus: FarmJoinCodeStatus
  codeString: string | undefined
}
/**
 *
 */
export function ShareFarmJoinCodeButton({
  codeStatus,
  codeString,
  joinFarmUrl,
  ...buttonProps
}: CopyButtonProps & {
  joinFarmUrl: string
}) {
  const { t } = useTranslation("farmJoinCodes")
  const activeFarmName = useRootSelector(getActiveFarmNameFromState)
  const isShareAvailable = useRootSelector(getIsShareAvailable)
  if (!isShareAvailable) {
    logger.info("share not available")
    return null
  }
  const handlePress = () => {
    if (codeStatus === "ACTIVE") {
      // Share the join code if it is active
      const title = t("farmJoinCodeShareTitleWithActiveFarmName", {
        activeFarmName,
      })

      const message = t(
        "farmJoinCodeShareMessageWithActiveFarmNameAndCodeString",
        { activeFarmName, codeString },
      )
      Share.share(
        { message, title, url: joinFarmUrl },
        { subject: title },
      ).catch((error) => {
        logger.error(error)
      })
    } else {
      // Otherwise, show a deny modal
      Alert.alert(t("farmJoinCodeNotCreatedMessage"))
    }
  }
  return (
    <Button
      IconComponent="Share"
      isDisabled={typeof activeFarmName === "undefined"}
      size="sm"
      text={t("shareCode")}
      variant="primary"
      {...buttonProps}
      onPress={handlePress}
    />
  )
}

const iconButtonProps: Omit<IconButtonProps, "IconComponent"> = {
  variant: "subtle",
}

export function CopyFarmJoinCodeButton({
  codeStatus,
  codeString,
  ...props
}: CopyButtonProps) {
  const { t } = useTranslation("farmJoinCodes")
  const { onCopy } = useClipboard()

  const handlePressCopy = () => {
    switch (codeStatus) {
      case "ACTIVE": {
        if (typeof codeString === "string") {
          onCopy(codeString)
        } else {
          Alert.alert(t("somethingWentWrong"), t("unableToCopyCode"))
        }
        break
      }
      case "EXPIRED": {
        Alert.alert(t("codeExpired"), t("codeExpiredErrorMessage"))
        break
      }
      case "NOT_CREATED": {
        Alert.alert(t("codeNotCreated"), t("codeNotCreatedErrorMessage"))
        break
      }
    }
  }
  return (
    <Button
      IconComponent={AppIcons.Clipboard}
      id="farm-join-code-copy-btn"
      size="sm"
      text={t("copyCode")}
      variant="outline"
      {...props}
      onPress={handlePressCopy}
    />
  )
}
/**
 * Show current farm join code
 */
export function FarmJoinCodeListItem({
  helpElement,
  joinFarmUrl,
  onPressCreateJoinCode,
  withPermissions,
  ...rest
}: PermissionCheckProps &
  RowProps & {
    helpElement: React.JSX.Element
    joinFarmUrl: string
    onPressCreateJoinCode: () => void
  }) {
  const { t } = useTranslation("farmJoinCodes")

  const currentCode = useShallowEqualSelector(getFarmJoinCodeFromState)

  if (!currentCode) {
    return null
  }

  return (
    <Box flexWrap="wrap" id="farm-join-code" w="$full" {...rest}>
      <TextWithIcon
        IconComponent={AppIcons.FarmJoinCode}
        actionElement={helpElement}
        text={t("farmJoinCodeTitle")}
      />
      <Row alignItems="baseline">
        <Box>
          <AppText colorScheme="secondary" fontSize="$sm">
            {t("yourCode")}
          </AppText>
          <Row>
            <AppText
              fontFamily="Poppins_800ExtraBold"
              fontSize="$h2"
              style={{ marginRight: SPACING.$4 }}
            >
              {currentCode.codeStr}
            </AppText>
            <FarmJoinCodeStatusBadge codeStatus={currentCode.codeStatus} />
          </Row>
        </Box>
      </Row>
      <Row>
        <Box mr="$4" my="$2">
          <CopyFarmJoinCodeButton
            codeStatus={currentCode.codeStatus}
            codeString={currentCode.codeStr}
          />
        </Box>
        <Box my="$2">
          <ShareFarmJoinCodeButton
            {...iconButtonProps}
            codeStatus={currentCode.codeStatus}
            codeString={currentCode.codeStr}
            joinFarmUrl={joinFarmUrl}
          />
        </Box>
        <Box ml="auto" my="$2">
          <Button
            {...iconButtonProps}
            IconComponent="Add"
            id="new-join-code-btn"
            size="sm"
            text={i18n.t("farmJoinCodes:newCode")}
            variant="text"
            onPress={withPermissions({
              callback: onPressCreateJoinCode,
              required: "canManageJoinCodes",
            })}
          />
        </Box>
      </Row>
    </Box>
  )
}
