/*
const options = {
  year: 'numeric',       // "numeric" | "2-digit"
  month: 'long',         // "numeric" | "2-digit" | "narrow" | "short" | "long"
  day: '2-digit',        // "numeric" | "2-digit"
  weekday: 'long',       // "narrow" | "short" | "long"
  hour: '2-digit',       // "numeric" | "2-digit"
  minute: '2-digit',     // "numeric" | "2-digit"
  second: '2-digit',     // "numeric" | "2-digit"
  timeZoneName: 'short', // "short" | "long"
};

 */

export interface I18NDateOptions {
  dateStyle?: "full" | "long" | "medium" | "short"
  day?: "2-digit" | "numeric"
  dayPeriod?: "long" | "narrow" | "short"
  hour?: "2-digit" | "numeric"
  minute?: "2-digit" | "numeric"
  month?: "2-digit" | "long" | "narrow" | "numeric" | "short"
  second?: "2-digit" | "numeric"
  timeStyle?: "full" | "long" | "medium" | "short"
  timeZoneName?: "long" | "short"
  weekday?: "long" | "narrow" | "short"
  year?: "2-digit" | "numeric"
}

export function getDateFormatOptions(options: I18NDateOptions) {
  return options
}
