/* eslint-disable @typescript-eslint/no-use-before-define */
import React from "react"
import { useTranslation } from "react-i18next"
import { StyleSheet } from "react-native"

import {
  AppIcons,
  AppText,
  Badge,
  Box,
  getIconForInstallationType,
  Pressable,
  renderIconFromIconProp,
  Row,
  View,
} from "./components"
import { Outline } from "./components/Outline"
import { SPACING } from "./components/theme"
import { useFormatDeviceActionDisplayName } from "./named-device-actions.reducer"
import { getDeviceSummaryByDeviceIdFromState } from "./selectors"
import { useFormatInstallationType } from "./sensor-configurations"
import { useFormatSensorName, useFormatSensorState } from "./sensor-formatting"
import { useRootSelector } from "./useRootSelector"

import type { ViewProps } from "./components"
import type { DeviceSummary } from "./device-configurations.reducer"
import type * as Models from "./models"
import type { DeviceIdProps } from "./types"

/**
 * Shows the given device's, name, installation type, and role in the device
 * connection
 */
function DeviceConnectionNameDisplay({
  deviceInstallationType,
  deviceName,
  deviceRole,
  textElement,
  ...rest
}: DeviceSummary &
  ViewProps & {
    deviceRole: "source" | "target"
    textElement: React.JSX.Element | null
  }): React.JSX.Element {
  const formatInstallationType = useFormatInstallationType()
  const installationTypeFormatted = formatInstallationType(
    deviceInstallationType,
  )
  const { t } = useTranslation("deviceConnections")
  let roleText: string
  if (deviceRole === "source") {
    roleText = t("source")
  } else {
    roleText = t("target")
  }

  return (
    <Outline style={styles.root} {...rest}>
      <Row flexWrap="nowrap" mb="$2">
        <View style={styles.iconContainer}>
          {renderIconFromIconProp(
            getIconForInstallationType(deviceInstallationType),
          )}
        </View>
        <Box flex={1}>
          <Row justifyContent="space-between">
            <AppText isTruncated flex={1} style={{ marginRight: SPACING.$2 }}>
              {deviceName}
            </AppText>
            <Badge isTruncated fontSize="$xs">
              {roleText}
            </Badge>
          </Row>
          <AppText colorScheme="secondary" fontSize="$sm">
            {installationTypeFormatted}
          </AppText>
        </Box>
      </Row>
      {textElement}
    </Outline>
  )
}
const styles = StyleSheet.create({
  iconContainer: {
    marginRight: SPACING.$4,
  },
  root: {
    marginHorizontal: SPACING.$1,
    marginVertical: SPACING.$1,
    overflow: "hidden",
    paddingVertical: SPACING.$1,
  },
})

// TODO: Simplify this further
export interface DeviceConnectionDisplayProps
  extends Models.EventActionTrigger {
  onPressDevice?: (
    params: DeviceIdProps & {
      codaDeviceAlias: string
    },
  ) => void
}

/**
 * Display a connection between two devices
 */
export function DeviceConnectionDisplay({
  namedDeviceAction,
  onPressDevice,
  sourceDeviceId,
  sourceSensor,
  sourceSensorStateCurrent,
  sourceSensorStatePrevious,
  targetDeviceId,
}: DeviceConnectionDisplayProps) {
  const { t } = useTranslation("devices")
  const formatSensorName = useFormatSensorName()
  const formatSensorState = useFormatSensorState()

  const formatAction = useFormatDeviceActionDisplayName()
  const actionFormatted = formatAction(namedDeviceAction)
  const sensorNameFormatted = formatSensorName(sourceSensor)
  const statePreviousFormatted = formatSensorState(sourceSensorStatePrevious, {
    labelLength: "long",
  })
  const stateCurrentFormatted = formatSensorState(sourceSensorStateCurrent, {
    labelLength: "long",
  })
  const sourceDevice = useRootSelector((state) => {
    return getDeviceSummaryByDeviceIdFromState(state, sourceDeviceId)
  })
  const targetDevice = useRootSelector((state) => {
    if (typeof targetDeviceId === "string") {
      return getDeviceSummaryByDeviceIdFromState(state, targetDeviceId)
    }
    return undefined
  })

  let sourceDeviceElement: React.ReactNode
  let targetDeviceElement: React.ReactNode | null = null

  const iconSize = 32
  if (sourceDevice) {
    sourceDeviceElement = (
      <DeviceConnectionNameDisplay
        deviceRole="source"
        textElement={
          <Row>
            <AppIcons.Signal size={iconSize} />
            <Box ml="$1">
              <AppText colorScheme="secondary" fontSize="$sm">
                {t("conditionLabel", { ns: "deviceActions" })}
              </AppText>
              <Row flexWrap="nowrap">
                <Box mr="$1">
                  <AppText>{sensorNameFormatted}</AppText>
                </Box>
                <AppText>{statePreviousFormatted}</AppText>
                <AppIcons.ArrowRight />
                <AppText>{stateCurrentFormatted}</AppText>
              </Row>
            </Box>
          </Row>
        }
        {...sourceDevice}
      />
    )
    if (onPressDevice) {
      sourceDeviceElement = (
        <Pressable onPress={() => onPressDevice(sourceDevice)}>
          {sourceDeviceElement}
        </Pressable>
      )
    }
    if (targetDevice) {
      targetDeviceElement = (
        <DeviceConnectionNameDisplay
          deviceRole="target"
          textElement={
            typeof actionFormatted === "string" ? (
              <Row flexWrap="nowrap">
                <AppIcons.DeviceCommandsRemote size={iconSize} />
                <Box ml="$1">
                  <AppText colorScheme="secondary" fontSize="$sm">
                    {t("actionLabel", { ns: "deviceActions" })}
                  </AppText>
                  <AppText>{actionFormatted}</AppText>
                </Box>
              </Row>
            ) : null
          }
          {...targetDevice}
        />
      )
      if (onPressDevice) {
        targetDeviceElement = (
          <Pressable onPress={() => onPressDevice(targetDevice)}>
            {targetDeviceElement}
          </Pressable>
        )
      }
    }
  }

  return (
    <Row alignItems="stretch" mx="$-2">
      <Box flex={1} minW="$xs" px="$1">
        {sourceDeviceElement}
      </Box>
      <Box flex={1} minW="$xs" px="$1">
        {targetDeviceElement}
      </Box>
    </Row>
  )
}
