import React from "react"
import { Clipboard } from "react-native"

import i18n from "../translations/i18n"
import { useToasts } from "./useToasts"

/**
 * A hook that provides a function to copy a string to the clipboard.
 */
export function useClipboard() {
  const [hasCopied, setHasCopied] = React.useState(false)
  const [value, setValue] = React.useState<string>("")
  const toast = useToasts()
  // TODO: use expo-clipboard
  const onCopy = React.useCallback(
    (copiedValue: string, options?: { successMessage?: string }) => {
      const successMessage =
        options?.successMessage ?? i18n.t("copiedToClipboard")
      Clipboard.setString(copiedValue)
      toast.info(successMessage)

      setValue(copiedValue)
      setHasCopied(true)
    },
    [toast],
  )
  return { hasCopied, onCopy, value }
}
