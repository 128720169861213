import React from "react"
import { FormProvider, useForm } from "react-hook-form"

import { selectReelRunHistorical, setFieldActionsName } from "./actions"
import { useToasts } from "./components/useToasts"
import { updateFieldBoundaryAsync } from "./farmhq-api"
import * as Geo from "./geo"
import { isValidNumber } from "./type-guards"
import { useBackendRequest } from "./useBackendRequest"
import { useRootDispatch } from "./useRootDispatch"

import type { AcceptsChildren } from "./components"

import type { FieldIdProps } from "./types"
import type { FieldActionName } from "./fields.reducer"
export interface FieldActionsFormData {
  fieldName: string
  labelRotationDegrees: number
  rowDirectionAzimuthDegrees: number
}

interface ProviderProps {
  fieldName: string | undefined
  labelRotationDegrees: number | undefined
  rowDirectionAzimuthDegrees: number | undefined
  selectedRunId: number | undefined
}

function useFieldProfileContext({
  fieldName = "",
  labelRotationDegrees = 0,
  rowDirectionAzimuthDegrees = 0,
  selectedRunId,
}: ProviderProps) {
  const dispatch = useRootDispatch()
  const setActionName = React.useCallback(
    (name: FieldActionName | undefined) => dispatch(setFieldActionsName(name)),
    [dispatch],
  )

  // Forms
  const formMethods = useForm<FieldActionsFormData>({
    defaultValues: {
      fieldName,
      labelRotationDegrees,
      rowDirectionAzimuthDegrees,
    },
  })
  const { setValue } = formMethods

  // Reset everything to the field's initial value
  const onCancel = React.useCallback((): void => {
    setValue("fieldName", fieldName)
    setValue("labelRotationDegrees", labelRotationDegrees)
    setValue("rowDirectionAzimuthDegrees", rowDirectionAzimuthDegrees)

    if (isValidNumber(selectedRunId)) {
      dispatch(selectReelRunHistorical({ reelRunId: undefined }))
    } else {
      setActionName(undefined)
    }
  }, [
    dispatch,
    fieldName,
    labelRotationDegrees,
    rowDirectionAzimuthDegrees,
    selectedRunId,
    setActionName,
    setValue,
  ])

  const toasts = useToasts()

  const onSuccess = React.useCallback(() => {
    toasts.success()
    return onCancel()
  }, [onCancel, toasts])

  /**
   * When user changes fields, reset the forms
   */
  React.useEffect(() => {
    setValue("fieldName", fieldName)
    setValue("labelRotationDegrees", labelRotationDegrees)
    setValue("rowDirectionAzimuthDegrees", rowDirectionAzimuthDegrees)
  }, [fieldName, labelRotationDegrees, rowDirectionAzimuthDegrees, setValue])

  // Reset everything to the field's initial value
  const handleClearFieldForm = React.useCallback((): void => {
    setValue("fieldName", fieldName)
    setValue("labelRotationDegrees", labelRotationDegrees)
    setValue("rowDirectionAzimuthDegrees", rowDirectionAzimuthDegrees)

    if (isValidNumber(selectedRunId)) {
      dispatch(selectReelRunHistorical({ reelRunId: undefined }))
    } else {
      setActionName(undefined)
    }
  }, [
    dispatch,
    fieldName,
    labelRotationDegrees,
    rowDirectionAzimuthDegrees,
    selectedRunId,
    setActionName,
    setValue,
  ])

  return {
    fieldName,
    formMethods,
    handleClearFieldForm,
    labelRotationDegrees,
    onCancel,
    onSuccess,
    rowDirectionAzimuthDegrees,
    selectedRunId,
    setActionName,
  }
}

type ContextValue = ReturnType<typeof useFieldProfileContext>
const Context = React.createContext<ContextValue | undefined>(undefined)

export function Provider({
  children,
  ...props
}: AcceptsChildren & ProviderProps): React.JSX.Element | null {
  const value = useFieldProfileContext(props)

  return (
    <Context.Provider value={value}>
      <FormProvider {...value.formMethods}>{children}</FormProvider>
    </Context.Provider>
  )
}

export function useEditFieldBoundary({
  fieldId,
  onSuccess,
}: FieldIdProps & { onSuccess?: () => void }) {
  const { handleError, isLoading, sendRequest } = useBackendRequest(
    updateFieldBoundaryAsync,
  )

  const onSubmit = (polygon: Geo.MakePolygonInput) => {
    const asGeoJson = Geo.polygon(polygon)?.toJson()
    if (asGeoJson) {
      sendRequest({ fieldId, polygon: asGeoJson })
        .then(onSuccess)
        .catch((error) =>
          handleError(error, {
            toastMessage: "default",
          }),
        )
    }
  }
  return { isLoading, onSubmit }
}
