import React from "react"
import { useTranslation } from "react-i18next"

import { Box, Button, FlatList, ListItemCard, Row } from "./components"
import { ListItemTextPrimary } from "./components/ListItem"
import { SIZES } from "./components/theme"
import { loadOtherFarmUsersAsync } from "./farmhq-api"
import { Loader } from "./Loader"
import { logger } from "./logger"
import { NoListItems } from "./NoListItems"
import { getIsAdminModeEnabledFromState } from "./selectors"
import i18n from "./translations/i18n"
import { useBackendRequest } from "./useBackendRequest"
import { useRootSelector } from "./useRootSelector"

import type { FlatListProps, HelpContentStatic } from "./components"
import type * as Models from "./models"
import type { PermissionCheckProps } from "./types"
export const HELP_CONTENT: HelpContentStatic = {
  bodyElement: i18n.t("farmUsers:farmUsersListHelpBodyText"),
  subject: "farm_users_list",
  titleElement: i18n.t("farmUsers:farmUsersListHelpTitle"),
}

function keyExtractor(item: { email: string }) {
  return item.email
}

const ItemSeparatorComponent = () => <Box my="$2" />

/**
 * Show all users for the active farm and expose a link to manage their permissions
 */
export function FarmUsersList({
  autoNavigate,
  isFocused,
  onPressManagePermissions,
  withPermissions,
  ...rest
}: Omit<FlatListProps<Models.OtherFarmUser>, "data" | "renderItem"> &
  PermissionCheckProps & {
    isFocused: boolean
    onPressManagePermissions: (item: Models.OtherFarmUser) => void
    autoNavigate?: boolean
  }) {
  const { t } = useTranslation("farmUsers")

  const { isLoading, sendRequest } = useBackendRequest(loadOtherFarmUsersAsync)
  const isAdminModeEnabled = useRootSelector(getIsAdminModeEnabledFromState)

  const [data, setData] = React.useState<Models.OtherFarmUser[]>([])

  React.useEffect(() => {
    if (isFocused) {
      sendRequest()
        .then(({ items }) => {
          setData(
            items.filter((otherUser) => {
              // If the active user is admin, they can see other admin users
              if (otherUser.isAdmin) {
                return isAdminModeEnabled
              }
              return true
            }),
          )
          if (__DEV__ && autoNavigate === true) {
            const [firstUser] = items
            if (firstUser) {
              onPressManagePermissions(firstUser)
            }
          }
          return undefined
        })
        .catch((error) => {
          logger.error(JSON.stringify(error))

          throw error
        })
    }
  }, [
    autoNavigate,
    isAdminModeEnabled,
    isFocused,
    onPressManagePermissions,
    sendRequest,
  ])

  return (
    <FlatList
      ItemSeparatorComponent={ItemSeparatorComponent}
      data={data}
      id="farm-users-list"
      keyExtractor={keyExtractor}
      refreshing={isLoading}
      variant="page"
      ListEmptyComponent={
        isLoading ? (
          <Loader />
        ) : (
          <NoListItems message={t("farmUsersListListEmpty")} mt="$toolbar" />
        )
      }
      renderItem={({ item }) => {
        return (
          <ListItemCard>
            <Row justifyContent="space-between" w="$full">
              <ListItemTextPrimary
                style={{ minWidth: SIZES.$xs, paddingVertical: SIZES.$2 }}
                textTransform="none"
              >
                {item.email}
              </ListItemTextPrimary>
              <Box ml="auto" py="$2">
                <Button
                  IconComponent="ListItemEnd"
                  iconPosition="right"
                  id="manage-permissions-link"
                  size="sm"
                  text={t("managePermissionsLink")}
                  variant="text"
                  onPress={withPermissions({
                    callback: () => onPressManagePermissions(item),
                    required: "canManageUserPermission",
                  })}
                />
              </Box>
            </Row>
          </ListItemCard>
        )
      }}
      {...rest}
    />
  )
}
