import React from "react"
import { Text as TextBase } from "react-native"
import { useStyle } from "react-native-style-utilities"

import { Box } from "./Box"
import { ICON_SIZES, renderIconFromIconProp } from "./icons"
import { Row } from "./Row"
import { testIds } from "./test-id"
import { FONT_SIZES, getFontName } from "./theme/fonts"
import { useIsDarkMode } from "./ThemeProvider"
import { getTextColorFromColorScheme } from "./utils"

import type { TextProps, TextStyle } from "react-native"
import type { MutuallyExclude } from "../types"
import type { IconProp } from "./icons"
import type { RowProps } from "./Row"
import type { TestId } from "./test-id"
import type { CachedFontName } from "./theme/fonts"
import type { TextColorScheme } from "./utils"
export interface AppTextProps extends Omit<TextProps, "id"> {
  color?: string
  colorScheme?: TextColorScheme
  flex?: TextStyle["flex"]
  fontFamily?: CachedFontName
  fontSize?: keyof typeof FONT_SIZES
  fontStyle?: TextStyle["fontStyle"]
  fontWeight?: TextStyle["fontWeight"]
  id?: TestId
  isTruncated?: boolean
  italic?: boolean
  textAlign?: TextStyle["textAlign"]
  textTransform?: TextStyle["textTransform"]
  underline?: boolean
  variant?: "imageCaption"
}

/**
 * Encapuslated  `Text` component
 */
export function AppText({
  children,
  color,
  colorScheme = "primary",
  disabled,
  flex,
  fontFamily = "OpenSans_500Medium",
  fontSize = "$body",
  fontStyle,
  fontWeight,
  id,
  isTruncated = false,
  italic,
  style,
  textAlign,
  textTransform,
  underline = false,
  ...rest
}: AppTextProps) {
  const isDark = useIsDarkMode()

  if (typeof color === "undefined") {
    color = getTextColorFromColorScheme({ colorScheme, isDark })
  }

  return (
    <TextBase
      {...testIds(id)}
      {...rest}
      allowFontScaling
      accessibilityRole="text"
      accessibilityState={{ disabled }}
      disabled={disabled}
      maxFontSizeMultiplier={1.3}
      {...(isTruncated
        ? { ellipsizeMode: "tail", numberOfLines: 1 }
        : undefined)}
      style={useStyle(() => {
        let fontFamilyNameFinal: CachedFontName = fontFamily
        if (italic === true) {
          fontFamilyNameFinal = getFontName("OpenSans_400Regular_Italic")
        }
        if (fontWeight === "bold") {
          fontFamilyNameFinal = getFontName("OpenSans_700Bold")
        }
        return [
          {
            color,
            flex,
            fontFamily: fontFamilyNameFinal,
            fontSize: FONT_SIZES[fontSize],
            fontStyle,
            fontWeight,
            textAlign,
            textDecorationLine: underline ? "underline" : "none",
            textTransform,
          },
          style,
        ]
      }, [
        color,
        flex,
        fontFamily,
        fontSize,
        fontStyle,
        fontWeight,
        italic,
        style,
        textAlign,
        textTransform,
        underline,
      ])}
    >
      {children}
    </TextBase>
  )
}

export function Instructions({
  children,
  ...rest
}: AppTextProps): React.JSX.Element {
  return (
    <AppText fontSize="$sm" {...rest}>
      {children}
    </AppText>
  )
}

/**
 *
 */
export function TextWithIcon({
  _text,
  IconComponent,
  actionElement,
  children,
  colorScheme,
  text,
  ...rest
}: MutuallyExclude<{ text: React.JSX.Element | string }, { children: string }> &
  Omit<RowProps, "children"> & {
    IconComponent: IconProp
    _text?: AppTextProps
    actionElement?: React.JSX.Element | null
    colorScheme?: TextColorScheme
  }) {
  const textElement = text ?? children
  const iconElement = renderIconFromIconProp(IconComponent, {
    colorScheme: colorScheme ?? _text?.colorScheme,
    size: ICON_SIZES.$lg,
  })
  return (
    <Row {...rest}>
      <Box flexShrink={0} mr="$2">
        {iconElement}
      </Box>
      <Box flex={1} minW="$0">
        {typeof textElement === "string" ? (
          <AppText colorScheme={colorScheme} {..._text}>
            {textElement}
          </AppText>
        ) : (
          textElement
        )}
      </Box>

      {actionElement ? <Box ml="$2">{actionElement}</Box> : null}
    </Row>
  )
}

export function DevText({ children, ...props }: AppTextProps) {
  if (__DEV__) {
    return <AppText {...props}>{children ?? "Hello"}</AppText>
  }
  return null
}
