import React from "react"

import { Divider, Heading, Row } from "./components"
import { Box } from "./components/Box"

import type { HeadingProps } from "./components"
/**
 * Show the title of the stage and the step number
 */
export function StageHeading({
  stageIndex,
  subtitleText,
  titleText,
}: {
  titleText: string
  stageIndex?: number
  subtitleText?: string
}): React.JSX.Element {
  const variant: HeadingProps["variant"] = "h5"
  return (
    <React.Fragment>
      <Row justifyContent="space-between">
        <Row flexGrow={1} flexWrap="nowrap">
          {typeof stageIndex === "number" ? (
            <Box mr="$2">
              <Heading variant={variant}>{stageIndex + 1}.</Heading>
            </Box>
          ) : null}
          <Heading variant={variant}>{titleText}</Heading>
        </Row>
        <Heading colorScheme="secondary" variant={variant}>
          {subtitleText}
        </Heading>
      </Row>
      <Divider mt="$2" />
    </React.Fragment>
  )
}
