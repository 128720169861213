import { StyleSheet } from "react-native"

import {
  COLORS,
  LinkText,
  Pressable,
  renderIconFromIconProp,
  testIds,
  Theme,
  useIsDarkMode,
} from "@fhq/app/components"

import type {
  IconProp,
  PressableProps,
  PressableState,
  TestIdProps,
} from "@fhq/app/components"

const styles = StyleSheet.create({
  tab: {
    alignItems: "center",
    borderColor: COLORS.$divider.light,
    borderWidth: 1,
    display: "flex",

    flexDirection: "row",
    flexGrow: 1,
    justifyContent: "center",
    paddingHorizontal: Theme.spacing.$2,
    paddingVertical: Theme.spacing.$1,
  },
  tabHovered: {
    backgroundColor: Theme.colors.$blue[200],
  },
  tabHoveredDark: {
    backgroundColor: Theme.colors.$gray[700],
  },
  tabPressed: {
    backgroundColor: Theme.colors.$yellow[600],
  },
  tabPressedDark: {
    backgroundColor: Theme.colors.$green[600],
  },
  tabSelected: {
    backgroundColor: COLORS.$yellow[400],
  },
  tabSelectedDark: {
    backgroundColor: Theme.colors.$green[800],
  },
  tabText: {
    marginLeft: Theme.spacing.$1,
  },
})

export function Tab({
  IconComponent,
  id,
  isSelected,
  style,
  text,
  ...rest
}: Omit<PressableProps, "children" | "id"> &
  TestIdProps & {
    isSelected: boolean
    text: string
    IconComponent?: IconProp
  }) {
  const isDark = useIsDarkMode()

  return (
    <Pressable
      {...testIds(id)}
      accessibilityRole="tab"
      style={(state) => {
        const { hovered = false, pressed } = state as PressableState
        if (isDark) {
          return [
            styles.tab,
            hovered && styles.tabHoveredDark,
            pressed && styles.tabPressedDark,
            isSelected && styles.tabSelectedDark,
            typeof style === "function" ? style(state) : style,
          ]
        }
        return [
          styles.tab,
          hovered && styles.tabHovered,
          pressed && styles.tabPressed,
          isSelected && styles.tabSelected,
          typeof style === "function" ? style(state) : style,
        ]
      }}
      {...rest}
    >
      {renderIconFromIconProp(IconComponent, {
        color: isDark ? COLORS.$link.dark : COLORS.$link.light,
        // colorScheme: "secondary",
        size: Theme.sizes.$3,
      })}

      <LinkText style={styles.tabText}>{text}</LinkText>
    </Pressable>
  )
}
