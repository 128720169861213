import React from "react"
import { useTranslation } from "react-i18next"
import { StyleSheet } from "react-native"

import { getAuthStatusFromState } from "./auth.reducer"
import { Progress, View } from "./components"
import { Background } from "./components/Background"
import { AppText } from "./components/Text"
import { SIZES } from "./components/theme"
import { FarmHqLogo } from "./FarmHqLogo"
import { useIsPending } from "./requests.reducer"
import { useRootSelector } from "./useRootSelector"

const styles = StyleSheet.create({
  container: {
    alignItems: "center",
    justifyContent: "flex-start",
    marginHorizontal: "auto",
    marginTop: 150,
  },
  logo: {
    height: SIZES.$32,
    minWidth: 275,
    width: "100%",
  },
  text: {
    marginBottom: SIZES.$4,
  },
})

/**
 * Loading screen intended to show while 'behind the scenes' loading finishes
 */
export function AppIsLoading(): React.JSX.Element {
  const { t } = useTranslation()
  const [value, setValue] = React.useState(30)
  const authStatus = useRootSelector(getAuthStatusFromState)
  const isLoadingUser = useIsPending("LoadActiveUser")
  const isLoadingFarm = useIsPending("LoadApp")
  const isPreparingDemo = useIsPending("PrepareDemoEnvironment")
  React.useEffect(() => {
    let isMounted = true
    const timeout = setTimeout(() => {
      if (isMounted && value < 99) {
        setValue((prev) => prev + 0.5)
      }
    }, 45)
    return () => {
      clearTimeout(timeout)
      isMounted = false
    }
  }, [value])

  let text = t("loading")
  if (isPreparingDemo) {
    text = t("preparingDemo")
  } else if (typeof authStatus === "undefined" || isLoadingUser) {
    text = t("gettingUser")
  } else if (isLoadingFarm) {
    text = t("gettingFarm")
  }

  return (
    <Background alignItems="center" display="flex">
      <View style={styles.container}>
        <FarmHqLogo
          name="lockup-inline-coda.png"
          resizeMode="contain"
          style={styles.logo}
        />
        <AppText style={styles.text}>{text}</AppText>
        <Progress value={value} />
      </View>
    </Background>
  )
}
