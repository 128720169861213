import React from "react"
import { StyleSheet } from "react-native"

import {
  AlertDialog,
  AlertDialogScrollView,
  AppIcons,
  AppText,
  Box,
  Button,
  Heading,
  ICON_SIZES,
  IconButton,
  Image,
  ImageCaptionGradient,
  Pressable,
  Row,
  useBreakpointValue,
  useIsDarkMode,
} from "./components"
import { COLORS, RADII } from "./components/theme"
import {
  formatPressureSensorModel,
  getPressureSensorModelImageUri,
  PRESSURE_SENSOR_MODEL_IDS,
} from "./sensor-configurations"
import i18n from "./translations/i18n"

import type { PaperProps } from "./components"
import type { PressureSensorModelId } from "./sensor-configurations"

const IMAGE_SIZE = 250

const styles = StyleSheet.create({
  image: {
    borderTopLeftRadius: RADII.$default,
    borderTopRightRadius: RADII.$default,
    height: IMAGE_SIZE,
    width: "100%",
  },
  imageEnlarged: {
    borderRadius: RADII.$default,
    height: 400,
    width: "100%",
  },
  itemBase: {
    width: "100%",
  },
  // itemMd: {
  //   width: "25%",
  // },
  itemSm: {
    width: "50%",
  },
})

function PressureSensorModelOption({
  children,
  isSelected,
  modelName,
  onPresssZoom,
  ...rest
}: PaperProps & {
  isSelected: boolean
  modelName: PressureSensorModelId
  onPresssZoom: (name: PressureSensorModelId) => void
}) {
  const isDark = useIsDarkMode()

  const uri = getPressureSensorModelImageUri(modelName)

  let iconElement: React.JSX.Element | undefined
  let backgroundColor: string | undefined

  if (isSelected) {
    backgroundColor = isDark ? COLORS.$info[700] : COLORS.$info[400]
    iconElement = (
      <AppIcons.CheckMark color={COLORS.$white} size={ICON_SIZES.$xl} />
    )
  }
  const enlargeButtonElement = (
    <Row space="$1">
      <AppText colorScheme="lightText" fontFamily="OpenSans_500Medium">
        {i18n.t("clickToEnlarge")}
      </AppText>
      <IconButton
        IconComponent="ZoomIn"
        iconColor={COLORS.$textDark.primary}
        size="sm"
        variant="subtle"
        onPress={() => onPresssZoom(modelName)}
      />
    </Row>
  )
  return (
    <Box bg={backgroundColor} rounded="$default" {...rest}>
      <Box position="relative">
        <Image source={uri} style={styles.image} />
        <ImageCaptionGradient orientation="top">
          <Row alignItems="center" space="$1" w="$full">
            <Heading colorScheme="lightText" variant="h3">
              {formatPressureSensorModel(modelName)}
            </Heading>
            {iconElement}
          </Row>
        </ImageCaptionGradient>
        <ImageCaptionGradient orientation="bottom">
          {enlargeButtonElement}
        </ImageCaptionGradient>
      </Box>
      {children}
    </Box>
  )
}

export function ChoosePressureSensorModel({
  onChange,
  selectedValue: selectedModelName,
}: {
  onChange: (modelName: PressureSensorModelId) => void
  selectedValue?: PressureSensorModelId | null | undefined
}) {
  const [zoomedModelName, setZoomedModelName] =
    React.useState<PressureSensorModelId>()

  const getValue = useBreakpointValue()
  const itemStyle = getValue({
    base: styles.itemBase,
    sm: styles.itemSm,
  })
  // TODO: Show alert when this is not set...also alert on forms when it is required
  return (
    <React.Fragment>
      {zoomedModelName ? (
        <AlertDialog
          isOpen
          titleElement={`${formatPressureSensorModel(zoomedModelName)} ${i18n.t(
            "deviceConfiguration:pressureSensor",
          )}`}
          onClose={() => setZoomedModelName(undefined)}
        >
          <AlertDialogScrollView>
            <Image
              // resizeMode="center"
              source={getPressureSensorModelImageUri(zoomedModelName)}
              style={styles.imageEnlarged}
            />
          </AlertDialogScrollView>
        </AlertDialog>
      ) : null}

      <Row id="choose-pressure-sensor-model">
        {PRESSURE_SENSOR_MODEL_IDS.map((modelName) => {
          const onSelect = () => onChange(modelName)
          const isSelected = selectedModelName === modelName
          return (
            <Pressable key={modelName} style={itemStyle} onPress={onSelect}>
              <PressureSensorModelOption
                isSelected={selectedModelName === modelName}
                modelName={modelName}
                p="$2"
                onPresssZoom={setZoomedModelName}
              >
                <Button
                  isDisabled={isSelected}
                  rounded="$none"
                  text={isSelected ? i18n.t("selected") : i18n.t("select")}
                  onPress={onSelect}
                />
              </PressureSensorModelOption>
            </Pressable>
          )
        })}
      </Row>
    </React.Fragment>
  )
}
