import React from "react"

import {
  Divider,
  getIconForInstallationType,
  MenuItemButton,
} from "./components"
import { Box } from "./components/Box"
import { SPACING } from "./components/theme"
import { useFormatInstallationType } from "./sensor-configurations"

import type { BoxProps } from "./components/Box"
import type { InstallationType } from "./sensor-configurations"

const INSTALLATION_TYPE_OPTIONS: InstallationType[] = [
  "reel",
  "reel_with_booster_off_only",
  "pump",
  "linear_move",
  "center_pivot",
  "valve",
  "traveller_soft",
]

/**
 *
 */
export function ChooseInstallationType({
  onChange,
  ...rest
}: BoxProps & {
  onChange: (nextValue: InstallationType) => void
}): React.JSX.Element {
  const format = useFormatInstallationType()
  return (
    <Box id="choose-installation-type" {...rest}>
      {INSTALLATION_TYPE_OPTIONS.map((value, index) => {
        const text = format(value)
        const IconComponent = getIconForInstallationType(value)
        const onPress = () => onChange(value)
        const element = (
          <MenuItemButton
            key={value}
            IconComponent={IconComponent}
            id={value}
            style={{ paddingHorizontal: SPACING.$4 }}
            text={text}
            onPress={onPress}
          />
        )

        if (index < INSTALLATION_TYPE_OPTIONS.length - 1) {
          return (
            <React.Fragment key={value}>
              {element}
              <Divider />
            </React.Fragment>
          )
        }
        return element
      })}
    </Box>
  )
}
