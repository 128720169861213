import React from "react"
import { Outlet } from "react-router-dom"

import { DeviceOwnership } from "@fhq/app"
import { Box } from "@fhq/app/components/Box"

import AddSensorFormPage from "./pages/AddSensorFormPage"
import { Environment } from "./pages/base/base"
import { ChooseInstallationTypePage } from "./pages/ChooseInstallationTypePage"
import { SetCenterPivotPathPage } from "./pages/SetCenterPivotPathPage"
import { SetLinearPathPage } from "./SetLinearPathPage"

import type { RouteObject } from "react-router-dom"
const DeviceConfigurationLandingPage = React.lazy(
  async () => import("./pages/DeviceConfigurationLandingPage"),
)

const EditSensorFieldPage = React.lazy(
  async () => import("./pages/EditSensorFieldPage"),
)

const DeviceSensorFieldsListPage = React.lazy(
  async () => import("./pages/DeviceSensorFieldsListPage"),
)

const CreateConfigurationPage = React.lazy(
  async () => import("./pages/CreateConfigurationPage"),
)

export const DeviceConfigurationRoutes: RouteObject = {
  children: [
    { element: <DeviceConfigurationLandingPage />, index: true },
    {
      element: <SetCenterPivotPathPage />,
      path: "set-center-pivot-path",
    },
    {
      element: <SetLinearPathPage />,
      path: "set-linear-path",
    },
    {
      element: <AddSensorFormPage />,
      id: "add-sensor",
      path: "add-sensor",
    },
    {
      element: (
        <DeviceOwnership.CreateDeviceShareCode
          baseUrl={Environment.linkUrls.baseUrl}
        />
      ),
      path: "create-share-code",
    },
    {
      children: [
        {
          element: <CreateConfigurationPage />,
          path: ":installationType",
        },
        {
          element: <ChooseInstallationTypePage />,
          index: true,
        },
      ],
      element: (
        <Box flex={1} id="create-configuration">
          <Outlet />
        </Box>
      ),
      path: "create",
    },
    {
      children: [
        { element: <DeviceSensorFieldsListPage />, index: true },
        { element: <EditSensorFieldPage />, path: ":fieldName" },
      ],
      path: ":sensorName",
    },
  ],
  element: (
    <Box flex={1} id="device-configuration">
      <Outlet />
    </Box>
  ),
}
