import { COLORS } from "./colors"
import { FONT_SIZES } from "./fonts"
import { RADII } from "./radii"
import { SIZES } from "./sizes"
import { SPACING } from "./spacing"
import { Z_INDEX } from "./z-index"

export * from "./breakpoints"
export * from "./colors"
export * from "./fonts"
export * from "./radii"
export * from "./sizes"
export * from "./spacing"
export * from "./z-index"

export const Theme = {
  colors: COLORS,
  fontSizes: FONT_SIZES,
  radii: RADII,
  sizes: SIZES,
  spacing: SPACING,
  zIndex: Z_INDEX,
}
