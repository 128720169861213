import type { SetDifference } from "utility-types"
import type {
  MutableSensorName,
  SensorConfigKey,
} from "./sensor-configurations"
import type { SensorEventKey } from "./sensor-events"

import type { PointGeoJson } from "./geo"

export const DEVICE_TIMESERIES_KEYS = [
  `voltageMv`,
  `rateLpmAvg`,
  `readingKpa`,
  `volumeLTotal`,
  `internalSoc`,
] as const
export type DeviceTimeseriesKey = (typeof DEVICE_TIMESERIES_KEYS)[number]
export const REEL_RUN_GRAPH_KEYS = [
  `applicationRateReportsMm`,
  `pressureReportsKpa`,
  `speedReportsMmpm`,
] as const
export type ReelRunGraphKey = (typeof REEL_RUN_GRAPH_KEYS)[number]

export const RUN_OBSERVATION_KEYS = [
  `applicationRateEstimatedMm`,
  `distanceObservedMm`,
  `flowRateEstimatedLpm`,
  `pressureObservedKpa`,
  `speedObservedMmpm`,
] as const
export type ReelRunObservation = {
  [key in (typeof RUN_OBSERVATION_KEYS)[number]]: number | null
}

export type DeviceLocation =
  | {
      errorCode:
        | `invalid location`
        | `no event`
        | `no gps sensor data`
        | `no location`
      value?: undefined
    }
  | { value: PointGeoJson; errorCode?: undefined }

export const SensorConfigBoundaries = {
  thresholdPsiUpper: {
    max: 240,
    min: undefined,
  },
} as const

export type AnySensorKey =
  | ReelRunGraphKey
  | SensorConfigKey
  | SensorEventKey
  | keyof ReelRunObservation
  | "pivotRadiusMeters"
  | `runDistanceMmElapsed`

export type ConfigurableSensorKey = SetDifference<
  SensorConfigKey,
  | `calibrationIntercept`
  | `calibrationSlope`
  | `gpsType`
  | `installedOrientation`
  | `ioPin`
  | `milliRpmFast`
  | `milliRpmSlow`
  | `threshold`
  | `timeBetweenMagnetsMs${`Max` | `Min`}`
  | `voltageIntercept`
  | `voltageSlope`
  | `voltageThresholdLowMv`
>

/**
 * @param sensorName
 */
export function isMutableSensorName(
  sensorName: string,
): sensorName is MutableSensorName {
  const value = sensorName as MutableSensorName | null
  if (
    value === `flow` ||
    value === `pressure` ||
    value === `pressureSwitch` ||
    value === `reel` ||
    value === `wheel`
  ) {
    return true
  }
  return false
}

export const ADMIN_ONLY_FIELDS = [
  "calibrationIntercept",
  "calibrationSlope",
  "configurationId",
  "gpsType",
  "i2CAddress",
  "installedOrientation",
  "ioPin",
  "linearSpeedMmHMax",
  "milliRpmFast",
  "milliRpmSlow",
  "runMagnetCountCurrent",
  "runMagnetCountMax",
  "sensorPriority",
  "statePreviousInitDt",
  "threshold",
  "voltageIntercept",
  "voltageSlope",
  "voltageThresholdLowMv",
  "maxTrackingPercentage",
  "minTrackingPercentage",
] as const

export function isAdminOnlyField(
  key: AnySensorKey,
): key is (typeof ADMIN_ONLY_FIELDS)[number] {
  return ADMIN_ONLY_FIELDS.includes(key as (typeof ADMIN_ONLY_FIELDS)[number])
}
