import React from "react"
import { Platform, StyleSheet } from "react-native"

import { Box, Button, CycleButton, Paper, Row } from "./components"
import { SPACING, Theme } from "./components/theme"
import { RADII } from "./components/theme/radii"
import { getIsChangingActiveFarmFromState } from "./user-farms.reducer"
import {
  getActiveFarmIdFromState,
  getActiveFarmNameFromState,
  getAllRealFarmsFromState,
  getIsDemoModeActiveFromState,
  getRealFarmCountFromState,
} from "./user-farms.selectors"
import { useChangeActiveFarm } from "./UserFarmsList"
import { useRootSelector, useShallowEqualSelector } from "./useRootSelector"

import type { IconButtonProps, PaperProps, RowProps } from "./components"
/**
 *
 */
export function ActiveFarmCycler({
  _buttonProps,
  isChangingFarms,
  ...rest
}: RowProps & {
  isChangingFarms: boolean
  _buttonProps?: Omit<IconButtonProps, "IconComponent">
}): React.JSX.Element {
  const handleChange = useChangeActiveFarm()
  const adjacentFarmIds = useShallowEqualSelector((state) => {
    const allFarms = getAllRealFarmsFromState(state)
    const activeFarmId = getActiveFarmIdFromState(state)
    const activeFarmIndex = allFarms.findIndex(
      (nextFarm) => nextFarm.id === activeFarmId,
    )
    const lastIndex = allFarms.length - 1
    let leftIndex = activeFarmIndex - 1
    let rightIndex = activeFarmIndex + 1

    if (leftIndex < 0) {
      leftIndex = lastIndex
    }
    if (rightIndex > lastIndex) {
      rightIndex = 0
    }

    return {
      left: allFarms[leftIndex],
      right: allFarms[rightIndex],
    }
  })

  const variant = "subtle"
  return (
    <Row id="change-farm-account" py="$2" {...rest}>
      <Box mr="$2">
        <CycleButton
          key="left"
          id="cycle-left"
          isDisabled={isChangingFarms}
          variant={variant}
          onPress={() => {
            if (adjacentFarmIds.left) {
              handleChange(adjacentFarmIds.left)
            }
          }}
          {..._buttonProps}
          IconComponent="ArrowLeft"
        />
      </Box>
      <CycleButton
        key="right"
        id="cycle-right"
        isDisabled={isChangingFarms}
        variant={variant}
        onPress={() => {
          if (adjacentFarmIds.right) {
            handleChange(adjacentFarmIds.right)
          }
        }}
        {..._buttonProps}
        IconComponent="ArrowRight"
      />
    </Row>
  )
}

const styles = StyleSheet.create({
  root: {
    borderRadius: RADII.$default,
    opacity: 0.8,
    paddingHorizontal: SPACING.$2,
    paddingVertical: Platform.select({ web: Theme.spacing.$1 }),
  },
})

export function ActiveFarmIndicator({
  onPress,
  ...rest
}: PaperProps & {
  onPress: (() => void) | undefined
}): React.JSX.Element | null {
  // const countdownValue = useDeviceUpdater()
  const activeFarmName = useRootSelector(getActiveFarmNameFromState)
  const isChangingFarms = useRootSelector(getIsChangingActiveFarmFromState)
  const isDemoMode = useRootSelector(getIsDemoModeActiveFromState)
  const numFarms = useRootSelector(getRealFarmCountFromState)

  let showCycler = numFarms > 1
  if (isDemoMode) {
    showCycler = false
  }
  if (typeof activeFarmName === "undefined") {
    return null
  }
  return (
    <Paper style={styles.root} {...rest}>
      <Row flexWrap="nowrap" justifyContent="flex-end">
        <Box mr="$2">
          <Button
            // _stack={{ flexWrap: "nowrap" }}
            // _text={{ fontSize: "2xs", isTruncated: true }}
            IconComponent="ExpandMore"
            // iconName="farm"
            iconPosition="right"
            isDisabled={isChangingFarms}
            size="sm"
            text={activeFarmName}
            onPress={onPress}
          />
        </Box>
        {showCycler ? (
          <ActiveFarmCycler isChangingFarms={isChangingFarms} />
        ) : null}
      </Row>
    </Paper>
  )
}
