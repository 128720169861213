import convert from "convert-units"
import React from "react"
import { StyleSheet } from "react-native"

import { AppText, Box, View } from "./components"
import { COLORS, RADII, SPACING } from "./components/theme"
import { useMeasurementPreference } from "./selectors"
import { SENSOR_DEFAULTS } from "./sensor-configurations"
import { useFormatSensorValue, useSensorUnitLabel } from "./sensor-formatting"
import { SensorConfigBoundaries } from "./sensors"
import { DoubleValueSlider } from "./sliders"
import i18n from "./translations/i18n"
import { isValidNumber } from "./type-guards"

import type { SensorConfigKey } from "./sensor-configurations"
// TODO: Fix conversions here
// TODO: add text inputs and validate with form

const styles = StyleSheet.create({
  aboveThumbComponent: {
    backgroundColor: COLORS.$green[700],
    borderRadius: RADII.$default,
    padding: SPACING.$1,
    paddingHorizontal: SPACING.$2,
    position: "relative",
    right: SPACING.$5,
  },
})

function useConvertPsi() {
  const measurementPreference = useMeasurementPreference()
  return (value: number) => {
    if (measurementPreference === "metric") {
      return Math.round(convert(value).from("psi").to("kPa"))
    }
    return value
  }
}

/**
 * Min, lower default, upper default, max
 */
const PRESSURE_TRACK_MARKS: [number, number, number, number] = [
  0,
  SENSOR_DEFAULTS.pressure.thresholdPsiLower,
  SENSOR_DEFAULTS.pressure.thresholdPsiUpper,
  SensorConfigBoundaries.thresholdPsiUpper.max,
]

export interface ThresholdSliderValues {
  thresholdPsiLower: number
  thresholdPsiUpper: number
}

/**
 *
 */
function ThresholdValueText({
  name,
  value,
}: {
  name: keyof ThresholdSliderValues
  value: number
}) {
  const formatValue = useFormatSensorValue()
  const valueFormatted = formatValue(name, value)
  const getUnitLabel = useSensorUnitLabel()

  if (typeof valueFormatted === "string") {
    return (
      <AppText>
        {i18n.t(`sensorFields:${name}.descriptionWithValue`, {
          value: `${valueFormatted} ${getUnitLabel(name)}`,
        })}
      </AppText>
    )
  }
  return null
}

const TINT_COLOR_MAX = COLORS.$green[600]
const TINT_COLOR_MIN = COLORS.$green[800]

interface PressureThresholdSliderProps extends ThresholdSliderValues {
  onChange: (next: ThresholdSliderValues) => void
  helpButton?: boolean
  isDisabled?: boolean
}
/**
 *
 */
export function PressureThresholdSlider({
  isDisabled,
  onChange,
  thresholdPsiLower,
  thresholdPsiUpper,
}: PressureThresholdSliderProps): React.JSX.Element {
  const formatValue = useFormatSensorValue()

  const convertPsi = useConvertPsi()
  const trackMarks = PRESSURE_TRACK_MARKS.map(convertPsi)

  const renderTrackMarkComponent = React.useCallback(
    (index: number) => {
      const value = trackMarks[index]
      if (isValidNumber(value)) {
        return (
          <Box mt="$12">
            <AppText>{`${value}`}</AppText>
          </Box>
        )
      }
      return null
    },
    [trackMarks],
  )

  const renderAboveThumb = React.useCallback(
    (index: number): React.JSX.Element => {
      let key: SensorConfigKey
      let rawValue: number
      if (index === 0) {
        key = "thresholdPsiLower"
        rawValue = thresholdPsiLower
      } else {
        key = "thresholdPsiUpper"
        rawValue = thresholdPsiUpper
      }
      return (
        <View style={styles.aboveThumbComponent}>
          <AppText colorScheme="lightText" fontSize="$md" fontWeight="bold">
            {formatValue(key, rawValue)}
          </AppText>
        </View>
      )
    },
    [formatValue, thresholdPsiLower, thresholdPsiUpper],
  )

  const handleChange = React.useCallback(
    (nextValues: [number, number]): void => {
      onChange({
        thresholdPsiLower: nextValues[0],
        thresholdPsiUpper: nextValues[1],
      })
    },
    [onChange],
  )

  return (
    <View id="pressure-slider">
      <ThresholdValueText name="thresholdPsiLower" value={thresholdPsiLower} />
      <Box my="$4">
        <ThresholdValueText
          name="thresholdPsiUpper"
          value={thresholdPsiUpper}
        />
      </Box>
      <Box px="$4" py="$8">
        <DoubleValueSlider
          animateTransitions
          animationType="spring"
          disabled={isDisabled}
          maximumTrackTintColor={TINT_COLOR_MAX}
          minimumTrackTintColor={TINT_COLOR_MIN}
          renderAboveThumbComponent={renderAboveThumb}
          renderTrackMarkComponent={renderTrackMarkComponent}
          trackMarks={trackMarks}
          value={[thresholdPsiLower, thresholdPsiUpper]}
          maximumValue={convertPsi(
            SensorConfigBoundaries.thresholdPsiUpper.max,
          )}
          onValueChange={handleChange}
        />
      </Box>
    </View>
  )
}
