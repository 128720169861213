import React from "react"

import { AlertBodyText, AlertCard, IconButton } from "./components"
import { COLORS } from "./components/theme"
import i18n from "./translations/i18n"

/**
 * This should be displayed when users are updating a configuration value,
 * including sensors, device names, installation types etc.
 */
export function UpdateConfigurationWarning() {
  const [isOpen, setIsOpen] = React.useState(true)

  const titleText = i18n.t("warning")
  if (isOpen) {
    const actionElement = (
      <IconButton
        IconComponent="Close"
        iconColor={COLORS.$textDark.primary}
        size="sm"
        variant="text"
        onPress={() => setIsOpen(false)}
      />
    )
    return (
      <AlertCard
        actionElement={actionElement}
        maxW="$full"
        severity="warning"
        titleText={titleText}
        w="$full"
      >
        <AlertBodyText fontSize="$sm">
          {i18n.t(
            "deviceConfiguration:updateConfigurationSwitchOnToOffWarning",
          )}
        </AlertBodyText>

        <AlertBodyText fontSize="$sm">
          {i18n.t("deviceConfiguration:updateConfigurationDataLossWarning")}
        </AlertBodyText>
      </AlertCard>
    )
  }
  return null
}
