import React from "react"
import { FormProvider } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { Platform, View } from "react-native"

import * as Auth from "./Auth"
import {
  forgotPasswordAsync,
  forgotPasswordSubmitAsync,
  maskEmailAddress,
  useAuthFormHelpers,
} from "./auth.reducer"
import { Box, CancelButton, Column, SubmitButton } from "./components"
import { FormError } from "./components/FormError"
import { useToasts } from "./components/useToasts"
import { useIsPending } from "./requests.reducer"
import { isTruthyString } from "./type-guards"
import { useRootDispatch } from "./useRootDispatch"

import type {
  AuthFormData,
  ForgotPasswordParams,
  UseAuthFormOptions,
} from "./auth.reducer"
import type { AcceptsChildren } from "./components"
interface ProviderProps extends UseAuthFormOptions {
  onPressSignIn: () => void
}

function usePasswordReset({
  defaultValues,
  ...rest
}: ProviderProps & {
  defaultValues?: Partial<AuthFormData>
}) {
  const { t } = useTranslation("auth")
  const isEndToEndTest =
    Platform.OS === "web" && Boolean(window) && Boolean(window.Cypress)

  const dispatch = useRootDispatch()
  const toast = useToasts()
  const [isCodeSent, setIsCodeSent] = React.useState(true)

  const isCodePending = useIsPending("forgotPassword")
  const isSubmitLoading = useIsPending("forgotPasswordSubmit")

  const { errorCode, form, formErrorMessage, handleError } = useAuthFormHelpers(
    {
      defaultValues:
        __DEV__ && !isEndToEndTest ? { ...defaultValues } : undefined,
    },
  )

  const [isSuccess, setIsSuccess] = React.useState(false)
  const [email, setEmail] = React.useState<string>()

  const handleGetCode = React.useCallback(
    (values: Pick<AuthFormData, "email">) => {
      dispatch(forgotPasswordAsync(values))
        .unwrap()
        .then(() => {
          setIsCodeSent(true)
          if (isCodeSent) {
            // only show the toast on a resend code press
            toast.success(t("codeSent", { ns: "common" }))
          }
          return setEmail(values.email)
        })
        .catch(handleError)
    },
    [dispatch, handleError, isCodeSent, t, toast],
  )
  React.useEffect(() => {
    if (!isCodeSent && isTruthyString(email)) {
      handleGetCode({ email })
    }
  }, [email, handleGetCode, isCodeSent])

  let instructions: string | undefined
  if (isTruthyString(email)) {
    instructions = t("passwordResetCodeSentWithEmail", {
      email: maskEmailAddress(email),
    })
  } else if (!Boolean(form.watch("email"))) {
    instructions = t("enterYourEmail")
  }
  return {
    email,
    handleError,
    instructions,
    isCodePending,
    onSubmit: form.handleSubmit((values: ForgotPasswordParams) => {
      if (typeof email === "undefined") {
        throw new TypeError("Email is undefined")
      }
      dispatch(forgotPasswordSubmitAsync({ ...values, email }))
        .unwrap()
        .then(() => setIsSuccess(true))
        .catch((e) => handleError(e))
    }),
    ...rest,
    errorCode,
    form,
    formErrorMessage,
    handleGetCode,
    isSubmitLoading,
    isSuccess,
  }
}
type ContextValue = ReturnType<typeof usePasswordReset>

const Context = React.createContext<ContextValue | undefined>(undefined)

function Provider({
  children,
  ...rest
}: AcceptsChildren & ProviderProps): React.JSX.Element | null {
  const value = usePasswordReset({ ...rest })
  return (
    <Context.Provider value={value}>
      <FormProvider {...value.form}>{children}</FormProvider>
    </Context.Provider>
  )
}

function useContext(): ContextValue {
  const ctx = React.useContext(Context)
  if (typeof ctx === "undefined") {
    throw new TypeError(`PasswordReset Context must be used inside of provider`)
  }
  return ctx
}

function CancelBtn() {
  const { onPressSignIn } = useContext()
  return <CancelButton variant="text" onPress={onPressSignIn} />
}

function Main(): React.JSX.Element {
  const {
    email,
    form,
    formErrorMessage,
    handleGetCode,
    instructions,
    isCodePending,
    isSubmitLoading,
    isSuccess,
    onPressSignIn,
    onSubmit,
  } = useContext()

  let content: React.JSX.Element
  if (isSuccess) {
    content = <Auth.AuthSuccess onPressSignIn={onPressSignIn} />
  } else if (isTruthyString(email)) {
    content = (
      <Column space="$2">
        <Auth.NewPasswordField />
        <Auth.ConfirmPasswordField />
        <Auth.CodeStringField
          label="Code"
          _button={{
            isDisabled: isSubmitLoading || isCodePending,
            isLoading: isCodePending,
            onPress: () => {
              if (!email) {
                throw new TypeError(`Email is undefined`)
              }
              handleGetCode({ email })
            },
          }}
        />
        <Box mt="$4">
          <SubmitButton onPress={onSubmit} />
        </Box>
        <CancelBtn />
        <Auth.SignInLink onPress={onPressSignIn} />
      </Column>
    )
  } else {
    content = (
      <View>
        <Box mb="$2">
          <Auth.EmailField isDisabled={isCodePending} />
        </Box>
        <Box my="$4">
          <SubmitButton size="lg" onPress={form.handleSubmit(handleGetCode)} />
        </Box>
        <CancelBtn />
      </View>
    )
  }

  return (
    <React.Fragment>
      <Auth.InstructionsText>{instructions}</Auth.InstructionsText>
      {content}
      <Box my="$2">
        <FormError>{formErrorMessage}</FormError>
      </Box>
    </React.Fragment>
  )
}

export function Form(props: ProviderProps) {
  return (
    <Provider {...props}>
      <Main />
    </Provider>
  )
}
