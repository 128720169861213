import React from "react"
import { useTranslation } from "react-i18next"

import {
  AlertDialog,
  AlertDialogScrollView,
  Divider,
  MenuItemButton,
} from "./components"
import { requestUpdateFromDeviceAsync } from "./farmhq-api"
import {
  canUseDelayedShutdown,
  useFormatDeviceActionDisplayName,
} from "./named-device-actions.reducer"
import { useIsPending } from "./requests.reducer"
import {
  SET_PID_SETPOINT_FORM_ACTION_NAME,
  SetPidSetpointForm,
} from "./SetPidSetpointForm"
import { SET_VFD_SPEED_FORM_ACTION_NAME, SetVfdSpeedForm } from "./SetVfdSpeed"
import i18n from "./translations/i18n"
import { useBackendRequest } from "./useBackendRequest"

import type { HelpContentStatic, IconProp } from "./components"
import type { DeviceSummary } from "./device-configurations.reducer"
import type * as Models from "./models"
import type { PermissionCheckProps } from "./types"
export const HELP_CONTENT: HelpContentStatic = {
  bodyElement: i18n.t("controlPanelHelpContent", { ns: "deviceActions" }),
  subject: "device_control_panel",
  titleElement: i18n.t("aboutDeviceControls", { ns: "deviceActions" }),
}
/**
 *
 */
export function DeviceControlPanel({
  // _buttons,
  deviceId,
  deviceInstallationType,
  namedDeviceActions,
  onPressAction,
  onPressReturnToOverview,
  onPressSetReelDistance,
  onPressShutdown,
  withPermissions,
}: PermissionCheckProps &
  Pick<DeviceSummary, "deviceId" | "deviceInstallationType" | "deviceName"> & {
    namedDeviceActions: Models.NamedDeviceAction[]
    onPressAction: (action: Models.NamedDeviceAction) => unknown
    onPressReturnToOverview: () => void
    onPressSetReelDistance: () => void
    onPressShutdown: () => void
  }) {
  const { t } = useTranslation()
  const isCallingNamedDeviceAction = useIsPending("CallNamedDeviceAction")

  const formatDisplayName = useFormatDeviceActionDisplayName()

  const {
    handleError,
    isLoading: isForcingUpdate,
    sendRequest,
    toasts,
  } = useBackendRequest(requestUpdateFromDeviceAsync)

  const [vfdAction, setVfdAction] = React.useState<{
    id: number
    name: "pid_setpoint" | "speed"
  }>()

  const handleCloseVfdForm = () => setVfdAction(undefined)
  let dialogElement: JSX.Element | null = null
  let IconComponent: IconProp | undefined
  if (typeof vfdAction?.id === "number") {
    if (vfdAction.name === "speed") {
      IconComponent = "Speed"
      dialogElement = (
        <AlertDialogScrollView>
          <SetVfdSpeedForm
            actionId={vfdAction.id}
            deviceId={deviceId}
            onClosed={handleCloseVfdForm}
            onSuccess={onPressReturnToOverview}
          />
        </AlertDialogScrollView>
      )
    } else {
      IconComponent = "Timeline"
      dialogElement = (
        <AlertDialogScrollView>
          <SetPidSetpointForm
            actionId={vfdAction.id}
            deviceId={deviceId}
            onClose={handleCloseVfdForm}
            onSuccess={onPressReturnToOverview}
          />
        </AlertDialogScrollView>
      )
    }
  }
  return (
    <React.Fragment>
      {Boolean(dialogElement) ? (
        <AlertDialog
          isOpen
          IconComponent={IconComponent}
          titleElement={t("setVfdSpeedFormTitle", { ns: "devices" })}
          onClose={handleCloseVfdForm}
        >
          {dialogElement}
        </AlertDialog>
      ) : null}

      {namedDeviceActions.map((action, i) => {
        const displayNameFormatted = formatDisplayName(action)
        const handlePress = withPermissions({
          callback: () => {
            // Note: we are overriding the displayName here
            // to avoid duplication
            if (action.name === SET_VFD_SPEED_FORM_ACTION_NAME) {
              setVfdAction({ id: action.id, name: "speed" })
            } else if (action.name === SET_PID_SETPOINT_FORM_ACTION_NAME) {
              setVfdAction({ id: action.id, name: "pid_setpoint" })
            } else {
              onPressAction({
                ...action,
                displayName: displayNameFormatted,
              })
            }
          },
          required: "canControlDevicesRemotely",
        })
        return (
          <React.Fragment key={action.id}>
            <MenuItemButton
              IconComponent={action.iconKey}
              disabled={isCallingNamedDeviceAction}
              id={`switch-control-${i}`}
              text={displayNameFormatted}
              onPress={handlePress}
            />
            <Divider />
          </React.Fragment>
        )
      })}
      {canUseDelayedShutdown(deviceInstallationType) ? (
        <React.Fragment>
          <MenuItemButton
            IconComponent="Clock"
            disabled={isCallingNamedDeviceAction}
            text={t("linkToDelayedShutdown", { ns: "createSchedule" })}
            onPress={withPermissions({
              callback: onPressShutdown,
              required: "canManageSchedules",
            })}
          />
          <Divider />
        </React.Fragment>
      ) : null}

      {deviceInstallationType === "reel" ? (
        <React.Fragment>
          <MenuItemButton
            IconComponent="ReelRun"
            disabled={isCallingNamedDeviceAction}
            text={t("setReelDistance.linkTo", { ns: "statusMap" })}
            onPress={withPermissions({
              callback: onPressSetReelDistance,
              required: "canControlDevicesRemotely",
            })}
          />
          <Divider />
        </React.Fragment>
      ) : null}
      <MenuItemButton
        IconComponent="Refresh"
        disabled={isForcingUpdate}
        text={t("forceUpdate", { ns: "devices" })}
        onPress={() => {
          sendRequest({ deviceId })
            .then(() => {
              toasts.success(t("updateRequested", { ns: "devices" }))
              return onPressReturnToOverview()
            })
            .catch((error) => {
              handleError(error, { toastMessage: "default" })
            })
        }}
      />
    </React.Fragment>
  )
}
