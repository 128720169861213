import { CreatePumpConfiguration } from "./CreatePumpConfiguration"
import { ConfigureReel } from "./CreateReelConfiguration"
import { CreateTraveler } from "./CreateTravelerConfiguration"
import { CreateValve } from "./CreateValveConfiguration"

import type { InstallationType } from "./sensor-configurations"

/**
 * Select form component based on installation type
 */
export function getFormComponent(installationType: InstallationType): React.FC {
  switch (installationType) {
    case "reel_with_booster_off_only":
    case "reel": {
      return ConfigureReel
    }
    case "prototype": {
      break
    }
    case "pump_vfd":
    case "pump_off_only":
    case "pump_on_off":
    case "pump": {
      return CreatePumpConfiguration
    }
    case "center_pivot":
    case "linear_move":
    case "traveller_soft": {
      return CreateTraveler
    }
    case "unconfigured": {
      break
    }

    case "valve": {
      return CreateValve
    }
  }
  throw new TypeError(
    `getFormComponent: Not implemented: ${installationType} case`,
  )
}
