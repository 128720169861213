import React from "react"
import { ScrollView, SectionList, StyleSheet } from "react-native"

import { ActionButtons } from "./ActionButtons"
import {
  Box,
  Button,
  Divider,
  FlatList,
  Heading,
  Paper,
  View,
} from "./components"
import { Row } from "./components/Row"
import { AppText } from "./components/Text"
import { SPACING } from "./components/theme"
import { createFarmJoinCodeAsync } from "./farmhq-api"
import {
  CopyFarmJoinCodeButton,
  ShareFarmJoinCodeButton,
} from "./FarmJoinCodes"
import {
  SetPermissionListItem,
  ViewPermissionListItem,
} from "./PermissionListItem"
import {
  DEVICE_PERMISSIONS,
  FARM_PERMISSIONS,
  FARM_USER_PERMISSION_SECTIONS,
  FARM_USER_PERMISSIONS,
} from "./permissions"
import {
  FarmUserPermissionHelpDialog,
  useFarmUserPermissionsHelpDialog,
} from "./PermissionsForm"
import { ResponsiveCard } from "./ResponsiveCard"
import { SuccessHandler } from "./SuccessHandler"
import { getDateFormatOptions } from "./translations"
import i18n from "./translations/i18n"
import { useBackendRequest } from "./useBackendRequest"

import type { AcceptsChildren, HelpContentStatic } from "./components"
import type { FarmJoinCode } from "./models"
import type { FarmUserPermissionName, FarmUserPermissions } from "./permissions"
export const HELP_CONTENT: HelpContentStatic = {
  bodyElement: i18n.t("farmJoinCodes:farmJoinCodeHelpSummary"),
  subject: "create_farm_join_code",
  titleElement: i18n.t("farmJoinCodes:farmJoinCodeTitle"),
}
interface ProviderProps {
  joinFarmUrl: string
  onClose: () => void
}

const STAGES = [
  "preview",
  "devicePermissions",
  "farmPermissions",
  "review",
] as const

// const NEW_CODE: FarmJoinCode = {
//   "codeStatus": "ACTIVE",
//   "codeStr": "2B6770",
//   "createDate": "2024-07-28T02:14:34.058942+00:00",
//   "createdByUserId": "FUCK",
//   "expirationDate": "2024-07-31T02:14:34.058947+00:00",
//   "id": 4,
//   "permissionExpirationDate": "9999-12-31T00:00:00+00:00",
//   "permissions": {
//     "canControlDevicesRemotely": false,
//     "canManageCustomTriggers": false,
//     "canManageDeviceConfiguration": false,
//     "canManageDeviceNotifications": false,
//     "canManageDevicePermissions": false,
//     "canManageFarmAccount": false,
//     "canManageFields": false,
//     "canManageJoinCodes": false,
//     "canManageSchedules": false,
//     "canManageUserPermission": false,
//   },
// }
function useCreateFarmJoinCode({ onClose, ...rest }: ProviderProps) {
  const helpDialog = useFarmUserPermissionsHelpDialog()
  const { handleError, isLoading, sendRequest, toasts } = useBackendRequest(
    createFarmJoinCodeAsync,
  )
  const [stageIndex, setStageIndex] = React.useState(0)
  const [permissions, setPermissions] = React.useState<FarmUserPermissions>({
    ...FARM_USER_PERMISSIONS.allFalse(),
    canControlDevicesRemotely: true,
  })
  const stage = STAGES[stageIndex] ?? STAGES[0]
  const [newCode, setNewCode] = React.useState<FarmJoinCode | undefined>()
  // NEW_CODE,
  return {
    ...rest,
    ...helpDialog,
    isLoading,
    newCode,
    onChangePermission: (
      permission: FarmUserPermissionName,
      value: boolean,
    ) => {
      setPermissions((prev) => ({ ...prev, [permission]: value }))
    },
    onClose: () => {
      setStageIndex(0)
      setNewCode(undefined)
      return onClose()
    },
    onSubmit: () => {
      sendRequest({
        permissions,
      })
        .then((response) => {
          setNewCode(response)
          return toasts.success(i18n.t("createFarmJoinCode:codeCreatedToast"))
        })
        .catch((error) => {
          handleError(error, { toastMessage: "default" })
        })
    },
    permissions,
    setStageIndex,
    stage,
    stageIndex,
  }
}

type ContextValue = ReturnType<typeof useCreateFarmJoinCode>

const Context = React.createContext<ContextValue | undefined>(undefined)

export function Provider({
  children,
  ...rest
}: AcceptsChildren & ProviderProps): JSX.Element | null {
  const value = useCreateFarmJoinCode(rest)
  return <Context.Provider value={value}>{children}</Context.Provider>
}

function useContext(): ContextValue {
  const ctx = React.useContext(Context)
  if (typeof ctx === "undefined") {
    throw new TypeError(`Context must be used inside of provider`)
  }
  return ctx
}

function StageHeader({
  instructionsText,
  stageIndex,
  titleText,
}: {
  instructionsText: string
  stageIndex: number
  titleText: string
}) {
  return (
    <Paper p="$4">
      <Row mb="$2">
        <Box mr="$2">
          <Heading colorScheme="secondary" variant="h5">
            {i18n.t("createFarmJoinCode:stepWithStepNumber", {
              stepNumber: stageIndex,
            })}
          </Heading>
        </Box>
        <Heading variant="h5">{titleText}</Heading>
      </Row>
      <AppText colorScheme="secondary" fontSize="$sm">
        {instructionsText}
      </AppText>
    </Paper>
  )
}
const styles = StyleSheet.create({
  formContentContainer: {
    flex: 1,
    paddingLeft: SPACING.$2,
    paddingRight: SPACING.$2,
    paddingVertical: SPACING.$4,
  },
  previewContentContainer: {
    padding: SPACING.$4,
    paddingBottom: SPACING.$toolbar,
  },

  reviewContentContainer: {
    flexGrow: 1,
    paddingBottom: SPACING.$toolbar,
    paddingTop: SPACING.$4,
  },
  view: {
    flex: 1,
  },
})
function PreviewText() {
  const { onClose, setStageIndex } = useContext()
  const stageDescriptions = [
    i18n.t("createFarmJoinCode:setDevicePermissionsPreviewText"),
    i18n.t("createFarmJoinCode:setFarmPermissionsPreviewText"),
    i18n.t("createFarmJoinCode:reviewChoicesPreviewText"),
    i18n.t("createFarmJoinCode:successPreviewText"),
  ]
  return (
    <View id="preview">
      <ScrollView contentContainerStyle={styles.previewContentContainer}>
        <AppText>{i18n.t("createFarmJoinCode:previewText")}</AppText>
        <Divider my="$2" />
        <Box>
          {stageDescriptions.map((text, index) => {
            return (
              <Row key={text} alignItems="flex-start" mb="$2">
                <Box mr="$2">
                  <AppText>{index + 1}.</AppText>
                </Box>
                <AppText flex={1}>{text}</AppText>
              </Row>
            )
          })}
        </Box>
      </ScrollView>
      <Paper p="$4">
        <ActionButtons
          SubmitIconComponent="StepForward"
          SubmitIconPosition="right"
          isLoading={false}
          mt="$0"
          submitText={i18n.t("createFarmJoinCode:begin")}
          onPressCancel={onClose}
          onPressSubmit={() => {
            setStageIndex((prev) => prev + 1)
          }}
        />
      </Paper>
    </View>
  )
}

function SetDevicePermissions() {
  const {
    onChangePermission,
    permissions,
    setHelpKey,
    setStageIndex,
    stageIndex,
  } = useContext()
  return (
    <View id="set-device-permissions" style={styles.view}>
      <StageHeader
        stageIndex={stageIndex}
        titleText={i18n.t("createFarmJoinCode:setDevicePermissionsStageTitle")}
        instructionsText={i18n.t(
          "createFarmJoinCode:setDevicePermissionsStageDescription",
        )}
      />
      <FlatList
        ItemSeparatorComponent={Divider}
        contentContainerStyle={styles.formContentContainer}
        data={DEVICE_PERMISSIONS.getKeys()}
        renderItem={({ item: key }) => {
          return (
            <SetPermissionListItem
              key={key}
              isEnabled={permissions[key]}
              name={key}
              onPressHelp={setHelpKey}
              onValueChange={(nextValue) => onChangePermission(key, nextValue)}
            />
          )
        }}
      />
      <Paper p="$4">
        <ActionButtons
          SubmitIconComponent="StepForward"
          SubmitIconPosition="right"
          cancelText={i18n.t("back")}
          isLoading={false}
          mt="$0"
          submitText={i18n.t("createFarmJoinCode:continueToFarmPermissions")}
          onPressCancel={() => setStageIndex((prev) => prev - 1)}
          onPressSubmit={() => setStageIndex((prev) => prev + 1)}
        />
      </Paper>
    </View>
  )
}

function SetFarmPermissions() {
  const {
    onChangePermission,
    permissions,
    setHelpKey,
    setStageIndex,
    stageIndex,
  } = useContext()
  return (
    <View id="set-farm-permissions" style={styles.view}>
      <StageHeader
        stageIndex={stageIndex}
        titleText={i18n.t("createFarmJoinCode:setFarmPermissionsStageTitle")}
        instructionsText={i18n.t(
          "createFarmJoinCode:setFarmPermissionsStageDescription",
        )}
      />
      <FlatList
        ItemSeparatorComponent={Divider}
        contentContainerStyle={styles.formContentContainer}
        data={FARM_PERMISSIONS.getKeys()}
        renderItem={({ item: key }) => {
          return (
            <SetPermissionListItem
              key={key}
              isEnabled={permissions[key]}
              name={key}
              onPressHelp={setHelpKey}
              onValueChange={(nextValue) => onChangePermission(key, nextValue)}
            />
          )
        }}
      />

      <Paper p="$4">
        <ActionButtons
          SubmitIconComponent="StepForward"
          SubmitIconPosition="right"
          cancelText={i18n.t("back")}
          isLoading={false}
          mt="$0"
          submitText={i18n.t("createFarmJoinCode:continueToReviewChoices")}
          onPressCancel={() => setStageIndex((prev) => prev - 1)}
          onPressSubmit={() => setStageIndex((prev) => prev + 1)}
        />
      </Paper>
    </View>
  )
}

function Review() {
  const { isLoading, onSubmit, permissions, setStageIndex, stageIndex } =
    useContext()

  return (
    <View id="review-choices" style={styles.view}>
      <StageHeader
        stageIndex={stageIndex}
        titleText={i18n.t("createFarmJoinCode:reviewChoicesTitle")}
        instructionsText={i18n.t(
          "createFarmJoinCode:reviewChoicesStageDescription",
        )}
      />
      <SectionList
        ItemSeparatorComponent={Divider}
        contentContainerStyle={styles.reviewContentContainer}
        keyExtractor={(item) => item}
        sections={FARM_USER_PERMISSION_SECTIONS}
        renderItem={({ item }) => {
          const isEnabled = permissions[item]
          return (
            <ViewPermissionListItem
              isEnabled={isEnabled}
              name={item}
              px="$4"
              py="$1"
            />
          )
        }}
        renderSectionHeader={({ section }) => {
          return (
            <Paper px="$4">
              <Heading variant="h6">{section.titleText}</Heading>
            </Paper>
          )
        }}
      />
      <Paper p="$4">
        <ActionButtons
          cancelText={i18n.t("back")}
          isLoading={isLoading}
          mt="$0"
          onPressSubmit={onSubmit}
          onPressCancel={() => {
            setStageIndex((prev) => prev - 1)
          }}
        />
      </Paper>
    </View>
  )
}

function Success() {
  const { joinFarmUrl, newCode, onClose } = useContext()
  if (!newCode) {
    return null
  }
  return (
    <ScrollView>
      <Box px="$4">
        <SuccessHandler
          message={i18n.t("createFarmJoinCode:successDescription")}
        />
      </Box>
      <ResponsiveCard mt="$4">
        <AppText colorScheme="secondary">
          {i18n.t("createFarmJoinCode:yourCode")}
        </AppText>
        <AppText fontSize="$h1">{newCode.codeStr}</AppText>
        <Row mb="$4" my="$2">
          <Box mr="$4">
            <CopyFarmJoinCodeButton
              codeStatus={newCode.codeStatus}
              codeString={newCode.codeStr}
              size="lg"
            />
          </Box>
          <ShareFarmJoinCodeButton
            codeStatus={newCode.codeStatus}
            codeString={newCode.codeStr}
            joinFarmUrl={joinFarmUrl}
            size="lg"
          />
        </Row>
        <AppText>
          {i18n.t("createFarmJoinCode:codeExpiresWithExpirationDate", {
            expirationDate: new Date(newCode.expirationDate),
            ...getDateFormatOptions({
              dateStyle: "short",
              timeStyle: "short",
            }),
          })}
        </AppText>
      </ResponsiveCard>
      <Paper p="$4">
        <Button id="done-btn" text={i18n.t("done")} onPress={onClose} />
      </Paper>
    </ScrollView>
  )
}
export function Form() {
  const { helpKey, newCode, onCloseHelpDialog, stage } = useContext()
  let formElement: React.JSX.Element
  switch (stage) {
    case "preview": {
      formElement = <PreviewText />
      break
    }
    case "devicePermissions": {
      formElement = <SetDevicePermissions />
      break
    }
    case "farmPermissions": {
      formElement = <SetFarmPermissions />
      break
    }
    case "review": {
      formElement = <Review />
      break
    }
  }
  if (newCode) {
    return <Success />
  }
  return (
    <React.Fragment>
      <FarmUserPermissionHelpDialog
        helpKey={helpKey}
        onClose={onCloseHelpDialog}
      />
      {formElement}
    </React.Fragment>
  )
}
