import React from "react"
import { useTranslation } from "react-i18next"

import { setFieldSortDirection, setFieldSortKey } from "./async-storage"
import {
  AppIcons,
  AppText,
  Avatar,
  Box,
  Button,
  CloseIconButton,
  Menu,
  Row,
  SortListButton,
} from "./components"
import {
  ListItemTextPrimary,
  ListItemTextSecondary,
} from "./components/ListItem"
import { testIds } from "./components/test-id"
import {
  getFieldSortDirectionFromState,
  getFieldSortKeyFromState,
} from "./fields.reducer"
import { FieldSvgComponent } from "./FieldSvg"
import { logger } from "./logger"
import { NoListItems } from "./NoListItems"
import { RosterHeader } from "./RosterHeader"
import {
  SortDirectionIconButton,
  SortListItem,
} from "./SortDirectionIconButton"
import { FieldSortKeys } from "./Sorting"
import i18n from "./translations/i18n"
import { useRootDispatch } from "./useRootDispatch"
import { useRootSelector } from "./useRootSelector"
import { objectEntries } from "./utility"

import type {
  ButtonProps,
  IconButtonStyleProps,
  InvertableProps,
  MenuProps,
  HelpContentStatic,
} from "./components"
import type { RosterHeaderProps } from "./RosterHeader"

import type { FieldListItemType } from "./fields.reducer"
import type {
  PermissionCheckProps,
  ProtectedButtonProps,
  TrackEventProps,
} from "./types"

export const HELP_CONTENT: HelpContentStatic = {
  bodyElement: i18n.t("fields:fieldsRosterHelpDescription"),
  subject: "fields_roster",
  titleElement: i18n.t("fields:fieldsRosterTitle"),
}
/**
 *
 */
export function CreateFieldButton({
  onPress,
  trackEvent,
  withPermissions,
  ...props
}: ProtectedButtonProps): React.JSX.Element {
  return (
    <Button
      IconComponent="Draw"
      id="create-field-btn"
      text={i18n.t("createField:linkTo")}
      onPress={withPermissions({
        callback: () => {
          onPress()
          trackEvent({
            elementName: "create-field-btn",
            name: "element_press",
          })
        },
        required: "canManageFields",
      })}
      {...props}
    />
  )
}

/**
 *
 */
export function FieldSortDirectionButton(
  props: IconButtonStyleProps & InvertableProps,
): React.JSX.Element {
  const dispatch = useRootDispatch()
  const current = useRootSelector(getFieldSortDirectionFromState)
  return (
    <SortDirectionIconButton
      currentDirection={current}
      {...props}
      onPress={() =>
        dispatch(setFieldSortDirection(current === "asc" ? "desc" : "asc"))
      }
    />
  )
}

/**
 *
 */
export function FieldsListSortMenu({
  _trigger,
  ...props
}: InvertableProps &
  Omit<MenuProps, "isOpen" | "onClose"> & {
    _trigger?: ButtonProps
  }): React.JSX.Element {
  const { t } = useTranslation("fields")
  const currentSortKey = useRootSelector(getFieldSortKeyFromState)
  const sortDirection = useRootSelector(getFieldSortDirectionFromState)
  const [isOpen, setIsOpen] = React.useState(false)
  const dispatch = useRootDispatch()
  const titleText = t("sortFields")
  const handleClose = () => setIsOpen(false)
  const items = objectEntries(FieldSortKeys)
  return (
    <React.Fragment>
      <SortListButton size="sm" {..._trigger} onPress={() => setIsOpen(true)} />
      <Menu
        style={{ width: 250 }}
        {...props}
        isOpen={isOpen}
        onClose={handleClose}
      >
        <Row justifyContent="space-between" mb="$2">
          <AppText colorScheme="secondary">{titleText}</AppText>
          <CloseIconButton onPress={handleClose} />
        </Row>
        {items.map(([key, { IconComponent, text }], index) => {
          const isSelected = currentSortKey === key
          /**
           *
           */
          const handlePress = () => {
            if (isSelected) {
              dispatch(
                setFieldSortDirection(sortDirection === "asc" ? "desc" : "asc"),
              ).catch((e) => {
                logger.error(e)
              })
            } else {
              dispatch(setFieldSortKey(key)).catch((e) => {
                logger.error(e)
              })
            }
          }

          return (
            <SortListItem
              key={key}
              IconComponent={IconComponent}
              isSelected={isSelected}
              showDivider={index < items.length - 1}
              sortDirection={sortDirection}
              text={text}
              onPress={handlePress}
            />
          )
        })}
      </Menu>
    </React.Fragment>
  )
}

const ICON_SIZE = 40

/**
 *
 */
export function RenderRow({
  areaText,
  fieldName,
  index,
  svgInfo,
}: FieldListItemType): React.JSX.Element {
  return (
    <Row
      {...testIds(`fields-list-item-${index}`)}
      alignItems="center"
      position="relative"
      py="$5"
    >
      <Avatar>
        <FieldSvgComponent
          {...svgInfo}
          fieldId={0}
          height={ICON_SIZE}
          width={ICON_SIZE}
        />
      </Avatar>
      <Box ml="$2">
        <ListItemTextPrimary isTruncated>{fieldName}</ListItemTextPrimary>
        <ListItemTextSecondary isTruncated fontSize="$xs">
          {areaText}
        </ListItemTextSecondary>
      </Box>
      <Box ml="auto">
        <AppIcons.ListItemEnd />
      </Box>
    </Row>
  )
}
export function ListEmptyComponent({
  onPressCreateField,
  trackEvent,
  withPermissions,
}: PermissionCheckProps &
  TrackEventProps & {
    onPressCreateField: () => void
  }) {
  const { t } = useTranslation("fields")

  return (
    <NoListItems
      message={t("fieldsRosterListEmpty")}
      action={
        <CreateFieldButton
          trackEvent={trackEvent}
          withPermissions={withPermissions}
          onPress={onPressCreateField}
        />
      }
    />
  )
}

/**
 *
 */
export function ListHeaderComponent({
  itemCount,
  onPressAdd,
  trackEvent,
  withPermissions,
  ...rest
}: PermissionCheckProps &
  RosterHeaderProps &
  TrackEventProps): React.JSX.Element {
  const { t } = useTranslation("fields")

  const titleText = t("fieldsRosterTitleWithItemCount", { itemCount })
  return (
    <RosterHeader
      {...rest}
      SortDirectionButtonComponent={<FieldSortDirectionButton />}
      SortMenuComponent={<FieldsListSortMenu />}
      itemCount={itemCount}
      titleText={titleText}
      AddButtonComponent={
        <CreateFieldButton
          size="sm"
          trackEvent={trackEvent}
          withPermissions={withPermissions}
          onPress={onPressAdd}
        />
      }
      onPressAdd={onPressAdd}
    />
  )
}
