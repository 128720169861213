import { addMilliseconds, getDayOfYear } from "date-fns"
import React from "react"
import { useTranslation } from "react-i18next"
import { Platform, SectionList, StyleSheet, View } from "react-native"

import { ActionButtons } from "./ActionButtons"
import {
  AppIcons,
  AppText,
  Background,
  Box,
  Button,
  Divider,
  HEADER_LARGE_SCROLL_CHILD_PROPS,
  Heading,
  ICON_SIZES,
  ListItemCard,
  Row,
  Spinner,
  TextWithIcon,
  TitleWithIcon,
} from "./components"
import { testIds } from "./components/test-id"
import { COLORS, SPACING } from "./components/theme"
import {
  getDeviceSchedulesByPastAndFuture,
  useDeleteDeviceSchedule,
  useGetWeekdayNamesFromCronString,
} from "./device-action-schedules.reducer"
import { useAutoRefresh } from "./DeviceActivityAutoRefresher"
import { loadDeviceSchedulesAsync } from "./farmhq-api"
import { ListEmptyContainer, NoListItems } from "./NoListItems"
import { useIsPending } from "./requests.reducer"
import i18n from "./translations/i18n"
import { isValidNumber } from "./type-guards"
import { useBackendRequest } from "./useBackendRequest"
import { useRootSelector } from "./useRootSelector"

import type { AppTextProps, HelpContentStatic, RowProps } from "./components"
import type { DeviceActionScheduleListItem } from "./device-action-schedules.reducer"
import type { PermissionCheckProps } from "./types"

import type { ScheduleSyncStatus } from "./models"
export type SyncStatusOrExecuted = ScheduleSyncStatus | "EXECUTED"
export const HELP_CONTENT: HelpContentStatic = {
  bodyElement: i18n.t("schedules:helpBody"),
  subject: "schedules",
  titleElement: i18n.t("schedules:helpTitle"),
}

function useTexts() {
  return useTranslation("schedules")
}

function keyExtractor(item: { scheduleId: number }): string {
  return `${item.scheduleId}`
}

function SyncIndicatorText(props: AppTextProps) {
  return <AppText fontSize="$sm" {...props} />
}

function SyncStatusIndicator({
  syncStatus,
  ...rest
}: RowProps & {
  syncStatus: SyncStatusOrExecuted | null | undefined
}) {
  const { t } = useTexts()

  if (syncStatus === "IN_PROGRESS") {
    return (
      <Row {...rest}>
        <SyncIndicatorText colorScheme="secondary">
          {t("syncStatus_IN_PROGRESS")}
        </SyncIndicatorText>
        <Spinner color={COLORS.$gray[400]} />
      </Row>
    )
  }
  if (syncStatus === "FAILED") {
    const color = COLORS.$error[500]
    return (
      <Row {...rest}>
        <Box mr="$2">
          <AppIcons.Warning color={color} size={ICON_SIZES.$sm} />
        </Box>
        <SyncIndicatorText color={color}>
          {t("syncStatus_FAILED")}
        </SyncIndicatorText>
      </Row>
    )
  }
  if (syncStatus === "SUCCESS") {
    const color = COLORS.$green[600]

    return (
      <Row {...rest}>
        <Box mr="$2">
          <AppIcons.Success color={color} size={ICON_SIZES.$sm} />
        </Box>
        <SyncIndicatorText color={color}>
          {t("syncStatus_SUCCESS")}
        </SyncIndicatorText>
      </Row>
    )
  }
  return null
}

/**
 * Confirm delete schedule
 */
function ConfirmDelete({ id, onCancel }: { id: number; onCancel: () => void }) {
  const { t } = useTexts()
  const { handleSubmit, isLoading } = useDeleteDeviceSchedule({
    deviceActionScheduleId: id,
  })
  return (
    <Box ml="auto" my="$4">
      <AppText>{t("deleteThisSchedule")}</AppText>
      <ActionButtons
        isLoading={isLoading}
        orientation="horizontal"
        onPressCancel={onCancel}
        onPressSubmit={handleSubmit}
      />
    </Box>
  )
}

const TIME_FORMAT_OPTIONS = {
  hour: "numeric",
  minute: "2-digit",
}
function useFormatStartAndEndTimes() {
  const { t } = useTranslation()

  return (item: DeviceActionScheduleListItem) => {
    let nextDayText = ""
    if (item.isNextDay) {
      nextDayText = t("nextDayParentheses", { ns: "schedules" })
    }

    if (typeof item.startTimeMsDerived !== "number") {
      return "Unknown"
    }
    let result = t("datetimeWithVal", {
      val: item.startTimeMsDerived,
      ...TIME_FORMAT_OPTIONS,
    })

    if (typeof item.actionEndTimeMs === "number") {
      const timeDiff = item.actionEndTimeMs - item.startTimeMsDerived
      // If the end time is more than 10 seconds after the start time, show it
      if (timeDiff > 10000) {
        result += ` - ${t("datetimeWithVal", {
          val: item.actionEndTimeMs,
          ...TIME_FORMAT_OPTIONS,
        })}`
      }
    }
    if (typeof item.cronSchedule?.timezone === "string") {
      result += ` (${item.cronSchedule.timezone})`
    }
    if (item.isNextDay) {
      result += ` ${nextDayText}`
    }
    return result
  }
}

const DATE_FORMAT_OPTIONS = { day: "numeric", month: "numeric" }

const styles = StyleSheet.create({
  deleteButton: {
    // marginLeft: "auto",
    marginRight: "auto",
    marginTop: SPACING.$2,
  },
})

/**
 * Device schedule render item
 */
function RenderItem({
  isDeleteDisabled,
  isDeleting,
  onCancelDelete,
  onPressDelete,
  withPermissions,
  ...item
}: DeviceActionScheduleListItem &
  PermissionCheckProps & {
    isDeleteDisabled: boolean
    isDeleting: boolean
    onCancelDelete: () => void
    onPressDelete: () => void
  }) {
  const { t } = useTexts()
  const getDays = useGetWeekdayNamesFromCronString()
  const repeatedWeekdays = getDays(item.cronSchedule?.raw)
  const formatTime = useFormatStartAndEndTimes()

  let activeDatesElement: React.JSX.Element | null = null

  if (
    typeof item.scheduleStart === "string" &&
    typeof item.scheduleEnd === "string" &&
    !Boolean(item.executeAtTs)
  ) {
    const scheduleStart = new Date(item.scheduleStart)
    const scheduleEnd = new Date(item.scheduleEnd)
    // options = {...options, end:scheduleEnd, start:scheduleStart}

    const startDate = getDayOfYear(scheduleStart)
    const endDate = getDayOfYear(scheduleEnd)

    let text = t("activeDatesValueWithStartAndEnd", {
      ...DATE_FORMAT_OPTIONS,
      end: scheduleEnd,
      start: scheduleStart,
    })
    // If the start and end dates are the same, we only show the start date
    if (endDate === startDate) {
      text = t("activeDatesWithStart", {
        ...DATE_FORMAT_OPTIONS,
        start: scheduleStart,
        weekday: "long",
      })
    }
    activeDatesElement = (
      <TextWithIcon IconComponent="CalendarSingle" text={text} />
    )
  } else if (typeof item.executeAtTs === "string") {
    activeDatesElement = (
      <TextWithIcon
        IconComponent="CalendarSingle"
        text={t("datetimeWithVal", {
          ...DATE_FORMAT_OPTIONS,
          ns: "common",
          val: new Date(item.executeAtTs),
          weekday: "long",
        })}
      />
    )
  }

  const timeFormatted = formatTime(item)

  return (
    <View>
      <TitleWithIcon
        IconComponent="DevicePump"
        _headingProps={{ variant: "h5" }}
        rightElement={activeDatesElement}
        titleText={item.deviceName ?? ""}
      />
      <Divider mb="$2" />
      <Row justifyContent="space-between">
        {typeof item.actionDisplayName === "string" ? (
          <TextWithIcon
            IconComponent="DeviceCommandsRemote"
            mr="$4"
            text={item.actionDisplayName}
          />
        ) : null}
        <TextWithIcon IconComponent="Clock" text={timeFormatted} />
      </Row>
      {repeatedWeekdays ? (
        <TextWithIcon
          IconComponent="Refresh"
          _text={{ colorScheme: "secondary" }}
          mt="$2"
          text={`${t("repeats")} ${repeatedWeekdays.join(", ")}`}
        />
      ) : null}
      <Divider my="$3" />
      <Row flexWrap="wrap-reverse" justifyContent="space-between" mb="$2">
        <Box>
          {isDeleting ? (
            <ConfirmDelete id={item.scheduleId} onCancel={onCancelDelete} />
          ) : (
            <Button
              IconComponent="TrashCan"
              isDisabled={isDeleteDisabled}
              size="sm"
              style={styles.deleteButton}
              text={t("delete")}
              variant="danger"
              onPress={withPermissions({
                callback: onPressDelete,
                required: "canManageSchedules",
              })}
            />
          )}
        </Box>
        <SyncStatusIndicator syncStatus={item.syncStatus} />
      </Row>
    </View>
  )
}
const ItemSeparatorComponent = () => <Box h="$4" />

/**
 * Device action schedules section list
 */
export function DeviceActionSchedulesSectionList({
  HelpComponent,
  isFocused,
  onPressNewSchedule,
  withPermissions,
}: PermissionCheckProps & {
  isFocused: boolean
  onPressNewSchedule: () => void
  HelpComponent?: React.JSX.Element
}) {
  const { t } = useTexts()

  const titleText: string = t("schedulesListTitle")

  const [idToDelete, setIdToDelete] = React.useState<number>()
  const { handleError, isLoading, sendRequest } = useBackendRequest(
    loadDeviceSchedulesAsync,
  )

  const sections = useRootSelector(getDeviceSchedulesByPastAndFuture)
  const onCancelDelete = React.useCallback(() => setIdToDelete(undefined), [])

  const isDeleteDisabled = useIsPending("DeleteDeviceActionSchedule")

  const handleFetch = React.useCallback(() => {
    sendRequest({}).catch(handleError)
  }, [handleError, sendRequest])

  React.useEffect(handleFetch, [handleFetch])
  useAutoRefresh({
    callback: handleFetch,
    isFocused,
    seconds: 10,
  })

  const addScheduleButton = (
    <Box ml="auto">
      <Button
        IconComponent="Add"
        size="lg"
        text={t("new")}
        variant="primary"
        onPress={withPermissions({
          callback: onPressNewSchedule,
          required: "canManageSchedules",
        })}
      />
    </Box>
  )
  return (
    <SectionList
      {...testIds("schedulesList")}
      ItemSeparatorComponent={ItemSeparatorComponent}
      contentContainerStyle={{ paddingBottom: SPACING.$toolbar }}
      {...HEADER_LARGE_SCROLL_CHILD_PROPS}
      keyExtractor={keyExtractor}
      refreshing={isLoading}
      sections={sections}
      // stickyHeaderIndices={STICKY_HEADER_INDICES}
      ListEmptyComponent={
        <ListEmptyContainer>
          {isLoading ? <Spinner /> : <NoListItems message={t("noSchedules")} />}
        </ListEmptyContainer>
      }
      ListHeaderComponent={() => {
        return Platform.OS === "ios" ? null : (
          <Background mt="$4" px="$4">
            <TitleWithIcon
              IconComponent="Clock"
              helpElement={HelpComponent}
              rightElement={Platform.OS === "web" ? addScheduleButton : null}
              titleText={titleText}
            />
          </Background>
        )
      }}
      renderItem={({ item, section }) => {
        return (
          <ListItemCard isDisabled={section.id === "expired"}>
            <RenderItem
              {...item}
              isDeleteDisabled={isDeleteDisabled}
              isDeleting={idToDelete === item.scheduleId}
              withPermissions={withPermissions}
              onCancelDelete={onCancelDelete}
              onPressDelete={withPermissions({
                callback: () => setIdToDelete(item.scheduleId),
                required: "canManageSchedules",
              })}
            />
          </ListItemCard>
        )
      }}
      renderSectionHeader={({ section }) => {
        if (section.id === "expired" && section.isEmpty) {
          return null
        }
        let rightElement: React.JSX.Element | null = null
        let emptyElement: React.JSX.Element | null = null
        if (section.id === "active") {
          if (Platform.OS !== "web") {
            rightElement = addScheduleButton
          }
          if (section.isEmpty) {
            emptyElement = (
              <NoListItems message={t("noSchedulesActive")} my="$8" />
            )
          }
        }

        return (
          <Background px="$4" py="$2">
            <Row>
              <Heading colorScheme="secondary" variant="h5">
                {section.title}
              </Heading>
              {rightElement}
            </Row>
            {emptyElement}
          </Background>
        )
      }}
    />
  )
}
/**
 *
 */
export function FutureExecutionsList({
  durationMs,
  futureExecutions,
  timezone,
}: {
  durationMs: number | null
  futureExecutions: number[]
  timezone: string | null | undefined
}) {
  const { t } = useTexts()
  const dateOptions = {
    hour: "numeric",
    minute: "2-digit",
    ns: "schedules",
    weekday: "long",
  }
  return (
    <View>
      <AppText colorScheme="secondary">{t("thisWeek")}</AppText>
      {futureExecutions.map((dateMs) => {
        const start = new Date(dateMs)

        // Default to just showing the start time
        let executionText: string = t("datetimeWithVal", {
          ...dateOptions,
          ns: "common",
          val: start,
        })
        if (isValidNumber(durationMs)) {
          // We can show the start and end time
          const end = addMilliseconds(start, durationMs)
          if (typeof timezone === "string") {
            // Use the timezone if we have it
            executionText = t("executionWithStartAndEndAndTimezone", {
              ...dateOptions,
              end,
              start,
              timezone,
            })
          } else {
            // Start and end without timezone
            executionText = t("executionWithStartAndEnd", {
              ...dateOptions,
              end,
              start,
            })
          }
        }

        return (
          <Row key={start.toISOString()} py="$1">
            <AppText>{executionText}</AppText>
          </Row>
        )
      })}
    </View>
  )
}
