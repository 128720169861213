import i18next from "i18next"
import _ from "lodash"
import { createCachedSelector } from "re-reselect"
import { createSelector } from "reselect"

import { createAction, createSlice, isAnyOf } from "@reduxjs/toolkit"
import * as turf from "@turf/turf"

import { setFieldActionsName } from "./actions"
import * as AppStorage from "./async-storage"
import { signOutAsync } from "./auth.reducer"
import {
  activateFieldsAsync,
  archiveFieldsAsync,
  createFieldAsync,
  deleteFieldAsync,
  renameFieldAsync,
  setFieldRowDirectionAsync,
  updateFieldBoundaryAsync,
} from "./farmhq-api"
import * as Geo from "./geo"
import { loadActiveFarmAsync } from "./load-app"
import * as Models from "./models"
import { deselectItem } from "./status-map.reducer"
import { useRootDispatch } from "./useRootDispatch"

export const CREATE_FIELD_STAGES = [
  "set-field-boundary",
  "set-field-name",
  "set-field-label-rotation",
  "set-field-row-direction",
] as const
export type CreateFieldStage = (typeof CREATE_FIELD_STAGES)[number]
export type FieldStatusAction = "activate-field" | "archive-field"
export type FieldActionName =
  | CreateFieldStage
  | FieldStatusAction
  | "delete-field"
  | "field-irrigation-history"

import type { Reducer } from "@reduxjs/toolkit"

import type { FieldSortKey, SortDirectionKey } from "./Sorting"

import type { FarmField, ModelState } from "./models"

import type { RootState } from "./root.reducer"
export type FieldType = "center_pivot" | "polygon"

const { adapter, selectAll, selectTotal } = Models.field
export const cancelFieldAction = createAction("fields/cancelFieldAction")

interface FieldsState extends ModelState<FarmField> {
  sortDirection: SortDirectionKey
  sortKey: FieldSortKey
  actionName?: FieldActionName
}

const INITIAL_STATE: FieldsState = {
  entities: {},
  ids: [],
  sortDirection: AppStorage.STORAGE_DEFAULTS.fieldSortDirection,
  sortKey: AppStorage.STORAGE_DEFAULTS.fieldSortKey,
}
const slice = createSlice({
  extraReducers: (builder) => {
    return builder
      .addCase(setFieldActionsName, (state, action) => {
        if (typeof action.payload === "string") {
          state.actionName = action.payload
        } else if (action.payload) {
          state.actionName = action.payload.value
        } else {
          state.actionName = undefined
        }
      })
      .addCase(cancelFieldAction, (state) => {
        state.actionName = undefined
      })
      .addCase(AppStorage.setFieldSortDirection.pending, (state, action) => {
        state.sortDirection = action.meta.arg
      })
      .addCase(AppStorage.setFieldSortKey.pending, (state, action) => {
        state.sortKey = action.meta.arg
      })
      .addCase(
        AppStorage.readLocalStorageAsync.fulfilled,
        (state, { payload }) => {
          state.sortDirection = payload.fieldSortDirection
          state.sortKey = payload.fieldSortKey
        },
      )
      .addCase(loadActiveFarmAsync.fulfilled, (state, action) => {
        adapter.setAll(
          state,
          // We don't want to show center pivot fields in the field list
          // because we don't use them and it is confusing for users
          action.payload.fields.filter((field) => {
            if (field.fieldType === "center_pivot") {
              return false
            }
            return true
          }),
        )
      })
      .addCase(createFieldAsync.fulfilled, (state, { payload }) => {
        adapter.upsertOne(state, Models.prepareField(payload))
      })
      .addCase(renameFieldAsync.fulfilled, (state, action) => {
        adapter.updateOne(state, {
          changes: {
            fieldName: action.meta.arg.fieldName,
            labelRotationDegrees: action.meta.arg.labelRotationDegrees,
          },
          id: action.meta.arg.fieldId,
        })
      })
      .addCase(setFieldRowDirectionAsync.fulfilled, (state, { meta }) => {
        adapter.updateOne(state, {
          changes: { rowDirectionAzimuthDegrees: meta.arg.value },
          id: meta.arg.fieldId,
        })
      })
      .addCase(updateFieldBoundaryAsync.fulfilled, (state, { meta }) => {
        adapter.updateOne(state, {
          changes: { polygon: meta.arg.polygon },
          id: meta.arg.fieldId,
        })
      })
      .addCase(archiveFieldsAsync.fulfilled, (state, { meta }) => {
        adapter.updateMany(
          state,
          meta.arg.ids.map((id) => ({ changes: { isActive: false }, id })),
        )
      })
      .addCase(activateFieldsAsync.fulfilled, (state, { meta }) => {
        adapter.updateMany(
          state,
          meta.arg.ids.map((id) => ({ changes: { isActive: true }, id })),
        )
      })
      .addCase(deleteFieldAsync.fulfilled, (state, { meta }) => {
        adapter.removeOne(state, meta.arg.fieldId)
      })
      .addMatcher(isAnyOf(signOutAsync.fulfilled), (state) => {
        adapter.removeAll(state)
      })
      .addMatcher(
        isAnyOf(
          archiveFieldsAsync.fulfilled,
          activateFieldsAsync.fulfilled,
          updateFieldBoundaryAsync.fulfilled,
          deselectItem,
        ),
        (state) => {
          state.actionName = undefined
        },
      )
  },
  initialState: INITIAL_STATE,
  name: `farmFields`,
  reducers: {},
})

export function useCancelFieldAction() {
  const dispatch = useRootDispatch()
  return () => dispatch(cancelFieldAction())
}

const farmFields: Reducer<FieldsState> = slice.reducer
export default farmFields

export function calculateFieldArrowLength(
  polygon: Geo.PolygonGeoJson,
): number | undefined {
  if (typeof polygon === "undefined") {
    throw new TypeError("Cannot convert polygon")
  }
  const center = Geo.polygon(polygon)?.getCenter()?.coordinates
  if (!center) {
    return undefined
  }

  const tangents = turf.polygonTangents(center, polygon)
  const longest = _.max(
    tangents.features.map((point) => {
      const coordinates = point.geometry.coordinates
      return turf.distance(coordinates, center, { units: "millimeters" })
    }),
  )
  return longest
}
export const getActiveFieldsFromState = createSelector(selectAll, (fields) => {
  return fields.filter((field): boolean => {
    return field.isActive
  })
})

export const getArchivedFieldIdsFromState = createSelector(
  selectAll,
  (fields) =>
    fields.filter((field): boolean => !field.isActive).map((field) => field.id),
)
export const getNumArchivedFieldsFromState = createSelector(
  getArchivedFieldIdsFromState,
  (fields) => fields.length,
)

export const getNumActiveFieldsFromState = createSelector(
  selectTotal,
  getNumArchivedFieldsFromState,
  (total, count) => total - count,
)

export interface FieldListItemType extends FarmField {
  areaText: string | undefined
  index: number
}
export function getFieldActionNameFromState(
  state: RootState,
): FieldActionName | undefined {
  return state.fields.actionName
}

export function getFieldSortKeyFromState(state: RootState): FieldSortKey {
  return state.fields.sortKey
}

export function getFieldSortDirectionFromState(
  state: RootState,
): SortDirectionKey {
  return state.fields.sortDirection
}

const sortFieldByName: Models.SortComparator<FieldListItemType> = (a, b) => {
  const nameA = a?.fieldName ?? ""
  const nameB = b?.fieldName ?? ""
  return nameA.localeCompare(nameB)
}

/**
 * Separate active and archived fields
 */
const sortByFieldStatus: Models.SortComparator<FieldListItemType> = (a, b) => {
  const isActiveB = b?.isActive === true
  const isActiveA = a?.isActive === true
  if (isActiveB && !isActiveA) {
    return -1
  }
  if (!isActiveB && isActiveA) {
    return 1
  }
  return 0
}

function makeFieldsSorter(
  sortKey: FieldSortKey,
  sortDirection: SortDirectionKey,
): ((a: FieldListItemType, b: FieldListItemType) => number) | undefined {
  return (a, b): number => {
    let sorter: Models.SortComparator<typeof a>
    switch (sortKey) {
      case "alphabetical": {
        sorter = Models.makePrioritySort(
          sortDirection,
          sortFieldByName,
          sortByFieldStatus,
        )
        break
      }
      case "field_status": {
        sorter = Models.makePrioritySort(
          sortDirection,
          sortByFieldStatus,
          sortFieldByName,
        )
        break
      }
    }
    return sorter(a, b)
  }
}

export const getFieldListItemsFromStateSorted: (
  state: RootState,
) => FieldListItemType[] = createCachedSelector(
  selectAll,
  getFieldSortKeyFromState,
  getFieldSortDirectionFromState,
  (fields, sortKey, sortDirection): FieldListItemType[] => {
    return fields
      .filter((field) => {
        // This is a deprecated type of field...we now handle this
        // in the device configuration
        if (field.fieldType === "center_pivot") {
          return false
        }
        return true
      })
      .map((field, index) => {
        const archivedText = i18next.t("archived")
        return {
          ...field,
          fieldName: `${field.fieldName}${
            field.isActive && typeof archivedText === "string"
              ? ""
              : `(${archivedText})`
          } `,
          index,
          svgInfo: Models.calculateFieldSvgPath(field),
        }
      })
      .sort(makeFieldsSorter(sortKey, sortDirection))
  },
)((state: RootState) => {
  const sortKey = getFieldSortKeyFromState(state)
  const direction = getFieldSortDirectionFromState(state)
  return `fields/${sortKey}/${direction}`
})
