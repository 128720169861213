import React from "react"
import { Image, StyleSheet } from "react-native"

import { useColorMode } from "./components"
import { Urls } from "./Internal"

import type { ImageProps } from "react-native"
type ImageName<T extends string> = T extends T ? `${T}.png` : never
export type FarmHqLogoFilename = ImageName<
  | "leaf-iso"
  | "leaf-square"
  | "lockup-inline-coda-monochrome"
  | "lockup-inline-coda"
  | "lockup-inline-monochrome"
  | "lockup-inline-nopad"
  | "lockup-inline"
  | "lockup-stacked"
>

interface Props extends Omit<ImageProps, "source"> {
  name: FarmHqLogoFilename
}
export function useFarmHqLogUri(name: FarmHqLogoFilename) {
  const colorMode = useColorMode()
  return { uri: `${Urls.IMAGE_HOST}/logos/${colorMode}/${name}` }
}
const height = 36
const styles = StyleSheet.create({
  root: {
    height,
    width: height,
  },
})

export function FarmHqLogo({
  alt = "FarmHQ",
  name,
  ...rest
}: Props): React.JSX.Element {
  const logoUrl = useFarmHqLogUri(name)

  return <Image style={styles.root} {...rest} alt={alt} source={logoUrl} />
}
