import React from "react"

import { AppText, Box, Button, IconButton } from "./components"
import { ListItemTextPrimary } from "./components/ListItem"
import { Row } from "./components/Row"
import * as CreateConfig from "./CreateDeviceConfigurationContext"
import { canDeleteSensor } from "./device-configurations.reducer"
import { createConfigurationAsync } from "./farmhq-api"
import * as Models from "./models"
import {
  getIsAdminModeEnabledFromState,
  useMeasurementPreference,
} from "./selectors"
import {
  formatSensorName,
  formatSensorValue,
  useSensorUnitLabel,
} from "./sensor-formatting"
import { isAdminOnlyField, isMutableSensorName } from "./sensors"
import i18n from "./translations/i18n"
import { useBackendRequest } from "./useBackendRequest"
import { useRootSelector, useShallowEqualSelector } from "./useRootSelector"
import { objectEntries } from "./utility"

import type { ButtonProps } from "./components"
import type { RowProps } from "./components/Row"
import type { GetActionArgumentsType } from "./send-request"
import type { ConfigSensorName, SensorConfigKey } from "./sensor-configurations"
import type {
  DeviceIdProps,
  PermissionCheckProps,
  TrackEventProps,
} from "./types"
/**
 * DeviceSensorFieldsList item
 */
export interface ItemType {
  fieldName: SensorConfigKey
  isAdminOnly: boolean
  keyFormatted: string
  valueFormatted: string
}

/**
 * Data for device sensor fields list items
 */
export function useListItems({
  deviceId,
  selectedSensorName,
}: {
  deviceId: string
  selectedSensorName: ConfigSensorName
}) {
  const isAdminModeEnabled = useRootSelector(getIsAdminModeEnabledFromState)
  const convertConfig = CreateConfig.useConvertConfiguration()
  const getUnitLabel = useSensorUnitLabel()
  const measurementPreference = useMeasurementPreference()
  const data = useShallowEqualSelector((state): ItemType[] => {
    const values = Models.deviceConfiguration.selectById(state, deviceId)
    if (values) {
      const converted = convertConfig(values, "user")
      const sensorConverted = converted[selectedSensorName]
      if (sensorConverted) {
        return objectEntries(sensorConverted)
          .map(([fieldName, rawValue]): ItemType => {
            // let valueFormatted = `${value ?? "None"}`
            let valueFormatted =
              formatSensorValue({
                convertValues: false,
                fieldName,
                measurementPreference,
                rawValue,
              }) ?? i18n.t("none")
            const unitLabel = getUnitLabel(fieldName)
            if (valueFormatted !== "None" && typeof unitLabel === "string") {
              valueFormatted += ` ${unitLabel}`
            }
            const keyFormatted = i18n.t(`sensorFields:${fieldName}.displayName`)

            return {
              fieldName,
              isAdminOnly: isAdminOnlyField(fieldName),
              keyFormatted,
              valueFormatted,
            }
          })
          .filter((item) => {
            if (item.fieldName === "linearSpeedMmHMax") {
              return isAdminModeEnabled
            }
            return true
          })
      }
    }
    return []
  })
  return data
}

/**
 * Device sensor fields list item
 */
export function RenderRow({
  fieldName,
  isAdminOnly,
  keyFormatted,
  onPressEdit,
  valueFormatted,
  withPermissions,
  ...rest
}: ItemType &
  PermissionCheckProps &
  RowProps & {
    onPressEdit: () => void
  }) {
  return (
    <Row id={fieldName} justifyContent="space-between" {...rest}>
      <Box mr="$4">
        <AppText isTruncated colorScheme="secondary">
          {keyFormatted}
        </AppText>
      </Box>
      <Row ml="auto">
        <ListItemTextPrimary>{valueFormatted}</ListItemTextPrimary>
        <Box ml="auto" pl="$4">
          {isAdminOnly ? (
            <Button
              disabled
              isDisabled
              size="sm"
              text={i18n.t("readonly")}
              w="24"
            />
          ) : (
            <IconButton
              IconComponent="Edit"
              id="edit-configuration-btn"
              variant="subtle"
              onPress={withPermissions({
                callback: onPressEdit,
                required: "canManageDeviceConfiguration",
              })}
            />
          )}
        </Box>
      </Row>
    </Row>
  )
}

/**
 *
 */
export function DeleteSensorButton({
  onPress,
  trackEvent,
  withPermissions,
  ...rest
}: ButtonProps &
  PermissionCheckProps &
  TrackEventProps & {
    onPress: () => void
  }) {
  return (
    <Button
      IconComponent="TrashCan"
      id="delete-sensor-btn"
      text={i18n.t("deviceConfiguration:deleteSensor")}
      variant="danger"
      onPress={withPermissions({
        callback: () => {
          onPress()
          trackEvent({
            elementName: "delete-sensor-btn",
            name: "element_press",
          })
        },
        required: "canManageDeviceConfiguration",
      })}
      {...rest}
    />
  )
}

/**
 *
 */
export function useDeleteSensor({
  deviceId,
  onSuccess,
  sensorName,
}: DeviceIdProps & {
  onSuccess: () => void
  sensorName: ConfigSensorName
}) {
  const { handleError, isLoading, sendRequest, toasts } = useBackendRequest(
    createConfigurationAsync,
  )
  const configuration = useRootSelector((state) =>
    Models.deviceConfiguration.selectById(state, deviceId),
  )
  const canDelete = canDeleteSensor({
    deviceInstallationType: configuration?.deviceInstallationType,
    sensorName,
  })
  let title: string
  let message: string

  if (canDelete) {
    title = i18n.t("deleteSensorTitleWithSensorName", {
      ns: "deviceConfiguration",
      sensorName: formatSensorName(sensorName),
    })
    message = i18n.t(
      "deviceConfiguration:deleteSensorImpactFunctionalityWarning",
    )
  } else {
    title = i18n.t("forbidden")
    message = i18n.t("deviceConfiguration:deleteSensorSensorIsRequiredMessage")
  }
  return {
    canDelete,
    isLoading,
    isSubmitDisabled: !canDelete,
    onSubmit: () => {
      if (!canDelete || !isMutableSensorName(sensorName)) {
        throw new TypeError("Cannot delete sensor")
      }
      if (typeof configuration === "undefined") {
        throw new TypeError("Configuration is undefined")
      }

      const {
        codaDeviceAlias,
        deviceInstallationType,
        deviceName,
        flow,
        linearPath: linearPathStops,
        linearPathStopsLabels,
        linearSpanHeadingDegrees,
        linearSpanWidthMm,
        pivotCenterGpsLocation,
        pivotPathStopsCoordinates,
        pivotPathStopsHeadingsDegrees,
        pivotPathStopsLabels,
        pivotRadiusMeters,
        pressure,
        pressureSwitch,
        reel,
        wheel,
      } = configuration
      const actionArguments: GetActionArgumentsType<"CreateConfiguration"> = {
        deviceId,
        deviceInstallationType,
        deviceName: deviceName ?? codaDeviceAlias,
        flow,
        linearPathStopsCoordinates: linearPathStops?.map(
          (stop) => stop.coordinates,
        ),
        linearPathStopsLabels,
        linearSpanHeadingDegrees,
        linearSpanWidthMm,
        pivotCenterGpsLocation,
        pivotPathStopsCoordinates,
        pivotPathStopsHeadingsDegrees,
        pivotPathStopsLabels,
        pivotRadiusMeters,
        pressure,
        pressureSwitch,
        reel,
        wheel,
      }
      actionArguments[sensorName] = null
      sendRequest(actionArguments)
        .then(() => {
          onSuccess()
          return toasts.success()
        })
        .catch((error) => handleError(error, { toastMessage: "default" }))
    },
    texts: { message, title },
  }
}
