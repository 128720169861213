import { useTranslation } from "react-i18next"
import { KeyboardAvoidingView, StyleSheet, View } from "react-native"

import {
  AddPhoneNumber,
  FarmHqLogo,
  Models,
  useRootDispatch,
  useRootSelector,
  useTooManyPhoneNumbers,
  VerifyPhoneNumber,
} from "@fhq/app"
import { setPhoneNumberPromptStatus } from "@fhq/app/async-storage"
import { AlertCard, AppText, Box, Button, testIds } from "@fhq/app/components"
import { SIZES, SPACING } from "@fhq/app/components/theme"

import type { AcceptsChildren, TestId } from "@fhq/app/components"

/**
 * Button to skip adding a phone number
 */
function SkipButton() {
  const { t } = useTranslation()
  const dispatch = useRootDispatch()

  return (
    <Box my="$4">
      <Button
        id="skip-for-now-btn"
        text={t("skipForNow")}
        variant="text"
        onPress={() => {
          return dispatch(setPhoneNumberPromptStatus("skipped"))
        }}
      />
    </Box>
  )
}

const styles = StyleSheet.create({
  logo: {
    height: 200,
  },
  root: {
    flex: 1,
    padding: SPACING.$4,
  },
  screenLayout: {
    marginHorizontal: "auto",
    // marginTop: "20%",
    maxWidth: SIZES.$md,
    width: "100%",
  },
})

/**
 * Layout for the screen
 */
function ScreenLayout({
  children,
  id,
}: AcceptsChildren & {
  id: TestId
}) {
  return (
    <View style={styles.screenLayout} {...testIds(id)}>
      <FarmHqLogo
        name="lockup-inline-coda.png"
        resizeMode="contain"
        style={styles.logo}
      />
      {children}
    </View>
  )
}

/**
 * Screen to add a phone number
 * when the user has no phone numbers
 * yet.
 */
export function AddInitialPhoneNumberPage() {
  const { t } = useTranslation("phoneNumbers")
  return (
    <ScreenLayout id="add-initial-phone-number">
      <KeyboardAvoidingView style={styles.root}>
        <AlertCard
          IconComponent="Info"
          bodyText={t("initialPhoneNumberPrompt")}
          mb="$4"
        />
        <Box my="$4">
          <AddPhoneNumber.AddPhoneNumberForm
            onCancel={null}
            onSuccess={null}
            onTooManyNumbers={useTooManyPhoneNumbers()}
          />
        </Box>
        <SkipButton />
        <AppText>{t("youCanAddYourPhoneNumberLater")}</AppText>
      </KeyboardAvoidingView>
    </ScreenLayout>
  )
}

/**
 * Screen to verify a phone number
 * when the user has no verified phone
 * numbers yet.
 */
export function VerifyInitialPhoneNumberPage() {
  const { t } = useTranslation("phoneNumbers")
  const phoneNumber = useRootSelector((state) => {
    return Models.phoneNumber.selectAll(state).find((pn) => !pn.isVerified)
  })

  return (
    <ScreenLayout id="verify-initial-phone-number">
      <KeyboardAvoidingView style={styles.root}>
        {phoneNumber ? (
          <VerifyPhoneNumber.VerifyPhoneNumberForm
            upnId={phoneNumber.id}
            onClose={null}
          />
        ) : (
          <Box my="$4">
            <AppText>{t("phoneNumberNotFound")}</AppText>
          </Box>
        )}
        <SkipButton />
        <AppText>{t("youCanAddYourPhoneNumberLater")}</AppText>
      </KeyboardAvoidingView>
    </ScreenLayout>
  )
}
