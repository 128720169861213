import React from "react"
import { Platform } from "react-native"
import * as ReactRouter from "react-router-dom"

import { SentryBase } from "@fhq/app"
import { isValidDatabaseName, Urls } from "@fhq/app/Internal"
import * as Sentry from "@sentry/react"
import { BrowserTracing } from "@sentry/tracing"

declare global {
  interface Window {
    Cypress?: unknown
  }
  namespace NodeJS {
    interface ProcessEnv {
      REACT_APP_ENABLE_SENTRY: string | undefined
      // See .env files in env root
      REACT_APP_LOCALHOST: string | undefined
      REACT_APP_LOG_LEVEL: string | undefined
      REACT_APP_SEGMENT_WRITE_KEY: string | undefined
      REACT_APP_TARGET_DATABASE_NAME: string | undefined
      REACT_APP_VERSION: string | undefined
      SENTRY_AUTH_TOKEN: string | undefined
    }
  }
}

export const IS_END_TO_END_TEST = Boolean(window.Cypress)
export const ENABLE_SENTRY =
  (!__DEV__ && !IS_END_TO_END_TEST) ||
  Boolean(process.env.REACT_APP_ENABLE_SENTRY)

let targetDatabaseName = process.env.REACT_APP_TARGET_DATABASE_NAME

// TARGET DB NAME
if (typeof targetDatabaseName === "string") {
  targetDatabaseName = targetDatabaseName.toUpperCase()
}

if (!isValidDatabaseName(targetDatabaseName)) {
  let message = `Database Name is undefined`
  if (typeof targetDatabaseName === "string") {
    message = `Invalid databae name supplied: ${JSON.stringify(
      targetDatabaseName,
    )}`
  }
  throw new TypeError(message)
}

export const TARGET_DATABASE_NAME = targetDatabaseName
export const APP_VERSION = process.env.REACT_APP_VERSION ?? "unknown"

export function initializeSentry() {
  Sentry.init({
    attachStacktrace: true,
    autoSessionTracking: true,

    beforeSend: SentryBase.beforeSend,
    // debug: __DEV__,
    dsn: Urls.SENTRY_DSN.web,
    enabled: ENABLE_SENTRY,
    environment: targetDatabaseName,
    ignoreErrors: SentryBase.IGNORE_ERRORS,
    integrations: [
      new BrowserTracing({
        routingInstrumentation: Sentry.reactRouterV6Instrumentation(
          React.useEffect,
          ReactRouter.useLocation,
          ReactRouter.useNavigationType,
          ReactRouter.createRoutesFromChildren,
          ReactRouter.matchRoutes,
        ),
        traceXHR: true,
      }),
    ],
    release: APP_VERSION,
    sampleRate: 1,
    sendClientReports: true,
    tracesSampleRate: 1,
  })
}
// https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
export const sentryCreateBrowserRouter = Sentry.wrapCreateBrowserRouter(
  ReactRouter.createBrowserRouter,
)
Sentry.setTag("Platform.OS", Platform.OS)
Sentry.setTag(
  "git_commit_sha",
  process.env.REACT_APP_GIT_COMMIT_SHA ?? "unknown",
)
