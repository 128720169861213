import React from "react"
import { Platform, ScrollView as Base, StyleSheet } from "react-native"
import { useStyle } from "react-native-style-utilities"

import { getCurrentBreakpointKey } from "../app-state.reducer"
import { useRootSelector } from "../useRootSelector"
import { SPACING, Theme } from "./theme"
import { useIsDarkMode } from "./ThemeProvider"

import type { TestId } from "./test-id"
import type { ScrollViewProps as BaseProps } from "react-native"
export type ScrollViewProps = Omit<BaseProps, "id"> & {
  id?: TestId
  variant?:
    | "pageScroll"
    | "paper"
    | "screenContainer"
    | "screenContainerHeaderLarge"
    | "webAppPage"
}

const styles = StyleSheet.create({
  pageScroll: {
    marginBottom: SPACING.$20,
  },
  paper: {
    backgroundColor: Theme.colors.$paper.light,
  },
  paperDark: {
    backgroundColor: Theme.colors.$paper.dark,
  },
  screenContainer: {
    padding: SPACING.$4,
    paddingBottom: SPACING.$toolbar,
  },
  screenContainerHeaderlarge: {
    padding: SPACING.$4,
    paddingBottom: SPACING.$toolbar,
    paddingTop: Platform.select({
      android: SPACING.$2,
      ios: SPACING.$toolbar,
    }),
  },
  webAppPageContentContainer: {
    flexGrow: 0,
  },
  webAppPageRoot: {
    flexGrow: 0,
  },
})

export const HEADER_LARGE_CHILD_SCROLL_ADJUSTMENT: ScrollViewProps["contentInsetAdjustmentBehavior"] =
  Platform.select({
    default: undefined,
    ios: "automatic",
  })

export const HEADER_LARGE_SCROLL_CHILD_PROPS = {
  contentAdjustmentBehavior: HEADER_LARGE_CHILD_SCROLL_ADJUSTMENT,
}

export const ScrollView = React.forwardRef<Base, ScrollViewProps>(
  function ScrollView({ contentContainerStyle, style, variant, ...rest }, ref) {
    const isDark = useIsDarkMode()
    const screenSize = useRootSelector(getCurrentBreakpointKey)
    return (
      <Base
        ref={ref}
        {...HEADER_LARGE_SCROLL_CHILD_PROPS}
        contentContainerStyle={useStyle(
          () => [
            variant === "screenContainerHeaderLarge" &&
              styles.screenContainerHeaderlarge,
            variant === "screenContainer"
              ? styles.screenContainer
              : variant === "pageScroll"
              ? styles.pageScroll
              : undefined,
            variant === "paper" && (isDark ? styles.paperDark : styles.paper),
            variant === "webAppPage" &&
              screenSize !== "xs" &&
              styles.webAppPageContentContainer,
            contentContainerStyle,
          ],
          [contentContainerStyle, isDark, screenSize, variant],
        )}
        style={useStyle(
          () => [
            variant === "webAppPage" &&
              screenSize !== "xs" &&
              styles.webAppPageRoot,
            style,
          ],
          [screenSize, style, variant],
        )}
        {...rest}
      />
    )
  },
)
