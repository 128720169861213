import { format, formatDistanceToNow } from "date-fns"
import React from "react"
import { Controller, useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { FlatList, Share, View } from "react-native"

import { captureException } from "@sentry/core"

import { ActionButtons } from "./ActionButtons"
import {
  AlertCard,
  AppText,
  Box,
  Button,
  Divider,
  FormControl,
  Heading,
  IconButton,
  Switch,
} from "./components"
import { ListItem, ListItemTextPrimary } from "./components/ListItem"
import { Row } from "./components/Row"
import { useClipboard } from "./components/useClipboard"
import * as DeviceSummary from "./DeviceSummaryContext"
import {
  createDeviceShareCodeAsync,
  loadFarmsBorrowingDeviceAsync,
  removeDeviceFromFarmAsync,
} from "./farmhq-api"
import { useFormValidation } from "./form-validation"
import * as Models from "./models"
import { DEVICE_PERMISSIONS } from "./permissions"
import { SuccessHandler } from "./SuccessHandler"
import { isTruthyString } from "./type-guards"
import { useBackendRequest } from "./useBackendRequest"
import {
  getActiveFarmIdFromState,
  getActiveFarmNameFromState,
} from "./user-farms.selectors"
import { useRootSelector, useShallowEqualSelector } from "./useRootSelector"

import type { AcceptsChildren } from "./components"
import type { DeviceFarmAssociation, DeviceShareCode } from "./models"
import type { PermissionCheckProps } from "./types"
export type DeviceOwnershipActionName = "CreateShareCode" | "RemoveDevice"
function useTexts() {
  return useTranslation("deviceOwnership")
}

interface ContextValue {
  actionName: DeviceOwnershipActionName | undefined
  onCancel: () => void
  onPressCreateShareCode: () => void
  onPressRemoveDevice: () => void
  shareCode: DeviceShareCode | undefined
}

const Context = React.createContext<ContextValue | undefined>(undefined)

export function Provider({
  children,
  initialActionName,
}: AcceptsChildren & {
  initialActionName?: DeviceOwnershipActionName
}): React.JSX.Element | null {
  const { deviceId } = DeviceSummary.useContext()
  const shareCode = useShallowEqualSelector((state) =>
    Models.deviceShareCode.selectById(state, deviceId),
  )
  const [actionName, setActionName] = React.useState<
    DeviceOwnershipActionName | undefined
  >(initialActionName)
  const value: ContextValue = {
    actionName,
    onCancel: () => setActionName(undefined),
    onPressCreateShareCode: () => setActionName("CreateShareCode"),
    onPressRemoveDevice: () => setActionName("RemoveDevice"),
    shareCode,
  }
  return <Context.Provider value={value}>{children}</Context.Provider>
}

export function useContext(): ContextValue {
  const ctx = React.useContext(Context)
  if (typeof ctx === "undefined") {
    throw new TypeError(
      `DeviceOwnershipContext must be used inside of provider`,
    )
  }
  return ctx
}

export function CurrentCodeString(): React.JSX.Element | null {
  const { shareCode } = useContext()

  return (
    <AppText colorScheme="lightText" fontFamily="OpenSans_700Bold_Italic">
      {shareCode?.codeStr}
    </AppText>
  )
}
export function ExpirationText(): React.JSX.Element | null {
  const { shareCode } = useContext()
  const permissionExpirationDate = shareCode?.permissionExpirationDate
  if (isTruthyString(permissionExpirationDate)) {
    const newLocal = formatDistanceToNow(new Date(permissionExpirationDate), {
      addSuffix: true,
    })
    return <AppText colorScheme="lightText">{`Expires ${newLocal}`}</AppText>
  }
  return null
}
export function CopyCodeButton(): React.JSX.Element | null {
  const { shareCode } = useContext()
  const { onCopy } = useClipboard()
  if (shareCode) {
    return (
      <IconButton
        IconComponent="Clipboard"
        size="lg"
        variant="outline"
        onPress={() => onCopy(shareCode.codeStr)}
      />
    )
  }
  return null
}
export function ShareCodeButton(props: {
  baseUrl: string
}): React.JSX.Element | null {
  const farmName = useRootSelector(getActiveFarmNameFromState) ?? "my farm"
  const { shareCode } = useContext()
  const { deviceName } = DeviceSummary.useContext()
  const { t } = useTexts()
  if (shareCode) {
    const url = `${props.baseUrl}${"/devices/add"}`
    const title = t("shareTitlePrefixWithFarmName", { farmName })
    const message = t(
      "shareDeviceShareCodeMessagePrefixWithCodeStrAndDeviceName",
      {
        codeStr: shareCode.codeStr,
        deviceName,
      },
    )

    return (
      <IconButton
        IconComponent="Share"
        size="lg"
        variant="outline"
        onPress={() => {
          Share.share({ message, title, url }, { subject: title }).catch(
            (error) => captureException(error),
          )
        }}
      />
    )
  }
  return null
}
export function CodeDisplay(props: { baseUrl: string }): React.JSX.Element {
  return (
    <Row justifyContent="space-between" w="$full">
      <View>
        <CurrentCodeString />
        <ExpirationText />
      </View>
      <CopyCodeButton />
      <ShareCodeButton baseUrl={props.baseUrl} />
    </Row>
  )
}
const DATA = DEVICE_PERMISSIONS.getKeys().map((key) => ({
  key,
  text: key,
}))
function keyExtractor(item: (typeof DATA)[number]): string {
  return item.key
}
function Success({ baseUrl }: { baseUrl: string }): React.JSX.Element {
  const { onCancel } = useContext()
  const { t } = useTexts()
  return (
    <SuccessHandler message={t("createDeviceShareCodeSuccessMessage")}>
      <CodeDisplay baseUrl={baseUrl} />
      <Button text={t("done", { ns: "common" })} onPress={onCancel} />
    </SuccessHandler>
  )
}

export function CreateDeviceShareCode({
  baseUrl,
}: {
  baseUrl: string
}): React.JSX.Element {
  const { t } = useTexts()

  const { onCancel } = useContext()
  const { deviceId } = DeviceSummary.useContext()
  const [isSuccess, setIsSuccess] = React.useState(true)

  const { handleError, isLoading, sendRequest } = useBackendRequest(
    createDeviceShareCodeAsync,
  )

  const [permissions, setPermissions] = React.useState(
    DEVICE_PERMISSIONS.allFalse,
  )

  const isDisabled = isLoading || isSuccess
  const handleSubmit = () => {
    sendRequest({ deviceId, permissions })
      .then(() => setIsSuccess(true))
      .catch((error) => handleError(error, { toastMessage: "default" }))
  }
  return (
    <View>
      <Heading colorScheme="secondary" variant="h2">
        {t("newDeviceShareCode")}
      </Heading>
      <FlatList
        ItemSeparatorComponent={Divider}
        data={DATA}
        keyExtractor={keyExtractor}
        scrollEnabled={false}
        ListHeaderComponent={
          isSuccess ? (
            <Success baseUrl={baseUrl} />
          ) : (
            <AlertCard severity="info">
              {t("createDeviceShareCodeInstructions")}
            </AlertCard>
          )
        }
        renderItem={({ item }): React.JSX.Element => {
          return (
            <Row justifyContent="space-between" py="$1">
              <ListItemTextPrimary>{item.key}</ListItemTextPrimary>
              <Switch
                isDisabled={isDisabled}
                onValueChange={(value) =>
                  setPermissions((prev) => ({ ...prev, [item.key]: value }))
                }
              />
            </Row>
          )
        }}
      />
      <ActionButtons
        isLoading={isLoading}
        onPressCancel={onCancel}
        onPressSubmit={handleSubmit}
      />
    </View>
  )
}
// export function RemoveDeviceButton({
//   withPermissions,
//   ...props
// }: Omit<ButtonProps, "onPress"> & PermissionCheckProps): React.JSX.Element {
//   const { onPressRemoveDevice } = useContext()
//   const { t } = useTranslation("devices")

//   return (
//     <Button
//       iconName="delete"
//       id="remove-device-btn"
//       size="xs"
//       text={t("requestRemoval.button")}
//       variant="outlined"
//       {...props}
//       onPress={withPermissions({
//         callback: onPressRemoveDevice,
//         required: "canManageDevicePermissions"
//       })}
//     />
//   )
// }
export function ConfirmRemoveDevice(): React.JSX.Element {
  // const farmName = useRootSelector(getActiveFarmNameFromState)
  const { onCancel } = useContext()
  const { t } = useTexts()
  const { required } = useFormValidation()

  const { codaDeviceAlias, deviceId } = DeviceSummary.useContext()
  const { handleError, isLoading, sendRequest } = useBackendRequest(
    removeDeviceFromFarmAsync,
  )

  const { control, handleSubmit } = useForm({
    defaultValues: { codaDeviceAlias: "" },
  })

  return (
    <View>
      <AppText>{t("requestDeviceRemovalDescription")}</AppText>
      <AppText>{t("requestDeviceRemovalNote")}</AppText>
      <AppText italic>{t("requestDeviceRemovalInstructions")}</AppText>
      <Controller
        control={control}
        name="codaDeviceAlias"
        render={({ field: { onChange, ref, ...field }, fieldState }) => {
          const errorMessage = fieldState.error?.message
          return (
            <FormControl.Provider
              id="coda_device_alias"
              isInvalid={Boolean(errorMessage)}
            >
              <FormControl.Label>
                {t("codaDeviceAlias", { ns: "common" })}
              </FormControl.Label>
              <FormControl.Input
                ref={ref}
                autoFocus
                autoCapitalize="none"
                placeholder={codaDeviceAlias}
                onChangeText={onChange}
                {...field}
              />
              <FormControl.ErrorMessage>
                {errorMessage}
              </FormControl.ErrorMessage>
            </FormControl.Provider>
          )
        }}
        rules={{
          required,
          validate: (value: string) =>
            value === codaDeviceAlias ||
            t("requestRemovalALiasMustMatchErrorMessageWithCodaDeviceAlias", {
              codaDeviceAlias,
            }),
        }}
      />
      <ActionButtons
        isDisabled={isLoading}
        isLoading={isLoading}
        onPressCancel={onCancel}
        onPressSubmit={handleSubmit(async () => {
          await sendRequest({ deviceId }).then(onCancel).catch(handleError)
        })}
      />
    </View>
  )
}

/**
 * Show all farms that have an active device-farm association with this
 * device (i.e. it was lent to them with a Device Share Code)
 */
export function FarmsBorrowingDeviceList({
  baseUrl,
  withPermissions,
}: PermissionCheckProps & {
  baseUrl: string
}): React.JSX.Element {
  const activeFarmId = useRootSelector(getActiveFarmIdFromState)
  const { t } = useTexts()
  const { onPressRemoveDevice } = useContext()
  const { deviceId } = DeviceSummary.useContext()
  const [farms, setItems] = React.useState<DeviceFarmAssociation[]>(() => [])
  const { fetchStatus, handleError, isLoading, sendRequest } =
    useBackendRequest(loadFarmsBorrowingDeviceAsync)

  React.useEffect(() => {
    sendRequest({ deviceId })
      .then((res) => {
        return setItems(
          res.items.filter((value) => {
            return value.farmId !== activeFarmId
          }),
        )
      })
      .catch((error) => handleError(error, { toastMessage: "default" }))
  }, [activeFarmId, deviceId, fetchStatus, handleError, sendRequest])

  return (
    <Box flex={1} pb="$4">
      <FlatList
        data={farms}
        refreshing={isLoading}
        ListEmptyComponent={
          <AppText>{t("farmsBorrowingDeviceListEmpty")}</AppText>
        }
        ListHeaderComponent={
          <AlertCard>
            <AppText colorScheme="lightText">
              {t("currentDeviceShareCode")}
            </AppText>
            <CodeDisplay baseUrl={baseUrl} />
          </AlertCard>
        }
        renderItem={({ item }): React.JSX.Element => (
          <ListItem
            key={item.id}
            text={item.farmName}
            EndComponent={
              <AppText>
                {t("expiresInRelativeTime", {
                  ns: "common",
                  value: item.expirationDate,
                })}
              </AppText>
            }
            secondary={
              // TODO:i18n
              isTruthyString(item.createDate)
                ? `Since ${format(new Date(item.createDate), "PP")}`
                : ""
            }
          />
        )}
      />

      <Box my="auto">
        <Button
          IconComponent="TrashCan"
          id="remove-device-btn"
          size="sm"
          text={t("requestDeviceRemovalButton")}
          variant="outline"
          onPress={withPermissions({
            callback: onPressRemoveDevice,
            required: "canManageDevicePermissions",
          })}
        />
      </Box>
    </Box>
  )
}
