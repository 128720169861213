import { createAction, createSlice, isAnyOf } from "@reduxjs/toolkit"

import * as AppStorage from "./async-storage"
import { getAuthSessionAsync, signOutAsync } from "./auth.reducer"
import {
  createFarmAsync,
  loadActiveUserAsync,
  prepareDemoEnvironmentAsync,
  userJoinFarmWithCodeAsync,
} from "./farmhq-api"
import { geocodeFarmAddressAsync } from "./geocoding"
import { loadActiveFarmAsync } from "./load-app"
import { logger } from "./logger"
import * as Models from "./models"
import { getFetchStatusByName } from "./requests.reducer"
import { isNullish, isValidNumber } from "./type-guards"
import { isDemoFarmId } from "./user-farms.selectors"

import type { FarmAccount, FarmStatusCode } from "./geocoding"
import type { RootState } from "./root.reducer"

import type { Reducer } from "@reduxjs/toolkit"

import type { ModelState } from "./models"
export type JoinFarmErrorCode = "ALREADY_JOINED" | "EXPIRED" | "INVALID"

// TODO: DELETE
export type FarmsStatusCode = "NO FARMS" | `USER ${"CREATED" | "JOINED"} FARM`
export type WelcomeMessageCode = FarmStatusCode | `NO DEVICES` | `NO FIELDS`

interface UserFarmsState extends ModelState<FarmAccount> {
  isFarmLoaded: boolean
  showNewFarmSuccess: boolean
  activeFarmId?: number | null
  demoFarmId?: number | null
  errorCode?: JoinFarmErrorCode
}

const adapter = Models.userFarm.adapter

const initialState: UserFarmsState = {
  entities: {},
  ids: [],
  isFarmLoaded: false,
  showNewFarmSuccess: false,
}

export const dismissNewFarmSuccess = createAction(
  "userFarms/dismissNewFarSuccess",
)

const userFarmsSlice = createSlice({
  extraReducers: (builder) => {
    return (
      builder
        .addCase(loadActiveFarmAsync.fulfilled, (state, { payload }) => {
          if (payload.errorCode === "NO FARM ASSOCIATION") {
            // Try to find a valid farm id here so that the request can be
            // triggered again.
            logger.warn("LoadApp returned NO FARM ASSOCIATION")
            const activeFarmId = state.ids[0]
            if (typeof activeFarmId === "number") {
              logger.debug(`Setting active farm id to fallback ${activeFarmId}`)
              state.activeFarmId = activeFarmId
            } else {
              logger.warn("No farm ids to fall back to!")
            }
          } else {
            state.isFarmLoaded = true
          }
        })
        /**
         * The user can save both a demo farm id and an active farm id
         * to the browser. The demo id supercedes the active farm id.
         */
        .addCase(
          AppStorage.readLocalStorageAsync.fulfilled,
          (state, { payload }) => {
            // Check to make sure this is a valid demo farm id
            const demoFarmId = payload.demoFarmId ?? null
            if (isDemoFarmId(demoFarmId)) {
              state.demoFarmId = demoFarmId
            }

            // Check to make sure this is NOT a valid demo farm id
            const activeFarmId = payload.activeFarmId ?? null

            if (
              typeof activeFarmId === "number" &&
              !isDemoFarmId(activeFarmId)
            ) {
              state.activeFarmId = activeFarmId
              logger.debug(
                `Setting active farm id from localStorage: ${activeFarmId}`,
              )
            }
          },
        )
        .addCase(AppStorage.setActiveFarmId.fulfilled, (state, action) => {
          if (action.meta.arg !== state.activeFarmId) {
            // If we are changing farms, we need to show a loading screen
            state.isFarmLoaded = false
          }
          if (!isDemoFarmId(action.meta.arg)) {
            state.activeFarmId = action.meta.arg
          }
          state.demoFarmId = undefined
        })
        .addCase(AppStorage.exitDemoMode.fulfilled, (state) => {
          state.demoFarmId = undefined
        })
        .addCase(loadActiveUserAsync.fulfilled, (state, { payload }) => {
          adapter.setAll(state, payload.farms)
          // If the farm id doesn't match, find a fallback
          if (payload.farms.length > 0 && isNullish(state.activeFarmId)) {
            const fallbackId = payload.farms.find(
              (farm) => !isDemoFarmId(farm.id),
            )?.id
            if (typeof fallbackId === "number") {
              state.activeFarmId = fallbackId
            }
          }
        })
        .addCase(
          prepareDemoEnvironmentAsync.fulfilled,
          /**
           * When the demo preparation succeeds, we need to get the current
           * demo environment's farm id. This will override the user's active
           * farm id (if any).
           */
          (state, action) => {
            const demoFarmId = action.payload.activeFarmId
            // TODO: Figure out why demo farm needs to be loaded twice after accepting TOS

            if (isDemoFarmId(demoFarmId)) {
              state.demoFarmId = demoFarmId
            }
          },
        )
        .addCase(userJoinFarmWithCodeAsync.fulfilled, (state, action) => {
          if (action.payload.codeStatus === "SUCCESS") {
            adapter.upsertOne(state, action.payload.farm)
            state.activeFarmId = action.payload.farm.id
            state.showNewFarmSuccess = true
          } else {
            state.errorCode = action.payload.codeStatus
          }
        })
        .addCase(createFarmAsync.fulfilled, (state, action) => {
          adapter.upsertOne(state, action.payload.farm)
          state.activeFarmId = action.payload.farm.id
          state.showNewFarmSuccess = true
        })
        .addCase(geocodeFarmAddressAsync.fulfilled, (state, { payload }) => {
          const farmId = payload.id
          if (isValidNumber(farmId)) {
            adapter.updateOne(state, {
              changes: { gpsLocation: payload.gpsLocation },
              id: farmId,
            })
          }
        })
        .addCase(dismissNewFarmSuccess, (state) => {
          state.showNewFarmSuccess = false
          state.errorCode = undefined
        })
        .addMatcher(
          isAnyOf(getAuthSessionAsync.rejected, signOutAsync.fulfilled),
          (state) => {
            adapter.removeAll(state)
            state.activeFarmId = undefined
          },
        )
    )
  },
  initialState,
  name: "userFarms",
  reducers: {},
})

const userFarms: Reducer<UserFarmsState> = userFarmsSlice.reducer
export default userFarms
/**
 * If we are changing farms manually, or transitioning from demo mode,
 * or loading the active farm, we should show a loading screen
 */
export function getIsChangingActiveFarmFromState(state: RootState) {
  if (getFetchStatusByName(state, "ExitDemoMode") === "pending") {
    return true
  }
  if (getFetchStatusByName(state, "SetActiveFarmId") === "pending") {
    return true
  }
  if (getFetchStatusByName(state, "LoadApp") === "pending") {
    return true
  }
  return false
}
