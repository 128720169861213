import React from "react"
import snakecaseKeys from "snakecase-keys"

import { captureException } from "@sentry/core"

import { useEnvironmentVariables } from "./Environment"
import { getActiveFarmIdFromState } from "./user-farms.selectors"
import { useRootSelector } from "./useRootSelector"

import type { TestId } from "./components/test-id"
import type { MapItemType, MapProvider } from "./geo"

import type { DeepPartial } from "utility-types"

import type {
  AnalyticsClient,
  AnalyticsProps,
  EnvironmentMobile,
  EnvironmentWeb,
  SharedEnvironment,
} from "./types"
declare type JsonValue =
  // eslint-disable-next-line @typescript-eslint/no-use-before-define
  JsonList | JsonMap | boolean | number | string | null | undefined
declare type JsonList = JsonValue[]
interface JsonMap {
  [index: number]: JsonValue
  [key: string]: JsonValue
}

export interface PressExternalLinkEvent {
  name: "external_link_press"
  pathname: string | null
  service_name: MapProvider
}

export interface PressEvent {
  elementName: TestId
  name: "element_press"
}

export interface BackendRequest {
  name: "backend_request"
  requestedAction: string
}

export interface CreateSupportIssue {
  name: "create_support_ticket"
  subject: string
}
export interface OpenHelpDialogEvent {
  help_topic: string
  name: "open_help_dialog"
}

type MapItemEntrypoint =
  | `list_item`
  | `map_marker`
  | `map_polygon`
  | `selected_device_sheet`
  | `selected_reel_run_sheet`

export interface SelectMapItem {
  mapItemEntrypoint: MapItemEntrypoint
  mapItemType: MapItemType | "farm"
  name: "select_map_item"
}

type TrackableEvent =
  | BackendRequest
  | CreateSupportIssue
  | OpenHelpDialogEvent
  | PressEvent
  | PressExternalLinkEvent
  | SelectMapItem

interface BaseParams {
  activeFarmId: number | null | undefined
  environmentInfo: SharedEnvironment
}

export function trackEvent(
  client: AnalyticsClient,
  params: BaseParams & TrackableEvent & { options?: JsonMap },
) {
  const {
    appBuildNumber,
    appVersion,
    applicationId,
    backendUrl,
    calendar,
    deviceInfo,
    // easBuildGitCommitHash,
    // enableReduxLogger,
    environmentName,
    getTimezone,
    // isAnalyticsEnabled,
    isEndToEndTest,
    isInternalDistribution,
    // linkUrls,
    locale,
    // localhost,
    localization,
    // logLevel,
    // segmentWriteKey,
    // splashScreen,
    targetDatabaseName,
    updates,
    // testUser,
    // ...rest
  } = params.environmentInfo as DeepPartial<typeof EnvironmentMobile> &
    DeepPartial<typeof EnvironmentWeb>

  const extra: JsonMap = {
    ...params.options,
    applicationId,
    environmentName,
    isEndToEndTest,
    ...locale,
    appBuildNumber,
    appVersion,
    backendUrl,
    isInternalDistribution,
    targetDatabaseName,
    ...deviceInfo,
    localization: localization?.locale,
    timezoneResolved: getTimezone ? getTimezone() : undefined,
    ...calendar,

    ...updates,
  }
  switch (params.name) {
    case "open_help_dialog": {
      extra.topic = params.help_topic
      break
    }
    case "external_link_press": {
      extra.pathname = params.pathname
      break
    }
    case "backend_request": {
      extra.requestedAction = params.requestedAction
      break
    }
    case "element_press": {
      extra.elementName = params.elementName
      break
    }
    case "create_support_ticket": {
      extra.subject = params.subject
      break
    }
    case "select_map_item": {
      extra.mapItemType = params.mapItemType
      extra.mapItemEntrypoint = params.mapItemEntrypoint
      break
    }
  }

  client.track(params.name, extra).catch((error) => {
    captureException(error, {
      tags: snakecaseKeys({
        analytics_event_name: params.name,
        ...extra,
      }),
    })
  })
}

/**
 * Factory for event tracking hook for each platform
 */
export function makeEventTrackerHook({ analyticsClient }: AnalyticsProps) {
  return function useTrackEvent() {
    const environmentInfo = useEnvironmentVariables()
    const activeFarmId = useRootSelector(getActiveFarmIdFromState)
    return React.useCallback(
      (event: TrackableEvent, extraParameters?: JsonMap) => {
        trackEvent(analyticsClient, {
          activeFarmId,
          environmentInfo,
          ...event,
          ...extraParameters,
        })
      },
      [activeFarmId, environmentInfo],
    )
  }
}
type ContextValue = AnalyticsClient

const Context = React.createContext<ContextValue | undefined>(undefined)

export function AnalyticsProvider({
  children,
  client,
}: {
  client: AnalyticsClient
  children?: React.ReactNode
}) {
  return <Context.Provider value={client}>{children}</Context.Provider>
}

export function useAnalyticsContext(): ContextValue {
  const ctx = React.useContext(Context)
  if (typeof ctx === "undefined") {
    throw new TypeError("Analytics context is undefined")
  }
  return ctx
}
