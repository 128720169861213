import { Platform } from "react-native"

import { getIsUserAuthenticatedFromState } from "./auth.reducer"
import { getIsAdminModeEnabledFromState } from "./selectors"
import { getIsMultifarmUserFromState } from "./user-farms.selectors"
import { useRootSelector } from "./useRootSelector"

import type { RootState } from "./root.reducer"
import type { AcceptsChildren } from "./components"
interface FilterOptions {
  displayName?: string
  fallbackElement?: React.JSX.Element
}
type Props = AcceptsChildren & FilterOptions
/**
 * If the filter return true, show the component. Otherwise, show the fallback
 */
export function makeFilterComponent({
  displayName,
  getIsVisible,
  ...defaultOptions
}: FilterOptions & {
  getIsVisible: (state: RootState) => boolean
}): React.FC<Props> {
  function Filter({ children, fallbackElement }: Props) {
    const isVisible = useRootSelector(getIsVisible)
    if (isVisible) {
      return children as JSX.Element | null
    }
    return fallbackElement ?? defaultOptions.fallbackElement ?? null
  }
  if (typeof displayName === "string") {
    Filter.displayName = displayName
  }
  return Filter
}

/**
 * Shown only in development mode
 */
export const DevOnly = makeFilterComponent({
  displayName: "DevOnly",
  getIsVisible: () => __DEV__,
})

/**
 * Show only if admin
 */
export const AdminOnly = makeFilterComponent({
  displayName: "AdminOnly",
  getIsVisible: (state) => __DEV__ || getIsAdminModeEnabledFromState(state),
})

export const AuthenticatedOnly = makeFilterComponent({
  displayName: "AuthenticatedOnly",
  getIsVisible: (state) => getIsUserAuthenticatedFromState(state),
})

export const MultifarmOnly = makeFilterComponent({
  displayName: "MultifarmOnly",
  getIsVisible: (state) => getIsMultifarmUserFromState(state),
})
export const AndroidOnly = makeFilterComponent({
  displayName: "AndroidOnly",
  getIsVisible: () => Platform.OS === "android",
})
