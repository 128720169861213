import React from "react"
import { useTranslation } from "react-i18next"

import { AddDeviceLinkButton } from "./AddDevice"
import { setDeviceSortDirection, setDeviceSortKey } from "./async-storage"
import {
  AppText,
  CloseIconButton,
  Menu,
  Row,
  SortListButton,
} from "./components"
import { NoListItems } from "./NoListItems"
import { RosterHeader } from "./RosterHeader"
import {
  getDeviceSortDirectionFromState,
  getDeviceSortKeyFromState,
} from "./selectors"
import {
  SortDirectionIconButton,
  SortListItem,
} from "./SortDirectionIconButton"
import { DeviceSortKeys } from "./Sorting"
import i18n from "./translations/i18n"
import { useErrorHandler } from "./useErrorHandler"
import { useRootDispatch } from "./useRootDispatch"
import { useRootSelector } from "./useRootSelector"
import { objectEntries } from "./utility"

import type {
  ButtonProps,
  IconButtonStyleProps,
  MenuProps,
  HelpContentStatic,
} from "./components"
import type { RosterHeaderProps } from "./RosterHeader"
export const HELP_CONTENT: HelpContentStatic = {
  bodyElement: i18n.t("devices:deviceRosterHelpContentBodyText"),
  subject: "device_roster",
  titleElement: i18n.t("devices:deviceRosterHelpContentTitle"),
}

export function SortMenu({
  _trigger,
  isInverted = false,
  ...props
}: Omit<MenuProps, "isOpen" | "onClose"> & {
  _trigger?: ButtonProps
  isInverted?: boolean
}): React.JSX.Element {
  const [isOpen, setIsOpen] = React.useState(false)
  const currentSortKey = useRootSelector(getDeviceSortKeyFromState)
  const currentSortDirection = useRootSelector(getDeviceSortDirectionFromState)
  const dispatch = useRootDispatch()
  const { t } = useTranslation("devices")
  const handleError = useErrorHandler()

  const titleText: string = t("deviceRosterSortMenuTitle")
  const handleClose = () => setIsOpen(false)
  const items = objectEntries(DeviceSortKeys)

  return (
    <React.Fragment>
      <SortListButton
        isInverted={isInverted}
        size="sm"
        onPress={() => setIsOpen(true)}
        {..._trigger}
      />
      <Menu
        style={{ width: 250 }}
        {...props}
        isOpen={isOpen}
        onClose={handleClose}
      >
        <Row justifyContent="space-between" mb="$2">
          <AppText colorScheme="secondary">{titleText}</AppText>
          <CloseIconButton onPress={handleClose} />
        </Row>

        {items.map(
          ([key, { IconComponent, text }], index): React.JSX.Element => {
            const isSelected = currentSortKey === key

            const handlePress = () => {
              if (isSelected) {
                dispatch(
                  setDeviceSortDirection(
                    /**
                     * Toggle the sort direction
                     */
                    currentSortDirection === "asc" ? "desc" : "asc",
                  ),
                ).catch((error) => {
                  handleError(error, {
                    toastMessage: "default",
                  })
                })
              } else {
                dispatch(setDeviceSortKey(key)).catch((error) => {
                  handleError(error, {
                    toastMessage: "default",
                  })
                })
              }
            }
            return (
              <SortListItem
                key={key}
                IconComponent={IconComponent}
                isSelected={isSelected}
                showDivider={index < items.length - 1}
                sortDirection={currentSortDirection}
                text={text}
                onPress={handlePress}
              />
            )
          },
        )}
      </Menu>
    </React.Fragment>
  )
}

export function SortDirectionButton({
  isInverted,
  ...props
}: IconButtonStyleProps & {
  isInverted?: boolean
}): React.JSX.Element {
  const dispatch = useRootDispatch()
  const currentValue = useRootSelector(getDeviceSortDirectionFromState)
  const handleError = useErrorHandler()

  return (
    <SortDirectionIconButton
      {...props}
      currentDirection={currentValue}
      isInverted={isInverted === true}
      variant="text"
      onPress={() => {
        dispatch(
          setDeviceSortDirection(currentValue === "asc" ? "desc" : "asc"),
        ).catch((error) => handleError(error, { toastMessage: "default" }))
      }}
    />
  )
}

export function ListHeaderComponent({
  HelpComponent,
  isInverted = false,
  isTitleHidden,
  itemCount,
  onPressAdd,
}: RosterHeaderProps) {
  const { t } = useTranslation("devices")
  return (
    <RosterHeader
      AddButtonComponent={AddDeviceLinkButton}
      HelpComponent={HelpComponent}
      SortDirectionButtonComponent={SortDirectionButton}
      SortMenuComponent={SortMenu}
      isInverted={isInverted}
      isTitleHidden={isTitleHidden}
      itemCount={itemCount}
      titleText={t("deviceRosterTitleWithItemCount", { itemCount })}
      onPressAdd={onPressAdd}
    />
  )
}

export function ListEmptyComponent({
  onPressAddDevice,
}: {
  onPressAddDevice: () => void
}) {
  const { t } = useTranslation("devices")

  return (
    <NoListItems
      action={<AddDeviceLinkButton size="md" onPress={onPressAddDevice} />}
      message={t("deviceRosterListEmpty")}
    />
  )
}
