import React from "react"
import { Outlet } from "react-router-dom"

import {
  AppIsLoading,
  getActiveFarmSubscriptionDueDate,
  getAppViewFromState,
  isDateWithinSubscriptionWarningThreshold,
  SubscriptionRenewal,
  useIsPending,
  useRootDispatch,
} from "@fhq/app"
import { onShareAvailable } from "@fhq/app/app-state.reducer"
import { Background } from "@fhq/app/components"
import { ModalLoader } from "@fhq/app/Loader"
import { useRootSelector } from "@fhq/app/useRootSelector"

import { AUTHENTICATED_ROUTES } from "./Authenticated.Routes"
import { useRecordPageVisit } from "./pages/base/Analytics"
import { PageErrorBoundary } from "./pages/base/PageErrorBoundary"
import { RootLayout } from "./pages/base/RootLayout"
import { sentryCreateBrowserRouter } from "./pages/base/sentry"
import { ContactFarmHqUnauthenticatedPage } from "./pages/ContactFarmHqUnauthenticatedPage"
import { TimeoutTracker } from "./TimeoutTracker"

const AppInfoPage = React.lazy(
  //
  async () => import("./pages/AppInfoPage"),
)
export const ContactUsPage = React.lazy(
  //
  async () => import("./pages/CreateSupportTicketPage"),
)
const PasswordResetPage = React.lazy(
  //
  async () => import("./pages/PasswordResetPage"),
)
const SignInPage = React.lazy(
  //
  async () => import("./pages/SignInPage"),
)
const SignOutPage = React.lazy(
  //
  async () => import("./pages/SignOutPage"),
)
const SignUpPage = React.lazy(
  //
  async () => import("./pages/SignUpPage"),
)

const ConfirmSignUpPage = React.lazy(
  //
  async () => import("./pages/ConfirmSignUpPage"),
)

function useIsShareAvailable() {
  const dispatch = useRootDispatch()

  /**
   * Show/hide share buttons based on whether the browser/platform supports it
   */
  // eslint-disable-next-line @typescript-eslint/unbound-method
  const isShareAvailable = Boolean(navigator.share)
  React.useEffect(() => {
    if (isShareAvailable) {
      // Share is available
      dispatch(onShareAvailable())
    }
  }, [dispatch, isShareAvailable])
}

function Root(): React.JSX.Element {
  // Collect analytics data on each page change
  useRecordPageVisit()
  useIsShareAvailable()

  const showModalLoader = useIsPending("PrepareDemoEnvironment")
  const appView = useRootSelector(getAppViewFromState)
  const farmSubscriptionDueDate = useRootSelector(
    getActiveFarmSubscriptionDueDate,
  )

  if (appView === "loading") {
    return (
      <React.Fragment>
        <TimeoutTracker />
        <AppIsLoading />
      </React.Fragment>
    )
  }

  if (appView === "load-error") {
    // This should trigger the error boundary
    throw new TypeError("Load Error")
  }
  if (appView === "account-locked") {
    return (
      <Background style={{ flex: 1 }}>
        <SubscriptionRenewal.AccountLockedDueToNonPayment />
      </Background>
    )
  }

  return (
    <React.Fragment>
      <ModalLoader isOpen={showModalLoader} />
      {typeof farmSubscriptionDueDate === "number" &&
      isDateWithinSubscriptionWarningThreshold(farmSubscriptionDueDate) ? (
        <SubscriptionRenewal.FarmAccountNonPaymentWarning
          dueDate={farmSubscriptionDueDate}
        />
      ) : null}
      <Outlet />
    </React.Fragment>
  )
}

export const router = sentryCreateBrowserRouter([
  {
    children: [
      { element: <AppInfoPage />, path: "/app-info" },
      { element: <SignOutPage />, path: "/sign-out" },
      { element: <SignInPage />, path: "/sign-in" },
      { element: <SignUpPage />, path: "/sign-up" },
      { element: <ConfirmSignUpPage />, path: "/confirm-sign-up" },
      { element: <ConfirmSignUpPage />, path: "/confirm-account" },
      { element: <PasswordResetPage />, path: "/password-reset" },
      { element: <ContactFarmHqUnauthenticatedPage />, path: "/contact" },
      { ...AUTHENTICATED_ROUTES, path: "/" },
    ],
    element: (
      <RootLayout>
        <Root />
      </RootLayout>
    ),
    errorElement: <PageErrorBoundary />,
    path: "/",
  },
])
