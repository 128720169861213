import React from "react"
import { useTranslation } from "react-i18next"

import { exitDemoMode } from "./async-storage"
import { Button } from "./components"
import { prepareDemoEnvironmentAsync } from "./farmhq-api"
import { useBackendRequest } from "./useBackendRequest"
import { useErrorHandler } from "./useErrorHandler"
import { useRootDispatch } from "./useRootDispatch"

import type { ButtonProps, IconProp } from "./components"
import type { TargetDatabaseName } from "./Internal"
export interface EnvironmentProps {
  targetDatabaseName: TargetDatabaseName
}

export type PrepareDemoButtonProps = EnvironmentProps & {
  onSuccess: (() => void) | null | undefined
}

/**
 * Logic for
 */
export function usePrepareDemoButton({
  onSuccess,
  targetDatabaseName,
}: PrepareDemoButtonProps) {
  const { t } = useTranslation("demoMode")
  const { handleError, isLoading, sendRequest, toasts } = useBackendRequest(
    prepareDemoEnvironmentAsync,
  )

  let text = t("loadDemoButton")
  if (targetDatabaseName !== "PROD") {
    text += ` (${targetDatabaseName})`
  }
  const IconComponent: IconProp = "Truck"
  return {
    IconComponent,
    isLoading,
    onPress: () => {
      sendRequest()
        .then(() => toasts.success(t("toasts.isEnabled", { ns: "demoMode" })))
        .then(onSuccess)
        .catch((error) => {
          handleError(error, { toastMessage: "default" })
        })
    },
    text,
  }
}
export function PrepareDemoButton({
  onSuccess,
  targetDatabaseName,
  ...props
}: ButtonProps & PrepareDemoButtonProps): React.JSX.Element {
  const {
    IconComponent: iconName,
    isLoading,
    onPress,
    text,
  } = usePrepareDemoButton({
    onSuccess,
    targetDatabaseName,
  })
  return (
    <Button
      {...props}
      IconComponent={iconName}
      isLoading={isLoading}
      text={text}
      onPress={onPress}
    />
  )
}

export function useExitDemoButton() {
  const dispatch = useRootDispatch()
  const { t } = useTranslation()
  const handleError = useErrorHandler()
  return {
    onPress: () => {
      dispatch(exitDemoMode()).catch((error) => {
        handleError(error, { toastMessage: "default" })
      })
    },
    text: t("exitDemoButton", { ns: "demoMode" }),
  }
}
