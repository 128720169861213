import React from "react"
import { useTranslation } from "react-i18next"

import { ActiveFarmCycler } from "./ActiveFarmIndicator"
import { SignOutButton } from "./Auth"
import {
  AlertBodyText,
  AlertCard,
  AlertDialog,
  AlertDialogScrollView,
  AppText,
  Box,
  Heading,
  IconButton,
  Link,
  useIsDarkMode,
} from "./components"
import { Row } from "./components/Row"
import { COLORS } from "./components/theme"
import { useClipboard } from "./components/useClipboard"
import { EmailAddresses } from "./Internal"
import { useIsPending } from "./requests.reducer"
import { getDateFormatOptions } from "./translations"
import {
  getActiveFarmNameFromState,
  getFarmAccountSubscriptionLockDate,
  getRealFarmCountFromState,
} from "./user-farms.selectors"
import { useRootSelector } from "./useRootSelector"

import type { TestId } from "./components/test-id"
const SALES_EMAIL = EmailAddresses.SALES
const SPACE = "%20"
const SUBJECT = "Subscription Renewal".replaceAll(" ", SPACE)
const MAILTO_LINK = `mailto:${SALES_EMAIL}?subject=${SUBJECT}`

const DATE_FORMAT_PARAMS = getDateFormatOptions({
  // numeric: "auto",
  dateStyle: "medium",
  timeStyle: "short",
})

function ContactActions() {
  const { onCopy } = useClipboard()

  return (
    <Box mt="$4">
      <Row>
        <Link to={MAILTO_LINK}>{SALES_EMAIL}</Link>
        <Box ml="$2">
          <IconButton
            IconComponent="Clipboard"
            variant="text"
            onPress={() => onCopy(SALES_EMAIL)}
          />
        </Box>
      </Row>
    </Box>
  )
}

/**
 *
 */
export function FarmAccountNonPaymentWarning({ dueDate }: { dueDate: number }) {
  const { t } = useTranslation("subscriptions")

  const [isOpen, setIsOpen] = React.useState(true)

  const handleClose = () => setIsOpen(false)

  let titleText: string
  let messageText: string
  let testId: TestId

  const isDueDateInTheFuture =
    new Date(dueDate).getTime() > new Date().getTime()

  if (isDueDateInTheFuture) {
    testId = "subscription-due-reminder"
    titleText = t("dueDateUpcomingTitle")
    messageText = t("dueDateUpcomingMessageWithDueDate", {
      dueDate,
      ...DATE_FORMAT_PARAMS,
    })
  } else {
    // Due date is in the past
    testId = "subscription-overdue-warning"
    titleText = t("dueDatePassedTitle")
    messageText = t("dueDatePassedMessagewithLockDate", {
      lockDate: getFarmAccountSubscriptionLockDate(dueDate),
      ...DATE_FORMAT_PARAMS,
    })
  }
  return (
    <AlertDialog
      IconComponent="Warning"
      id={testId}
      isOpen={isOpen}
      titleElement={titleText}
      onClose={handleClose}
    >
      <AlertDialogScrollView>
        <AlertCard mb="$4">
          <AlertBodyText style={{ flex: 1 }}>
            {t("dueDateWithValue", {
              value: dueDate,
              ...DATE_FORMAT_PARAMS,
            })}
          </AlertBodyText>
        </AlertCard>
        <Box mb="$4">
          <AppText>{messageText}</AppText>
        </Box>
        <Box mb="$4">
          <AppText>{t("eitherPayOrContactUs")}</AppText>
        </Box>
        <ContactActions />
      </AlertDialogScrollView>
    </AlertDialog>
  )
}

export function AccountLockedDueToNonPayment() {
  const { t } = useTranslation("subscriptions")
  const numFarms = useRootSelector(getRealFarmCountFromState)
  const activeFarmName = useRootSelector(getActiveFarmNameFromState)

  const isChangingFarms = useIsPending("SetActiveFarmId", "LoadApp")
  const isDark = useIsDarkMode()

  return (
    <Box id="account-locked-due-to-nonpayment" mx="$4" my="$4">
      <Heading>{t("accountLockedTitle")}</Heading>
      <Box mt="$4">
        <AppText>{t("accountLockedMessage")}</AppText>
      </Box>
      <ContactActions />
      {numFarms > 1 ? (
        <Row
          bg={isDark ? COLORS.$gray[600] : COLORS.$gray[200]}
          justifyContent="space-between"
          minW="$xs"
          mt="$4"
          p="$3"
          rounded="$default"
        >
          <Box flex={1}>
            <AppText>{t("account", { ns: "common" })}</AppText>
            <AppText colorScheme="secondary">{activeFarmName}</AppText>
          </Box>
          <ActiveFarmCycler isChangingFarms={isChangingFarms} />
        </Row>
      ) : null}
      <Box mt="$4" mx="auto">
        <SignOutButton />
      </Box>
    </Box>
  )
}
