import React from "react"
import { Pressable as Base, StyleSheet } from "react-native"

import { testIds } from "./test-id"
import { COLORS, SIZES } from "./theme"
import { useIsDarkMode } from "./ThemeProvider"

import type { WithOptionalTestId } from "./test-id"
import type { SpaceToken } from "./theme"
import type { PressableProps as BaseProps } from "react-native"
import type { PressableState } from "./Button"

export interface PressableProps extends WithOptionalTestId<BaseProps> {
  px?: SpaceToken
  trackHover?: boolean
  trackPress?: boolean
}

const styles = StyleSheet.create({
  hovered: {
    backgroundColor: COLORS.$gray[300],
  },
  hoveredDark: {
    backgroundColor: COLORS.$warmGray[700],
  },
  pressed: {
    backgroundColor: COLORS.$gray[200],
  },
  pressedDark: {
    backgroundColor: COLORS.$warmGray[600],
  },
})

/**
 * Encapsulated pressable component
 */
export function Pressable({
  disabled = false,
  id,
  px,
  style,
  trackHover = false,
  trackPress = false,
  ...rest
}: PressableProps) {
  const isDark = useIsDarkMode()
  return (
    <Base
      {...testIds(id)}
      accessibilityRole="button"
      accessibilityState={{
        disabled: disabled === true,
      }}
      style={(state) => {
        const { hovered, pressed } = state as PressableState
        let paddingHorizontal: number | string = 0

        if (px) {
          paddingHorizontal = SIZES[px]
        }
        return [
          { paddingHorizontal },
          trackHover &&
            hovered &&
            (isDark ? styles.hoveredDark : styles.hovered),
          trackPress &&
            pressed &&
            (isDark ? styles.pressedDark : styles.pressed),
          typeof style === "function" ? style(state) : style,
        ]
      }}
      {...rest}
    />
  )
}
