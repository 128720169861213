import React from "react"

import {
  AlertBodyText,
  AlertCard,
  Box,
  Button,
  CloseIconButton,
  Row,
} from "./components"
import { COLORS } from "./components/theme"
import { getDeviceSummaryByDeviceIdFromState } from "./selectors"
import i18n from "./translations/i18n"
import { useRootSelector } from "./useRootSelector"

import type { BoxProps } from "./components"
import type { DeviceIdProps, PermissionCheckProps } from "./types"
interface Props extends DeviceIdProps, PermissionCheckProps, BoxProps {
  isDismissable: boolean
  onPressCreateConfiguration: () => void
}

/**
 * Warning to display when a device is unconfigured
 */
export function UnconfiguredDeviceWarning({
  deviceId,
  isDismissable,
  onPressCreateConfiguration,
  withPermissions,
  ...rest
}: Props): React.JSX.Element | null {
  const [isOpen, setIsOpen] = React.useState(true)

  const isUnconfigured = useRootSelector(
    (state) =>
      getDeviceSummaryByDeviceIdFromState(state, deviceId)
        ?.deviceInstallationType === "unconfigured",
  )

  if (isUnconfigured && isOpen) {
    return (
      <AlertCard
        {...rest}
        severity="warning"
        titleText={i18n.t("warning")}
        actionElement={
          isDismissable ? (
            <Box ml="$2">
              <CloseIconButton
                iconColor={COLORS.$textDark.primary}
                variant="text"
                onPress={() => setIsOpen(false)}
              />
            </Box>
          ) : null
        }
      >
        <Row>
          <Box mb="$2">
            <AlertBodyText>
              {i18n.t("deviceConfiguration:unconfiguredDeviceWarning")}
            </AlertBodyText>
          </Box>
          <Box maxW="$xs" ml="auto" w="$full">
            <Button
              flexGrow={1}
              id="set-up-device-btn"
              text={i18n.t("deviceConfiguration:setUpDevice")}
              onPress={withPermissions({
                callback: onPressCreateConfiguration,
                required: "canManageDeviceConfiguration",
              })}
            />
          </Box>
        </Row>
      </AlertCard>
    )
  }
  return null
}
