import { createSlice, isAnyOf } from "@reduxjs/toolkit"

import { clearSelectedTrigger, clickCancel } from "./actions"
import { signOutAsync } from "./auth.reducer"
import {
  addDeviceAsync,
  createCustomAutomationAsync,
  createCustomNotificationAsync,
  customizeNotificationStringAsync,
  deleteEventActionTriggersAsync,
  isDeviceInTrigger,
  removeDeviceFromFarmAsync,
  replaceDeviceAsync,
  updateDeviceConnectionAsync,
} from "./farmhq-api"
import { loadActiveFarmAsync } from "./load-app"
import * as Models from "./models"

import type { PayloadAction, Reducer } from "@reduxjs/toolkit"

import type { EventActionTrigger, ModelState } from "./models"
import type { InstallationType } from "./sensor-configurations"

export type TriggerFilterKey = "automation" | "notification" | "pair"

/**
 * If true, the device can be used as a 'Target Device' in a trigger/connection.
 */
export function isValidConnectionTarget(device: {
  deviceInstallationType: InstallationType | null | undefined
}) {
  return (
    device.deviceInstallationType === "pump" ||
    device.deviceInstallationType === "pump_off_only" ||
    device.deviceInstallationType === "pump_on_off" ||
    device.deviceInstallationType === "pump_vfd" ||
    device.deviceInstallationType === "valve"
  )
}

const adapter = Models.trigger.adapter

const initialState: ModelState<EventActionTrigger> & { selectedId?: number } = {
  entities: {},
  ids: [],
}

const triggersSlice = createSlice({
  extraReducers: (builder) => {
    return builder
      .addCase(updateDeviceConnectionAsync.pending, (state, { meta }) => {
        if (typeof meta.arg.notify === "boolean") {
          adapter.updateOne(state, {
            changes: { notify: meta.arg.notify },
            id: meta.arg.connectionId,
          })
        }
      })
      .addCase(
        customizeNotificationStringAsync.fulfilled,
        (state, { meta }) => {
          adapter.updateOne(state, {
            changes: { notificationString: meta.arg.notificationString },
            id: meta.arg.triggerId,
          })
        },
      )
      .addCase(loadActiveFarmAsync.fulfilled, (state, { payload }) => {
        adapter.setAll(state, payload.triggers)
      })
      .addCase(deleteEventActionTriggersAsync.fulfilled, (state, { meta }) => {
        adapter.removeMany(state, meta.arg.ids)
      })
      .addCase(addDeviceAsync.fulfilled, (state, { payload }) => {
        if (payload.status === "VALID" && payload.deviceConnections) {
          adapter.upsertMany(state, payload.deviceConnections)
        }
      })
      .addCase(replaceDeviceAsync.fulfilled, (state, { meta, payload }) => {
        if (payload.status === "VALID") {
          if (payload.deviceConnections) {
            adapter.upsertMany(state, payload.deviceConnections)
          }
          adapter.removeMany(
            state,
            Object.values(state.entities)
              .filter((entity) => {
                if (entity) {
                  return isDeviceInTrigger({
                    device: { deviceId: meta.arg.deviceIdOld },
                    trigger: entity,
                  })
                }
                return false
              })
              .map((entity) => {
                if (entity) {
                  return entity.id
                }
                return 0
              }),
          )
        }
      })
      .addCase(removeDeviceFromFarmAsync.fulfilled, (state, { meta }) => {
        adapter.removeMany(
          state,
          state.ids.filter((id): boolean => {
            const entity = state.entities[id]
            return (
              entity?.sourceDeviceId === meta.arg.deviceId ||
              entity?.targetDeviceId === meta.arg.deviceId
            )
          }),
        )
      })
      .addMatcher(
        isAnyOf(
          createCustomAutomationAsync.fulfilled,
          createCustomNotificationAsync.fulfilled,
        ),
        (state, { payload }) => {
          adapter.upsertOne(state, payload)
        },
      )
      .addMatcher(
        isAnyOf(
          clickCancel,
          clearSelectedTrigger,
          createCustomAutomationAsync.fulfilled,
          createCustomNotificationAsync.fulfilled,
          deleteEventActionTriggersAsync.fulfilled,
          customizeNotificationStringAsync.fulfilled,
        ),
        (state) => {
          state.selectedId = undefined
        },
      )
      .addMatcher(isAnyOf(signOutAsync.fulfilled), () => ({ ...initialState }))
  },
  initialState,
  name: "triggers",
  reducers: {
    selectTrigger(state, { payload }: PayloadAction<number>) {
      state.selectedId = payload
    },
  },
})
const triggersReducer: Reducer<typeof initialState> = triggersSlice.reducer

export default triggersReducer

export const { selectTrigger } = triggersSlice.actions
