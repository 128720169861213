import React from "react"

import type { AcceptsChildren } from "./components"
import type { DeviceStatusData } from "./device-event-last.reducer"

export type ContextValue = DeviceStatusData

const Context = React.createContext<ContextValue | undefined>(undefined)

/**
 *
 */
export function Provider({
  children,
  ...value
}: AcceptsChildren & ContextValue): React.JSX.Element | null {
  return <Context.Provider value={value}>{children}</Context.Provider>
}

export function useContext() {
  const ctx = React.useContext(Context)
  if (typeof ctx === "undefined") {
    throw new TypeError(
      `[DeviceSummaryContext]: must be used inside of provider`,
    )
  }
  return ctx
}
