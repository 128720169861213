import type { PayloadAction } from "@reduxjs/toolkit"
import React from "react"
import { Alert } from "react-native"

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { captureException } from "@sentry/core"

import { ActionButtons } from "./ActionButtons"
import { AlertCard, Button, FormControl } from "./components"
import {
  createCustomAutomationAsync,
  loadDeviceActionsAsync,
} from "./farmhq-api"
import * as Models from "./models"
import { useFormatDeviceActionDisplayName } from "./named-device-actions.reducer"
import { useIsPending } from "./requests.reducer"
import {
  formatInstallationType,
  listConfigSensors,
} from "./sensor-configurations"
import { SENSOR_NAME_TO_STATES } from "./sensor-events"
import { formatSensorName, formatSensorState } from "./sensor-formatting"
import i18n from "./translations/i18n"
import { isValidConnectionTarget } from "./triggers.reducer"
import { isValidNumber } from "./type-guards"
import { useBackendRequest } from "./useBackendRequest"
import { useRootDispatch } from "./useRootDispatch"
import { useRootSelector } from "./useRootSelector"

import type { Reducer } from "redux"
import type { HelpContentStatic } from "./components"
import type { FormControlProviderProps } from "./components/form-control/base"
import type { GetRootState, RootState, RootThunkConfig } from "./root.reducer"
import type { SensorName } from "./sensor-configurations"
import type { SensorState } from "./sensor-events"
import type { RootDispatch } from "./store"
// eslint-disable-next-line @typescript-eslint/consistent-type-definitions
export type CreateConnectionRouteParams = {
  initiatorDeviceId: string
}

export const HELP_CONTENT: HelpContentStatic = {
  bodyElement: i18n.t("createDeviceConnection:helpBodyText"),
  subject: "create_device_connection",
  titleElement: i18n.t("createDeviceConnection:aboutDeviceConnections"),
}

export interface CreateConnectionFormData {
  sourceDeviceId: string
  sourceSensor: SensorName | ""
  sourceSensorStateCurrent: SensorState | ""
  sourceSensorStatePrevious: SensorState | ""
  targetActionId: number | null
  targetDeviceId: string
}

type Errors = {
  [key in keyof CreateConnectionFormData | "form"]?: string
}

export interface CreateDeviceConnectionState {
  formData: CreateConnectionFormData

  showErrors: boolean

  actionOptions?: Models.NamedDeviceAction[]
}

/**
 * Check properties of form data to see if matching connection already exists
 */
function getConnectionAlreadyCreated(state: RootState) {
  const allConnections = Models.trigger.selectAll(state)
  const {
    sourceDeviceId,
    sourceSensor,
    sourceSensorStateCurrent,
    sourceSensorStatePrevious,
    targetActionId,
    targetDeviceId,
  } = state.createConnection.formData
  return Boolean(
    allConnections.find((connection): boolean => {
      if (
        connection.sourceDeviceId === sourceDeviceId &&
        connection.sourceSensor === sourceSensor &&
        connection.sourceSensorStateCurrent === sourceSensorStateCurrent &&
        connection.sourceSensorStatePrevious === sourceSensorStatePrevious &&
        connection.targetDeviceId === targetDeviceId &&
        connection.namedDeviceAction?.id === targetActionId
      ) {
        return true
      }
      return false
    }),
  )
}
function getStateCurrentAndPreviousAreEqual(state: RootState) {
  const { sourceSensorStateCurrent, sourceSensorStatePrevious } =
    state.createConnection.formData
  if (
    sourceSensorStateCurrent.length > 0 &&
    sourceSensorStatePrevious.length > 0
  ) {
    return sourceSensorStateCurrent === sourceSensorStatePrevious
  }
  return false
}

function validate(state: RootState): Errors {
  const formData = state.createConnection.formData
  const errors: Errors = {}
  const sourceConfig = Models.deviceConfiguration.selectById(
    state,
    formData.sourceDeviceId,
  )
  const targetConfig = Models.deviceConfiguration.selectById(
    state,
    formData.targetDeviceId,
  )
  if (
    typeof formData.sourceDeviceId === "string" &&
    formData.sourceDeviceId.length > 0
  ) {
    if (sourceConfig?.deviceInstallationType === "unconfigured") {
      errors.sourceDeviceId = i18n.t("errorMessageDeviceIsNotConfigured", {
        ns: "createDeviceConnection",
      })
    }
  } else {
    errors.sourceDeviceId = i18n.t("validation:required")
  }

  // SOURCE SENSOR
  if (!formData.sourceSensor) {
    errors.sourceSensor = i18n.t("validation:required")
  }
  // STATES
  if (getStateCurrentAndPreviousAreEqual(state)) {
    errors.sourceSensorStateCurrent = i18n.t(
      "createDeviceConnection:errorMessageStatesEqual",
    )
  } else {
    if (!formData.sourceSensorStatePrevious) {
      errors.sourceSensorStatePrevious = i18n.t("validation:required")
    }
    if (!formData.sourceSensorStateCurrent) {
      errors.sourceSensorStateCurrent = i18n.t("validation:required")
    }
  }
  // TARGET DEVICE
  if (
    typeof formData.targetDeviceId === "string" &&
    formData.targetDeviceId.length > 0
  ) {
    if (targetConfig?.deviceInstallationType === "unconfigured") {
      errors.targetDeviceId = i18n.t(
        "createDeviceConnection:errorMessageDeviceIsNotConfigured",
      )
    }
  } else {
    errors.targetDeviceId = i18n.t("validation:required")
  }

  // ACTIONS
  const actions = state.createConnection.actionOptions
  if (Array.isArray(actions) && actions.length === 0) {
    errors.targetActionId = i18n.t(
      "createDeviceConnection:noActionsConfiguredErrorMessage",
    )
  } else if (typeof formData.targetActionId !== "number") {
    errors.targetActionId = i18n.t("validation:required")
  }

  // FORM ERRORS
  if (getConnectionAlreadyCreated(state)) {
    errors.form = i18n.t("createDeviceConnection:errorMessageAlreadyCreated")
  }
  return errors
}

const initialState: CreateDeviceConnectionState = {
  formData: {
    sourceDeviceId: "",
    sourceSensor: "",
    sourceSensorStateCurrent: "",
    sourceSensorStatePrevious: "",
    targetActionId: null,
    targetDeviceId: "",
  },
  showErrors: false,
}

const slice = createSlice({
  extraReducers: (builder) => {
    return builder
      .addCase(loadDeviceActionsAsync.pending, (state) => {
        state.actionOptions = undefined

        // state.errors.targetActionId = undefined
      })
      .addCase(loadDeviceActionsAsync.fulfilled, (state, action) => {
        state.actionOptions = action.payload.items
      })
      .addCase(createCustomAutomationAsync.fulfilled, () => {
        return {
          ...initialState,
        }
      })
  },
  initialState,
  name: "createConnection",
  reducers: {
    resetCreateConnectionsForm: () => {
      return {
        ...initialState,
      }
    },
    setSourceDevice: (state, action: PayloadAction<string>) => {
      state.formData.sourceDeviceId = action.payload
    },
    setSourceSensor: (
      state,
      { payload: sensorName }: PayloadAction<SensorName | "">,
    ) => {
      state.formData.sourceSensor = sensorName
      // Use the same defaults for this sensor as we do for the source device
      // change handler
      switch (sensorName) {
        case "reel":
        case "pressure":
        case "wheel":
        case "flow": {
          const defaultSensorStates = {
            flow: {
              current: "FLN",
              previous: "FLF",
            },
            pressure: {
              current: "PHI",
              previous: "PLO",
            },
            reel: {
              current: "RS",
              previous: "RR",
            },
            wheel: {
              current: "WS",
              previous: "WL",
            },
          } as const
          const { current, previous } = defaultSensorStates[sensorName]
          state.formData.sourceSensorStatePrevious = previous
          state.formData.sourceSensorStateCurrent = current

          break
        }
        default: {
          // By default, we will use the first and second state for the sensor
          if (sensorName) {
            const states = Object.values(SENSOR_NAME_TO_STATES[sensorName])
            state.formData.sourceSensorStatePrevious = states[0] ?? ""
            state.formData.sourceSensorStateCurrent = states[1] ?? ""
          }
          break
        }
      }
    },
    setSourceSensorStateCurrent: (
      state,
      action: PayloadAction<SensorState | "">,
    ) => {
      state.formData.sourceSensorStateCurrent = action.payload
    },
    setSourceSensorStatePrevious: (
      state,
      action: PayloadAction<SensorState | "">,
    ) => {
      state.formData.sourceSensorStatePrevious = action.payload
    },
    setTargetActionId: (state, action: PayloadAction<number | null>) => {
      state.formData.targetActionId = action.payload
    },
    setTargetDevice: (state, action: PayloadAction<string>) => {
      state.actionOptions = undefined
      state.formData.targetDeviceId = action.payload
    },
    showErrors: (state) => {
      state.showErrors = true
    },
  },
})

export const createDeviceConnectionReducer: Reducer<CreateDeviceConnectionState> =
  slice.reducer

const {
  setSourceDevice,
  setSourceSensor,
  setSourceSensorStateCurrent,
  setSourceSensorStatePrevious,
  setTargetActionId,
  setTargetDevice,
  showErrors,
} = slice.actions
export const { resetCreateConnectionsForm } = slice.actions

export function onChangeSourceDevice(deviceId: string) {
  return (dispatch: RootDispatch, getState: GetRootState) => {
    const state = getState()
    dispatch(setSourceDevice(deviceId))

    const config = Models.deviceConfiguration.selectById(state, deviceId)
    if (config) {
      // const sensors = listConfigSensors(config)
      switch (config.deviceInstallationType) {
        case "prototype":
        case "reel_with_booster_off_only":
        case "reel": {
          dispatch(setSourceSensor("reel"))
          break
        }
        case "traveller_soft":
        case "linear_move":
        case "center_pivot": {
          // TODO: Update these to use activity state
          dispatch(setSourceSensor("wheel"))
          break
        }
        case "pump_off_only":
        case "pump_on_off":
        case "pump_vfd":
        case "pump": {
          dispatch(setSourceSensor("pressure"))
          break
        }

        case "valve": {
          dispatch(setSourceSensor("flow"))
          break
        }

        case "unconfigured": {
          dispatch(setSourceSensor(""))
          dispatch(setSourceSensorStatePrevious(""))
          dispatch(setSourceSensorStateCurrent(""))

          //
          break
        }
      }
    }
  }
}

/**
 * Validate current form data for given key
 */
function useErrorMessage(key: keyof Errors) {
  return useRootSelector((state) => {
    if (state.createConnection.showErrors) {
      return validate(state)[key]
    }
    return undefined
  })
}

/**
 * When the user selects a target device, we need to load the actions for that
 * device in the background and pick a default action if possible.
 */
export const onChangeTargetDevice = createAsyncThunk<
  void,
  string,
  RootThunkConfig
>(
  "createConnection/onChangeTargetDevice",
  async (deviceId: string, { dispatch }) => {
    dispatch(setTargetDevice(deviceId))
    let actions: Models.NamedDeviceAction[] = []

    // Load the actions for the device
    try {
      const response = await dispatch(
        loadDeviceActionsAsync({ deviceId }),
      ).unwrap()
      actions = response.items
    } catch (error) {
      captureException(error)
    }

    // Find the best action
    const defaultAction =
      actions.find((action) => action.expectedOutcome === "dry") ?? actions[0]
    if (defaultAction) {
      dispatch(setTargetActionId(defaultAction.id))
    }
  },
)

/**
 * This component is used to select the source device
 */
export function SourceDeviceSelect(props: FormControlProviderProps) {
  const selectedValue = useRootSelector(
    (state) => state.createConnection.formData.sourceDeviceId,
  )
  const dispatch = useRootDispatch()
  const options = useRootSelector((state) => {
    return Models.deviceConfiguration.selectAll(state).map((config) => {
      const deviceName = config.deviceName ?? config.codaDeviceAlias
      const installationType = config.deviceInstallationType
      return {
        label: `${deviceName} (${installationType})`,
        value: config.deviceId,
      }
    })
  })
  const errorMessage = useErrorMessage("sourceDeviceId")

  const labelText: string = i18n.t(
    "createDeviceConnection:sourceDeviceSelectLabel",
  )
  return (
    <FormControl.Provider isInvalid={Boolean(errorMessage)} {...props}>
      <FormControl.Label>{labelText}</FormControl.Label>
      <FormControl.Select
        label={labelText}
        options={options}
        selectedValue={selectedValue}
        placeholder={i18n.t(
          "createDeviceConnection:sourceDeviceSelectPlaceholder",
        )}
        onFocusWhenEmptyNative={() => {
          // eslint-disable-next-line no-alert
          alert(
            i18n.t("createDeviceConnection:noSuitableSourceDevicesAvailable"),
          )
        }}
        onValueChange={(nextValue) => {
          dispatch(onChangeSourceDevice(nextValue))
        }}
      />
      <FormControl.ErrorMessage>{errorMessage}</FormControl.ErrorMessage>
    </FormControl.Provider>
  )
}

/**
 * This component is used to select  the source sensor
 */
export function SourceSensorSelect(props: FormControlProviderProps) {
  const selectedValue = useRootSelector(
    (state) => state.createConnection.formData.sourceSensor,
  )

  const dispatch = useRootDispatch()
  const options = useRootSelector((state) => {
    const deviceId = state.createConnection.formData.sourceDeviceId
    const config = Models.deviceConfiguration.selectById(state, deviceId)
    if (config) {
      return listConfigSensors(config).map((sensorName) => {
        return {
          label: formatSensorName(sensorName),
          value: sensorName,
        }
      })
    }
    return []
  })
  const errorMessage = useErrorMessage("sourceSensor")
  const isDisabled = useRootSelector(
    (state) => !state.createConnection.formData.sourceDeviceId,
  )
  const labelText: string = i18n.t(
    "createDeviceConnection:sourceSensorSelectLabel",
  )

  const placeholderText: string = i18n.t(
    "createDeviceConnection:sourceSensorSelectPlaceholder",
  )
  return (
    <FormControl.Provider
      isDisabled={isDisabled}
      isInvalid={Boolean(errorMessage)}
      {...props}
    >
      <FormControl.Label>{labelText}</FormControl.Label>
      <FormControl.Select
        label={labelText}
        options={options}
        placeholder={placeholderText}
        selectedValue={selectedValue}
        onValueChange={(nextValue) => {
          dispatch(setSourceSensor(nextValue as SensorName))
        }}
      />
      <FormControl.ErrorMessage>{errorMessage}</FormControl.ErrorMessage>
    </FormControl.Provider>
  )
}

/**
 * This component is used to select the previous state of the source sensor
 */
export function SourceSenseorStatePreviousSelect(
  props: FormControlProviderProps,
) {
  const selectedValue = useRootSelector(
    (state) => state.createConnection.formData.sourceSensorStatePrevious,
  )
  const dispatch = useRootDispatch()
  const options = useRootSelector((state) => {
    const sensorName = state.createConnection.formData.sourceSensor
    if (sensorName) {
      const states = SENSOR_NAME_TO_STATES[sensorName]
      return Object.values(states).map((sensorState) => {
        return {
          label: i18n.t(`${sensorState}.long`, { ns: "sensorStates" }),
          value: sensorState,
        }
      })
    }
    return []
  })
  const isDisabled = useRootSelector(
    (state) => !state.createConnection.formData.sourceSensor,
  )
  const errorMessage = useErrorMessage("sourceSensorStatePrevious")

  const labelText: string = i18n.t(
    "createDeviceConnection:sourceSensorStatePreviousSelectLabel",
  )
  return (
    <FormControl.Provider
      isDisabled={isDisabled}
      isInvalid={Boolean(errorMessage)}
      {...props}
    >
      <FormControl.Label>{labelText}</FormControl.Label>
      <FormControl.Select
        label={labelText}
        options={options}
        selectedValue={selectedValue}
        placeholder={i18n.t(
          "createDeviceConnection:sourceSensorStatePreviousSelectPlaceholder",
        )}
        onValueChange={(nextValue) => {
          dispatch(setSourceSensorStatePrevious(nextValue as SensorState))
        }}
      />
      <FormControl.ErrorMessage>{errorMessage}</FormControl.ErrorMessage>
    </FormControl.Provider>
  )
}

/**
 * This component is used to select the current state of the source sensor
 */
export function SourceSensorStateCurrentSelect(
  props: FormControlProviderProps,
) {
  const dispatch = useRootDispatch()

  const selectedValue = useRootSelector(
    (state) => state.createConnection.formData.sourceSensorStateCurrent,
  )

  const options = useRootSelector((state) => {
    const sensorName = state.createConnection.formData.sourceSensor
    if (sensorName) {
      const states = SENSOR_NAME_TO_STATES[sensorName]
      return Object.values(states).map((sensorState) => {
        return {
          label: formatSensorState(sensorState, { labelLength: "long" }),
          value: sensorState,
        }
      })
    }
    return []
  })
  const isDisabled = useRootSelector(
    (state) => !state.createConnection.formData.sourceSensor,
  )
  const errorMessage = useErrorMessage("sourceSensorStateCurrent")

  const labelText: string = i18n.t(
    "createDeviceConnection:sourceSensorStateCurrentSelectLabel",
  )
  return (
    <FormControl.Provider
      isDisabled={isDisabled}
      isInvalid={Boolean(errorMessage)}
      {...props}
    >
      <FormControl.Label>{labelText}</FormControl.Label>
      <FormControl.Select
        label={labelText}
        options={options}
        selectedValue={selectedValue}
        placeholder={i18n.t(
          "createDeviceConnection:sourceSensorStateCurrentSelectPlaceholder",
        )}
        onValueChange={(nextValue) => {
          dispatch(setSourceSensorStateCurrent(nextValue as SensorState))
        }}
      />
      <FormControl.ErrorMessage>{errorMessage}</FormControl.ErrorMessage>
    </FormControl.Provider>
  )
}

/**
 * This component is used to select the target device
 */
export function TargetDeviceSelect(props: FormControlProviderProps) {
  const selectedValue = useRootSelector(
    (state) => state.createConnection.formData.targetDeviceId,
  )
  const dispatch = useRootDispatch()

  const options = useRootSelector((state) => {
    return Models.deviceConfiguration.selectAll(state).map((config) => {
      const deviceName = config.deviceName ?? config.codaDeviceAlias
      const installationType = config.deviceInstallationType
      const installationTypeFormatted = formatInstallationType(installationType)

      return {
        label: `${deviceName} (${installationTypeFormatted})`,
        value: config.deviceId,
      }
    })
  })

  const errorMessage = useErrorMessage("targetDeviceId")

  const labelText: string = i18n.t(
    "createDeviceConnection:targetDeviceSelectLabel",
  )
  const placeholderText: string = i18n.t(
    "createDeviceConnection:targetDeviceSelectPlaceholder",
  )
  return (
    <FormControl.Provider isInvalid={Boolean(errorMessage)} {...props}>
      <FormControl.Label>{labelText}</FormControl.Label>
      <FormControl.Select
        label={labelText}
        options={options}
        placeholder={placeholderText}
        selectedValue={selectedValue}
        onFocusWhenEmptyNative={() => {
          // eslint-disable-next-line no-alert
          alert(
            i18n.t("createDeviceConnection:noSuitableTargetDevicesAvailable"),
          )
        }}
        onValueChange={(nextValue) => {
          // eslint-disable-next-line @typescript-eslint/no-floating-promises
          dispatch(onChangeTargetDevice(nextValue))
        }}
      />
      <FormControl.ErrorMessage>{errorMessage}</FormControl.ErrorMessage>
    </FormControl.Provider>
  )
}

/**
 * This component is used to select the target action
 */
export function TargetActionSelect(props: FormControlProviderProps) {
  const formatDisplayName = useFormatDeviceActionDisplayName()
  const dispatch = useRootDispatch()
  const options = useRootSelector((state) => {
    return (state.createConnection.actionOptions ?? []).map((action) => {
      return {
        label: formatDisplayName(action),
        value: `${action.id}`,
      }
    })
  })
  const errorMessage = useErrorMessage("targetActionId")
  const selectedValue = useRootSelector(
    (state) => state.createConnection.formData.targetActionId,
  )
  const labelText: string = i18n.t(
    "createDeviceConnection:targetActionSelectLabel",
  )
  const placeholderText: string = i18n.t(
    "createDeviceConnection:targetActionSelectPlaceholder",
  )
  return (
    <FormControl.Provider {...props} isInvalid={Boolean(errorMessage)}>
      <FormControl.Label>{labelText}</FormControl.Label>
      <FormControl.Select
        label={labelText}
        options={options}
        placeholder={placeholderText}
        selectedValue={`${selectedValue ?? ""}`}
        onFocusWhenEmptyNative={() => {
          const titleText = i18n.t("createDeviceConnection:noActionsAvailable")
          const messageText = i18n.t(
            "createDeviceConnection:noActionsAvailableForDevice",
          )
          Alert.alert(titleText, messageText)
        }}
        onValueChange={(nextValue) => {
          const asNumber = Number.parseInt(nextValue, 10)
          if (isValidNumber(asNumber)) {
            dispatch(setTargetActionId(asNumber))
          } else {
            dispatch(setTargetActionId(null))
          }
        }}
      />
      <FormControl.ErrorMessage>{errorMessage}</FormControl.ErrorMessage>
    </FormControl.Provider>
  )
}

/**
 * Errors that are not associated with any specific field
 */
export function FormErrorMessage() {
  const alreadyCreated = useRootSelector(getConnectionAlreadyCreated)

  if (alreadyCreated) {
    return (
      <FormControl.ErrorMessage isInvalid>
        {i18n.t("createDeviceConnection:errorMessageAlreadyCreated")}
      </FormControl.ErrorMessage>
    )
  }
  return null
}

export function SubmitButton({ onSuccess }: { onSuccess: () => void }) {
  const formData = useRootSelector((state) => state.createConnection.formData)
  const dispatch = useRootDispatch()
  const { handleError, isLoading, sendRequest, toasts } = useBackendRequest(
    createCustomAutomationAsync,
  )

  const errors = useRootSelector((state) => validate(state))
  const handleSubmit = () => {
    if (Object.values(errors).length > 0) {
      return dispatch(showErrors())
    }

    if (!formData.sourceSensor) {
      throw new TypeError("sourceSensor is required")
    }
    if (!formData.sourceSensorStateCurrent) {
      throw new TypeError("sourceSensorStateCurrent is required")
    }
    if (!formData.sourceSensorStatePrevious) {
      throw new TypeError("sourceSensorStatePrevious is required")
    }
    if (typeof formData.targetActionId !== "number") {
      throw new TypeError("targetActionId is required")
    }
    return sendRequest({
      namedDeviceActionId: formData.targetActionId,
      notify: false,
      sourceDeviceId: formData.sourceDeviceId,
      sourceSensor: formData.sourceSensor,
      sourceSensorStateCurrent: formData.sourceSensorStateCurrent,
      sourceSensorStatePrevious: formData.sourceSensorStatePrevious,
      targetDeviceId: formData.targetDeviceId,
    })
      .then(() => {
        toasts.success()
        return onSuccess()
      })
      .catch((error) => {
        handleError(error, {
          toastMessage: "default",
        })
      })
  }
  return (
    <Button
      isLoading={isLoading}
      text={i18n.t("submit")}
      variant="primary"
      onPress={handleSubmit}
    />
  )
}

export function isCreatingDevicePair(state: RootState) {
  const {
    sourceSensor,
    sourceSensorStateCurrent,
    sourceSensorStatePrevious,
    targetActionId,
  } = state.createConnection.formData
  if (typeof targetActionId === "number") {
    const targetAction = state.namedDeviceActions.entities[targetActionId]
    if (
      sourceSensor === "reel" &&
      sourceSensorStateCurrent === "RS" &&
      sourceSensorStatePrevious === "RR" &&
      targetAction?.expectedOutcome === "dry"
    ) {
      return true
    }
  }
  return false
}

export function CreatingDevicePairAlert() {
  const bodyText = i18n.t("deviceConnections:commonConnectionDevicePair")
  return (
    <AlertCard
      IconComponent="Success"
      bodyText={bodyText}
      mt="$4"
      severity="success"
      titleText="Popular"
    />
  )
}

/**
 * This component is used to pre-select the device in the form if provided
 */
export function useInitiatorDevice({
  initiatorDeviceId,
}: {
  initiatorDeviceId?: string
}) {
  const dispatch = useRootDispatch()
  /**
   * If an initiatorDeviceId is provided, we can determine whether it is
   * most likely a source or target device based on the device configuration
   */
  const initiatorRole = useRootSelector((state) => {
    const config = Models.deviceConfiguration.selectById(
      state,
      initiatorDeviceId,
    )
    if (config) {
      if (isValidConnectionTarget(config)) {
        return "target"
      }
      return "source"
    }
    return undefined
  })

  /**
   * If an initiatorDeviceId is provided, we can pre-select the device in the
   * form
   */
  React.useEffect(() => {
    if (typeof initiatorDeviceId === "string") {
      if (initiatorRole === "source") {
        dispatch(onChangeSourceDevice(initiatorDeviceId))
      } else {
        void dispatch(onChangeTargetDevice(initiatorDeviceId))
      }
    }
  }, [dispatch, initiatorDeviceId, initiatorRole])
}

export function Actions({ onClose }: { onClose: () => void }) {
  const formData = useRootSelector((state) => state.createConnection.formData)
  const dispatch = useRootDispatch()
  const { handleError, sendRequest, toasts } = useBackendRequest(
    createCustomAutomationAsync,
  )

  const errors = useRootSelector((state) => validate(state))
  const handleSubmit = () => {
    if (Object.values(errors).length > 0) {
      return dispatch(showErrors())
    }

    if (!formData.sourceSensor) {
      throw new TypeError("sourceSensor is required")
    }
    if (!formData.sourceSensorStateCurrent) {
      throw new TypeError("sourceSensorStateCurrent is required")
    }
    if (!formData.sourceSensorStatePrevious) {
      throw new TypeError("sourceSensorStatePrevious is required")
    }
    if (typeof formData.targetActionId !== "number") {
      throw new TypeError("targetActionId is required")
    }
    return sendRequest({
      namedDeviceActionId: formData.targetActionId,
      notify: false,
      sourceDeviceId: formData.sourceDeviceId,
      sourceSensor: formData.sourceSensor,
      sourceSensorStateCurrent: formData.sourceSensorStateCurrent,
      sourceSensorStatePrevious: formData.sourceSensorStatePrevious,
      targetDeviceId: formData.targetDeviceId,
    })
      .then(() => {
        toasts.success()
        return onClose()
      })
      .catch((error) => {
        handleError(error, {
          toastMessage: "default",
        })
      })
  }
  const isLoading = useIsPending("CreateCustomAutomation")

  return (
    <ActionButtons
      isLoading={isLoading}
      mt="$4"
      onPressCancel={onClose}
      onPressSubmit={handleSubmit}
    />
  )
}
