import React from "react"

import { createSelector } from "@reduxjs/toolkit"

import { trackEvent } from "./Analytics"
import { Box, CycleButton, Row } from "./components"
import { useEnvironmentVariables } from "./Environment"
import * as Models from "./models"
import {
  getDeviceSummaryByAliasFromState,
  getDeviceSummaryByDeviceIdFromState,
} from "./selectors"
import { isTruthyString } from "./type-guards"
import { getActiveFarmIdFromState } from "./user-farms.selectors"
import { useRootSelector } from "./useRootSelector"

import type { IconButtonStyleProps } from "./components"
import type { TestId } from "./components/test-id"
import type {
  AnalyticsProps,
  CodaDeviceAliasProps,
  DeviceIdProps,
  MutuallyExclude,
  SelectDeviceHandler,
} from "./types"
export type CycleDirection = "left" | "right"

const getAdjacentDeviceIdsFromState = createSelector(
  [
    (_state, deviceId: string) => deviceId,
    Models.deviceConfiguration.selectIds,
  ],
  (deviceId, ids) => {
    const index = ids.indexOf(deviceId)
    if (typeof index === "number" && index >= 0) {
      let nextIndex = index + 1
      if (nextIndex > ids.length - 1) {
        nextIndex = 0
      }

      let previousIndex = index - 1
      if (previousIndex < 0) {
        previousIndex = ids.length - 1
      }

      return {
        nextDeviceId: ids[nextIndex],
        previousId: ids[previousIndex],
      }
    }
    return undefined
  },
)

function DeviceCyclerButton({
  analyticsClient,
  deviceId,
  direction,

  onPress,
  ...rest
}: AnalyticsProps &
  DeviceIdProps &
  IconButtonStyleProps & {
    direction: CycleDirection
    onPress: SelectDeviceHandler
    id?: TestId
  }): React.JSX.Element | null {
  const environmentInfo = useEnvironmentVariables()
  const numDevices = useRootSelector(Models.deviceConfiguration.selectTotal)
  const isRight = direction === "right"
  const activeFarmId = useRootSelector(getActiveFarmIdFromState)
  const nextDevice = useRootSelector((state) => {
    const adjacentIds = getAdjacentDeviceIdsFromState(state, deviceId)
    const nextId = isRight ? adjacentIds?.nextDeviceId : adjacentIds?.previousId
    if (typeof nextId === "string") {
      return getDeviceSummaryByDeviceIdFromState(state, nextId)
    }
    return undefined
  })

  if (numDevices > 1 && nextDevice) {
    return (
      <CycleButton
        {...rest}
        IconComponent={direction === "left" ? "ArrowLeft" : "ArrowRight"}
        onPress={() => {
          onPress({
            codaDeviceAlias: nextDevice.codaDeviceAlias,
            deviceId: nextDevice.deviceId,
            gpsLocation: nextDevice.gpsLocation,
          })
          trackEvent(analyticsClient, {
            activeFarmId,
            elementName: "device-cycler",
            environmentInfo,
            name: "element_press",
          })
        }}
      />
    )
  }
  return null
}

interface DeviceCyclerProps extends AnalyticsProps {
  onPress: SelectDeviceHandler
  _buttons?: IconButtonStyleProps
  _left?: IconButtonStyleProps
  _right?: IconButtonStyleProps
  isDisabled?: boolean
}

/**
 * Allows user to navigate cyclically through their devices
 */
export function DeviceCycler({
  _buttons,
  _left,
  _right,
  analyticsClient,
  codaDeviceAlias,

  isDisabled,
  onPress,
  ...rest
}: DeviceCyclerProps &
  MutuallyExclude<
    DeviceIdProps,
    CodaDeviceAliasProps
  >): React.JSX.Element | null {
  const numDevices = useRootSelector(Models.deviceConfiguration.selectTotal)
  // const {} =useEnvironmentVariables()
  const deviceId = useRootSelector((state) => {
    if (isTruthyString(rest.deviceId)) {
      return rest.deviceId
    }

    if (isTruthyString(codaDeviceAlias)) {
      return getDeviceSummaryByAliasFromState(state, {
        codaDeviceAlias,
      })?.deviceId
    }
    return undefined
  })

  if (isTruthyString(deviceId)) {
    const commonProps = {
      ..._buttons,
      analyticsClient,
      deviceId,
      isDisabled: numDevices === 0 || isDisabled,
      onPress,
    }
    return (
      <Row>
        <Box mr="$2">
          <DeviceCyclerButton
            {...commonProps}
            {..._left}
            deviceId={deviceId}
            direction="left"
            id="previous-device-btn"
          />
        </Box>
        <DeviceCyclerButton
          {...commonProps}
          {..._right}
          deviceId={deviceId}
          direction="right"
          id="next-device-btn"
        />
      </Row>
    )
  }
  return null
}
