import React from "react"
import { StyleSheet } from "react-native"

import {
  AppIcons,
  AppText,
  Box,
  ICON_SIZES,
  IconButton,
  Row,
  Switch,
} from "./components"
import { COLORS, SPACING } from "./components/theme"
import i18n from "./translations/i18n"

import type { RowProps } from "./components"

import type { FarmUserPermissionName } from "./permissions"

const styles = StyleSheet.create({
  text: {
    marginHorizontal: SPACING.$2,
  },
  textWithIconContainer: {
    maxWidth: 425,
  },
})

/**
 * Display name of the permission along with its status (enabled vs. disabled).
 * Passing onValueChange handler will replace the status display with a switch
 * to toggle it.
 */
export function SetPermissionListItem({
  isEnabled,
  name,
  onPressHelp,
  onValueChange,
  ...rest
}: RowProps & {
  isEnabled: boolean
  name: FarmUserPermissionName
  onValueChange: (next: boolean) => void
  onPressHelp?: (name: FarmUserPermissionName) => void
}) {
  return (
    <Row key={name} id={name} justifyContent="space-between" py="$1" {...rest}>
      <Row
        flexGrow={1}
        flexWrap="nowrap"
        my="$1"
        style={styles.textWithIconContainer}
      >
        {onPressHelp ? (
          <IconButton
            IconComponent="Help"
            size="sm"
            variant="text"
            onPress={() => onPressHelp(name)}
          />
        ) : null}
        <AppText style={styles.text}>
          {i18n.t(`farmUserPermissions:${name}.displayName`)}
        </AppText>
      </Row>
      <Box ml="auto" my="$1">
        <Switch value={isEnabled} onValueChange={onValueChange} />
      </Box>
    </Row>
  )
}

export function ViewPermissionListItem({
  helpElement,
  isEnabled,
  name,
  ...rest
}: RowProps & {
  isEnabled: boolean
  name: FarmUserPermissionName
  helpElement?: React.JSX.Element
}) {
  let endElement: React.JSX.Element
  const size = ICON_SIZES.$3xl
  if (isEnabled) {
    endElement = <AppIcons.CheckMark color={COLORS.$success[500]} size={size} />
  } else {
    endElement = <AppIcons.Disabled size={size} />
  }
  return (
    <Row flexWrap="nowrap" id={name} {...rest}>
      {helpElement}
      <AppText flex={1} fontSize="$sm">
        {i18n.t(`farmUserPermissions:${name}.displayName`)}
      </AppText>
      <Box ml="auto">{endElement}</Box>
    </Row>
  )
}
