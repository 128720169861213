import React from "react"
import { FormProvider, useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { Platform } from "react-native"

import { ActionButtons } from "./ActionButtons"
import { CodeStringField } from "./Auth"
import { AlertCard, Box, SubmitButton } from "./components"
import {
  completeSmsVerificationAsync,
  generateSmsVerificationCodeAsync,
} from "./farmhq-api"
import i18n from "./translations/i18n"
import { useBackendRequest } from "./useBackendRequest"

import type { BoxProps, HelpContentStatic } from "./components"
export const HELP_CONTENT: HelpContentStatic = {
  bodyElement: i18n.t("phoneNumbers:verifyHelpBodyText"),
  subject: "verify_phone_number",
  titleElement: i18n.t("phoneNumbers:verifyHelpTitle"),
}
/**
 * Handler for user to send a verification code (after adding a phone number or
 * from the list of phone numbers)
 */
export function useGenerateSmsVerification({ upnId }: { upnId: number }) {
  const { t } = useTranslation()
  const { handleError, isLoading, sendRequest, toasts } = useBackendRequest(
    generateSmsVerificationCodeAsync,
  )

  const codeSentMessage = t("codeSent", { ns: "common" })
  const handleGenerateCode = React.useCallback(async () => {
    return sendRequest({ upnId }).then(() => {
      // setCodeSent(true)
      return toasts.info(codeSentMessage)
    })
  }, [codeSentMessage, sendRequest, toasts, upnId])
  return {
    handleError,
    handleGenerateCode,
    isCodeLoading: isLoading,
  }
}

/**
 * Allow user to verify the phone number using a one-time-code
 *
 * NOTE: this component does not automatically send the code.
 */

export function VerifyPhoneNumberForm({
  onClose,
  upnId,
  ...props
}: BoxProps & {
  onClose: (() => void) | null
  upnId: number
}) {
  const { t } = useTranslation("phoneNumbers")

  const form = useForm({ defaultValues: { codeString: "" } })

  const { handleError, isLoading, sendRequest, toasts } = useBackendRequest(
    completeSmsVerificationAsync,
  )
  const { handleGenerateCode, isCodeLoading } = useGenerateSmsVerification({
    upnId,
  })
  const handleSubmitCode = form.handleSubmit(({ codeString }) => {
    sendRequest({
      codeString: codeString.toLocaleUpperCase(),
      upnId,
    })
      .then((response) => {
        if (response.status === "INVALID") {
          // Show invalid code message
          return form.setError("codeString", {
            message: t("codeInvalid", { ns: "validation" }),
          })
        }
        // Success case
        toasts.success()
        if (Platform.OS !== "web" && onClose) {
          onClose()
        }
        return response
      })
      .catch((error) => {
        handleError(error, { toastMessage: "default" })
      })
  })
  return (
    // CodeStringField requires control available in context
    <FormProvider {...form}>
      <Box id="verify-phone-number" {...props}>
        <AlertCard bodyText={t("verifyInstructions", { ns: "phoneNumbers" })} />
        <Box my="$4">
          <CodeStringField
            label={t("otpFieldLabel", { ns: "common" })}
            _button={{
              IconComponent: "Refresh",
              isDisabled: isLoading,
              isLoading: isCodeLoading,
              // mt: "1",
              onPress: handleGenerateCode,
            }}
            _input={{
              style: {
                minWidth: 160,
              },
            }}
          />
        </Box>
        <Box mt="$4">
          {onClose ? (
            <ActionButtons
              isDisabled={isCodeLoading}
              isLoading={isLoading}
              orientation="vertical"
              onPressCancel={onClose}
              onPressSubmit={handleSubmitCode}
            />
          ) : (
            <SubmitButton
              isDisabled={isCodeLoading}
              isLoading={isLoading}
              onPress={handleSubmitCode}
            />
          )}
        </Box>
      </Box>
    </FormProvider>
  )
}
