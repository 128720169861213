import type { ValuesType } from "utility-types"

export const SupportProductIds = {
  FC2: "FC2",
  PC1: "PC1",
  SOLAR_PANEL: "solar-panel",
  TC3: "TC3",
} as const
export type SupportProductId = ValuesType<typeof SupportProductIds>

// TODO: Move to WebApp
export const SUPPORT_PAGE_DEVICES = [
  {
    id: "TC3",
    imageUrl: `tc3_mounted.jpg`,
    name: "TC-3 Device (2022)",
  },
  {
    id: "FC2",
    imageUrl: `fc2Pro.png`,
    name: "FC-2 Device (2021-2022)",
  },
  {
    id: "solar-panel",
    imageUrl: `solar-panel.png`,
    name: "Solar Panel",
  },
  {
    id: "PC1",
    imageUrl: `pc_1_mounted.png`,
    name: "Legacy Products (PC-1 Devices)",
  },
] as const

export const SUPPORT_PAGE_ID = {
  deviceOverview: "device-overview",
  electricPump: "electric-pump-installation",
  flowmeter: "flowmeter",
  inTheBox: "in-the-box",
  pumpHardwareInstallation: "pump-hardware-installation",
  reelHardwareInstallation: "reel-hardware-installation",
  troubleshooting: "troubleshooting",
} as const

export type SupportPageId = ValuesType<typeof SUPPORT_PAGE_ID> | "print-all"
