import React from "react"
import { Platform } from "react-native"

import { ActionButtons } from "./ActionButtons"
import {
  AlertDialog,
  AlertDialogBody,
  AppIcons,
  AppText,
  Box,
  Divider,
  FlatList,
  IconButton,
  Paper,
  Row,
  TextWithIcon,
} from "./components"
import { SPACING, Theme } from "./components/theme"
import { CreateButton } from "./CreateCustomNotification"
import { DeviceNotificationsToggleSwitch } from "./DeviceNotificationsToggleSwitch"
import * as DeviceSummaryContext from "./DeviceSummaryContext"
import { deleteEventActionTriggersAsync } from "./farmhq-api"
import * as Models from "./models"
import { NoListItems } from "./NoListItems"
import { isCustomNotificationTrigger } from "./selectors"
import { formatSensorName, formatSensorState } from "./sensor-formatting"
import i18n from "./translations/i18n"
import { useBackendRequest } from "./useBackendRequest"
import { useShallowEqualSelector } from "./useRootSelector"

import type { HelpContentStatic } from "./components"
import type { ToggleSwitchProps } from "./DeviceNotificationsToggleSwitch"
import type { PermissionCheckProps } from "./types"

import type { EventActionTrigger } from "./models"

export const HELP_CONTENT: HelpContentStatic = {
  bodyElement: i18n.t("deviceNotifications:createNotificationHelpBodyText"),
  subject: "device_notifications",
  titleElement: i18n.t("deviceNotifications:aboutCustomNotifications"),
}

/**
 * Custom notification list item
 */
function RenderItem({
  deleteButtonElement,
  ...props
}: PermissionCheckProps &
  Pick<
    EventActionTrigger,
    | "id"
    | "sourceSensor"
    | "sourceSensorStateCurrent"
    | "sourceSensorStatePrevious"
  > & {
    deleteButtonElement?: React.JSX.Element
  }) {
  return (
    <Row key={props.id} alignItems="flex-start" py="$2">
      <Box flex={1}>
        <AppText colorScheme="secondary" id="source-sensor">
          {`${formatSensorName(props.sourceSensor)} ${i18n.t(
            "sensor",
          )} ${i18n.t("deviceNotifications:changesFrom")}`}
        </AppText>
        <Box>
          <AppText id="source-sensor-state-previous">
            {formatSensorState(props.sourceSensorStatePrevious)}
          </AppText>
          <AppIcons.ArrowDown />
          <AppText id="source-sensor-state-current">
            {formatSensorState(props.sourceSensorStateCurrent)}
          </AppText>
        </Box>
      </Box>
      <Box ml="auto" mt="$2">
        {deleteButtonElement}
      </Box>
    </Row>
  )
}

/**
 * Confirmation dialog for deleting a custom notification
 */
function ConfirmDeleteDeviceNotificationDialogDialog({
  onCancelDelete,
  rowToDelete,
  withPermissions,
}: PermissionCheckProps & {
  onCancelDelete: () => void
  rowToDelete: EventActionTrigger
}): React.JSX.Element {
  const { deviceId } = DeviceSummaryContext.useContext()
  const { handleError, isLoading, sendRequest, toasts } = useBackendRequest(
    deleteEventActionTriggersAsync,
  )

  const handleSubmit = () => {
    sendRequest({
      deviceId,
      ids: [rowToDelete.id],
      kind: "notification",
    })
      .then(() => {
        onCancelDelete()
        return toasts.success()
      })
      .catch((error) => {
        handleError(error, { toastMessage: "default" })
      })
  }
  return (
    <AlertDialog
      isOpen
      id="confirm-delete-notification"
      titleElement={i18n.t("deviceNotifications:deleteThisRule")}
      actionElement={
        <ActionButtons
          buttonSize="sm"
          isLoading={isLoading}
          orientation="horizontal"
          onPressCancel={onCancelDelete}
          onPressSubmit={handleSubmit}
        />
      }
      onClose={onCancelDelete}
    >
      <AlertDialogBody>
        <RenderItem {...rowToDelete} withPermissions={withPermissions} />
      </AlertDialogBody>
    </AlertDialog>
  )
}

const ItemSeparatorComponent = () => <Divider />
interface Props extends ToggleSwitchProps, PermissionCheckProps {
  onPressCreate: () => void
}
/**
 * List of custom notifications for specified (in context) device
 */
export function DeviceNotificationsList({
  onDenied,
  onPressCreate,
  withPermissions,
}: Props): React.JSX.Element {
  const { deviceId } = DeviceSummaryContext.useContext()
  const [idToDelete, setIdToDelete] = React.useState<number>()

  const rows = useShallowEqualSelector((state) => {
    const allTriggers = Models.trigger.selectAll(state)
    return allTriggers.filter((trigger) => {
      return (
        trigger.sourceDeviceId === deviceId &&
        isCustomNotificationTrigger(trigger)
      )
    })
  })
  const rowToDelete = rows.find((row) => row.id === idToDelete)

  return (
    <React.Fragment>
      <FlatList
        ItemSeparatorComponent={ItemSeparatorComponent}
        data={rows}
        id="device-notifications-list"
        ListEmptyComponent={
          <Box px="$4">
            <NoListItems
              message={i18n.t(
                "deviceNotifications:noCustomNotificationRulesHaveBeenCreatedForThisDevice",
              )}
            />
          </Box>
        }
        ListHeaderComponent={
          <React.Fragment>
            {rowToDelete ? (
              <ConfirmDeleteDeviceNotificationDialogDialog
                rowToDelete={rowToDelete}
                withPermissions={withPermissions}
                onCancelDelete={() => setIdToDelete(undefined)}
              />
            ) : null}
            <AppText
              colorScheme="secondary"
              flex={1}
              style={{ paddingHorizontal: SPACING.$4 }}
            >
              {i18n.t("deviceNotifications:sendANotificationWhen")}
            </AppText>
          </React.Fragment>
        }
        renderItem={({ index, item }) => {
          const handlePressDelete = withPermissions({
            callback: () => setIdToDelete(item.id),
            required: "canManageDeviceNotifications",
          })
          return (
            <Paper
              key={item.id}
              id={`device-notifications-list-item-${index}`}
              px="$4"
              rounded={Platform.select({ web: "$default" })}
            >
              <RenderItem
                withPermissions={withPermissions}
                deleteButtonElement={
                  <IconButton
                    IconComponent="TrashCan"
                    id="delete-notification-btn"
                    variant="outline"
                    onPress={handlePressDelete}
                  />
                }
                {...item}
              />
            </Paper>
          )
        }}
      />
      <Paper
        pb="$2"
        pt="$2"
        px="$4"
        style={{
          borderTopColor: Theme.colors.$divider.light,
          borderTopWidth: 1,
        }}
      >
        <Row justifyContent="space-between">
          <Box mb="$2" mr="$4">
            <TextWithIcon
              IconComponent="NotificationsOn"
              text={i18n.t("deviceNotifications:notificationsEnabled")}
              actionElement={
                <DeviceNotificationsToggleSwitch
                  deviceId={deviceId}
                  onDenied={onDenied}
                />
              }
            />
          </Box>
          <Box ml="auto">
            <CreateButton
              withPermissions={withPermissions}
              onPress={onPressCreate}
            />
          </Box>
        </Row>
      </Paper>
    </React.Fragment>
  )
}
