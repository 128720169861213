import React from "react"
import { useTranslation } from "react-i18next"

import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogBodyText,
  LinkText,
  Pressable,
} from "./components"

export function DataCollectionNoticeDialog({
  bodyText,
  titleText,
}: {
  bodyText: string
  titleText: string
}) {
  const { t } = useTranslation()
  const [isDialogOpen, setIsDialogOpen] = React.useState(false)
  return (
    <React.Fragment>
      <AlertDialog
        isOpen={isDialogOpen}
        titleElement={titleText}
        onClose={() => setIsDialogOpen(false)}
      >
        <AlertDialogBody>
          <AlertDialogBodyText>{bodyText}</AlertDialogBodyText>
        </AlertDialogBody>
      </AlertDialog>
      <Pressable onPress={() => setIsDialogOpen(true)}>
        <LinkText fontSize="$sm">{t("whyDoWeCollectThis")}</LinkText>
      </Pressable>
    </React.Fragment>
  )
}
