import { useNavigate } from "react-router"

import { Forbidden, i18n, useRootSelector } from "@fhq/app"
import { Box, Button } from "@fhq/app/components"
import { getUserHasAllPermissions } from "@fhq/app/user-permissions.reducer"

import { PageContainer } from "./PageContainer"
import { PageHeader } from "./PageHeader"

import type { AcceptsChildren, BoxProps } from "@fhq/app/components"
import type { PageHeaderProps } from "./PageHeader"

import type { FarmUserPermissionName } from "@fhq/app"
export interface PageProps extends PageHeaderProps, AcceptsChildren, BoxProps {
  requiredPermissions?: FarmUserPermissionName | FarmUserPermissionName[] | null
}

function DeniedFallback() {
  const navigate = useNavigate()
  return (
    <Forbidden
      backButtonElement={
        <Button text={i18n.t("back")} onPress={() => navigate(-1)} />
      }
    />
  )
}

export function Page({
  IconComponent,
  children,
  helpContent,
  onClose,
  requiredPermissions,
  rightElement,
  subheaderElement,
  titleText,
  ...rest
}: PageProps): React.JSX.Element {
  const hasPermission = useRootSelector((state) => {
    return getUserHasAllPermissions(state, requiredPermissions)
  })

  if (!hasPermission) {
    return <DeniedFallback />
  }

  return (
    <PageContainer {...rest}>
      <Box pb="$2" pt="$2" px="$4">
        <PageHeader
          IconComponent={IconComponent}
          helpContent={helpContent}
          rightElement={rightElement}
          subheaderElement={subheaderElement}
          titleText={titleText}
          onClose={onClose}
        />
      </Box>
      {children}
    </PageContainer>
  )
}
