import type { PivotDirection } from "./constants"
import { PIVOT_DIRECTIONS } from "./constants"
import { makeValidator } from "./type-guards"

import type * as Models from "./models"
export interface PivotActivityStartAndEndValues {
  activityStartAzimuth: number
  currentDirection: PivotDirection
  mostRecentValidAzimuth: number
}

const isPivotDirection = makeValidator(PIVOT_DIRECTIONS)

/**
 * Given a device activity, return the azimuth values for the start and end of
 * the pivot activity, as well as the direction. This ensures that
 * the 'sector' we draw represents a continues movement in a single direction,
 * because currently, the pivot activity data can have direction changes.
 */
export function getSectorDrawingValuesForPivotActivity(
  activity: Pick<
    Models.DeviceActivity,
    "pivotHeadingsDegrees" | "travelerDirections"
  >,
): PivotActivityStartAndEndValues | undefined {
  let currentDirection: PivotDirection | undefined
  let mostRecentValidAzimuth: number | undefined
  let activityStartAzimuth: number | undefined
  let hasAzimuthChanged = false

  const azimuths = activity.pivotHeadingsDegrees ?? []
  const directions = activity.travelerDirections ?? []

  for (let index = azimuths.length - 1; index >= 0; index--) {
    const directionNext = directions[index]

    // If we have not yet determined the most recent direction, set it
    if (!currentDirection && isPivotDirection(directionNext)) {
      currentDirection = directionNext
    }

    // If the direction changes, stop iterating
    if (currentDirection && directionNext !== currentDirection) {
      break
    }

    // If we don't yet know the direction, don't collect azimuths
    if (!currentDirection) {
      continue
    }
    const azimuthNext = azimuths[index]

    // If the azimuth is null, keep looking
    if (typeof azimuthNext !== "number") {
      continue
    }

    // If we have not determined the azimuth at the 'end' of the activity set it
    if (typeof mostRecentValidAzimuth === "undefined") {
      mostRecentValidAzimuth = azimuthNext
    } else {
      if (azimuthNext !== mostRecentValidAzimuth) {
        hasAzimuthChanged = true
      }

      // If the pivot has just been sitting there, we don't want to
      // return it since it has not actually gone in a circle
      if (hasAzimuthChanged) {
        activityStartAzimuth = azimuthNext
      }
    }
  }

  // Check results to make sure they are all defined and valid
  if (
    typeof mostRecentValidAzimuth === "number" &&
    typeof activityStartAzimuth === "number" &&
    isPivotDirection(currentDirection)
  ) {
    return {
      activityStartAzimuth,
      currentDirection,
      mostRecentValidAzimuth,
    }
  }
  return undefined
}
