import React from "react"

import { AppText, Box, IconButton, Row } from "./components"
import { useClipboard } from "./components/useClipboard"
import * as DeviceSummaryContext from "./DeviceSummaryContext"
import { formatInstallationType } from "./sensor-configurations"
import i18n from "./translations/i18n"

import type { AppTextProps, RowProps, HelpContentStatic } from "./components"

export const HELP_CONTENT: HelpContentStatic = {
  bodyElement: i18n.t("deviceConfiguration:helpContentBodyText"),
  subject: "device_configuration",
  titleElement: i18n.t("deviceConfiguration:title"),
}
/**
 *
 */
function TextRow({
  action,
  fontSize,
  label,
  value,
  ...rest
}: RowProps & {
  label: string
  value: string
  action?: React.JSX.Element
  fontSize?: AppTextProps["fontSize"]
}): React.JSX.Element {
  const actionSize = "$12"
  return (
    <Row py="$1" {...rest} alignItems="center">
      <Box flex={1}>
        <AppText colorScheme="secondary" fontSize="$sm">
          {label}:
        </AppText>
        <AppText isTruncated flex={1} fontSize={fontSize}>
          {value}
        </AppText>
      </Box>
      {action ? (
        <Box h={actionSize} ml="auto">
          {action}
        </Box>
      ) : null}
    </Row>
  )
}

export function DeviceIdText() {
  const { onCopy } = useClipboard()
  const { deviceId } = DeviceSummaryContext.useContext()

  return (
    <TextRow
      fontSize="$xs"
      label={i18n.t("deviceId")}
      value={deviceId}
      action={
        <IconButton
          IconComponent="Clipboard"
          onPress={() => onCopy(deviceId)}
        />
      }
    />
  )
}

export function DeviceAliasText() {
  const { onCopy } = useClipboard()
  const { codaDeviceAlias } = DeviceSummaryContext.useContext()

  return (
    <TextRow
      label={i18n.t("codaDeviceAlias")}
      value={codaDeviceAlias}
      action={
        <IconButton
          IconComponent="Clipboard"
          onPress={() => onCopy(codaDeviceAlias)}
        />
      }
    />
  )
}

import type { PermissionCheckProps } from "./types"
export function InstallationTypeText({
  onPressCreateConfiguration,
  withPermissions,
}: PermissionCheckProps & {
  onPressCreateConfiguration: () => void
}) {
  const { deviceInstallationType } = DeviceSummaryContext.useContext()
  return (
    <TextRow
      label={i18n.t("deviceInstallationType")}
      value={formatInstallationType(deviceInstallationType)}
      action={
        <IconButton
          IconComponent="Edit"
          onPress={withPermissions({
            callback: onPressCreateConfiguration,
            required: "canManageDeviceConfiguration",
          })}
        />
      }
    />
  )
}

export function DeviceNameText({
  onPressRenameDevice,
  withPermissions,
}: PermissionCheckProps & {
  onPressRenameDevice: () => void
}) {
  const { deviceName } = DeviceSummaryContext.useContext()

  return (
    <TextRow
      label={i18n.t("deviceName")}
      value={deviceName}
      action={
        <IconButton
          IconComponent="Edit"
          id="rename-device-btn"
          onPress={withPermissions({
            callback: onPressRenameDevice,
            required: "canManageDeviceConfiguration",
          })}
        />
      }
    />
  )
}
