import React from "react"
import { Controller, FormProvider, useForm } from "react-hook-form"

import { ActionButtons } from "./ActionButtons"
import {
  AppText,
  Button,
  Column,
  Container,
  FormControl,
  Theme,
} from "./components"
import { FormError } from "./components/FormError"
import { testIds } from "./components/test-id"
import { useToasts } from "./components/useToasts"
import * as DeviceSummaryContext from "./DeviceSummaryContext"
import { createCustomNotificationAsync } from "./farmhq-api"
import { useFormValidation } from "./form-validation"
import * as Models from "./models"
import { isCustomNotificationTrigger } from "./selectors"
import { listConfigSensors } from "./sensor-configurations"
import { SENSOR_NAME_TO_STATES } from "./sensor-events"
import { formatSensorName } from "./sensor-formatting"
import i18n from "./translations/i18n"
import { useBackendRequest } from "./useBackendRequest"
import { useRootSelector } from "./useRootSelector"

import type { Path } from "react-hook-form"
import type { Overwrite } from "utility-types"
import type {
  AcceptsChildren,
  ButtonProps,
  HelpContentStatic,
} from "./components"
import type { SensorName } from "./sensor-configurations"
import type { SensorState } from "./sensor-events"

import type { GetActionArgumentsType } from "./send-request"
import type { PermissionCheckProps } from "./types"

export const HELP_CONTENT: HelpContentStatic = {
  bodyElement: i18n.t("deviceNotifications:createNotificationHelpBodyText"),
  subject: "create_custom_notification",
  titleElement: i18n.t("deviceNotifications:aboutCustomNotifications"),
}
interface ProviderProps {
  onClose: () => void
}

function useCreateCustomNotification({ onClose: onCancel }: ProviderProps) {
  const toast = useToasts()

  const { deviceId: sourceDeviceId, deviceInstallationType } =
    DeviceSummaryContext.useContext()

  // Get default values
  let sourceSensor: SensorName
  let sourceSensorStateCurrent: SensorState
  let sourceSensorStatePrevious: SensorState
  switch (deviceInstallationType) {
    case "traveller_soft":
    case "center_pivot":
    case "linear_move": {
      sourceSensor = "wheel"
      sourceSensorStateCurrent = "WF"
      sourceSensorStatePrevious = "WS"
      break
    }
    case "pump_off_only":
    case "pump_on_off":
    case "pump_vfd":
    case "pump": {
      sourceSensor = "pressure"
      sourceSensorStateCurrent = "PHI"
      sourceSensorStatePrevious = "PLO"
      break
    }
    case "reel_with_booster_off_only":
    case "reel": {
      sourceSensor = "reel"
      sourceSensorStateCurrent = "RR"
      sourceSensorStatePrevious = "RS"
      break
    }
    case "valve": {
      sourceSensor = "flow"
      sourceSensorStateCurrent = "FLF"
      sourceSensorStatePrevious = "FLN"
      break
    }
    case "prototype":
    case "unconfigured": {
      sourceSensor = "device"
      sourceSensorStateCurrent = "DVSW"
      sourceSensorStatePrevious = "DVS"
      break
    }
  }

  type Values = GetActionArgumentsType<"CreateCustomNotification">

  const form = useForm<Values>({
    defaultValues: {
      sourceDeviceId,
      sourceSensor,
      sourceSensorStateCurrent,
      sourceSensorStatePrevious,
    },
  })
  const { handleError, isLoading, sendRequest, ...rest } = useBackendRequest(
    createCustomNotificationAsync,
  )
  const statePrevious = form.watch("sourceSensorStatePrevious")
  const stateCurrent = form.watch("sourceSensorStateCurrent")
  const formErrorMessage = useRootSelector((state) => {
    const triggers = Models.trigger.selectAll(state)
    if (
      triggers.find((trigger) => {
        return (
          isCustomNotificationTrigger(trigger) &&
          trigger.sourceDeviceId === sourceDeviceId &&
          trigger.sourceSensorStateCurrent === stateCurrent &&
          trigger.sourceSensorStatePrevious === statePrevious
        )
      })
    ) {
      return i18n.t("deviceNotifications:errorMessageAlreadyCreated")
    }

    return undefined
  })
  const onSubmit = form.handleSubmit((values) => {
    sendRequest(values)
      .then((response) => {
        toast.success()
        onCancel()
        return response
      })
      .catch((error) => handleError(error, { toastMessage: "default" }))
  })
  const isSubmitDisabled = isLoading || Boolean(formErrorMessage)
  return {
    ...rest,
    form,
    formErrorMessage,
    isLoading,
    isSubmitDisabled,
    onCancel,
    onSubmit,
    sourceDeviceId,
  }
}

type ContextValue = ReturnType<typeof useCreateCustomNotification>

const Context = React.createContext<ContextValue | undefined>(undefined)

export function Provider({
  children,
  ...rest
}: AcceptsChildren & ProviderProps): React.JSX.Element | null {
  const value = useCreateCustomNotification(rest)
  return (
    <Context.Provider value={value}>
      <FormProvider {...value.form}>{children}</FormProvider>
    </Context.Provider>
  )
}

function useContext(): ContextValue {
  const ctx = React.useContext(Context)
  if (typeof ctx === "undefined") {
    throw new TypeError(
      `CreateCustomNotification Context must be used inside of provider`,
    )
  }
  return ctx
}

export function SourceSensorSelect(): React.JSX.Element {
  const { form, formErrorMessage, isLoading, sourceDeviceId } = useContext()
  const { required } = useFormValidation()

  const options = useRootSelector((state) => {
    const config = Models.deviceConfiguration.selectById(state, sourceDeviceId)

    const sensors: SensorName[] = config ? listConfigSensors(config) : []
    if (config) {
      sensors.push("device")
    }
    return sensors.map((sensorName) => {
      return {
        "data-testid": sensorName,
        "key": sensorName,
        "label": formatSensorName(sensorName),
        "value": sensorName,
        ...testIds(sensorName),
      }
    })
  })
  const labelText: string = i18n.t(
    "createDeviceConnection:sourceSensorSelectLabel",
  )
  return (
    <Controller
      control={form.control}
      name="sourceSensor"
      rules={{ required }}
      render={({ field, fieldState }) => {
        const errorMessage = fieldState.error?.message
        return (
          <FormControl.Provider
            id="source-sensor-select"
            isDisabled={isLoading || options.length === 0}
            isInvalid={Boolean(errorMessage) || Boolean(formErrorMessage)}
          >
            <FormControl.Label>{labelText}</FormControl.Label>
            <FormControl.Select
              label={labelText}
              selectedValue={field.value}
              options={options.map(({ key, ...option }) => {
                return { ...option, key }
              })}
              placeholder={i18n.t(
                "createDeviceConnection:sourceSensorSelectPlaceholder",
              )}
              onValueChange={(nextValue) => {
                field.onChange(nextValue)
                if (nextValue !== field.value) {
                  const [previous, current] = Object.values(
                    SENSOR_NAME_TO_STATES[nextValue as SensorName],
                  )
                  if (previous) {
                    form.setValue("sourceSensorStatePrevious", previous)
                  }
                  if (current) {
                    form.setValue("sourceSensorStateCurrent", current)
                  }
                }
              }}
            />
            <FormControl.ErrorMessage>{errorMessage}</FormControl.ErrorMessage>
          </FormControl.Provider>
        )
      }}
    />
  )
}

function useSensorStateOptions(sourceSensor: string) {
  const sensorName = sourceSensor as SensorName | undefined

  if (sensorName) {
    return Object.values(SENSOR_NAME_TO_STATES[sensorName]).map((value) => {
      return {
        id: value,
        label: i18n.t(`sensorStates:${value}.short`),
        value,
      }
    })
  }
  return []
}

export function StatePreviousSelect(): React.JSX.Element {
  const { form, formErrorMessage, isLoading } = useContext()
  const { required } = useFormValidation()

  const sourceSensor = form.watch("sourceSensor")
  const labelText: string = i18n.t(
    "createDeviceConnection:statePreviousSelectLabel",
  )

  const selectLabel = `${labelText} (${formatSensorName(sourceSensor)})`

  const options = useSensorStateOptions(sourceSensor).map(
    ({ id, label, value: key }) => {
      return { id, label, value: key }
    },
  )
  return (
    <Controller
      control={form.control}
      name="sourceSensorStatePrevious"
      rules={{ required }}
      render={({ field, fieldState }) => {
        const errorMessage = fieldState.error?.message
        return (
          <FormControl.Provider
            id="source-sensor-state-previous-select"
            isDisabled={isLoading}
            isInvalid={Boolean(errorMessage) || Boolean(formErrorMessage)}
          >
            <FormControl.Label>{labelText}</FormControl.Label>
            <FormControl.Select
              isDisabled={isLoading}
              label={selectLabel}
              options={options}
              selectedValue={field.value}
              onValueChange={field.onChange}
            />
            <FormControl.ErrorMessage>{errorMessage}</FormControl.ErrorMessage>
          </FormControl.Provider>
        )
      }}
    />
  )
}

export function StateCurrentSelect<
  T extends {
    sourceSensor: SensorName
    sourceSensorStateCurrent: SensorState
    sourceSensorStatePrevious: SensorState
  },
>(): React.JSX.Element {
  const { form, formErrorMessage, isLoading } = useContext()
  const { required } = useFormValidation()

  const sourceSensor = form.watch("sourceSensor")

  const labelText: string = i18n.t(
    "createDeviceConnection:stateCurrentSelectLabel",
  )
  const sensorNameFormatted = formatSensorName(sourceSensor)
  const selectLabel = `${labelText} (${sensorNameFormatted})`

  const options = useSensorStateOptions(sourceSensor)
  return (
    <Controller
      control={form.control}
      name={"sourceSensorStateCurrent" as Path<T>}
      render={({ field, fieldState }) => {
        const errorMessage = fieldState.error?.message
        const isInvalid = Boolean(errorMessage) || Boolean(formErrorMessage)
        return (
          <FormControl.Provider
            id="source-sensor-state-current-select"
            isDisabled={isLoading}
            isInvalid={isInvalid}
          >
            <FormControl.Label>{labelText}</FormControl.Label>
            <FormControl.Select
              label={selectLabel}
              options={options}
              selectedValue={field.value}
              onValueChange={field.onChange}
            />
            <FormControl.ErrorMessage>{errorMessage}</FormControl.ErrorMessage>
          </FormControl.Provider>
        )
      }}
      rules={{
        required,
        validate: (value) => {
          if (value === form.watch("sourceSensorStatePrevious")) {
            return i18n.t(
              "createDeviceConnection:errorMessageCurrentEqualsPrevious",
            )
          }
          return true
        },
      }}
    />
  )
}

export function Buttons(): React.JSX.Element {
  const { isLoading, isSubmitDisabled, onCancel, onSubmit } = useContext()
  return (
    <ActionButtons
      isLoading={isLoading}
      isSubmitDisabled={isSubmitDisabled}
      onPressCancel={onCancel}
      onPressSubmit={onSubmit}
    />
  )
}

export function ErrorText() {
  return <FormError>{useContext().formErrorMessage}</FormError>
}

export function Instructions() {
  return (
    <AppText style={{ marginBottom: Theme.sizes.$4 }}>
      {i18n.t(
        "deviceNotifications:sendANotificationToAllPhoneNumbersWhenThisStateChangeHappens",
      )}
    </AppText>
  )
}
export function Form(props: ProviderProps): React.JSX.Element {
  return (
    <Provider {...props}>
      <Column pb="$toolbar" space="$2">
        <AppText style={{ marginBottom: Theme.sizes.$4 }}>
          {i18n.t(
            "deviceNotifications:sendANotificationToAllPhoneNumbersWhenThisStateChangeHappens",
          )}
        </AppText>
        <SourceSensorSelect />
        <StatePreviousSelect />
        <StateCurrentSelect />
        <ErrorText />
      </Column>
      <Container pb="$4">
        <Buttons />
      </Container>
    </Provider>
  )
}

/**
 * Link button to create a custom notification.
 */
export function CreateButton({
  onPress,
  withPermissions,
  ...props
}: Overwrite<Omit<ButtonProps, "text">, { onPress: () => void }> &
  PermissionCheckProps): React.JSX.Element {
  return (
    <Button
      IconComponent="Add"
      id="create-custom-notification-btn"
      text={i18n.t("deviceNotifications:newRule")}
      variant="primary"
      onPress={withPermissions({
        callback: onPress,
        required: "canManageDeviceNotifications",
      })}
      {...props}
    />
  )
}
