import React from "react"
import { Pressable } from "react-native"

import {
  FIELD_COLOR_SCHEMES,
  setFieldColorPreference,
  setMapTypeId,
} from "./async-storage"
import { Avatar, Row } from "./components"
import { Box } from "./components/Box"
import { Paper } from "./components/Paper"
import { Radio } from "./components/Radio"
import { COLORS, RADII, SIZES } from "./components/theme"
import { FieldSvgConnected } from "./FieldSvg"
import { isValidMapTypeId, MapTypeIds } from "./geo"
import { logger } from "./logger"
import * as Models from "./models"
import { getMapTypeIdFromState } from "./selectors"
import { humanize } from "./string-formatting"
import { useFieldColors } from "./useFieldColors"
import { useRootDispatch } from "./useRootDispatch"
import { useRootSelector, useShallowEqualSelector } from "./useRootSelector"
import { objectEntries } from "./utility"

import type { FieldColorScheme } from "./async-storage"
export const PAIR_LINE_OPTIONS: google.maps.PolylineOptions = {
  // clickable: false,
  strokeColor: COLORS.$connectionLine,
  strokeWeight: 3,
}

export const MAP_SETTINGS_GEOMETRY = {
  center: { lat: 48.3425789, lng: -122.3469094 },
  fields: [
    {
      key: "1",
      path: [
        { lat: 48.3373755, lng: -122.342068 },
        { lat: 48.3355497, lng: -122.3424542 },
        { lat: 48.3354071, lng: -122.3409951 },
        { lat: 48.3349221, lng: -122.3409093 },
        { lat: 48.3348365, lng: -122.3358453 },
        { lat: 48.334808, lng: -122.3352874 },
        { lat: 48.337404, lng: -122.3355019 },
        { lat: 48.3374326, lng: -122.3365748 },
        { lat: 48.3375182, lng: -122.3415101 },
        { lat: 48.3373755, lng: -122.342068 },
      ],
    },
    {
      key: "2",
      path: [
        { lat: 48.3347509, lng: -122.3331845 },
        { lat: 48.3346368, lng: -122.3299229 },
        { lat: 48.3318979, lng: -122.3299229 },
        { lat: 48.3322403, lng: -122.3331845 },
        { lat: 48.3347509, lng: -122.3331845 },
      ],
    },
    {
      key: "3",
      path: [
        { lat: 48.3411981, lng: -122.3246014 },
        { lat: 48.3412551, lng: -122.3300946 },
        { lat: 48.3415404, lng: -122.3311246 },
        { lat: 48.343423, lng: -122.3349011 },
        { lat: 48.3447922, lng: -122.3350728 },
        { lat: 48.344621, lng: -122.3215974 },
        { lat: 48.3434801, lng: -122.3215974 },
        { lat: 48.3411981, lng: -122.3246014 },
      ],
    },
    {
      key: "4",
      path: [
        { lat: 48.3326165, lng: -122.3352815 },
        { lat: 48.3327306, lng: -122.3425771 },
        { lat: 48.3329018, lng: -122.3428775 },
        { lat: 48.3348704, lng: -122.3424483 },
        { lat: 48.3348133, lng: -122.3354102 },
        { lat: 48.3346136, lng: -122.3351527 },
        { lat: 48.3326165, lng: -122.3352815 },
      ],
    },
    {
      key: "5",
      path: [
        { lat: 48.329364, lng: -122.3352815 },
        { lat: 48.3294496, lng: -122.3430921 },
        { lat: 48.3326405, lng: -122.3427556 },
        { lat: 48.3326405, lng: -122.3351596 },
        { lat: 48.329364, lng: -122.3352815 },
      ],
    },
    {
      key: "6",
      path: [
        { lat: 48.3425789, lng: -122.3469094 },
        { lat: 48.346344, lng: -122.3467806 },
        { lat: 48.3463725, lng: -122.3531321 },
        { lat: 48.3455739, lng: -122.3529605 },
        { lat: 48.3456024, lng: -122.3522309 },
        { lat: 48.3450034, lng: -122.3519734 },
        { lat: 48.3449464, lng: -122.351158 },
        { lat: 48.3442333, lng: -122.3510293 },
        { lat: 48.3441763, lng: -122.3500422 },
        { lat: 48.3443474, lng: -122.3496131 },
        { lat: 48.3438625, lng: -122.3493985 },
        { lat: 48.3433205, lng: -122.3491839 },
        { lat: 48.3426645, lng: -122.3482398 },
        { lat: 48.3425789, lng: -122.3469094 },
      ],
    },
  ],
}

export function FieldColorSwatch({
  isSelected,
  size,
  value,
}: {
  isSelected: boolean
  value: FieldColorScheme
  size?: number
}): React.JSX.Element {
  const dispatch = useRootDispatch()
  const { svgColor } = useFieldColors(value)

  if (typeof size === "undefined") {
    size = SIZES.$8
  }

  return (
    <Pressable
      disabled={isSelected}
      style={{
        backgroundColor: isSelected ? COLORS.$info[200] : undefined,
        borderRadius: RADII.$default,
        height: size,
        marginHorizontal: SIZES.$1,
        marginVertical: SIZES.$1,
        padding: SIZES.$1,
        width: size,
        // shadowColor: isSelected ? COLORS.$info[500] : undefined,
        // shadowOpacity: isSelected ? 0.5 : undefined,
        // shadowRadius: isSelected ? 5 : undefined
      }}
      onPress={() => {
        dispatch(setFieldColorPreference(value)).catch((e) => {
          logger.error(e)
          throw e
        })
      }}
    >
      <Box key={value} bg={svgColor} flex={1} rounded="$default" />
    </Pressable>
  )
}
export function FieldColorPreference(): React.JSX.Element | null {
  const { scheme } = useFieldColors()
  const fieldData = useShallowEqualSelector(
    (state) => Models.field.selectAll(state)[0],
  )

  if (typeof fieldData === "undefined") {
    return null
  }

  return (
    <Paper>
      <Row alignItems="center" justifyContent="space-between">
        <Avatar>
          <FieldSvgConnected fieldId={fieldData.id} height={80} />
        </Avatar>
        <Row flex={1} style={{ marginLeft: "auto", maxWidth: 200 }}>
          {FIELD_COLOR_SCHEMES.map((value): React.JSX.Element => {
            return (
              <FieldColorSwatch
                key={value}
                isSelected={value === scheme}
                value={value}
              />
            )
          })}
        </Row>
      </Row>
    </Paper>
  )
}
export function MapTypeRadio(): React.JSX.Element {
  const mapTypeId = useRootSelector(getMapTypeIdFromState)

  const dispatch = useRootDispatch()
  const handleChange = (next: string) => {
    if (!isValidMapTypeId(next)) {
      throw new TypeError(`Invalid map type id`)
    }
    dispatch(setMapTypeId(next)).catch((e) => {
      logger.error(e)
      throw e
    })
  }
  const entries = objectEntries(MapTypeIds)
  return (
    <Radio
      orientation="horizontal"
      selectedValue={mapTypeId}
      options={entries.map(([value]) => {
        return {
          label: humanize(value, { textFormat: "titleCase" }),
          value,
        }
      })}
      onChange={handleChange}
    />
  )
}
